import usePayByCheckPopupForm from "./UsePayByCheckPopupForm";

import PopUpModal from "../../../dialogs/PopUpModal";
import SearchMemberPopup from '../../../searches/searchMember/SearchMemberPopup';
import SecondaryButton from "../../../buttons/SecondaryButton";
import PrimaryButton from "../../../buttons/PrimaryButton";

const PayByCheckPopupForm = ({ onSubmitFormCallback, onCloseModal }) => {
  const {
    formState,
    errorState,
    setFormData,
    onFormValueChanged,
    handleSubmit,
  } = usePayByCheckPopupForm(onSubmitFormCallback);

  return (
    <PopUpModal
      widthPct={90}
      maxWidth={720}
      title={'Provide a Member Id to Associate with the Check Payment'}
      displayPopUp={true}
      onModalCanceled={onCloseModal}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SearchMemberPopup
              label={'Member Id*'}
              memberIdName={'memberId'}
              formState={formState}
              errorState={errorState}
              setFormData={setFormData}
              onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onCloseModal}>Cancel</SecondaryButton>&nbsp;
            <PrimaryButton type="submit" onClick={handleSubmit}>Continue</PrimaryButton>
          </div>
        </div>
      </form>
    </PopUpModal>
  );
};

export default PayByCheckPopupForm;