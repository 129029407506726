import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import {VisuallyHidden} from '@reach/visually-hidden';

import useLeftNav from './UseLeftNav';

import Headings from '../../../common/components/headings/Headings';

import style from './LeftNav.module.css';
import { faAsterisk, faCheck, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';

const Li = ({ item, onClick }) => {
  if (item.isCurrent === true) {
    return (
      <li tabIndex="-1">
        <span className={style.Current}>
          <VisuallyHidden>This is the current step</VisuallyHidden>
          <FontAwesomeIcon icon={faAsterisk} />
          {item.displayName}
        </span>
      </li>
    );
  } else if (item.isComplete === true) {
    return (
      <li tabIndex="-1" onClick={() => { onClick(item.path) }}>
        <span className={style.Complete}>
          <VisuallyHidden>This step is complete</VisuallyHidden>
          <FontAwesomeIcon icon={faCheck} />
          {item.displayName}
        </span>
      </li>
    );
  } else if (item.isVisited === true && item.isComplete !== true) {
    return (
      <li tabIndex="-1" onClick={() => { onClick(item.path) }}>
        <span className={style.Incomplete}>
          <VisuallyHidden>This step is started, but not complete</VisuallyHidden>
          <FontAwesomeIcon icon={faQuestion} />
          {item.displayName}
        </span>
      </li>
    );
  } else if (item.isVisited !== true && item.arePrerequisitesDone === true) {
    return (
      <li tabIndex="-1" onClick={() => { onClick(item.path) }}>
        <span className={style.NotStarted}>
          <VisuallyHidden>This step is not started yet, but is currently available</VisuallyHidden>
          <FontAwesomeIcon icon={faCircle} className={style.InvisibleIcon} />
          {item.displayName}
        </span>
      </li>
    );
  } else {
    return (
      <li tabIndex="-1">
        <span className={style.NotAllowed}>
          <VisuallyHidden>This step is currently unavailable</VisuallyHidden>
          <div>
            <FontAwesomeIcon icon={faTimes} />
            {item.displayName}
          </div>
          <div className={style.NotAllowedNote}>(complete other steps first)</div>
        </span>
      </li>
    );
  }
};

const LeftNav = ({ title, state, formattedTitle, children, validateBeforeRedirect }) => {
  const { items, onClick } = useLeftNav(state);

  return (
    <div className="row">
      <div className="col-lg-3">
        <ul tabIndex="0" className={style.LeftNav}>
          {formattedTitle ? <li>{formattedTitle}</li>
            : title ? <li className={style.Title}><Headings.H5>{title}</Headings.H5></li> : <Fragment />}
          {items.map((item, i) => <Li key={i} item={item} onClick={(e) => onClick(e, item.path, validateBeforeRedirect)} />)}
        </ul>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
      </div>
      <div className="col-lg-9">
        {children}
      </div>

    </div>
  );
}

export default LeftNav;