import { useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useSearchParams } from '../../../../wrappers/ReactRouterDom';

import useShoppingCartLoadingModalData from '../../../../state/payments/paymentsShoppingCart/shoppingCartLoadingModal/UseShoppingCartLoadingModalData';
import useSecurityData from '../../../../state/security/UseSecurityData';

const INITIAL_STATE = {
  isLoading: false,
  errorMessages: []
};

const useStripeCheckoutForm = (orderId, swimmingWorldOptIn, onTransactionComplete, resultPromise) => {
  const stripe = useStripe();
  const elements = useElements();
  const { userInfo } = useSecurityData();
  const [searchParams,] = useSearchParams();
  const { setShoppingCartError } = useShoppingCartLoadingModalData();
  const [state, setState] = useState(INITIAL_STATE);

  const handleSubmit = async (event) => {
    event?.preventDefault();

    setState({
      ...state,
      isLoading: true
    });

    stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: getReturnUrl(orderId),
        payment_method_data: {
          billing_details: {
            name: `${userInfo?.memberId}`
          }
        }
      }
    }).then(({ error }) => {
      if (error && error.type === 'validation_error') {
        setState({
          ...state,
          isLoading: false,
          errorMessages: [error.message || 'An error occurred while attempting to process your payment.']
        });
      } else {
        const swimmingWorldOptInObj = { swimmingWorldOptIn: swimmingWorldOptIn };
        const transactionResultPromise = resultPromise(swimmingWorldOptInObj);

        if (transactionResultPromise ?? false) {
          transactionResultPromise.then((newState) => {
            if (newState ?? false) {
              const resultData = newState.objData;

              if ((resultData.paymentSucceeded === true || resultData.paymentPending === true)
                && resultData.paymentFailed === false) {
                onTransactionComplete(resultData, swimmingWorldOptIn);
              } else if (resultData.reloadCart === true) {
                setState(INITIAL_STATE);
                setShoppingCartError(true, 'Transaction Error', 'An error occurred while attempting to process your payment.');
              } else {
                const failedPaymentAttempts = Array.isArray(resultData.failedPaymentAttempts) && resultData.failedPaymentAttempts.length > 0
                  ? resultData.failedPaymentAttempts
                  : [{ errorMessage: 'An error occurred while attempting to process your payment.' }]

                setState({
                  ...state,
                  isLoading: false,
                  errorMessages: failedPaymentAttempts.map((x) => x.errorMessage)
                });
              }
            }
          }).catch(() => {
            setState(INITIAL_STATE);
            setShoppingCartError(true, 'Transaction Error', 'An error occurred while attempting to review your transaction.');
          });
        }
      }
    }).catch((e) => {
      setState({
        ...state,
        isLoading: false,
        errorMessages: [e.message || 'An error occurred while attempting to process your payment.']
      });
    });
  };

  const onCloseErrorPopup = () => {
    setState(INITIAL_STATE);
  };

  function getReturnUrl(orderId) {
    let returnUrl = window.location.href.split('?')[0];
    let nextCharacter = '?';

    if (searchParams.get('ItemSubsystemId')) {
      returnUrl += `${nextCharacter}ItemSubsystemId=${searchParams.get('ItemSubsystemId')}`;
      nextCharacter = '&';
    }

    returnUrl += `${nextCharacter}OrderId=${orderId}`;

    return returnUrl;
  }

  return {
    ...state,
    handleSubmit,
    onCloseErrorPopup
  };
};

export default useStripeCheckoutForm;