import { useCookies } from 'react-cookie';
import Constants from '../../utils/Constants';

const useMemberPersistentStorage = () => {
  const [cookies, setCookie] = useCookies([Constants.MEMBER_STORAGE_NAME]);

  const getDefaultCookieObject = () => {
    return {
      personId: '0',
      uIProjectName: Constants.UI_PROJECT_NAMES.MEMBER,
      returnUrl: '/',
      backToSystemLabel: 'Back to Member Search'
    };
  };

  const setPersistentStorage = (personId, uIProjectName, returnUrl, backToSystemLabel) => {
    const obj = cookies[Constants.MEMBER_STORAGE_NAME] || {};
    setCookieStorage({
      ...obj,
      personId,
      uIProjectName,
      returnUrl,
      backToSystemLabel
    });
  };

  const setCookieStorage = (newObject) => {
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 15);
    setCookie(Constants.MEMBER_STORAGE_NAME, {...newObject}, {...Constants.COOKIE_OPTIONS, expires});
  };

  const getPersistentStorage = () => {
    const obj = cookies[Constants.MEMBER_STORAGE_NAME];
    if (!obj) {
      const newObj = getDefaultCookieObject();
      setCookieStorage(newObj);
      return newObj;
    } else {
      setCookieStorage(obj);
      return obj;
    }
  };

  const resetExpiration = () => {
    const obj = getPersistentStorage();
    setCookieStorage(obj);
  };

  const resetPersistentStorage = () => {
    setCookieStorage({...getDefaultCookieObject()})
  };

  return {
    cookies,
    getPersistentStorage,
    resetExpiration,
    setPersistentStorage,
    resetPersistentStorage
  };
};

export default useMemberPersistentStorage;