import { ClubHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

const SearchFacilitiesData = (filterObject, sortBy, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    let filterObjectString = JSON.stringify(filterObject)
    const sortByValue = sortBy !== undefined && sortBy.length > 0 ? sortBy : 'facilityName';

    const filterObjectStringForUrl = filterObjectString ? encodeURIComponent(filterObjectString) : 'NaN';
    filterObjectStringForUrl.replace('&', '%26');
    const url = `/Facility/search?filters=${filterObjectStringForUrl}&sortBy=${sortByValue}`;
    ClubHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default SearchFacilitiesData;