import React, { Fragment } from 'react';

import { NavLink } from '../../wrappers/ReactRouterDom';

import style from './PillList.module.css';

const Pill = ({ navLink }) => {
  if (navLink && navLink.linkUrl && navLink.displayName) {
    return (
      <li className={style.PillNavItem}>
        <NavLink
          className={navData => (navData.isActive ? style.ActiveNavLink : style.NavLink)} 
          to={navLink.linkUrl}>
            {navLink.displayName}
        </NavLink>
      </li>
    );
  }

  return <Fragment />;
};

export default Pill;