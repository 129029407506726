import useMeetClassificationTreeView from './UseMeetClassificationTreeView';

import TreeView from '../TreeView';

import global from '../../GlobalStyle.module.css';

const MeetClassificationTreeView = ({ label, name, value, error, message, selectableLeavesOnly, singleSelect,
  onChange, orgUnitId, filterId, filterMode }) => {
  const { meetClassificationState } = useMeetClassificationTreeView(orgUnitId, filterId, filterMode);

  return meetClassificationState.message
    ? <span className={global.LoadingMsg}>{meetClassificationState.message}</span>
    : <TreeView
      label={label}
      name={name}
      value={value}
      error={error}
      message={message}
      isLoading={meetClassificationState.isArrayLoading}
      options={orgUnitId ? meetClassificationState.treeData : []}
      selectableLeavesOnly={selectableLeavesOnly}
      singleSelect={singleSelect}
      onChange={onChange} />
};

export default MeetClassificationTreeView;