import React, { Fragment } from 'react';

import useBottomHeader from './UseBottomHeader';
import useSmallNav from './UseSmallNav';

import MenuLink from '../../MenuLink';
import MenuIcon from '../../../icons/MenuIcon';

import style from '../Header.module.css';

const PrimaryNav = ({ menuItem, onPrimaryNavClicked }) => {
  if (Array.isArray(menuItem?.children) && menuItem.children.length > 0) {
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <a onClick={(e) => onPrimaryNavClicked(e, menuItem.taxonomyId)}>{menuItem.displayName}</a>
  }

  return <MenuLink menuItem={menuItem} />
};

const SecondaryNavLink = ({ menuItem, onClick, onClickLanding }) => {
  return <MenuLink menuItem={menuItem} onClick={onClick} onClickLanding={onClickLanding} />;
};

const SecondaryNav = ({ currentNavItem, onBlurSecondaryNav, onClickLanding }) => {
  return (
    <div className={[style.Secondary, 'hidden-xs hidden-sm'].join(' ')} onBlur={onBlurSecondaryNav}>
      <ul>
        <li>{currentNavItem.displayName}</li>
        {currentNavItem.children.map((menuItem, i) => <li key={i}><SecondaryNavLink menuItem={menuItem} index={i} onClickLanding={onClickLanding} /></li>)}
      </ul>
    </div>
  );
};

const LargeBottomHeader = () => {
  const { menuItems, currentNavItem, onPrimaryNavClicked, onClickLanding } = useBottomHeader();

  return (
    <div className={[style.LargeBottomHeader, 'hidden-xs hidden-sm'].join(' ')}>
      <div className={style.Primary}>
        <nav>
          <ul>
            {Array.isArray(menuItems) && menuItems.map((menuItem, i) => (
              <li key={i}>
                <PrimaryNav menuItem={menuItem} onPrimaryNavClicked={onPrimaryNavClicked} />
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {Array.isArray(currentNavItem?.children)
        && currentNavItem?.children.length > 0
        && <SecondaryNav
          currentNavItem={currentNavItem}
          onClickLanding={onClickLanding} />}
    </div>
  );
};

const SmallNavItem = ({ menuItem, expandedId, expandNode, collapseNode, onClickLanding }) => {
  const hasChildren = Array.isArray(menuItem.children) && menuItem.children.length > 0 ? true : false;

  if (hasChildren === true && expandedId === menuItem.taxonomyId) {
    return (
      <Fragment>
        <li className={style[`${menuItem.menuName || 'SubMenu'}SmallNavItem`]} onClick={() => collapseNode()}>
          <MenuLink menuItem={menuItem} onClickLanding={onClickLanding} />
        </li>
        {menuItem.children.map((x, i) => <SmallNavItem menuItem={x} key={i} />)}
      </Fragment>
    );
  } else if (hasChildren === true) {
    return (
      <li className={style[`${menuItem.menuName || 'SubMenu'}SmallNavItem`]} onClick={() => expandNode(menuItem.taxonomyId)}>
        <MenuLink menuItem={menuItem} onClickLanding={onClickLanding} />
      </li>
    );
  } else {
    return (
      <li className={style[`${menuItem.menuName || 'SubMenu'}SmallNavItem`]}>
        <MenuLink menuItem={menuItem} onClickLanding={onClickLanding} />
      </li>
    );
  }
};

const SmallNav = ({ onClickLanding }) => {
  const { menuItems, expandedId, expandNode, collapseNode } = useSmallNav();
  return (
    <div
      className={[style.SmallNav, 'visible-xs visible-sm'].join(' ')}>
      <ul>
        {menuItems.map((x, i) => (
          <SmallNavItem
            menuItem={x}
            expandedId={expandedId}
            expandNode={expandNode}
            collapseNode={collapseNode}
            key={i}
            onClickLanding={onClickLanding} />))}
      </ul>
    </div>
  );
};

const SmallBottomHeader = () => {
  const { isOpen, toggleNav, onClickLanding } = useBottomHeader();

  return (
    <div className={[style.SmallBottomHeader, 'visible-xs visible-sm'].join(' ')}>
      <div className={[style.MobileMenuIcon, 'visible-xs visible-sm'].join(' ')}>
        <button onClick={toggleNav}><MenuIcon /></button>
      </div>
      {isOpen === true && <SmallNav onClickLanding={onClickLanding} />}
    </div>
  );
};

const BottomHeader = () => {
  return (
    <Fragment>
      <LargeBottomHeader />
      <SmallBottomHeader />
    </Fragment>
  );
};

export default BottomHeader;