/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import MaskEmail from './MaskEmail';

import ActionIntraPageButton from '../buttons/ActionIntraPageButton';

import global from '../GlobalStyle.module.css';

//TODO: hard coded email address
const LargeGrid = ({ state, onSendEmailModalClicked }) => {
  const gridData = state.arrayData?.filter(x => x.email ? true : false);
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>EMAIL ADDRESS</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoaded !== true
          ? (
            <tr>
              {state.isArrayLoading === true ?
                <td colSpan="2">Loading...</td>
                : <td colSpan="2">No Results</td>
              }
            </tr>
          ) : Array.isArray(gridData) && gridData.length > 0
            ? gridData.map((person, i) => (
              <tr key={i}>
                <td style={{ width: 880 }}>{MaskEmail(person.email)}</td>
                <td className="pull-right">
                  <ActionIntraPageButton
                    onClick={(e) => onSendEmailModalClicked(e, person.personId)}>
                    Email Member Id
                  </ActionIntraPageButton>
                </td>
              </tr>
            )) : (
              <Fragment>
                <tr>
                  <td colSpan="2">No Results</td>
                </tr>
                <tr>
                  <a href="mailto:support@usaswimming.org">Can't find your membership information? Get some help here.</a>
                </tr>
              </Fragment>
            )
        }
      </tbody>
    </table>
  );
};

const SmallGrid = ({ state, onSendEmailModalClicked }) => {
  const gridData = state.arrayData?.filter(x => x.email ? true : false);
  return (
    <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
      {state.isArrayLoaded !== true
        ? (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>
              &nbsp;
            </div>
            <div className={global.SmallTableRowInfo}>
              {state.isArrayLoading === true ?
                <div className={global.SmallTableRowLabels}>Loading...</div>
                : <div className={global.SmallTableRowLabels}>No Results</div>
              }
            </div>
          </div>
        ) : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((person, i) => (
            <div className={global.SmallTableRow} key={i}>
              <div className={global.SmallTableRowHead}>
                <div style={{ maxWidth: '160px' }}>
                  <ActionIntraPageButton
                    onClick={(e) => onSendEmailModalClicked(e, person.personId)}>
                    Email Member Id
                  </ActionIntraPageButton>
                </div>
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className='row'>
                  <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Email Address</div>
                  <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{MaskEmail(person.email)}</div>
                </div>
              </div>
            </div>
          )) : (
            <div className={global.SmallTableRow}>
              <div className={global.SmallTableRowHead}>
                &nbsp;
              </div>
              <div className={global.SmallTableRowInfo}>
                <div className={global.SmallTableRowLabels}>No Results</div>
              </div>
            </div>
          )
      }
    </div >
  );
};

const RequestMemberIdGrid = ({ state, onSendEmailModalClicked }) => {
  return (
    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
      <div className="col-xs-12">
        <LargeGrid state={state} onSendEmailModalClicked={onSendEmailModalClicked} />
        <SmallGrid state={state} onSendEmailModalClicked={onSendEmailModalClicked} />
      </div>
    </div>
  );
}

export default RequestMemberIdGrid;