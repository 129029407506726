import React, { Fragment } from 'react';

import useMemberContacts from './UseMemberContacts';

import MemberContactsGrid from './grids/MemberContactsGrid';

import Headings from '../headings/Headings';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import PopUpModal from '../dialogs/PopUpModal';
import ActionIntraPageButton from '../buttons/ActionIntraPageButton';

import Constants from '../../utils/Constants';

const MemberContacts = ({ acknowledgementsView, personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState }) => {
  const {
    deleteModalState,
    viewState,
    personGeneralState,
    basePersonState,
    onAddPerson,
    onEditPerson,
    onAddEditAddress,
    onAddEditPhone,
    onDeletePerson,
    onDeleteAddress,
    onDeletePhone,
    onDeleteModalConfirmClicked,
    onDeleteModalCanceledClicked
  } = useMemberContacts(acknowledgementsView, personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState);

  return (
    <Fragment>
      {acknowledgementsView === true ?
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Headings.H4>Member Contact Info</Headings.H4>
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H5>Selected Member: {personAcknowledgementInfoPageViewState.selectedPerson?.firstName || ''} {personAcknowledgementInfoPageViewState.selectedPerson?.middleName || ''}  {personAcknowledgementInfoPageViewState.selectedPerson?.lastName || ''}</Headings.H5>
          </div>
        </div>
        :
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>Member Contact Info</Headings.H3>
          </div>
        </div>}
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton onClick={onAddPerson}>Add Contact</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <MemberContactsGrid
            contacts={viewState.contactDisplayList}
            isLoading={personGeneralState.isObjLoading}
            onEditPerson={onEditPerson}
            onDeletePerson={onDeletePerson}
            onAddEditAddress={onAddEditAddress}
            onDeleteAddress={onDeleteAddress}
            onAddEditPhone={onAddEditPhone}
            onDeletePhone={onDeletePhone} />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personGeneralState.isObjLoading || basePersonState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personGeneralState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={deleteModalState.modalTitle}
        displayPopUp={deleteModalState.displayPopUp === true}
        onModalCanceled={onDeleteModalCanceledClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{deleteModalState.targetObjectName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteModalConfirmClicked}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onDeleteModalCanceledClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default MemberContacts;