import { useState } from 'react';
import MeetResultsData from './MeetResultsData';

const useMeetResutlsData = () => {
  const [meetResultsState, setMeetResultsState] = useState(MeetResultsData.INITIAL_STATE);
  const [meetRelayResultsState, setMeetRelayResultsState] = useState(MeetResultsData.INITIAL_STATE);

  const postMeetResults = (meetResultsObj, incrementalMeetTimes) => MeetResultsData.postMeetResultsData(meetResultsObj, incrementalMeetTimes, meetResultsState, setMeetResultsState);
  const postMeetRelayResults = (meetResultsObj, incrementalMeetTimes) => MeetResultsData.postMeetResultsData(meetResultsObj, incrementalMeetTimes, meetRelayResultsState, setMeetRelayResultsState);

  return {
    meetResultsState,
    meetRelayResultsState,
    postMeetResults,
    postMeetRelayResults
  };
}

export default useMeetResutlsData;