import { SecurityHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const areStringArraysEqual = (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (a.length !== b.length) return false;
  let result = true;
  a.forEach(ea1 => {
    if (result === true) {
      const eb1 = b.find(x => x === ea1);
      if (!eb1) result = false;
    }
  });
  b.forEach(eb2 => {
    if (result === true) {
      const ea2 = a.find(x => x === eb2);
      if (!ea2) result = false;
    }
  });
  return result;
};
const getRoutesForContext = (contextId, taxonomies, scope, state, setState) => {
  if ((state?.retries || 0) < 3
    && contextId
    && Array.isArray(taxonomies) 
    && taxonomies.length > 0 
    && scope 
    && state?.isObjLoading !== true 
    && (state?.isObjLoaded !== true 
      || state?.contextId !== contextId 
      || state?.scope !== scope
      || areStringArraysEqual(taxonomies, state?.taxonomies) === false)) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      objData: {},
      message: 'Loading...'
    };
    setState(newState);

    const postPayload = {
      contextId,
      toxonomies: taxonomies,
      scope,
      uIProjectName: import.meta.env.VITE_PROJECT_NAME
    };
    const url = '/AppRoute/taxonomy/RoutesForContext';
    SecurityHttpHelper(url, 'POST', postPayload)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData: {...objData},
            contextId,
            scope,
            taxonomies: [...taxonomies],
            message: '',
            retries: 0
          };
          setState(newState);
          return objData;
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        const retries = (newState.retries || 0) + 1;
        newState = {
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          objData: {},
          message: message,
          retries
        };
        setState(newState);
      });
    return 'sent';
  };
};

export default getRoutesForContext;