import useCourseDropdown from './UseCourseDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const CourseDropdown = ({ label, name, value, error, message, onChange, poolOnlyOptions }) => {
  const { courseState } = useCourseDropdown();

  return courseState.message
    ? <span className={global.LoadingMsg}>{courseState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={poolOnlyOptions === true ? courseState.poolOnlyDataAsOptionsWithNames : courseState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={courseState.isLoading}
      />
    );
};

export default CourseDropdown;