import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import getPersonGeneralData from './GetPersonGeneralData';
import getPersonGeneralByMemberIdData from './GetPersonGeneralByMemberIdData';
import putPersonGeneralData from './PutPersonGeneralData';
import putPersonGeneralContactData from './PutPersonGeneralContactData';
import deletePersonAddressData from './DeletePersonAddressData';
import deletePersonPhoneData from './DeletePersonPhoneData';
import deletePersonContactData from './DeletePersonContactData';
import deletePersonContactAddressData from './DeletePersonContactAddressData';
import deletePersonContactPhoneData from './DeletePersonContactPhoneData';


const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonGeneralData = {
  INITIAL_STATE: { ...INITIAL_STATE }, getPersonGeneralData, getPersonGeneralByMemberIdData, putPersonGeneralData,
  putPersonGeneralContactData, deletePersonAddressData, deletePersonPhoneData,
  deletePersonContactData, deletePersonContactAddressData, deletePersonContactPhoneData
};

export default PersonGeneralData;