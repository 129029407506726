const useButton = (type, className, style, isPrimary) => {
  const buttonType = type !== 'button' && type !== 'submit' && type !== 'reset' ? 'button' : type;

  const fullClassAttribute = isPrimary === true
    ? [style.Button, style.Primary, (className || '')].join(' ')
    : [style.Button, style.Secondary, (className || '')].join(' ');

  const actionClassAttribute = isPrimary === null
    ? [style.ActionButton, style.ActionIntraPage, (className || '')].join(' ')
    : [style.Button, style.Secondary, (className || '')].join(' ');

  const isReadWrite = true;
  
  if (!className) className = '';

  return { buttonType, fullClassAttribute, actionClassAttribute, isReadWrite };
};

export default useButton;