import React, { Fragment } from 'react';

import useAcknowledgements from '../UseAcknowledgements';

import ContactInfo from './ContactInfo';

import PrimaryButton from '../../buttons/PrimaryButton';
import Headings from '../../headings/Headings';

const AcknowledgeExistingMemberInfo = () => {
    const { onYesContactInfoIsTheSameClicked } = useAcknowledgements();
    return (
        <Fragment>
            <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                <div className="col-xs-12 usas-extra-bottom-margin">
                    <Headings.H5>Is the following contact information correct? If any contact information is not correct, please use the 'Update Contact Information' button to make updates.</Headings.H5>
                </div>
            </div>
            <ContactInfo />
            <div className="row">
                <div className="col-xs-12">
                    <PrimaryButton type="button" onClick={(e) => onYesContactInfoIsTheSameClicked(e)}>Yes, Contact Information is Correct</PrimaryButton>
                </div>
            </div>
        </Fragment>
    );
};

export default AcknowledgeExistingMemberInfo;