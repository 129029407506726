import React, { createContext, useState } from 'react';

import CartItemData from './CartItemData';

export const CartItemStateContext = createContext();

const CartItemContextProvider = ({ children }) => {
  const stateHook = useState(CartItemData.INITIAL_STATE);

  return (
    <CartItemStateContext.Provider value={stateHook}>
      {children}
    </CartItemStateContext.Provider>
  );
};

export default CartItemContextProvider;