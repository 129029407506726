import { Fragment } from "react";

import PopUpModal from "../../../dialogs/PopUpModal";
import PrimaryButton from "../../../buttons/PrimaryButton";
import SecondaryButton from "../../../buttons/SecondaryButton";

import { FormatMoneyWithSymbol } from "../../../../utils/FormatMoney";

const DeleteCartItemPopup = ({ itemDescription, itemPrice, onConfirmDeleteItem, onCloseModal }) => (
  <PopUpModal
    widthPct={90}
    maxWidth={720}
    title={'Delete item from cart?'}
    displayPopUp={true}
    onModalCanceled={onCloseModal}>
    <Fragment>
      <div className="row">
        <div className="col-xs-12 usas-extra-bottom-margin">
          <b>Are you sure you wish to delete the following item?</b>
        </div>
        <div className="col-xs-12 usas-extra-bottom-margin">
          <p>
            {itemDescription}
            <br />
            {FormatMoneyWithSymbol(itemPrice)}
          </p>
        </div>
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <b>This cannot be undone.</b>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton onClick={onCloseModal}>No</SecondaryButton>&nbsp;
          <PrimaryButton onClick={onConfirmDeleteItem}>Yes</PrimaryButton>
        </div>
      </div>
    </Fragment>
  </PopUpModal>
);

export default DeleteCartItemPopup;