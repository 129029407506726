import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import searchPersonsData from './SearchPersonsData';
import getBasePersonData from './GetBasePersonData';
import putBasePersonData from './PutBasePersonData';
import putPersonMergeData from './PutPersonMergeData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  filterObject: ''
};

const BasePersonData = {
  INITIAL_STATE, searchPersonsData, getBasePersonData, putBasePersonData, putPersonMergeData
};

export default BasePersonData;