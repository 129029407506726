import { useEffect, useState } from "react";

import useAccountLoginData from "../../../../../../state/account/accountLogin/UseAccountLoginData";

import { SE_STEP_EXPIRED_PASSWORD, SE_STEP_ONE_TIME_PASSWORD } from "../../utils/SessionEndingConstants";
import { getStoredTokenObj, setStoredToken } from "../../../../../../utils/TokenUtils";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayError: false,
  errorMessage: ''
};

const INITIAL_STATE = {
  username: ''
};

const useSessionEndingLogin = (updatePopupStep, onPopupClosed) => {
  const { accountLoginState, postAccountLogin, resetAccountLoginState
  } = useAccountLoginData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  const [state, setState] = useState(INITIAL_STATE);

  const onCloseLoginErrorPopupClicked = () => {
    resetAccountLoginState();
    setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const username = state.username;
    const password = formState.password;

    const postAccountLoginPromise = postAccountLogin(username, password);

    if (postAccountLoginPromise ?? false) {
      postAccountLoginPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.oneTimePasswordRequired === true) {
            const loginData = {
              href: newState.objData?.href,
              device: newState.objData?.device
            };

            updatePopupStep(SE_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.accountLockedOut === true) {
            const minutes = Math.ceil(newState.objData?.secondsUntilUnlock / 60);

            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: `Too many unsuccessful login attempts. Your account will unlock in ${minutes} minute${minutes === 1 ? '' : 's'}.`
            });
          } else if (newState.objData?.mustChangePassword === true) {
            const loginData = {
              href: newState.objData?.href,
              username: username
            };

            updatePopupStep(SE_STEP_EXPIRED_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.accessToken === null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The username and/or password is invalid.'
            });
          } else {
            setStoredToken(newState.objData.accessToken, newState.objData.refreshToken, newState.objData.expirationDatetime, newState.objData.username);
            onPopupClosed(false);
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  useEffect(() => {
    setState({
      ...state,
      username: getStoredTokenObj().username
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: accountLoginState.isObjLoading,
    username: state.username,
    loginErrorPopupState,
    onSubmitFormCallback,
    onCloseLoginErrorPopupClicked
  };
};

export default useSessionEndingLogin;