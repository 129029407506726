import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

import Shield from '../Shield';
import Icon from '../../icons/Icon';
import MenuLink from '../MenuLink';
import useFooter from './UseFooter';

import global from '../../GlobalStyle.module.css';
import style from './Footer.module.css';

const Social = ({tooltip, iconObj, href}) => {
  return (
    <a className={style.SocialLink} href={href} target="_blank" rel="noopener noreferrer">
      <div>
        <Icon tooltip={tooltip} iconObj={iconObj} />
      </div>
    </a>
  );
};

const Menu = ({menuItem}) => {
  const hasChildren = Array.isArray(menuItem?.children) && menuItem.children.length > 0;
  return (
    <div className={['col-xs-6 col-sm-3', global.CenterText].join(' ')}>
      <div className={style.MenuContainer}>
        <ul className={style.FooterMenu}>
          <li className={style.HeadingsText}>{menuItem.displayName}</li>
          {hasChildren === true && menuItem.children.map((x, i) => <li key={i}><MenuLink menuItem={x} /></li>)}
        </ul>
      </div>
    </div>
  );
};

const Footer = () => {
  const {menuItems, privacyPolicy, termsAndConditions, year} = useFooter();
  return (
    <div className={style.FooterPadding}>
      <div className="row">
        <div className={['col-xs-12', global.CenterText].join(' ')}>
          <Shield className={style.Shield} />
        </div>
        <div className={['col-xs-12', style.HeadingsText, global.CenterText].join(' ')}>
          Follow
        </div>
        <div className={['col-xs-12', global.CenterText].join(' ')}>
          <div className={style.SocialBlock}>
            <Social tooltip="Instagram" iconObj={faInstagram} href="//www.instagram.com/usaswimming" />
            <Social tooltip="Youtube" iconObj={faYoutube} href="//www.youtube.com/user/usaswimmingorg" />
            <Social tooltip="Facebook" iconObj={faFacebook} href="//www.facebook.com/usaswimming" />
            <Social tooltip="Twitter" iconObj={faTwitter} href="//www.twitter.com/usaswimming" />
          </div>
        </div>
      </div>
      <div className="row">
        {Array.isArray(menuItems) && menuItems.map((menuItem, i) => <Menu menuItem={menuItem} key={i} />)}
      </div>
      <div className={['row', style.ExtraPadding].join(' ')}>
        <div className={['col-xs-6', style.SmallLink, global.CenterText].join(' ')}>
          <MenuLink menuItem={privacyPolicy} />
        </div>
        <div className={['col-xs-6', style.SmallLink, global.CenterText].join(' ')}>
          <MenuLink menuItem={termsAndConditions} />
        </div>
        <div className={['col-xs-12', style.SmallLink, global.CenterText].join(' ')}>
          &copy;Copyright {year} USA Swimming. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;