import ToIntIfInt from "../../utils/ToIntIfInt";

const padNumber = (value, size) => {
  let s = value.toString();
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
};

const getMonthsAsOptions = (useAbbreviations) => [
  {id: padNumber(0, 2), name: 'Month'},
  {id: padNumber(1, 2), name: useAbbreviations === true ? 'JAN' : 'January'},
  {id: padNumber(2, 2), name: useAbbreviations === true ? 'FEB' : 'February'},
  {id: padNumber(3, 2), name: useAbbreviations === true ? 'MAR' : 'March'},
  {id: padNumber(4, 2), name: useAbbreviations === true ? 'APR' : 'April'},
  {id: padNumber(5, 2), name: useAbbreviations === true ? 'MAY' : 'May'},
  {id: padNumber(6, 2), name: useAbbreviations === true ? 'JUN' : 'June'},
  {id: padNumber(7, 2), name: useAbbreviations === true ? 'JUL' : 'July'},
  {id: padNumber(8, 2), name: useAbbreviations === true ? 'AUG' : 'August'},
  {id: padNumber(9, 2), name: useAbbreviations === true ? 'SEP' : 'September'},
  {id: padNumber(10, 2), name: useAbbreviations === true ? 'OCT' : 'October'},
  {id: padNumber(11, 2), name: useAbbreviations === true ? 'NOV' : 'November'},
  {id: padNumber(12, 2), name: useAbbreviations === true ? 'DEC' : 'December'}
];

const getDaysAsOptions = (monthId, isLeapYear) => {
  const days = [{id: padNumber(0, 2), name: 'Day'}];
  for (let i = 1; i <= 28; i++) {
    const day = padNumber(i, 2);
    days.push({id: day, name: day});
  }

  if (monthId === '02' && isLeapYear === false) {
    return days;
  }
  
  days.push({id: '29', name: '29'});
  if (monthId === '02') {
    return days;
  }

  days.push({id: '30', name: '30'});
  if (['04', '06', '09', '11'].find(x => x === monthId)) return days;

  days.push({id: '31', name: '31'});
  return days;
};

const getYearsAsOptions = (countOfYears, startYearOffset) => {
  countOfYears = ToIntIfInt(countOfYears);
  if (Number.isInteger(countOfYears) === false) countOfYears = 150;
  startYearOffset = ToIntIfInt(startYearOffset);
  if (Number.isInteger(startYearOffset) === false) startYearOffset = -150;
  const currentYear = new Date().getFullYear();
  const startYear = currentYear + startYearOffset;
  const endYear = startYear + countOfYears;
  const years = [{id: '0000', name: 'Year'}];
  for (let i = endYear; i >= endYear - countOfYears; i--) {
    const year = padNumber(i, 4);
    years.push({id: year, name: year});
  }

  return years;
};

const getTimezonesAsOptions = () => {
  return [
    {name: 'Time Zone', value: ''},
    {name: 'Eastern Time Zone', value: 'Eastern Standard Time'},
    {name: 'Central Time Zone', value: 'Central Standard Time'},
    {name: 'Mountain Time Zone', value: 'Mountain Standard Time'},
    {name: 'Mountain Time (Arizona)', value: 'US Mountain Standard Time'},
    {name: 'Pacific Time Zone', value: 'Pacific Standard Time'},
    {name: 'Alaskan Time Zone', value: 'Alaskan Standard Time'},
    {name: 'Hawaii-Aleutian Time Zone', value: 'Hawaiian Standard Time'}
  ];
};

const getTimesAsOptions = () => {
  return [
    {name: 'Time', value: ''},
    {name: '12:00 AM', value: '00:00:00'},
    {name: '6:00 AM', value: '06:00:00'},
    {name: '7:00 AM', value: '07:00:00'},
    {name: '8:00 AM', value: '08:00:00'},
    // {name: '9:00 AM', value: '09:00:00'},
    {name: '10:00 AM', value: '10:00:00'},
    // {name: '11:00 AM', value: '11:00:00'},
    {name: '12:00 PM', value: '12:00:00'},
    {name: '1:00 PM', value: '01:00:00'},
    // {name: '2:00 PM', value: '02:00:00'},
    {name: '3:00 PM', value: '03:00:00'},
    // {name: '4:00 PM', value: '04:00:00'},
    {name: '5:00 PM', value: '05:00:00'},
    // {name: '6:00 PM', value: '06:00:00'},
    // {name: '7:00 PM', value: '07:00:00'},
    {name: '8:00 PM', value: '08:00:00'},
    // {name: '9:00 PM', value: '09:00:00'},
    // {name: '10:00 PM', value: '10:00:00'},
    // {name: '11:00 PM', value: '11:00:00'},
    {name: '11:59 PM', value: '11:59:59'}
  ];
};

const DateData = {
  getMonthsAsOptions,
  getDaysAsOptions,
  getYearsAsOptions,
  getTimesAsOptions,
  getTimezonesAsOptions
};

export default DateData;