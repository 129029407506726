import { API_NAMES, initApi } from '../../utils/HttpApiHelper';

export const createUrl = (url, orgUnitId, itemSubsystemId, itemContract, referenceId, personId) => {
  let nextCharacter = '?';

  if (orgUnitId) {
    url += `${nextCharacter}OrgUnitId=${encodeURIComponent(orgUnitId)}`;
    nextCharacter = '&';
  }

  if (itemSubsystemId) {
    url += `${nextCharacter}ItemSubsystemId=${encodeURIComponent(itemSubsystemId)}`;
    nextCharacter = '&';
  }

  if (itemContract) {
    url += `${nextCharacter}ItemContract=${encodeURIComponent(itemContract)}`;
    nextCharacter = '&';
  }

  if (referenceId) {
    url += `${nextCharacter}ReferenceId=${encodeURIComponent(referenceId)}`;
    nextCharacter = '&';
  }

  if (personId) {
    url += `${nextCharacter}PersonId=${encodeURIComponent(personId)}`;
    nextCharacter = '&';
  }

  return url;
};

const getCartCountData = async (orgUnitId, itemSubsystemId, itemContract, referenceId, state, setState) => {
  const api = initApi(API_NAMES.PAYMENTS, state, setState);
  const url = createUrl('/Payments/Cart/Count', orgUnitId, itemSubsystemId, itemContract, referenceId);

  return api?.executeObject ? api.executeObject(url, 'GET') : null;
};

const getPaymentReceiptData = (receiptNumber, state, setState) => {
  const api = initApi(API_NAMES.PAYMENTS, state, setState);
  const receiptNumberForUrl = encodeURIComponent(receiptNumber);
  const url = `/Receipts/${receiptNumberForUrl}/DownloadReceipt`;

  return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
};

const PaymentsData = {
  getPaymentReceiptData,
  getCartCountData
};

export default PaymentsData;