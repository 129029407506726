import { Fragment } from "react";

import useSessionEndingLogin from "./UseSessionEndingLogin";

import PopUpModal from "../../../../../dialogs/PopUpModal";
import SecondaryButton from "../../../../../buttons/SecondaryButton";
import ReLoginForm from "../../../../../forms/login/reLogin/ReLoginForm";

import Constants from "../../../../../../utils/Constants";

const SessionEndingLogin = ({ updatePopupStep, onPopupClosed }) => {
  const {
    isLoading,
    username,
    loginErrorPopupState,
    onSubmitFormCallback,
    onCloseLoginErrorPopupClicked
  } = useSessionEndingLogin(updatePopupStep, onPopupClosed);

  return (
    <Fragment>
      <div className={'col-xs-12 usas-extra-bottom-margin'}>
        <b>If you would like to keep working, please login again.</b>
        <p>Otherwise, you will be redirected to the login page when your session expires.</p>
        <ReLoginForm
          username={username}
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={() => onPopupClosed(true)} />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Login Error'}
        displayPopUp={loginErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{loginErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseLoginErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default SessionEndingLogin;