import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const INITIAL_STATE = { ...BASIC_INITIAL_STATE };

const padNumber = (value, size) => {
  let s = value.toString();
  while (s.length < (size || 2)) { s = "0" + s; }
  return s;
};

const getDaysAsOptions = (monthId = '00', isLeapYear = false) => {
  const days = [{ id: padNumber(0, 2), name: 'Day' }];
  for (let i = 1; i <= 28; i++) {
    const day = padNumber(i, 2);
    days.push({ id: day, name: day });
  }

  if (monthId === '02' && isLeapYear === false) {
    return days;
  }

  days.push({ id: '29', name: '29' });
  if (monthId === '02') {
    return days;
  }

  days.push({ id: '30', name: '30' });
  if (['04', '06', '09', '11'].find(x => x === monthId)) return days;

  days.push({ id: '31', name: '31' });
  return days;
};

const getMonthsAsOptions = (useAbbreviations) => {
  return [
    { id: '00', name: 'Month', days: [] },
    { id: '01', name: useAbbreviations === true ? 'JAN' : 'January' },
    { id: '02', name: useAbbreviations === true ? 'FEB' : 'February' },
    { id: '03', name: useAbbreviations === true ? 'MAR' : 'March' },
    { id: '04', name: useAbbreviations === true ? 'APR' : 'April' },
    { id: '05', name: useAbbreviations === true ? 'MAY' : 'May' },
    { id: '06', name: useAbbreviations === true ? 'JUN' : 'June' },
    { id: '07', name: useAbbreviations === true ? 'JUL' : 'July' },
    { id: '08', name: useAbbreviations === true ? 'AUG' : 'August' },
    { id: '09', name: useAbbreviations === true ? 'SEP' : 'September' },
    { id: '10', name: useAbbreviations === true ? 'OCT' : 'October' },
    { id: '11', name: useAbbreviations === true ? 'NOV' : 'November' },
    { id: '12', name: useAbbreviations === true ? 'DEC' : 'December' }
  ];
};

const getYearsAsOptions = (countOfYears = 150, startYearOffset = -150) => {
  countOfYears = parseInt(countOfYears);
  startYearOffset = parseInt(startYearOffset);
  const currentYear = new Date().getFullYear();
  const startYear = currentYear + startYearOffset;
  const endYear = startYear + countOfYears;
  const years = [{ id: '0000', name: 'Year' }];
  for (let i = endYear; i >= endYear - countOfYears; i--) {
    const year = padNumber(i, 4);
    years.push({ id: year, name: year });
  }

  return years;
};

const getTimezonesAsOptions = () => {
  return [
    { name: 'Time Zone', id: '' },
    { name: 'Eastern Time Zone', id: 'Eastern Standard Time' },
    { name: 'Central Time Zone', id: 'Central Standard Time' },
    { name: 'Mountain Time Zone', id: 'Mountain Standard Time' },
    { name: 'Mountain Time (Arizona)', id: 'US Mountain Standard Time' },
    { name: 'Pacific Time Zone', id: 'Pacific Standard Time' },
    { name: 'Alaskan Time Zone', id: 'Alaskan Standard Time' },
    { name: 'Hawaii-Aleutian Time Zone', id: 'Hawaiian Standard Time' }
  ];
};

const getTimesAsOptions = () => {
  return [
    { name: 'Time', id: '99:99' },
    { name: '12:00 AM', id: '00:00' },
    { name: '6:00 AM', id: '06:00' },
    { name: '7:00 AM', id: '07:00' },
    { name: '8:00 AM', id: '08:00' },
    { name: '10:00 AM', id: '10:00' },
    { name: '12:00 PM', id: '12:00' },
    { name: '1:00 PM', id: '13:00' },
    { name: '3:00 PM', id: '15:00' },
    { name: '5:00 PM', id: '17:00' },
    { name: '8:00 PM', id: '20:00' },
    { name: '11:59 PM', id: '23:59' }
  ];
};

const getHoursAsOptions = () => {
  const hours = [{ id: '99', name: 'Hour' }, { id: '00', name: '00' }];
  for (let i = 1; i <= 23; i++) {
    const hour = padNumber(i, 2);
    hours.push({ id: hour, name: hour });
  }
  return hours;
};

const getMinutesAsOptions = () => {
  const minutes = [{ id: '99', name: 'Minute' }, { id: '00', name: '00' }];
  for (let i = 1; i <= 59; i++) {
    const minute = padNumber(i, 2);
    minutes.push({ id: minute, name: minute });
  }
  return minutes;
};

const getObjData = () => {
  return {
    days: getDaysAsOptions(),
    months: getMonthsAsOptions(),
    years: getYearsAsOptions(),
    times: getTimesAsOptions(),
    hours: getHoursAsOptions(),
    minutes: getMinutesAsOptions(),
    timeZones: getTimezonesAsOptions()
  };
};

const getDateTimeData = (state, setState) => {
  if (state.isObjLoaded !== true) {
    setState({
      ...state,
      isObjLoaded: true,
      isObjLoading: false,
      objData: getObjData()
    });
  }
};

const DateTimeData = {
  INITIAL_STATE,
  getDateTimeData,
  getYearsAsOptions
};

export default DateTimeData;