const useCalendarDateInput = (value, onChange) => {

  const onDateValueChanged = (newDateValue) => {
    if (newDateValue) {
      const [y, m, d] = newDateValue.split("-");

      onChange(`${m}/${d}/${y}`);
    }
  };

  function getDateValueForInput(dateValue) {
    const [m, d, y] = dateValue.split("/");

    return `${y}-${m}-${d}`;
  }

  return {
    formattedDateValue: getDateValueForInput(value),
    onDateValueChanged
  };
};

export default useCalendarDateInput;