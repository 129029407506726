import React, { createContext, useState } from 'react';

import PersonAcknowledgementAddAFamilyMemberData from './PersonAcknowledgementAddAFamilyMemberData';

export const PersonAcknowledgementAddAFamilyMemberStateContext = createContext();

const PersonAcknowledgementAddAFamilyMemberContextProvider = ({ children }) => {
  const stateHook = useState(PersonAcknowledgementAddAFamilyMemberData.INITIAL_STATE);

  return (
    <PersonAcknowledgementAddAFamilyMemberStateContext.Provider value={stateHook}>
      {children}
    </PersonAcknowledgementAddAFamilyMemberStateContext.Provider>
  );
}

export default PersonAcknowledgementAddAFamilyMemberContextProvider;
