import { initCustomApi } from "../AccountApiHelper";

const postAccountResetPasswordData = (href, currentPassword, newPassword, state, setState) => {
  if (href && currentPassword && newPassword) {
    const api = initCustomApi(state, setState);
    const fullUrl = `${import.meta.env.VITE_SECURITYAPI_URL}/Account/PasswordReset`;
    const headers = {
      "Content-Type": "application/json"
    };
    const payload = {
      href: href,
      currentPassword: currentPassword,
      newPassword: newPassword
    };

    return api?.executeApi ? api.executeApi(fullUrl, headers, 'POST', payload, 'include') : null;
  }
};

const AccountExpiredPasswordData = {
  postAccountResetPasswordData
};

export default AccountExpiredPasswordData;