import { useEffect } from 'react';

import useLscData from '../../../state/lsc/UseLscData';

const useLscCombobox = () => {
  const { lscState, getLscs } = useLscData();

  useEffect(() => {
    if (lscState.isLoading !== true && lscState.isLoaded !== true) {
      getLscs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lscState
  };

};

export default useLscCombobox;