import { useState, useEffect } from 'react';

import useMeetResultsData from '../../../state/meet/meetResults/UseMeetResultsData';
import useMeetTimesStatusData from '../../../state/meet/meetTimesStatus/UseMeetTimesStatusData';

import useMeetNoContextData from '../../../state/meet/UseMeetNoContextData';

import sdifConversion from './lib/SDIFConversion';
import lefConversion from './lib/LEFConversion';
import shared from './lib/Shared';

const useMeetResultsUpload = (meetId, meetStartDate, meetEndDate) => {
  const { meetResultsState, meetRelayResultsState, postMeetResults, postMeetRelayResults } = useMeetResultsData();
  const { meetTimesStatusState, getMeetTimesStatus } = useMeetTimesStatusData();
  const { meetState, getMeet } = useMeetNoContextData();
  const [jsonFileState, setJsonFileState] = useState(getInitialJSONFileState);
  const [uiState, setUiState] = useState({ canUpload: true, message: ''});
  
  const makeIndividualTimesArray = sdifConversion.makeIndividualTimesArray;
  const makeClubObject = sdifConversion.makeClubObject;
  const makeMeetRelaysObject = shared.makeMeetRelaysObject;
  const makeMeetObject = shared.makeMeetObject;
  const makeRelayArray = sdifConversion.makeRelayArray;
  const checkFileStructure = sdifConversion.checkFileStructure;
  const makeErrorsObject = sdifConversion.makeErrorsObject;
  const processClubs = lefConversion.processClubs;
  const getSessionArrays = lefConversion.getSessionArrays;

  const INVALID_FILE_MESSAGE = "This is not a valid Meet Results file";
  const ZIP_FILE_MESSAGE = "The Meet Results file must be unzipped before uploading";

  function formatDate(dateString) {
    if (dateString.indexOf("-") > 0 && dateString.indexOf("T") > 0) return dateString;
    if (dateString.indexOf("-") > 0 && dateString.indexOf("T") === -1) { 
      return dateString + "T00:00:00";
    }

    const [m, d, y] = dateString.split("/");
    return `${y}-${m}-${d}T00:00:00`;
  }

  function postResults(appendResults) {
    //get a swim date

    let resultsObj = JSON.parse(jsonFileState.individualTimesContent);
    let relayResultsObj = {};
    
    if (Object.keys(jsonFileState.relayTimesContent).length > 0) {
      relayResultsObj = JSON.parse(jsonFileState.relayTimesContent);
    }

    let swimDate = null;

    //if file only contains relays get swimDate from the relayResultsObj
    if (resultsObj.meet.events.length > 0) {
      swimDate = new Date(formatDate(resultsObj.meet.events[0].sessions[0].times[0].swimDate));
    } else if (relayResultsObj.meet.relayEvents.length > 0) {
      swimDate = new Date(formatDate(relayResultsObj.meet.relayEvents[0].sessions[0].relayTimes[0].swimDate));
    }

    const meetStartDateAsDate = new Date(meetStartDate); 
    const meetEndDateAsDate = new Date(meetEndDate);  

    if (swimDate >= meetStartDateAsDate && swimDate <= meetEndDateAsDate) {
      if (meetResultsState.isSaving !== true && resultsObj.meet.events.length > 0) {
        postMeetResults(resultsObj, appendResults);
      }

      if (Object.keys(jsonFileState.relayTimesContent).length > 0) {
        if (meetRelayResultsState.isSaving !== true && relayResultsObj.meet.relayEvents.length > 0) {
          postMeetRelayResults(relayResultsObj, appendResults);
        }
      }
    } else {
      setUiState({...uiState, canUpload: false, message: 'Swim Dates in the meet results file must be between the Meet Start Date and Meet End Date'});
    }
  }

  const onSubmitMeetResults = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    postResults(false);
  };

  const onSubmitIncrementalMeetResults = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    postResults(true);
  };

  const onDownloadClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let output = jsonFileState.individualTimesContent;
    const file = new Blob([output], { type: 'application/json' });
    const a = document.createElement('a');

    a.href = URL.createObjectURL(file);
    let fileName = "MeetResults_IndividualTimes.json";
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);

    if (Object.keys(jsonFileState.relayTimesContent).length !== 0) {
      let output2 = jsonFileState.relayTimesContent;
      const file2 = new Blob([output2], { type: 'application/json' });
      const a2 = document.createElement('a');

      a2.href = URL.createObjectURL(file2);
      fileName = "MeetResults_RelayTimes.json";
      a2.download = fileName;
      a2.click();

      URL.revokeObjectURL(a2.href);
    }

    if (Object.keys(jsonFileState.errorsContent).length !== 0) {
      let output3 = jsonFileState.errorsContent;
      const file3 = new Blob([output3], { type: 'application/json' });
      const a3 = document.createElement('a');

      a3.href = URL.createObjectURL(file3);
      fileName = "Errors.json";
      a3.download = fileName;
      a3.click();

      URL.revokeObjectURL(a3.href);
    }
  };

  const processFileLef = (fileContent, isValidFileType, isZipFile) => {
    let lefErrorArray = [];

    let individualTimesArray = [];
    let relayTimesArray = [];
    let parser = new DOMParser();
    const xmlDoc = parser.parseFromString(fileContent, "text/xml");
    const meet = xmlDoc.getElementsByTagName("MEET");
    const course = meet[0].attributes.course.value;

    const { eventsArray, heatsArray, rankingArray } = getSessionArrays(xmlDoc, lefErrorArray);

    let clubs = xmlDoc.getElementsByTagName("CLUBS");
    processClubs(clubs, individualTimesArray, relayTimesArray, eventsArray, rankingArray, course, heatsArray, lefErrorArray);

    let meetObject = makeMeetObject(individualTimesArray, meetId);
    let relayMeetObject = makeMeetRelaysObject(relayTimesArray, meetId);
    let errorsObject = makeErrorsObject(lefErrorArray, meetId);


    setJsonFileState({
      ...jsonFileState,
      individualTimesContent: JSON.stringify(meetObject, null, 4),
      relayTimesContent: relayMeetObject.meet.relayEvents.length > 0 ? JSON.stringify(relayMeetObject, null, 4) : {},
      errorsContent: errorsObject.errors.length > 0 ? JSON.stringify(errorsObject, null, 4) : {},
      indTimesCount: individualTimesArray.length,
      relayTimesCount: relayTimesArray.length,
      errors: lefErrorArray.length,
      isValidFileType: isValidFileType,
      isZipFile: isZipFile
    });
  };

  const processFile = (fileContent, fileType) => {
    setUiState({...uiState, canUpload: true, message: ''});
  
    const isValidFileType = fileType.toUpperCase() === "SD3" || fileType.toUpperCase() === "CL2" || fileType.toUpperCase() === "LEF" || fileType.toUpperCase() === "ZIP";
    const isZipFile = fileType.toUpperCase() === "ZIP";

    if (fileContent.toString().includes('LENEX')) {
      processFileLef(fileContent, isValidFileType, isZipFile);
      return {};
    }

    const fileArray = fileContent.toString().replace(/\r\n/g, '\n').split('\n');
    let clubObj = { lscId: '', clubCode: '', clubName: '' };
    let individualTimesArray = [];
    let relayTimesArray = [];
    let fileArrayIndex = 0;
    let errorArray = [];
    let memberIdObj = { id: "" };
    
    if (checkFileStructure(fileArray, errorArray, fileArrayIndex) && isValidFileType && !isZipFile) {
      for (let fileLine of fileArray) {
        switch (fileLine.substring(0, 2)) {
          case 'C1':
            clubObj = makeClubObject(fileLine);
            break;
          case 'D0':
            makeIndividualTimesArray(fileLine, individualTimesArray, fileArray, fileArrayIndex + 1, clubObj, errorArray, memberIdObj);
            break;
          case 'E0':
            makeRelayArray(relayTimesArray, fileArray, fileArrayIndex, clubObj, errorArray, memberIdObj);
            break;
          default:
            break;
        }
        fileArrayIndex++;
      }

      let meetObject = makeMeetObject(individualTimesArray, meetId);
      let relayMeetObject = makeMeetRelaysObject(relayTimesArray, meetId);
      let errorsObject = makeErrorsObject(errorArray, meetId);

      setJsonFileState({
        ...jsonFileState,
        individualTimesContent: JSON.stringify(meetObject, null, 4),
        relayTimesContent: relayMeetObject.meet.relayEvents.length > 0 ? JSON.stringify(relayMeetObject, null, 4) : {},
        errorsContent: errorsObject.errors.length > 0 ? JSON.stringify(errorsObject, null, 4) : {},
        indTimesCount: individualTimesArray.length,
        relayTimesCount: relayTimesArray.length,
        errors: errorArray.length,
        isValidFileType: isValidFileType,
        isZipFile: isZipFile
      });
    } else {
      setJsonFileState({
        ...jsonFileState,
        indTimesCount: 0,
        relayTimesCount: 0,
        errors: errorArray.length,
        isValidFileType: isValidFileType,
        isZipFile: isZipFile
      });
    }
  };

  useEffect(() => {
    if (meetTimesStatusState.isObjLoaded === true) {
      getMeet(meetId);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetTimesStatusState]);
  

  useEffect(() => {
    getMeetTimesStatus(meetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialJSONFileState() {
    return {
      individualTimesContent: '',
      relayTimesContent: '',
      errorsContent: '',
      fileDownloadUrl: '',
      indTimesCount: 0,
      relayTimesCount: 0,
      errors: 0,
      isValidFileType: true,
      isZipFile: false
    };
  };

  return {
    meetState,
    jsonFileState,
    meetResultsState,
    meetRelayResultsState,
    uiState,
    meetTimesStatusState,
    processFile,
    onDownloadClicked,
    onSubmitMeetResults,
    onSubmitIncrementalMeetResults,
    INVALID_FILE_MESSAGE,
    ZIP_FILE_MESSAGE
  };
};

export default useMeetResultsUpload;