import { useEffect, useState } from 'react';

import { useNavigate } from '../../wrappers/ReactRouterDom';

import validate from './SelectOrgUnitValidation';

import usePersonOrgRoleData from '../../state/personOrgRole/UsePersonOrgRoleData';
import useSelectOrgUnitData from '../../state/selectOrgUnit/UseSelectOrgUnitData';
import useSecurityData from '../../state/security/UseSecurityData';
import useEnvironmentVariableData from '../../state/environmentVariable/UseEnvironmentVariableData';

import useForm from '../../utils/UseForm';

const useSelectOrgUnit = ({ navLink, roleGroupId, getFunction, TAXONOMIES, SCOPE }) => {
    const navigate = useNavigate();
    const { environmentVariableState } = useEnvironmentVariableData();
    const { personOrgRoleState, getPersonOrgRoleByRoleGroup } = usePersonOrgRoleData();
    const { selectOrgUnitState, setSelectOrgUnitState } = useSelectOrgUnitData();
    const { getContextSecurity } = useSecurityData();
    const [state, setState] = useState({ radioButtonList: [] });
    const { handleSubmit, formState, errorState, onFormValueChanged
    } = useForm(getInitialFormState, submitFormCallback, validate);

    function submitFormCallback() {
        setSelectOrgUnitState({
            ...selectOrgUnitState,
            orgUnitId: formState.orgUnitId,
            orgUnitName: personOrgRoleState.arrayData.find(x => x.orgUnitId === formState.orgUnitId)?.orgUnitName,
            showBackToSelection: true
        });
        if (getFunction && TAXONOMIES && SCOPE) {
            getFunction(formState.orgUnitId);
            getContextSecurity(formState.orgUnitId, TAXONOMIES, SCOPE);
        }
        navigate(navLink);
    };

    useEffect(() => {
        if (environmentVariableState.isLoaded === true) {
            getPersonOrgRoleByRoleGroup(0, roleGroupId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleGroupId, environmentVariableState])

    useEffect(() => {
        if (personOrgRoleState.isArrayLoading === false && personOrgRoleState.isArrayLoaded === true) {
            if (personOrgRoleState.arrayData.length === 1 && personOrgRoleState.arrayData[0].orgUnitId) {
                //Redirect if there is only one personOrgRole and it has an orgUnitId
                setSelectOrgUnitState({
                    ...selectOrgUnitState,
                    orgUnitId: personOrgRoleState.arrayData[0].orgUnitId,
                    orgUnitName: personOrgRoleState.arrayData[0].orgUnitName,
                    showBackToSelection: false
                });
                if (getFunction && TAXONOMIES && SCOPE) {
                    getFunction(personOrgRoleState.arrayData[0].orgUnitId);
                    getContextSecurity(personOrgRoleState.arrayData[0].orgUnitId, TAXONOMIES, SCOPE);
                }
                navigate(navLink);
            }
            else if (personOrgRoleState.arrayData.length > 1) {
                const radioButtonOptions = getRadioButtonList();
                //Redirect if all of the personOrgRoles are associated with the same orgUnitId
                if (radioButtonOptions.length === 1 && radioButtonOptions[0].value) {
                    setSelectOrgUnitState({
                        ...selectOrgUnitState,
                        orgUnitId: radioButtonOptions[0].value,
                        orgUnitName: radioButtonOptions[0].label,
                        showBackToSelection: false
                    });
                    if (getFunction && TAXONOMIES && SCOPE) {
                        getFunction(radioButtonOptions[0].value);
                        getContextSecurity(radioButtonOptions[0].value, TAXONOMIES, SCOPE);
                    }
                    navigate(navLink);
                }
                else {
                    //Otherwise show the radio button options
                    setState({ ...state, radioButtonList: radioButtonOptions });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personOrgRoleState])

    function getRadioButtonList() {
        const radiobuttonlist = personOrgRoleState.arrayData.map((personOrgRole) => {
            return {
                label: personOrgRole.orgUnitName || '',
                value: personOrgRole.orgUnitId || '',
            }
        })
        //remove any duplicate options
        const uniqueRadioButtonlist = radiobuttonlist.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.label === value.label && t.value === value.value
            ))
        )
        return uniqueRadioButtonlist;
    };

    function getInitialFormState() {
        return { orgUnitId: '' };
    };

    return {
        state,
        handleSubmit,
        formState,
        errorState,
        onFormValueChanged,
        personOrgRoleState
    };
};

export default useSelectOrgUnit;