import React, { Fragment } from 'react';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import Icon from './Icon';

import style from './ShoppingCartIcon.module.css';

const CartCountBubble = ({ cartCount }) => (
  <span className={style.CartCountBubble}>{cartCount || 0}</span>
);

const ShoppingCartIcon = ({ showCartCount = false, cartCount = 0 }) => {
  if (showCartCount === true && cartCount > 0) {
    return (
      <Fragment>
        <Icon tooltip={`Shopping Cart has ${cartCount} unpaid item(s)`} iconObj={faShoppingCart} />
        <CartCountBubble cartCount={cartCount} />
      </Fragment>
    );
  } else {
    return (
      <Icon tooltip="Shopping Cart" iconObj={faShoppingCart} />
    );
  }
};

export default ShoppingCartIcon;