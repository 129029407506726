import { useContext, useState } from 'react';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import PaymentsData from './PaymentsData';

import { PaymentsStateContext } from './PaymentsContextProvider';

const INITIAL_PAYMENT_RECEIPT_STATE = {
  ...BASIC_INITIAL_STATE
};

const usePaymentsData = () => {
  const [paymentsState, setPaymentsState] = useContext(PaymentsStateContext);
  const [paymentReceiptState, setPaymentReceiptState] = useState(INITIAL_PAYMENT_RECEIPT_STATE);
  const [cartCountState, setCartCountState] = useState({});

  const getPaymentReceipt = (receiptNumber) => {
    return PaymentsData.getPaymentReceiptData(receiptNumber, paymentReceiptState, setPaymentReceiptState);
  };

  const getCartCount = (orgUnitId, itemSubsystemId, itemContract, referenceId) => {
    return PaymentsData.getCartCountData(orgUnitId, itemSubsystemId, itemContract, referenceId, cartCountState, setCartCountState);
  };

  const setCartChanged = (cartChanged) => {
    setPaymentsState({
      ...paymentsState,
      cartChanged
    });
  };

  const resetPaymentsReceiptState = () => {
    setPaymentReceiptState(INITIAL_PAYMENT_RECEIPT_STATE);
  };

  return {
    cartChanged: paymentsState.cartChanged === true,
    paymentsState,
    cartCountState,
    paymentReceiptState,
    getPaymentReceipt,
    getCartCount,
    setCartChanged,
    resetPaymentsReceiptState
  };
};

export default usePaymentsData;