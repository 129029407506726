import { useContext } from 'react';

import { LscStateContext } from './LscContextProvider';

import LscData from './LscData';

const useLscData = () => {
  const [lscState, setLscState] = useContext(LscStateContext);
  const getLscs = () => { LscData.getLscs(lscState, setLscState); }

  return {
    lscState,
    getLscs
  };
};

export default useLscData;