import { FormatMoneyWithSymbol } from '../../../../utils/FormatMoney';

import Headings from '../../../headings/Headings';
import YesNoSwitch from '../../../yesNoSwitch/YesNoSwitch';

import style from '../../Payments.module.css'

const TechFee = ({ technologyFee, coverTechnologyFee, onValueChanged }) => (
  <div className="col-xs-12">
    <div className={style.OptionalBox}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H5>Cover the {FormatMoneyWithSymbol(technologyFee)} Credit Card Transaction Fee?</Headings.H5>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-10">
          You have the option to help USA Swimming cover the cost of your credit card transaction fee. Note: USA Swimming
          cannot accept transaction fee contributions if you are paying by e-check or debit card; only opt to cover the
          transaction fee if you are paying by credit card. If you also elected to donate to the USA Swimming Foundation,
          that transaction fee is included below.
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-6 col-md-4">
          <YesNoSwitch
            name="coverTechnologyFee"
            checked={coverTechnologyFee === true}
            onChange={(newValue) => { onValueChanged('coverTechnologyFee', newValue); }}
          />
        </div>
        <div className="col-xs-6 col-md-8">
          <div className={[style.Bold, "text-right"].join(' ')}>
            {FormatMoneyWithSymbol(coverTechnologyFee === true ? technologyFee : 0)}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default TechFee;