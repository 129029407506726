import { useState } from 'react';
import EmbeddedReportData from './EmbeddedReportData';

const useEmbeddedReportData = () => {
  const [reportState, setReportState] = useState({ ...EmbeddedReportData.INITIAL_STATE });
  const [reportListState, setReportListState] = useState({ ...EmbeddedReportData.INITIAL_STATE });

  const getEmbeddedReport = (reportInfoId, reportParameters) => {
    return EmbeddedReportData.getEmbeddedReport(reportInfoId, reportParameters, reportState, setReportState);
  };

  const getEmbeddedReportListForRoute = (routeName) => {
    return EmbeddedReportData.getEmbeddedReportListForRoute(routeName, reportListState, setReportListState);
  };

  const getEmbeddedReportListForContext = (routeName, contextId) => {
    return EmbeddedReportData.getEmbeddedReportListForContext(routeName, contextId, reportListState, setReportListState);
  };

  return {
    reportState,
    reportListState,
    getEmbeddedReport,
    getEmbeddedReportListForRoute,
    getEmbeddedReportListForContext
  };
};

export default useEmbeddedReportData;