import { useState } from 'react';
import MeetReconData from './MeetReconData';

const useMeetReconData = () => {
  const [meetReconState, setMeetReconState] = useState(MeetReconData.INITIAL_STATE);

  const postMeetRecon = (meetReconObj) => MeetReconData.postMeetReconData(meetReconObj, meetReconState, setMeetReconState);

  return {
    meetReconState,
    postMeetRecon
  };
}

export default useMeetReconData;