import React, { Fragment } from 'react';

import AddMeetTimeLargeGrid from './AddMeetTimeLargeGrid';
import AddMeetTimeSmallGrid from './AddMeetTimeSmallGrid';

import useExpandedRow from '../../../../../common/components/grids/UseExpandedRow';

const AddMeetTimesGrid = ({ state }) => {
  const { onClick, expandedId } = useExpandedRow();

  return (
    <Fragment>
      <AddMeetTimeLargeGrid
        state={state}
        expandedId={expandedId}
        onClick={onClick}
      />
      <AddMeetTimeSmallGrid
        state={state}
        expandedId={expandedId}
        onClick={onClick}
      />
    </Fragment>
  )
};

export default AddMeetTimesGrid;