import CountryData from './CountryData';

import Constants from '../../utils/Constants';

const useCountryData = () => {
  const countryData = CountryData;

  const convertDataToIdNamePair = (arrayData) => {
    const countryArray = arrayData.map(country => { return { id: country.code, name: country.name}});
    countryArray.unshift({id: Constants.DEFAULT_ID, name: ''});
    return countryArray;
  };
  
  const getCountryState = () => {
    return convertDataToIdNamePair(countryData);
  };

  return {
    countryData,
    getCountryState
  };
};

export default useCountryData;