/* eslint-disable no-unused-vars */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import useYesNoSwitch from './UseYesNoSwitch';

import global from '../GlobalStyle.module.css';
import style from './YesNoSwitch.module.css';
import { Fragment } from 'react';

const NotDefined = ({ disabled }) => {
  const textStyle = disabled === true
    ? [style.NA, style.Disabled].join(' ')
    : style.NA;
  const iconStyle = disabled === true
    ? [style.NAIcon, style.DisabledIcon].join(' ')
    : style.NAIcon;
  return (
    <Fragment>
      <span className={[style.YesIcon, iconStyle].join(' ')}>
        <FontAwesomeIcon
          icon={faCheck}
          aria-hidden="true"
        />
      </span>
      <span className={textStyle}>&nbsp;</span>
      <span className={[style.NoIcon, iconStyle].join(' ')}>
        <FontAwesomeIcon
          icon={faTimes}
          aria-hidden="true"
        />
      </span>
    </Fragment>
  );
};

const Yes = ({ disabled }) => {
  const textStyle = disabled === true
    ? [style.Yes, style.Disabled].join(' ')
    : style.Yes;
  const iconStyle = disabled === true
    ? [style.YesIcon, style.DisabledIcon].join(' ')
    : style.YesIcon;
  return (
    <Fragment>
      <span className={iconStyle}>
        <FontAwesomeIcon
          icon={faCheck}
          aria-hidden="true"
        />
      </span>
      <span className={textStyle}>Yes</span>
    </Fragment>
  );
};

const No = ({ disabled }) => {
  const textStyle = disabled === true
    ? [style.No, style.Disabled].join(' ')
    : style.No;
  const iconStyle = disabled === true
    ? [style.NoIcon, style.DisabledIcon].join(' ')
    : style.NoIcon;
  return (
    <Fragment>
      <span className={textStyle}>No</span>
      <span className={iconStyle}>
        <FontAwesomeIcon
          icon={faTimes}
          aria-hidden="true"
        />
      </span>
    </Fragment>
  );
};

const YesNoSwitch = ({ error, className, label, message, name, checked, onChange, disabled, isHorizontal = false }) => {
  const { fullClassAttribute, iconClass, isDisabled, onChanged }
    = useYesNoSwitch(className, error, style, global, disabled, checked, onChange);
  return (
    <div className={global.ComponentFlex}>
      <label htmlFor={name} className={style.Label}>
          <input
            type="checkbox"
            checked={checked}
            id={name}
            name={name}
            onChange={onChanged}
            disabled={isDisabled === true}
          />
        <label className={[global.UsasLabel, isHorizontal === true ? style.Horizontal : ''].join(' ')} htmlFor={name}>{label}</label>
        {checked === true ?
          (<Yes disabled={disabled} />)
          : checked === false ?
            (<No disabled={disabled} />)
            : (<NotDefined disabled={disabled} />)}
      </label>
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
};

export default YesNoSwitch;