import useDropdown from './UseDropdown';

import ReadOnly from '../readOnly/ReadOnly';

import global from '../GlobalStyle.module.css';

const Dropdown = ({ error, label, message, options, name, value, onChange, 
  isLoading, areValuesIntegers, defaultValue, notSelectedLabel, setDefaults }) => {
  const { selectedValue, selectedName, isReadWrite, handleChange } 
    = useDropdown(value, options, onChange, areValuesIntegers, defaultValue, setDefaults);

  if (isReadWrite === true) {
    return (
      <div className={global.ComponentFlex}>
        {label && <label className={global.UsasLabel} htmlFor={name}>{label}</label>}
        {isLoading === true
          ? <div className={global.InputLoading}>Loading...</div>
          : <select
            className={error ? global.Error : global.BaseInputBox}
            value={value}
            name={name}
            id={name}
            onChange={handleChange}
          >
            <option value="" disabled hidden>{notSelectedLabel || 'Select'}</option>
            {options.map((option, i) => <option key={i} value={option.id}>{option.name}</option>)}
          </select>}
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    );
  } else {
    return (
      <ReadOnly
        label={label}
        name={selectedName}
        value={selectedValue} />
    );
  }
};

export default Dropdown;