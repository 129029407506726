import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../utils/Constants';

export const CACHE_NAME = 'Organization';
export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  dataAsOptionsWithCodes: [],
  quickAddOrganizationsAsOptions: [],
  timeRecognizedOrganizationsAsOptions: [],
  message: ''
};
export const defaultOrganizationState = {
  organizationId: Constants.DEFAULT_ID,
  organizationName: '',
  organizationCode: '',
  modifiedDatetime: '',
  allowQuickAdd: false
};

const getOrganizations = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);
    const url = `/Organization`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const orgsForQuickAdd = data.filter(x => x.allowQuickAdd === true);
          const quickAddOrganizationsAsOptions = orgsForQuickAdd.map((organization) => { return { id: organization.organizationId, name: organization.organizationCode } });
          const orgForTimesRecognized = data.filter(x => x.isTimeRecognized === true);
          const timeRecognizedOrganizationsAsOptions = orgForTimesRecognized.map((organization) => { return { id: organization.organizationId, name: organization.organizationName } });
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((organization) => { return { id: organization.organizationId, name: organization.organizationName } }),
            dataAsOptionsWithCodes: data.map((organization) => { return { id: organization.organizationId, name: organization.organizationCode } }),
            quickAddOrganizationsAsOptions,
            timeRecognizedOrganizationsAsOptions,
            message: '',
            orgUnitId: 26, //TODO not ok
            orgUnitName: 'Indiana Swim Club (IN/IST)'
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const OrganizationData = { CACHE_NAME, INITIAL_STATE, getOrganizations };
export default OrganizationData; 