import { Fragment } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import useCheckout from './UseCheckout';

import StripeCheckoutForm from '../../components/forms/StripeCheckoutForm';
import ShoppingCartLoadingModal from '../../components/dialogs/ShoppingCartLoadingModal';
import ZeroDollarCheckout from '../../components/miniViews/ZeroDollarCheckout';
import PayByCheckCheckout from '../../components/miniViews/PayByCheckCheckout';

import { CLIENT_SECRET_CHECK_TRANSACTION, CLIENT_SECRET_ZERO_DOLLAR_TRANSACTION } from '../../utils/PaymentsConstants';

// Note from Stripe:
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

/**
 * Sub view that holds the checkout form. Allows for payment transactions via stripe.
 * @param {Function} onReturnClicked optional return button, only shown if no payment was due
 */
const Checkout = ({ onReturnClicked }) => {
  const {
    isLoading,
    clientSecret,
    orderId,
    totalAmount,
    swimmingWorldOptIn,
    onTransactionComplete,
    resultPromise,
    onCancelClicked,
    onBackToCartClicked
  } = useCheckout();

  if (clientSecret === CLIENT_SECRET_ZERO_DOLLAR_TRANSACTION) {
    return <ZeroDollarCheckout onReturnClicked={onReturnClicked} onBackToCartClicked={onBackToCartClicked} />;
  } else if (clientSecret === CLIENT_SECRET_CHECK_TRANSACTION) {
    return <PayByCheckCheckout onReturnClicked={onReturnClicked} onBackToCartClicked={onBackToCartClicked} />;
  } else if (clientSecret && orderId) {
    return (
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <StripeCheckoutForm orderId={orderId} totalAmount={totalAmount} swimmingWorldOptIn={swimmingWorldOptIn} onTransactionComplete={onTransactionComplete} onCancelClicked={onCancelClicked} resultPromise={resultPromise} />
      </Elements>
    );
  } else {
    return (
      <Fragment>
        <ShoppingCartLoadingModal isLoading={isLoading} loadingMessage={'Checking Out...'} />
      </Fragment>
    );
  }
};

export default Checkout;