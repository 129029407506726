import React, { Fragment } from 'react';

import useMemberContactPhoneDetail from './UseMemberContactPhoneDetail';

import Headings from '../headings/Headings';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import YesNoSwitch from '../yesNoSwitch/YesNoSwitch';
import InternationalPhoneInput from '../inputs/InternationalPhoneInput';
import PopUpModal from '../dialogs/PopUpModal';
import Dropdown from '../dropdowns/Dropdown';
import Constants from '../../utils/Constants';

const MemberContactPhoneDetail = ({ acknowledgementsView, personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState }) => {
  const {
    personGeneralState,
    formState,
    errorState,
    state,
    modalState,
    onSavePhone,
    onCancelClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    onModalCancelClicked,
    putPersonGeneralContactPhones,
    phoneTypeOptions
  } = useMemberContactPhoneDetail(acknowledgementsView, personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState);

  return (
    <Fragment>
      {acknowledgementsView === true ?
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Headings.H4>{formState.personContactPhoneId > 0 ? "Edit" : "Add"} Phone Number</Headings.H4>
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H5>Selected Member: {personAcknowledgementInfoPageViewState.selectedPerson?.firstName || ''} {personAcknowledgementInfoPageViewState.selectedPerson?.middleName || ''}  {personAcknowledgementInfoPageViewState.selectedPerson?.lastName || ''}</Headings.H5>
          </div>
        </div>
        :
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>{formState.personContactPhoneId > 0 ? "Edit" : "Add"} Phone Number</Headings.H3>
          </div>
        </div>}
      <div className="row">
        <div className="col-xs-12">
          <p><b>Contact Name:</b> {state.personContactName}</p>
        </div>
      </div>
      <form onSubmit={onSavePhone} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Phone Type*"
              name="phoneTypeId"
              value={formState.phoneTypeId}
              error={errorState.phoneTypeId}
              message={errorState.phoneTypeId}
              options={phoneTypeOptions}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'phoneTypeId', newValueLabel, 'phoneTypeName');
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <InternationalPhoneInput
              label="Phone Number*"
              name="phoneNumber"
              value={formState.phoneNumber}
              error={errorState.phoneNumber}
              message={errorState.phoneNumber}
              onChange={(value) => onFormValueChanged('phoneNumber', value)} />
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <YesNoSwitch
              label="Primary Phone?"
              name="isPrimary"
              checked={formState.isPrimary === true}
              error={errorState.isPrimary}
              message={errorState.isPrimary}
              onChange={(value) => { onFormValueChanged('isPrimary', value); }}
              disabled={state.disablePrimaryCheckbox === true} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personGeneralState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personGeneralState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Current Primary Phone Number:</b> {modalState.currentPrimaryInfo}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => putPersonGeneralContactPhones(true)}>Yes</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment >
  );
};

export default MemberContactPhoneDetail;