import { useState, useEffect } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './AddMeetTimeRelayValidation';

import { getMeetDates } from '../utils/MeetUtils';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetTimesData from '../../../../../common/state/meet/meetTimes/UseMeetTimesData';
import useEventData from '../../../../../common/state/event/UseEventData';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';

import NavLinks from '../../NavLinks';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import useLscData from '../../../../../common/state/lsc/UseLscData';

const useAddMeetTimeRelay = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(getInitialState);
  const { meetState } = useMeetData();
  const { lscState } = useLscData();
  const { eventState, getEvents } = useEventData();
  const { meetTimeAddPostDataState, setMeetTimeAddPostDataState, postMeetTime, meetTimesAddState } = useMeetTimesData();
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged,
    setFormData
  } = useForm(getInitialFormState, submitFormCallback, validate);

  const [meetDatesState, setMeetDatesState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select a Swim Date" }
  ]);

  const [eventCompetitionGenderTypeState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select an Event Gender" },
    { id: 1, name: "Male" },
    { id: 2, name: "Female" },
    { id: 3, name: "Mixed" }
  ]);

  const [sessionTypeState] = useState([
    { id: Constants.DEFAULT_ID, name: "Select a Session Type" },
    { id: 1, name: "Prelim" },
    { id: 2, name: "SwimOff" },
    { id: 3, name: "Final" },
    { id: 4, name: "SemiFinal" },
    { id: 5, name: "QuarterFinal" },
    { id: 6, name: "TimedFinal" }
  ]);

  function submitFormCallback(formState) {
    postMeetTime(createMeetTimeRelayObject(formState))
    setState({
      ...state,
      tryPageRedirect: true
    });
  }

  const createMeetTimeRelayObject = (formState) => {
    return {
      personId: null,
      eventId: formState.eventId,
      meetId: formState.meetId,
      eventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
      sessionId: formState.sessionId,
      time: formatTimeForFilterObject(formState.time),
      swimDate: formState.swimDate,
      finishPosition: parseInt(formState.finishPosition.trim()),
      isLeadOffTime: null,
      teamName: formState.teamName.trim(),
      lscCode: lscState.data.find(x => x.orgUnitId === formState.lscId).orgUnitCode,
      clubCode: formState.clubCode,
      swimTimeRelay: [
        {
          personId: formState.personId1,
          legNumber: 1,
          legEventId: formState.eventId,
          legTime: formatTimeForFilterObject(formState.time1)
        },
        {
          personId: formState.personId2,
          legNumber: 2,
          legEventId: formState.eventId,
          legTime: formatTimeForFilterObject(formState.time2)
        },
        {
          personId: formState.personId3,
          legNumber: 3,
          legEventId: formState.eventId,
          legTime: formatTimeForFilterObject(formState.time3)
        },
        {
          personId: formState.personId4,
          legNumber: 4,
          legEventId: formState.eventId,
          legTime: formatTimeForFilterObject(formState.time4)
        }]
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME_SELECT_TIME_TYPE);
  };

  useEffect(() => {
    if (meetTimeAddPostDataState.isSaved === true
      && state.tryPageRedirect === true) {
      const newArrayData = meetTimeAddPostDataState.arrayData;
      newArrayData.push(meetTimeAddPostDataState.objData);
      setMeetTimeAddPostDataState({
        ...meetTimeAddPostDataState,
        arrayData: newArrayData,
        isSaved: false
      });
      navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetTimeAddPostDataState]);

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      const startDate = formatDate(meetState.objData.startDate);
      const endDate = formatDate(meetState.objData.endDate);
      const meetDatesRange = getMeetDates(startDate, endDate).map((date, i) => {
        return {
          id: i + 1,
          name: date
        }
      })
      setMeetDatesState([
        { id: Constants.DEFAULT_ID, name: "Select a Swim Date" },
        ...meetDatesRange
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState]);

  useEffect(() => {
    if (eventState.isArrayLoaded === false) {
      getEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventState.isArrayLoaded]);

  return {
    formState,
    errorState,
    eventState,
    meetTimeAddPostDataState,
    meetDatesState,
    sessionTypeState,
    eventCompetitionGenderTypeState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onCancelClicked,
    setFormData
  };

  function getInitialFormState() {
    return {
      personId1: Constants.DEFAULT_ID,
      pesonName1: '',
      personGender1: '',
      personId2: Constants.DEFAULT_ID,
      pesonName2: '',
      personGender2: '',
      personId3: Constants.DEFAULT_ID,
      pesonName3: '',
      personGender3: '',
      personId4: Constants.DEFAULT_ID,
      pesonName4: '',
      personGender4: '',
      eventId: Constants.DEFAULT_ID,
      meetId: meetState.objData.meetId,
      eventCompetitionGenderTypeId: Constants.DEFAULT_ID,
      sessionId: Constants.DEFAULT_ID,
      teamName: '',
      time: '',
      time1: '',
      time2: '',
      time3: '',
      time4: '',
      swimDate: '',
      swimDateId: Constants.DEFAULT_ID,
      finishPosition: '',
      eventName: '',
      memberId1: '',
      memberId2: '',
      memberId3: '',
      memberId4: '',
      lscId: '',
      clubCode: ''
    };
  }

  function getInitialState() {
    return {
      tryPageRedirect: false
    };
  };
}

export default useAddMeetTimeRelay;