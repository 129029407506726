import { useState } from "react";

import useAccountOneTimePasswordData from "../../../../../../state/account/accountOneTimePassword/UseAccountOneTimePasswordData";

import { setStoredToken } from "../../../../../../utils/TokenUtils";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useSessionEndingOneTimePassword = (popupState, onPopupClosed) => {
  const { accountOneTimePasswordState, postAccountOneTimePassword, resetAccountOneTimePasswordState
  } = useAccountOneTimePasswordData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onCloseLoginErrorPopupClicked = () => {
    resetAccountOneTimePasswordState();
    setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const href = popupState.loginData.href;
    const oneTimePassword = formState.oneTimePassword;

    const postAccountOneTimePasswordPromise = postAccountOneTimePassword(href, oneTimePassword);

    if (postAccountOneTimePasswordPromise ?? false) {
      postAccountOneTimePasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData.accessToken === null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: 'The one-time password is invalid.'
            });
          } else {
            setStoredToken(newState.objData.accessToken, newState.objData.refreshToken, newState.objData.expirationDatetime, newState.objData.username);
            onPopupClosed(false);
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountOneTimePasswordState.isObjLoading,
    device: popupState.loginData?.device || '',
    loginErrorPopupState,
    onSubmitFormCallback,
    onCloseLoginErrorPopupClicked
  };
};

export default useSessionEndingOneTimePassword;