import useLscCombobox from './UseLscCombobox';

import Combobox from '../Combobox';

import global from '../../GlobalStyle.module.css';

const LscCombobox = ({ label, name, valueToMatch, error, message, onChange }) => {
  const { lscState } = useLscCombobox();

  return lscState.message
    ? <span className={global.LoadingMsg}>{lscState.message}</span>
    : (
      <Combobox
        label={label}
        name={name}
        isLoading={lscState.isLoading}
        items={lscState.dataAsOptionsWithNames}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
    );
};

export default LscCombobox;