import React, { createContext, useState } from 'react';

import MeetSoftwareData from './MeetSoftwareData';

export const MeetSoftwareStateContext = createContext();

const MeetSoftwareContextProvider = ({ children }) => {
  const stateHook = useState(MeetSoftwareData.INITIAL_STATE);

  return (
    <MeetSoftwareStateContext.Provider value={stateHook}>
      {children}
    </MeetSoftwareStateContext.Provider>
  );
};

export default MeetSoftwareContextProvider;