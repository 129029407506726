import React from 'react';

import LinkButton from '../buttons/LinkButton';

import global from '../GlobalStyle.module.css';

const ReportGridSmall = ({ reportList, onReportSelected }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {reportList.map((report, i) => (
      <div className={global.SmallTableRow} key={i}>
        <div className={global.SmallTableRowHead}>&nbsp;</div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={'col-xs-12'}>
              <LinkButton action={(e) => {
                e?.preventDefault();
                onReportSelected(report.reportInfoId);
              }}><b>{report.reportTitle}</b></LinkButton>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);


export default ReportGridSmall;