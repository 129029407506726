import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import postMeetReconData from './PostMeetReconData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetReconData = {
  INITIAL_STATE, postMeetReconData
};

export default MeetReconData;