import { useContext, useEffect } from 'react';
import useSecurityData from './UseSecurityData';
import { NavRoutesStateContext } from './SecurityContextProvider';

const useNavRoutes = () => {
  const {securityState, contextSecurityState} = useSecurityData();
  const [ navRoutesState, setNavRoutesState ] = useContext(NavRoutesStateContext);

  const flattenNavRoutes = () => {
    const navRoutes = {};
    (securityState.userInfo?.appRoutes || [])
      .filter(x => x.uIProjectName === import.meta.env.VITE_PROJECT_NAME)
      .forEach(appRoute => {
        navRoutes[appRoute.routeName] = {...appRoute};
      });
    (contextSecurityState.objData?.appRoutes || [])
      .filter(x => x.uIProjectName === import.meta.env.VITE_PROJECT_NAME)
      .forEach(appRoute => {
        navRoutes[appRoute.routeName] = {...appRoute};
      });
    
    return navRoutes;
  };

  const areNavRouteObjectsDifferent = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return true;
    }
    for (let key of keys1) {
      if (!object1[key].routeName || !object2[key].routeName || object1[key].routeName !== object2[key].routeName) {
        return true;
      }
    }
    return false;
  };
  
  useEffect(() => {
    const navRoutes = flattenNavRoutes();
    if (areNavRouteObjectsDifferent(navRoutes, navRoutesState.navRoutes) === true) {
      setNavRoutesState({
        isReadyToRoute: true,
        navRoutes
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityState.userInfo?.appRoutes, contextSecurityState.objData?.appRoutes]);
  
  return {
    ...navRoutesState
  };
};

export default useNavRoutes;