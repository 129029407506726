import React, { Fragment } from 'react';

import Pill from './Pill';

import style from './PillList.module.css';

const PillList = ({ pillsState }) => {
  
  return Array.isArray(pillsState) === true && pillsState.length > 0
    ? (
        <ul className={style.PillNav}>
          {pillsState.map((navLink, i) => {
            return navLink.linkUrl && navLink.displayName
              ? <Pill navLink={navLink} key={i} />
              : <Fragment />;})}
        </ul>
      )
    : <Fragment />;
};

export default PillList;