import React from 'react';

import Headings from '../../../common/components/headings/Headings';

import { Dialog } from '@reach/dialog';
import {VisuallyHidden} from "@reach/visually-hidden";

import ModalWindowCloseIcon from '../icons/ModalWidowCloseIcon';

import '@reach/dialog/styles.css';
import style from './Dialogs.module.css';

// NOTE:  using style attribue is ok here because the width values are calculated based upon 
// props that are passed into the component.
const PopUpModal = ({ title, displayPopUp, widthPct, maxWidth, children, onModalCanceled, className, id }) => {

  return (

    <Dialog
      className={className}
      isOpen={displayPopUp}
      allowPinchZoom={true}
      aria-label={title || 'title'}
      style={{
        width: Number.isInteger(widthPct) ? widthPct.toString() + 'vw' : '50vw',
        maxWidth: Number.isInteger(maxWidth) ? maxWidth.toString() + 'px' : '1140px'
      }}>
      {id && <div id={id} />}
      <div className="row">
        <div className="col-xs-10">
          <Headings.H6>{title}</Headings.H6>
        </div>
        <div className="col-xs-2">
          {onModalCanceled && (
            <button className={style.CloseIcon} type="button" onClick={onModalCanceled}>
              <VisuallyHidden>Cancel</VisuallyHidden>
              <ModalWindowCloseIcon />
            </button>
          )}
        </div>
      </div>
      <hr />
      {children}
    </Dialog>
  );
}
export default PopUpModal;