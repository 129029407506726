import React, { createContext, useState } from 'react';

export const TimeFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  backToSystem: 'Times',
  showFilters: true
};
const TimeFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <TimeFiltersStateContext.Provider value={stateHook}>
      {children}
    </TimeFiltersStateContext.Provider>
  );
};

export default TimeFiltersContextProvider;