const INITIAL_STATE = {
  orgUnitId: '',
  orgUnitName: '',
  showBackToSelection: false
};

const SelectOrgUnitData = {
  INITIAL_STATE,
}

export default SelectOrgUnitData;