import React from 'react';

import style from './ExistingMemberInfoReadOnly.module.css';
import global from '../../../GlobalStyle.module.css';

const ExistingMemberInfoReadOnly = ({ label, name, value, className }) => (
  <div className={global.ComponentFlex}>
    <label className={style.ReadOnlyLabel} htmlFor={name}>{label}</label>
    <div>
      <input
        className={className}
        type="hidden"
        value={value}
        id={name}
        name={name} />
      <span className={style.ReadOnlyValue}>{value || <span>&nbsp;</span>}</span>
    </div>
  </div>
);

export default ExistingMemberInfoReadOnly;