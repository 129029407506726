import { useContext } from 'react';
import { GlobalRoutesContext } from './GlobalRoutesContextProvider';
import GlobalRoutesData from './GlobalRoutesData';

const useGlobalRoutesData = () => {
  const [globalRoutesState, setGlobalRoutesState] = useContext(GlobalRoutesContext);
  
  const getGlobalRoutesData = () => {
    GlobalRoutesData.getGlobalRoutesData(globalRoutesState, setGlobalRoutesState);
  };

  const getGlobalRoute = (routeName) => {
    const ary = globalRoutesState.arrayData.filter(route => route.routeName === routeName);
    if (ary.length > 1) {
      const projectName = import.meta.env.VITE_PROJECT_NAME;
      const prjValue = ary.find(route => route.uiProjectName === projectName);
      if (prjValue && prjValue.routeName) {
        return prjValue;
      }
    }

    return ary[0];
  };
  
  return {
    getGlobalRoutesData,
    globalRoutesState,
    getGlobalRoute
  };
};

export default useGlobalRoutesData;