import { useContext } from 'react';

import TimeData from './TimeData';
import { TimeStateContext } from './TimeContextProvider';
import { TimeFiltersStateContext } from './TimeFiltersContextProvider';
import { TimesOrganizationStateContext } from './TimesOrganizationContextProvider';
import { SessionStateContext } from './SessionContextProvider';

const useTimeData = () => {
  const [timeState, setTimeState] = useContext(TimeStateContext);
  const [timeFiltersState, setTimeFiltersState] = useContext(TimeFiltersStateContext);
  const [timesOrganizationState, setTimesOrganizationState] = useContext(TimesOrganizationStateContext);
  const [sessionState, setSessionState] = useContext(SessionStateContext);

  const searchTimes = (filterObject, sortBy) => {
    setTimeFiltersState({ ...timeFiltersState, filterObject, sortBy });
    TimeData.searchTimes(JSON.stringify(filterObject), sortBy, timeState, setTimeState);
  };

  const searchTimesEventRank = (filterObject) => {
    setTimeFiltersState({ ...timeFiltersState, filterObject });
    return TimeData.searchTimesEventRankData(JSON.stringify(filterObject), timeState, setTimeState);
  };

  const getTime = (swimTimeId) => {
    TimeData.getTime(swimTimeId, timeState, setTimeState);
  };

  const getTimesOrganization = () => {
    TimeData.getTimesOrganization(timesOrganizationState, setTimesOrganizationState);
  };

  const getTimesAthleteRoster = (orgUnitIdArray) => {
    TimeData.getTimesAthleteRoster(orgUnitIdArray, timesOrganizationState, setTimesOrganizationState);
  };

  const getSession = () => {
    TimeData.getSession(sessionState, setSessionState);
  };

  const putTime = (swimTimeId, timeObj) => {
    TimeData.putTime(swimTimeId, timeObj, timeState, setTimeState);
  };

  const deleteTime = (swimTimeId) => {
    TimeData.deleteTime(swimTimeId, timeState, setTimeState);
  };

  const clearObjData = () => {
    setTimeState({
      ...timeState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearSearchResults = () => {
    setTimeState({
      ...timeState,
      isArrayLoaded: false,
      isArrayLoading: false,
      arrayData: [],
      message: ''
    })
  };

  const toggleTimeFilters = () => {
    setTimeFiltersState({
      ...timeFiltersState,
      showFilters: !timeFiltersState.showFilters
    });
  };

  return {
    timeState,
    timeFiltersState,
    setTimeFiltersState,
    timesOrganizationState,
    setTimesOrganizationState,
    sessionState,
    searchTimes,
    searchTimesEventRank,
    getTime,
    getTimesOrganization,
    getTimesAthleteRoster,
    getSession,
    putTime,
    deleteTime,
    clearObjData,
    clearSearchResults,
    toggleTimeFilters
  };
};

export default useTimeData;