import { formatTimeForDisplay } from "./TimesUtils";

export const compareAlphabetical = (a, b) => {
  try {
    return a.localeCompare(b, undefined, { ignorePunctuation: true });
  } catch {
    return 0;
  }
};

export const compareNumerical = (a, b) => {
  try {
    if (a > b) {
      return -1;
    } else if (a < b) {
      return 1;
    } else {
      return 0;
    }
  } catch {
    return 0;
  }
};

export const compareDate = (a, b) => {
  try {
    const aDate = new Date(a);
    const bDate = new Date(b);

    if (aDate > bDate) {
      return -1;
    } else if (aDate < bDate) {
      return 1;
    } else {
      return 0;
    }
  } catch {
    return 0;
  }
};

export const compareTime = (a, b) => {
  try {
    const aTime = formatTimeForDisplay(a);
    const bTime = formatTimeForDisplay(b);

    if (aTime.length < bTime.length) {
      return -1;
    } else if (aTime.length > bTime.length) {
      return 1;
    } else if (aTime < bTime) {
      return -1;
    } else if (aTime > bTime) {
      return 1;
    } else {
      return 0;
    }
  } catch {
    return 0;
  }
};

export const compareLength = (a, b) => {
  try {
    if (a.length < b.length) {
      return -1;
    } else if (a.length > b.length) {
      return 1;
    } else if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    } else {
      return 0;
    }
  } catch {
    return 0;
  }
};