import React, { createContext, useState } from 'react';

import PersonGeneralData from './PersonGeneralData';

export const PersonGeneralStateContext = createContext();

const PersonGeneralContextProvider = ({ children }) => {
  const stateHook = useState(PersonGeneralData.INITIAL_STATE);

  return (
    <PersonGeneralStateContext.Provider value={stateHook}>
      {children}
    </PersonGeneralStateContext.Provider>
  );
};

export default PersonGeneralContextProvider;