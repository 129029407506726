import { Fragment } from 'react';

import useShoppingCartLoadingModal from './UseShoppingCartLoadingModal';

import PopUpModal from '../../../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

import { LOADING_MSG } from '../../../../../common/utils/Constants';

const ShoppingCartLoadingModal = ({ isLoading, loadingMessage = LOADING_MSG, onPaymentCancel }) => {
  const {
    shoppingCartErrorState,
    onReloadShoppingCartClicked
  } = useShoppingCartLoadingModal();

  if (shoppingCartErrorState.isCartError === true) {
    return (
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={shoppingCartErrorState.errorTitle}
        displayPopUp={true}
        onModalCanceled={onReloadShoppingCartClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p>{shoppingCartErrorState.errorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            {onPaymentCancel &&
              <Fragment><SecondaryButton type="button" onClick={onPaymentCancel}>Exit Shopping Cart</SecondaryButton>&nbsp;</Fragment>
            }
            <PrimaryButton type="button" onClick={onReloadShoppingCartClicked}>{shoppingCartErrorState.redirectToLogin === true ? 'Go To Login' : 'Reload Shopping Cart'}</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
    );
  } else {
    return (
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={loadingMessage}
        displayPopUp={isLoading === true} />
    );
  }
};

export default ShoppingCartLoadingModal;