import { Fragment } from 'react';

import useTopHeader from './UseTopHeader';

import MenuLink from '../../MenuLink';
import ShoppingCartIcon from '../../../icons/ShoppingCartIcon';
import UserIcon from '../../../icons/UserIcon';
import GearIcon from '../../../icons/GearIcon';

import style from '../Header.module.css';

const TopHeader = () => {
  const {
    menuItems,
    //hasUnpaidTransactions,
    isLoggedIn,
    userName,
    showLogin,
    cartCount,
    onShoppingCartClicked,
    onLoginClicked,
    onSettingsClicked,
    onLogoutClicked,
  } = useTopHeader();

  return (
    <nav className={style.TopHeader}>
      <ul>
        {Array.isArray(menuItems) && menuItems.map((menuItem, i) => <li key={i} className='hidden-xs hidden-sm'><MenuLink menuItem={menuItem} /></li>)}
        {import.meta.env.VITE_TIMES_MICROSITE_UI_DOMAIN === 'self' ? <Fragment /> :
          <Fragment>
            <li></li>
            {userName && <li className={style.Welcome}>Welcome {userName}</li>}
            {/* {hasUnpaidTransactions && <li><button onClick={onShoppingCartClicked}><ShoppingCartIcon /></button></li>} */}
            {isLoggedIn && <li><button onClick={onShoppingCartClicked}><ShoppingCartIcon showCartCount={true} cartCount={cartCount} /></button></li>}
            {userName && <li><button onClick={onSettingsClicked}><GearIcon tooltip="Settings" />&nbsp;Settings</button></li>}
            {userName && <li><button onClick={onLogoutClicked}><UserIcon tooltip="Logout" />&nbsp;Logout</button></li>}
            {showLogin && <li><button onClick={onLoginClicked}><UserIcon tooltip="Login" />&nbsp;Login</button></li>}
          </Fragment>}
      </ul>
    </nav>
  );
};

export default TopHeader;