import { createContext, useState } from 'react';

import ReportPeriodSeasonData from './ReportPeriodSeasonData';

export const ReportPeriodSeasonStateContext = createContext();

const ReportPeriodSeasonContextProvider = ({ children }) => {
  const stateHook = useState(ReportPeriodSeasonData.INITIAL_STATE);

  return (
    <ReportPeriodSeasonStateContext.Provider value={stateHook}>
      {children}
    </ReportPeriodSeasonStateContext.Provider>
  );
};

export default ReportPeriodSeasonContextProvider;