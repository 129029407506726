import { useState } from 'react';

import MeetData from './MeetData';

const useMeetNoContextData = () => {
  const [meetState, setMeetState] = useState(MeetData.INITIAL_STATE);

  const getMeet = (meetId) => MeetData.getMeetData(meetId, meetState, setMeetState);

  return {
    meetState,
    setMeetState,
    getMeet
  }
}

export default useMeetNoContextData;