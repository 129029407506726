const TIME_TYPE_INDIVIDUAL = "I";
const TIME_TYPE_RELAY = "R";

const SESSION_PRELIM = "P";
const SESSION_FINAL = "F";
const SESSION_SEMIFINAL = "E";
const SESSION_QUARTERFINAL = "Q";
const SESSION_TIMEDFINAL = "T";
const SESSION_SWIMOFF = "S";
const TIMES_RECON = "R";

const SESSION_PRELIM_LABEL = "Prelim";
const SESSION_SEMIFINAL_LABEL = "Semifinal";
const SESSION_QUARTERFINAL_LABEL = "Quarterfinal";
const SESSION_FINAL_LABEL = "Final";
const SESSION_TIMEDFINAL_LABEL = "Timedfinal";
const SESSION_SWIMOFF_LABEL = "SwimOff";

const convertTimeToSeconds = (time) => {
  // eslint-disable-next-line no-unused-vars
  const [hh = '0', mm = '0', ss = '00.00'] = time.split(':');
  const minute = parseInt(mm, 10) || 0;
  const second = parseFloat(ss, 10) || 0;

  return (minute * 60) + (second);
}

const convertSecondsToTime = (seconds) => {
  const dateObj = new Date(seconds * 1000);
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const secondsPart = dateObj.getSeconds();
  const millisecondsPart = dateObj.getMilliseconds();

  const timeString = hours.toString().padStart(2, '0')
    + ':' + minutes.toString().padStart(2, '0')
    + ':' + secondsPart.toString().padStart(2, '0')
    + '.' + millisecondsPart;

  return timeString;
}

const getSessionName = (session) => {
  switch (session) {
    case SESSION_PRELIM:
      return SESSION_PRELIM_LABEL;
    case SESSION_QUARTERFINAL:
      return SESSION_QUARTERFINAL_LABEL;
    case SESSION_SEMIFINAL:
      return SESSION_SEMIFINAL_LABEL;
    case SESSION_FINAL:
      return SESSION_FINAL_LABEL;
    case SESSION_TIMEDFINAL:
      return SESSION_TIMEDFINAL_LABEL;
    case SESSION_SWIMOFF:
      return SESSION_SWIMOFF_LABEL;
    default:
      return '';
  }
}

function makeSessionObject(distance, stroke, course, session, timesArray) {
  let sessionObj = {
    sessionId: getSessionName(session),
    times: []
  }

  let eventTimesArray = timesArray.filter(
    (item) =>
      +item.distance === +distance &&
      item.stroke === stroke &&
      item.courseCode === course &&
      item.session === session);

  for (let item of eventTimesArray) {
    const timeObj = {
      swimDate: item.swimDate,
      swimTime: item.swimTime,
      reactionTime: item.reactionTime,
      seedTime: item.seedTime,
      person: item.person,
      club: item.club,
      splits: item.splits,
      heatNumber: item.heatNumber,
      laneNumber: item.laneNumber,
      finishPos: item.placeRanking
    }
    sessionObj.times.push(timeObj);
  }

  return sessionObj;
}

function makeEventObject(distance, stroke, course, timesArray, timeType) {
  let eventsObj = {
    distance: distance,
    stroke: stroke,
    course: course,
    sessions: []
  }

  let sessionsArray = [];
  let prelimsObj = makeSessionObject(distance, stroke, course, SESSION_PRELIM, timesArray);
  let finalsObj = makeSessionObject(distance, stroke, course, SESSION_FINAL, timesArray);
  let semifinalsObj = makeSessionObject(distance, stroke, course, SESSION_SEMIFINAL, timesArray);
  let quarterfinalsObj = makeSessionObject(distance, stroke, course, SESSION_QUARTERFINAL, timesArray);
  let timedfinalsObj = makeSessionObject(distance, stroke, course, SESSION_TIMEDFINAL, timesArray);
  let swimOffsObj = makeSessionObject(distance, stroke, course, SESSION_SWIMOFF, timesArray);
  let timesReconObj = makeSessionObject(distance, stroke, course, TIMES_RECON, timesArray);
  
  if (prelimsObj.times.length > 0) { sessionsArray.push(prelimsObj); }
  if (quarterfinalsObj.times.length > 0) { sessionsArray.push(quarterfinalsObj); }
  if (semifinalsObj.times.length > 0) { sessionsArray.push(semifinalsObj); }
  if (finalsObj.times.length > 0) { sessionsArray.push(finalsObj); }
  if (timedfinalsObj.times.length > 0) { sessionsArray.push(timedfinalsObj); }
  if (swimOffsObj.times.length > 0) { sessionsArray.push(swimOffsObj); }
  if (timesReconObj.times.length > 0) { sessionsArray.push(timesReconObj); }

  eventsObj.sessions = sessionsArray;

  return eventsObj;
}

const makeMeetObject = (timesArray, meetId) => {
  let meetObj = {
    meet: {
      sanctionId: meetId,
      events: []
    }
  };

  let eventsArray = [];
  let eventsObj = {};

  //TODO: verify that some meets have multiple courses. This would run faster if we only iterate through events for a single course
  const events = [
    { distance: '25', stroke: 'FR', course: 'SCY' },
    { distance: '50', stroke: 'FR', course: 'SCY' },
    { distance: '100', stroke: 'FR', course: 'SCY' },
    { distance: '200', stroke: 'FR', course: 'SCY' },
    { distance: '500', stroke: 'FR', course: 'SCY' },
    { distance: '1000', stroke: 'FR', course: 'SCY' },
    { distance: '1650', stroke: 'FR', course: 'SCY' },
    { distance: '25', stroke: 'BK', course: 'SCY' },
    { distance: '50', stroke: 'BK', course: 'SCY' },
    { distance: '100', stroke: 'BK', course: 'SCY' },
    { distance: '200', stroke: 'BK', course: 'SCY' },
    { distance: '25', stroke: 'BR', course: 'SCY' },
    { distance: '50', stroke: 'BR', course: 'SCY' },
    { distance: '100', stroke: 'BR', course: 'SCY' },
    { distance: '200', stroke: 'BR', course: 'SCY' },
    { distance: '25', stroke: 'FL', course: 'SCY' },
    { distance: '50', stroke: 'FL', course: 'SCY' },
    { distance: '100', stroke: 'FL', course: 'SCY' },
    { distance: '200', stroke: 'FL', course: 'SCY' },
    { distance: '100', stroke: 'IM', course: 'SCY' },
    { distance: '200', stroke: 'IM', course: 'SCY' },
    { distance: '400', stroke: 'IM', course: 'SCY' },
    { distance: '25', stroke: 'FR', course: 'SCM' },
    { distance: '50', stroke: 'FR', course: 'SCM' },
    { distance: '100', stroke: 'FR', course: 'SCM' },
    { distance: '200', stroke: 'FR', course: 'SCM' },
    { distance: '400', stroke: 'FR', course: 'SCM' },
    { distance: '800', stroke: 'FR', course: 'SCM' },
    { distance: '1500', stroke: 'FR', course: 'SCM' },
    { distance: '25', stroke: 'BK', course: 'SCM' },
    { distance: '50', stroke: 'BK', course: 'SCM' },
    { distance: '100', stroke: 'BK', course: 'SCM' },
    { distance: '200', stroke: 'BK', course: 'SCM' },
    { distance: '25', stroke: 'BR', course: 'SCM' },
    { distance: '50', stroke: 'BR', course: 'SCM' },
    { distance: '100', stroke: 'BR', course: 'SCM' },
    { distance: '200', stroke: 'BR', course: 'SCM' },
    { distance: '25', stroke: 'FL', course: 'SCM' },
    { distance: '50', stroke: 'FL', course: 'SCM' },
    { distance: '100', stroke: 'FL', course: 'SCM' },
    { distance: '200', stroke: 'FL', course: 'SCM' },
    { distance: '100', stroke: 'IM', course: 'SCM' },
    { distance: '200', stroke: 'IM', course: 'SCM' },
    { distance: '400', stroke: 'IM', course: 'SCM' },
    { distance: '50', stroke: 'FR', course: 'LCM' },
    { distance: '100', stroke: 'FR', course: 'LCM' },
    { distance: '200', stroke: 'FR', course: 'LCM' },
    { distance: '400', stroke: 'FR', course: 'LCM' },
    { distance: '800', stroke: 'FR', course: 'LCM' },
    { distance: '1500', stroke: 'FR', course: 'LCM' },
    { distance: '50', stroke: 'BK', course: 'LCM' },
    { distance: '100', stroke: 'BK', course: 'LCM' },
    { distance: '200', stroke: 'BK', course: 'LCM' },
    { distance: '50', stroke: 'BR', course: 'LCM' },
    { distance: '100', stroke: 'BR', course: 'LCM' },
    { distance: '200', stroke: 'BR', course: 'LCM' },
    { distance: '50', stroke: 'FL', course: 'LCM' },
    { distance: '100', stroke: 'FL', course: 'LCM' },
    { distance: '200', stroke: 'FL', course: 'LCM' },
    { distance: '100', stroke: 'IM', course: 'LCM' },
    { distance: '200', stroke: 'IM', course: 'LCM' },
    { distance: '400', stroke: 'IM', course: 'LCM' }
  ]

  for (let event of events) {
    eventsObj = makeEventObject(event.distance, event.stroke, event.course, timesArray, TIME_TYPE_INDIVIDUAL);

    if (eventsObj.sessions.length > 0) {
      eventsArray.push(eventsObj);
    }
  }

  meetObj.meet.events = eventsArray;

  return meetObj;
}

function makeRelaySessionObject(distance, stroke, course, session, relayTimesArray) {
  let sessionObj = {
    sessionId: getSessionName(session), 
    relayTimes: []
  }

  let eventTimesArray = relayTimesArray.filter(
    (item) =>
      +item.distance === +distance &&
      item.stroke === stroke &&
      item.courseCode === course &&
      item.session === session);

  for (let item of eventTimesArray) {
    const relayTimeObj = {
      swimDate: item.swimDate,
      swimTime: item.relayTime,
      seedTime: item.seedTime,
      team: item.team,
      legs: item.legs,
      heatNumber: item.heatNumber,
      laneNumber: item.laneNumber,
      finishPos: item.placeRanking
    }

    //If relay was withdrawn (WDR), no leg info is included in the LEF file. We will skip because it is useless info.
    if (relayTimeObj.legs !== undefined) {
      sessionObj.relayTimes.push(relayTimeObj);
    }
  }

  return sessionObj;
}

function makeRelayEventObject(distance, stroke, course, relaytTimesArray) {
  let eventsObj = {
    distance: distance,
    stroke: stroke,
    course: course,
    sessions: []
  }

  let sessionsArray = [];
  //TODO: do other session types apply for relays? Research this.
  let prelimsObj = makeRelaySessionObject(distance, stroke, course, SESSION_PRELIM, relaytTimesArray);
  let finalsObj = makeRelaySessionObject(distance, stroke, course, SESSION_FINAL, relaytTimesArray);
  let timedfinalsObj = makeRelaySessionObject(distance, stroke, course, SESSION_TIMEDFINAL, relaytTimesArray);
  let timesReconObj = makeRelaySessionObject(distance, stroke, course, TIMES_RECON, relaytTimesArray);

  if (prelimsObj.relayTimes.length > 0) {
    sessionsArray.push(prelimsObj);
  };

  if (finalsObj.relayTimes.length > 0) {
    sessionsArray.push(finalsObj);
  }

  if (timedfinalsObj.relayTimes.length > 0) {
    sessionsArray.push(timedfinalsObj);
  }

  if (timesReconObj.relayTimes.length > 0) {
    sessionsArray.push(timesReconObj);
  }

  eventsObj.sessions = sessionsArray;

  return eventsObj;
}

const makeMeetRelaysObject = (relayTimesArray, meetId) => {
  let meetObj = {
    meet: {
      sanctionId: meetId,
      relayEvents: []
    }
  };

  let relayEventsArray = [];
  let relayEventsObj = {};

  const relayEvents = [
    { distance: '200', stroke: 'FR-R', course: 'SCY' },
    { distance: '400', stroke: 'FR-R', course: 'SCY' },
    { distance: '800', stroke: 'FR-R', course: 'SCY' },
    { distance: '200', stroke: 'MED-R', course: 'SCY' },
    { distance: '400', stroke: 'MED-R', course: 'SCY' },
    { distance: '200', stroke: 'FR-R', course: 'SCM' },
    { distance: '400', stroke: 'FR-R', course: 'SCM' },
    { distance: '800', stroke: 'FR-R', course: 'SCM' },
    { distance: '200', stroke: 'MED-R', course: 'SCM' },
    { distance: '400', stroke: 'MED-R', course: 'SCM' },
    { distance: '200', stroke: 'FR-R', course: 'LCM' },
    { distance: '400', stroke: 'FR-R', course: 'LCM' },
    { distance: '800', stroke: 'FR-R', course: 'LCM' },
    { distance: '200', stroke: 'MED-R', course: 'LCM' },
    { distance: '400', stroke: 'MED-R', course: 'LCM' }
  ];

  for (let event of relayEvents) {
    relayEventsObj = makeRelayEventObject(event.distance, event.stroke, event.course, relayTimesArray);

    if (relayEventsObj.sessions.length > 0) {
      relayEventsArray.push(relayEventsObj);
    }
  }

  meetObj.meet.relayEvents = relayEventsArray;

  return meetObj;
}

const Shared = {
  TIME_TYPE_INDIVIDUAL,
  TIME_TYPE_RELAY,
  SESSION_FINAL,
  SESSION_PRELIM,
  SESSION_SWIMOFF,
  SESSION_FINAL_LABEL,
  makeMeetObject,
  makeMeetRelaysObject,
  getSessionName,
  convertTimeToSeconds,
  convertSecondsToTime
}

export default Shared;