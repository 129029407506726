const useDataHubSwimmerIconPic = () => {
  const getFillColor = () => {
      return '#041e42';
  };

  return {
    fillColor: getFillColor()
  };
};

export default useDataHubSwimmerIconPic;