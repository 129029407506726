import useErrorBoundaryFallback from "./UseErrorBoundaryFallback";

import Headings from '../headings/Headings';

import global from '../GlobalStyle.module.css';

const ErrorBoundaryFallback = ({ message = 'An unexpected error occurred. Please try again later.',
  linkText = 'Back To Landing Page', routeName = 'HUB_LANDING_PAGES' }) => {
  const { linkUrl } = useErrorBoundaryFallback(routeName);

  return (
    <div className={global.CenterText}>
      <img src={`${import.meta.env.VITE_CDN_URL}/exclamation1.png`} alt="Unexpected Error" height="100" width="100" />
      <div className="usas-extra-top-margin">
        <Headings.H5>{message}</Headings.H5>
      </div>
      {linkUrl && <p><a href={linkUrl}>{linkText}</a></p>}
    </div>
  );
}

export default ErrorBoundaryFallback;