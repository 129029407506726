import { useEffect, useState } from 'react';
import usePersonInboxData from '../../state/personInbox/UsePersonInboxData';
import useUserAccountData from '../../state/userAccount/UseUserAccountData';
import useForm from '../../utils/UseForm';
import validate from './PersonValidation';
import Constants from '../../utils/Constants';

const INITIAL_FORM_STATE = {
  firstName: '',
  preferredName: '',
  middleName: '',
  lastName: '',
  email: '',
  birthDate: '',
};

const INITIAL_VIEW_STATE = {
  message: '',
  showForm: true,
  showButtons: true,
  showGrid: false,
  showCreate: false,
  showSave: false,
  showSaving: false
};

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Send Email?',
  personId: Constants.DEFAULT_ID
};

const useLinkByPersonalInfo = (goToLinkById, hasPriorMembership, onComplete) => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const [sendEmailModalState, setSendEmailModalState] = useState({ ...INITIAL_MODAL_STATE });
  const {
    userAccountPersonSearchState,
    searchUserAccountPerson,
    postPersonAndUserAccountPersonPingAssociation,
    userAccountPersonState } = useUserAccountData();
  const { personInboxState, postPersonInbox } = usePersonInboxData();

  const submitFormCallback = (formState) => {
    if (state.showSave === true) {
      postPersonAndUserAccountPersonPingAssociation({
        firstName: formState.firstName,
        lastName: formState.lastName,
        preferredName: formState.preferredName,
        middleName: formState.middleName,
        email: formState.email,
        birthDate: formState.birthDate
      });
    } else {
      searchUserAccountPerson(formState.firstName, formState.lastName, formState.birthDate);
    }
  };

  const formObj = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onBackClicked = (e) => {
    e?.preventDefault();
    goToLinkById()
  };

  const onCreateClicked = (e) => {
    e?.preventDefault();

    setState({
      ...state,
      showForm: true,
      showButtons: false,
      showGrid: false,
      showCreate: false,
      showSave: true
    });
  };

  const onSaveClicked = (e) => {
    formObj.handleSubmit(e);
  };

  const onSendEmailModalClicked = (e, personId) => {
    e?.preventDefault();

    setSendEmailModalState({ ...sendEmailModalState, displayPopUp: true, personId });
  };

  const onSendEmail = (e) => {
    e?.preventDefault();

    postPersonInbox(sendEmailModalState.personId);
  };

  const onSendEmailModalCanceled = (e) => {
    e?.preventDefault();

    setSendEmailModalState({ ...INITIAL_MODAL_STATE });
  };

  useEffect(() => {
    if (userAccountPersonSearchState.isArrayLoaded === true) {
      const newState = {
        ...state,
        showCreate: true
      };

      if (Array.isArray(userAccountPersonSearchState.arrayData) === false || userAccountPersonSearchState.arrayData.length === 0) {
        if (hasPriorMembership === true) {
          setState({
            ...newState,
            message: 'There are no membership accounts that match the given information.  Please check the information.  If it is correct, then click the "Create New Account" button.',
            showForm: true,
            showGrid: false
          });
        } else {
          setState({
            ...state,
            message: '',
            showForm: true,
            showButtons: false,
            showGrid: false,
            showCreate: false,
            showSave: true,
            showSaving: true
          });
          onSaveClicked();
        }
      } else {
        setState({
          ...newState,
          message: "Please look at the list of emails.  If one of them is yours, please click the 'Email Member ID' button to the right, and then check your email.  If none of them are yours, then click the 'Create New Account' button.",
          showForm: false,
          showGrid: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountPersonSearchState]);

  useEffect(() => {
    if (personInboxState.isSaved === true) {
      goToLinkById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personInboxState]);

  useEffect(() => {
    if (formObj.isDirty !== true) {
      formObj.setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formObj.isDirty]);

  useEffect(() => {
    if (userAccountPersonState.isSaved === true) {
      onComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountPersonState]);

  return {
    message: state.message,
    showForm: state.showForm,
    showButtons: state.showButtons,
    showGrid: state.showGrid,
    showCreate: state.showCreate,
    showSave: state.showSave,
    userAccountPersonSearchState,
    sendEmailModalState,
    formObj,
    onBackClicked,
    onCreateClicked,
    onSaveClicked,
    onSendEmailModalClicked,
    onSendEmail,
    onSendEmailModalCanceled
  };
};

export default useLinkByPersonalInfo;