import { Fragment } from 'react';

import CartItemsGridRecursive from './CartItemsGridRecursive';

const CartItemsGrid = ({ cartItems, subTotal, onDeleteItem, onSelectedChanged }) => (
  <Fragment>
    <CartItemsGridRecursive cartItems={cartItems} subTotal={subTotal} onDeleteItem={onDeleteItem} onSelectedChanged={onSelectedChanged} />
  </Fragment>
);

export default CartItemsGrid;