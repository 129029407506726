import { useContext } from 'react';

import SelectOrgUnitData from './SelectOrgUnitData';

import { SelectOrgUnitStateContext } from './SelectOrgUnitContextProvider';

const useSelectOrgUnitData = () => {
  const [selectOrgUnitState, setSelectOrgUnitState] = useContext(SelectOrgUnitStateContext);

  const resetSelectOrgUnitState = () => {
    setSelectOrgUnitState(SelectOrgUnitData.INITIAL_STATE);
  }

  return {
    selectOrgUnitState,
    setSelectOrgUnitState,
    resetSelectOrgUnitState
  };
};

export default useSelectOrgUnitData;