import React, { Fragment } from 'react';

import useMobileContextBasedNav from './UseMobileContextBasedNav';

import SmallNav from './SmallNav';

import style from './ContextBasedNav.module.css';

const MobileContextBasedNav = ({ taxonomyName, renderPillsOnly }) => {
  const { showNav, toggleMobileMenu } = useMobileContextBasedNav();

  return (
    <Fragment>
      <div className={style.MobileContextBasedNavTrigger}>
        <input
          type="button"
          name="MobileContextBasedMenu"
          id="MobileContextBasedMenu"
          defaultValue={"Navigation Quick Links"}
          onClick={toggleMobileMenu}
        />
      </div>
      <SmallNav taxonomyName={taxonomyName} renderPillsOnly={renderPillsOnly} />
      <div
        className={[style.Background, showNav ? 'visible' : 'hidden'].join(' ')}
        onClick={toggleMobileMenu}>&nbsp;</div>
    </Fragment>
  );
};

export default MobileContextBasedNav;