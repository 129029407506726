import { useContext } from 'react';

import ContactRelationshipData from './ContactRelationshipTypesData';
import { ContactRelationshipTypesStateContext } from './ContactRelationshipTypesContextProvider';

const useContactRelationshipData = () => {
  const [contactRelationshipTypeState, setContactRelationshipTypeState] = useContext(ContactRelationshipTypesStateContext);

  const getContactRelationshipTypes = () => {
    ContactRelationshipData.getContactRelationshipTypes(contactRelationshipTypeState, setContactRelationshipTypeState)
  }
  return {
    contactRelationshipTypeState,
    getContactRelationshipTypes
  }
}


export default useContactRelationshipData;