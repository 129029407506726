import React, { createContext, useState } from 'react';

import GlobalRoutesData from './GlobalRoutesData';

export const GlobalRoutesContext = createContext();

const GlobalRoutesContextProvider = ({ children }) => {
  const stateHook = useState(GlobalRoutesData.INITIAL_STATE);

  return (
    <GlobalRoutesContext.Provider value={stateHook}>
      {children}
    </GlobalRoutesContext.Provider>
  );
};

export default GlobalRoutesContextProvider;