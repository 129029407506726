import React, { Fragment } from 'react';

import style from './Headings.module.css';

const H1 = ({ className, children }) => (
  <h1 className={[className ? className : "", style.H1].join(' ')}>{children}</h1>
);

const H2 = ({ className, children }) => (
  <h2 className={[className ? className : "", style.H2].join(' ')}>{children}</h2>
);

const H3 = ({ className, children, excludeBar, isBarOnBottom }) => {
  if (excludeBar === true) {
    return <h3 className={[className ? className : "", style.H3].join(' ')}>{children}</h3>;
  }

  if (isBarOnBottom === true) {
    return (
      <Fragment>
        <h3 className={[className ? className : "", style.H3].join(' ')}>{children}</h3>
        <div className="row">
          <div className="col-xs-12">
            <hr className="usas-bar-turmeric" />
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={[className ? className : "", "usas-bar-turmeric", style.H2, style.ShortBar].join(' ')}></div>
      <h3 className={[className ? className : "", style.H3].join(' ')}>{children}</h3>
    </Fragment>
  );
};


const H4 = ({ className, children }) => (
  <h4 className={[className ? className : "", style.H4].join(' ')}>{children}</h4>
);


const H5 = ({ className, children }) => (
  <h5 className={[className ? className : "", style.H5].join(' ')}>{children}</h5>
);

const H6 = ({ className, children, isAltColor }) => (
  <h6 className={[className ? className : "", isAltColor === true ? style.AltColor : "", style.H6].join(' ')}>{children}</h6>
);



const Headings = { H1, H2, H3, H4, H5, H6};
export default Headings;