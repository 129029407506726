import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useMeetTimesData from '../../../../../common/state/meet/meetTimes/UseMeetTimesData';

import NavLinks from '../../NavLinks';

const useAddMeetTime = () => {
  const navigate = useNavigate();
  const [ listState, setListState ] = useState([]);
  const { meetState } = useMeetData();
  const { meetTimeAddPostDataState } = useMeetTimesData();

  const onContinueToMeetTimeType = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_TIMES_ADD_MEET_TIME_SELECT_TIME_TYPE);
  };

  const onBackToTimesResults = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MEET_TIMES);
  };

  useEffect(() => {
    if (meetTimeAddPostDataState.arrayData.length > 0 && Object.keys(meetState.objData).length > 0) {
      const addedMeetTimes = meetTimeAddPostDataState.arrayData.filter(x => x.meetId === meetState.objData.meetId);
      setListState(
        addedMeetTimes
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    listState,
    onContinueToMeetTimeType,
    onBackToTimesResults
  };
}

export default useAddMeetTime;