const StateData = {
  USA: [
    { StateAbbreviation: 'AL', StateName: 'Alabama (AL)' }, { StateAbbreviation: 'AK', StateName: 'Alaska (AK)' },
    { StateAbbreviation: 'AZ', StateName: 'Arizona (AZ)' }, { StateAbbreviation: 'AR', StateName: 'Arkansas (AR)' },
    { StateAbbreviation: 'CA', StateName: 'California (CA)' }, { StateAbbreviation: 'CO', StateName: 'Colorado (CO)' },
    { StateAbbreviation: 'CT', StateName: 'Connecticut (CT)' }, { StateAbbreviation: 'DE', StateName: 'Delaware (DE)' },
    { StateAbbreviation: 'FL', StateName: 'Florida (FL)' }, { StateAbbreviation: 'GA', StateName: 'Georgia (GA)' },
    { StateAbbreviation: 'HI', StateName: 'Hawaii (HI)' }, { StateAbbreviation: 'ID', StateName: 'Idaho (ID)' },
    { StateAbbreviation: 'IL', StateName: 'Illinois (IL)' }, { StateAbbreviation: 'IN', StateName: 'Indiana (IN)' },
    { StateAbbreviation: 'IA', StateName: 'Iowa (IA)' }, { StateAbbreviation: 'KS', StateName: 'Kansas (KS)' },
    { StateAbbreviation: 'KY', StateName: 'Kentucky (KY)' }, { StateAbbreviation: 'LA', StateName: 'Louisiana (LA)' },
    { StateAbbreviation: 'ME', StateName: 'Maine (ME)' }, { StateAbbreviation: 'MD', StateName: 'Maryland (MD)' },
    { StateAbbreviation: 'MA', StateName: 'Massachusetts (MA)' }, { StateAbbreviation: 'MI', StateName: 'Michigan (MI)' },
    { StateAbbreviation: 'MN', StateName: 'Minnesota (MN)' }, { StateAbbreviation: 'MS', StateName: 'Mississippi (MS)' },
    { StateAbbreviation: 'MO', StateName: 'Missouri (MO)' }, { StateAbbreviation: 'MT', StateName: 'Montana (MT)' },
    { StateAbbreviation: 'NE', StateName: 'Nebraska (NE)' }, { StateAbbreviation: 'NV', StateName: 'Nevada (NV)' },
    { StateAbbreviation: 'NH', StateName: 'New Hampshire (NH)' }, { StateAbbreviation: 'NJ', StateName: 'New Jersey (NJ)' },
    { StateAbbreviation: 'NM', StateName: 'New Mexico (NM)' }, { StateAbbreviation: 'NY', StateName: 'New York (NY)' },
    { StateAbbreviation: 'NC', StateName: 'North Carolina (NC)' }, { StateAbbreviation: 'ND', StateName: 'North Dakota (ND)' },
    { StateAbbreviation: 'OH', StateName: 'Ohio (OH)' }, { StateAbbreviation: 'OK', StateName: 'Oklahoma (OK)' },
    { StateAbbreviation: 'OR', StateName: 'Oregon (OR)' }, { StateAbbreviation: 'PA', StateName: 'Pennsylvania (PA)' },
    { StateAbbreviation: 'RI', StateName: 'Rhode Island (RI)' }, { StateAbbreviation: 'SC', StateName: 'South Carolina (SC)' },
    { StateAbbreviation: 'SD', StateName: 'South Dakota (SD)' }, { StateAbbreviation: 'TN', StateName: 'Tennessee (TN)' },
    { StateAbbreviation: 'TX', StateName: 'Texas (TX)' }, { StateAbbreviation: 'UT', StateName: 'Utah (UT)' },
    { StateAbbreviation: 'VT', StateName: 'Vermont (VT)' }, { StateAbbreviation: 'VA', StateName: 'Virginia (VA)' },
    { StateAbbreviation: 'WA', StateName: 'Washington (WA)' }, { StateAbbreviation: 'WV', StateName: 'West Virginia (WV)' },
    { StateAbbreviation: 'WI', StateName: 'Wisconsin (WI)' }, { StateAbbreviation: 'WY', StateName: 'Wyoming (WY)' },
    { StateAbbreviation: 'DC', StateName: 'District of Columbia (DC)' }, { StateAbbreviation: 'PR', StateName: 'The Commonwealth Of Puerto Rico (PR)' },
    { StateAbbreviation: 'GU', StateName: 'Guam (GU)' }, { StateAbbreviation: 'AS', StateName: 'American Samoa (AS)' },
    { StateAbbreviation: 'VI', StateName: 'The US Virgin Islands (VI)' }, { StateAbbreviation: 'MP', StateName: 'The Northern Mariana Islands (MP)' }
  ],
  Canada: [
    { StateAbbreviation: 'AB', StateName: 'Alberta (AB)' },
    { StateAbbreviation: 'BC', StateName: 'British Columbia (BC)' },
    { StateAbbreviation: 'MB', StateName: 'Manitoba (MB)' },
    { StateAbbreviation: 'NB', StateName: 'New Brunswick (NB)' },
    { StateAbbreviation: 'NF', StateName: 'Newfoundland (NF)' },
    { StateAbbreviation: 'NS', StateName: 'Nova Scotia (NS)' },
    { StateAbbreviation: 'NT', StateName: 'Northwest Territories (NT)' },
    { StateAbbreviation: 'ON', StateName: 'Ontario (ON)' },
    { StateAbbreviation: 'PE', StateName: 'Prince Edward Island (PE)' },
    { StateAbbreviation: 'QC', StateName: 'Quebec (QC)' },
    { StateAbbreviation: 'SK', StateName: 'Saskatchewan (SK)' },
    { StateAbbreviation: 'YT', StateName: 'Yukon Territory (YT)' }
  ],
  Mexico: [
    { StateAbbreviation: 'AG', StateName: 'Aguascalientes (AG)' },
    { StateAbbreviation: 'BN', StateName: 'Baja California Norte (BN)' },
    { StateAbbreviation: 'BS', StateName: 'Baja California Sur (BS)' },
    { StateAbbreviation: 'CH', StateName: 'Coahuila (CH)' },
    { StateAbbreviation: 'CI', StateName: 'Chihuahua (CI)' },
    { StateAbbreviation: 'CL', StateName: 'Colima (CL)' },
    { StateAbbreviation: 'CP', StateName: 'Campeche (CP)' },
    { StateAbbreviation: 'CS', StateName: 'Chiapas (CS)' },
    { StateAbbreviation: 'DF', StateName: 'Districto Federal (DF)' },
    { StateAbbreviation: 'DG', StateName: 'Durango (DG)' },
    { StateAbbreviation: 'GE', StateName: 'Guerrero (GE)' },
    { StateAbbreviation: 'GJ', StateName: 'Guanajuato (GJ)' },
    { StateAbbreviation: 'HD', StateName: 'Hidalgo (HD)' },
    { StateAbbreviation: 'JA', StateName: 'Jalisco (JA)' },
    { StateAbbreviation: 'MC', StateName: 'Michoacan (MC)' },
    { StateAbbreviation: 'MR', StateName: 'Morelos (MR)' },
    { StateAbbreviation: 'MX', StateName: 'Mexico (MX)' },
    { StateAbbreviation: 'NA', StateName: 'Nayarit (NA)' },
    { StateAbbreviation: 'NL', StateName: 'Nuevo Leon (NL)' },
    { StateAbbreviation: 'OA', StateName: 'Oaxaca (OA)' },
    { StateAbbreviation: 'PU', StateName: 'Puebla (PU)' },
    { StateAbbreviation: 'QE', StateName: 'Queretaro (QE)' },
    { StateAbbreviation: 'QI', StateName: 'Quintana Roo (QI)' },
    { StateAbbreviation: 'SI', StateName: 'Sinaloa (SI)' },
    { StateAbbreviation: 'SL', StateName: 'San Luis Potosi (SL)' },
    { StateAbbreviation: 'SO', StateName: 'Sonora (SO)' },
    { StateAbbreviation: 'TA', StateName: 'Tamaulipas (TA)' },
    { StateAbbreviation: 'TB', StateName: 'Tabasco (TB)' },
    { StateAbbreviation: 'TL', StateName: 'Tlaxcala (TL)' },
    { StateAbbreviation: 'VC', StateName: 'Veracruz (VC)' },
    { StateAbbreviation: 'YU', StateName: 'Yucatan (YU)' },
    { StateAbbreviation: 'ZA', StateName: 'Zacatecas (ZA)' }
  ]
};

export default StateData;
