import useFoundationDonation from './UseFoundationDonation';

import Headings from '../../../headings/Headings';
import Input from '../../../inputs/Input';
import Dropdown from '../../../dropdowns/Dropdown';
import ToolTipIcon from '../../../icons/ToolTipIcon';

import style from '../../Payments.module.css'

const FoundationDonation = ({ foundationDonation, onValueChanged }) => {
  const {
    DROPDOWN_OPTIONS,
    FOUNDATION_BLURB,
    FOUNDATION_TOOL_TIP,
    state,
    onFoundationDonationChanged,
    onFoundationDonationBlurred,
    onDropdownValueChanged
  } = useFoundationDonation(foundationDonation, onValueChanged);

  return (
    <div className="col-xs-12">
      <div className={style.OptionalBox}>
        <div className="row">
          <div className="col-xs-8 col-md-10">
            <Headings.H5>Donate to the USA Swimming Foundation <span className='hidden-xs'><ToolTipIcon toolTipText={FOUNDATION_TOOL_TIP} /></span></Headings.H5>
          </div>
          <div className="col-xs-4 col-md-2">
            <span className={style.Bold}>
              <Dropdown
                name="dropdownId"
                options={DROPDOWN_OPTIONS}
                value={state.dropdownId}
                isLoading={false}
                onChange={(newValue, newValueLabel, e) => {
                  e && e.target && e.target.value &&
                    onDropdownValueChanged(newValue);
                }} />
            </span>
          </div>
        </div>
        <div className='row visible-xs usas-extra-bottom-margin'>
          <div className="col-xs-8">
            {FOUNDATION_TOOL_TIP}
          </div>
        </div>
        <div className='row'>
          <div className="col-xs-8 col-md-10">
            <i>{FOUNDATION_BLURB}</i>
          </div>
        </div>
        {state.displayTextInput === true &&
          <div className='row usas-extra-top-margin'>
            <div className="col-xs-8 col-md-10"></div>
            <div className="col-xs-4 col-md-2">
              <Input
                className={[style.Bold, "text-right"].join(' ')}
                label={'Custom Amount'}
                name="foundationDonation"
                value={foundationDonation}
                onBlur={() => { onFoundationDonationBlurred(foundationDonation); }}
                onChange={(newValue) => { onFoundationDonationChanged(newValue); }}
                type="number" />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default FoundationDonation;