import { Fragment } from 'react';

import useShoppingCart from './UseShoppingCart';

import CartItems from './subViews/cartItems/CartItems';
import Checkout from './subViews/checkout/Checkout';
import PaymentComplete from './subViews/paymentComplete/PaymentComplete';

import {
  CART_STEP_PAYMENT_COMPLETE, CART_STEP_CART_ITEMS, CART_STEP_CHECKOUT,
} from './utils/PaymentsConstants';

import Headings from '../headings/Headings';

import PaymentsShoppingCartContextProvider from '../../state/payments/paymentsShoppingCart/PaymentsShoppingCartContextProvider';
import ShoppingCartLoadingModalContextProvider from '../../state/payments/paymentsShoppingCart/shoppingCartLoadingModal/ShoppingCartLoadingModalContextProvider';

const ShoppingCart = ({ title, instructionText, isBarOnBottom, showOnPayByCheck,
  orgUnitId, itemSubsystemId, itemContract, referenceId, onPaymentCompletion, onPaymentCancel }) => {
  const {
    currentStep
  } = useShoppingCart(orgUnitId, itemSubsystemId, itemContract, referenceId);

  return (
    <Fragment>
      <div className="row">
        {title && (
          <div className="col-xs-12">
            <Headings.H3 isBarOnBottom={isBarOnBottom}>{title}</Headings.H3>
          </div>
        )}
        {instructionText && (
          <div className="col-xs-12 usas-extra-bottom-margin">
            {instructionText}
          </div>
        )}
        <div className="col-xs-12">
          {currentStep === CART_STEP_CART_ITEMS
            ? <CartItems onCancelClicked={onPaymentCancel} showOnPayByCheck={showOnPayByCheck} />
            : currentStep === CART_STEP_CHECKOUT
              ? <Checkout onReturnClicked={onPaymentCompletion} />
              : currentStep === CART_STEP_PAYMENT_COMPLETE
                ? <PaymentComplete onReturnClicked={onPaymentCompletion} />
                : <Fragment />
          }
        </div>
      </div>
    </Fragment>
  );
};

/**
 * Reusable shopping cart component. Manages it's own context state and the items displayed by the cart 
 * can be filtered to fit the desired sub system.
 * @param {String} title optional header that will display on every view of the cart
 * @param {String} instructionText optional text that will display on every view of the cart
 * @param {Boolean} isBarOnBottom styling option for the "title" header
 * @param {Boolean} showOnPayByCheck whether or not to allow pay by check
 * @param {String} orgUnitId used to filter the items in the cart
 * @param {Number} itemSubsystemId used to filter the items in the cart
 * @param {Number} itemContract used to filter the items in the cart
 * @param {Number} referenceId used to filter the items in the cart
 * @param {Function} onPaymentCompletion optional return button, shown to the user after a successful payment
 * @param {Function} onPaymentCancel optional cancel button, shown to the user on the initial cart view
 */
const ShoppingCartWrapper = ({ title = '', instructionText = '', isBarOnBottom = false, showOnPayByCheck = false,
  orgUnitId = null, itemSubsystemId = null, itemContract = null, referenceId = null,
  onPaymentCompletion, onPaymentCancel }) => (
  <ShoppingCartLoadingModalContextProvider>
    <PaymentsShoppingCartContextProvider>
      <ShoppingCart title={title} isBarOnBottom={isBarOnBottom} instructionText={instructionText} orgUnitId={orgUnitId} itemSubsystemId={itemSubsystemId} itemContract={itemContract} referenceId={referenceId} onPaymentCompletion={onPaymentCompletion} onPaymentCancel={onPaymentCancel} showOnPayByCheck={showOnPayByCheck} />
    </PaymentsShoppingCartContextProvider>
  </ShoppingCartLoadingModalContextProvider>
);

export default ShoppingCartWrapper;