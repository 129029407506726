import { useEffect } from 'react';

// import useGlobalRoutesData from '../../state/security/UseGlobalRoutesData';
// import useCrossUINavigation from '../../state/crossUINavigation/UseCrossUINavigation';

const useToLandingPage = () => {
  // const { globalRoutesState, getGlobalRoutesData } = useGlobalRoutesData();
  // const { navigateToRouteName } = useCrossUINavigation();

  // useEffect(() => {
  //   if (globalRoutesState.isArrayLoaded === true) {
  //     if (import.meta.env.VITE_PROJECT_NAME === 'times-microsite-ui') {
  //       navigateToRouteName('TIMES_MICROSITE_ROOT');
  //     }
  //     else {
  //       navigateToRouteName('HUB_LANDING_PAGES');
  //     }
  //   } else if (globalRoutesState.isArrayLoading !== true) {
  //     getGlobalRoutesData();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [globalRoutesState]);

  useEffect(() => {
    if (import.meta.env.VITE_PROJECT_NAME === 'times-microsite-ui') {
      window.location.href = `https://${import.meta.env.VITE_BASE_DOMAIN}/datahub`;
    } else {
      window.location.href = `${import.meta.env.VITE_LOGIN_URL}`;
    }
  }, []);

  return {};
};

export default useToLandingPage;