import React, { useState } from 'react';

import useInput from './UseInput';

import { convertNumberToCurrencyString, validateCurrencyValue } from '../../utils/CurrencyUtils';
import { CURRENCY_FORMATTER } from '../../utils/Constants';

import global from '../GlobalStyle.module.css';
import style from './CurrencyInput.module.css';
import ReadOnly from '../readOnly/ReadOnly';

const CurrencyInput = ({ className, label, name, value, error, message, onChange }) => {
  const { fullClassAttribute, isReadWrite } = useInput(className, global, error);
  const [formatValueState, setFormatValueState] = useState(true);

  if (isReadWrite === true) {
    return (
      <div className={global.ComponentFlex}>
        <label className={global.UsasLabel} htmlFor={name}>{label}</label>
        <div>
          <span className={style.InputSymbolDollar}>
            <input
              className={fullClassAttribute}
              type={'text'}
              value={formatValueState ? convertNumberToCurrencyString(value) : value}
              id={name}
              name={name}
              onBlur={() => { setFormatValueState(true); }}
              onFocus={() => { setFormatValueState(false); }}
              onChange={(e) => { onChange(validateCurrencyValue(e.target.value)); }}
            />
          </span>
        </div>
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    );
  } else {
    return (
      <ReadOnly
        label={label}
        name={name}
        value={CURRENCY_FORMATTER.format(value)} />
    );
  }
};

export default CurrencyInput;