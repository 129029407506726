import useSessionDropdown from './UseSessionDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const SessionDropdown = ({ label, name, value, error, message, onChange }) => {
  const { sessionState } = useSessionDropdown();

  return sessionState.message
    ? <span className={global.LoadingMsg}>{sessionState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={sessionState?.options || []}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={sessionState.isArrayLoading}
      />
    );
};

export default SessionDropdown;