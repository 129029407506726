import { useContext } from 'react';

import { BasePersonStateContext } from './BasePersonContextProvider';
import { BasePersonFiltersStateContext } from './BasePersonFiltersContextProvider';
import BasePersonData from './BasePersonData';

const useBasePersonData = () => {
  const [basePersonState, setBasePersonState] = useContext(BasePersonStateContext);
  const [basePersonFiltersState, setBasePersonFiltersState] = useContext(BasePersonFiltersStateContext);

  const searchPersons = (filterObject, sortBy, isAdvancedSearch) => {
    setBasePersonFiltersState({ ...basePersonFiltersState, filterObject, sortBy, isAdvancedSearch, backToSystem: 'Member' });
    const filterObj = { ...filterObject };
    delete filterObj.orgUnitName;
    delete filterObj.stateName;
    delete filterObj.lscOrgUnitId;
    BasePersonData.searchPersonsData(JSON.stringify(filterObj), sortBy, basePersonState, setBasePersonState);
  };
  const getBasePerson = (personId) => BasePersonData.getBasePersonData(personId, basePersonState, setBasePersonState);
  const putBasePerson = (personId, personObj) => BasePersonData.putBasePersonData(personId, personObj, basePersonState, setBasePersonState);
  const putPersonMerge = (personAId, personBId, resultsObj) => BasePersonData.putPersonMergeData(personAId, personBId, resultsObj, basePersonState, setBasePersonState);

  const clearBasePersonObjData = () => {
    setBasePersonState({
      ...basePersonState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearBasePersonArrayData = () => {
    setBasePersonState({
      ...basePersonState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmBasePersonSave = () => {
    setBasePersonState({
      ...basePersonState,
      isSaved: false
    });
  };

  return {
    basePersonState,
    basePersonFiltersState,
    setBasePersonState,
    setBasePersonFiltersState,
    clearBasePersonObjData,
    clearBasePersonArrayData,
    confirmBasePersonSave,
    searchPersons,
    getBasePerson,
    putBasePerson,
    putPersonMerge
  };
};

export default useBasePersonData;