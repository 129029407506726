import React, { Fragment } from 'react';

import style from './Button.module.css';
import useButton from './UseButton';

const Button = ({ children, isPrimary, type, onClick, className, isDisabled=false }) => {
  const {buttonType, fullClassAttribute, isReadWrite} = useButton(type, className, style, isPrimary);

  return isReadWrite === true
    ? (
      <button
        className={fullClassAttribute}
        onClick={onClick}
        type={buttonType}
        disabled={isDisabled === true}>
        {children}
      </button>
    )
    : <Fragment />;
}

export default Button;