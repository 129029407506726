import React, { Fragment } from 'react';

import { formatPlaceString } from '../utils/MeetUtils';

import EditIcon from '../../../../../common/components/icons/EditIcon';
import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRelayRow = ({ swimmer }) => {
  return (
    <Fragment>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>{`${formatPlaceString(swimmer.legNumber)} Leg`}</div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{swimmer.person.firstName} {swimmer.person.lastName}</div>
    </Fragment>);
};

const DetailTableSingleRow = ({ org }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{org?.qualifiedOrgUnitCode || <span>&nbsp;</span>}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Organization
        </div>
        <div className={['col-xs-6 visible-xs', global.SmallTableRowData].join(' ')}>
          {org?.qualifiedOrgUnitCode || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div>
);

const GridRowDetailTableRelay = ({ relayTeam }) => {
  return (
    <div className={global.SmallTableDetailTable}>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableDetailRowHead}>
          &nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            {Array.isArray(relayTeam) && relayTeam.map((swimmer, i) =>
              <DetailTableRelayRow key={i} swimmer={swimmer} />)}
          </div>
        </div>
      </div>
    </div>);
};

const GridRowDetailTableSingle = ({ individualOrganization }) => {
  //individualOrganization is not populated by API at this point.
  return (
    <div className={global.SmallTableDetailTable}>
      {individualOrganization && individualOrganization.map((org, i) =>
        <DetailTableSingleRow key={i} org={org} />)}
    </div>
  );
};

const GridRowContent = ({ time }) => (
  <div className={global.SmallTableRowInfo}>
    <div className='row'>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Time
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        {time.time ? formatTimeForDisplay(time.time) : 'Time'}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Competitor
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {time.teamName ? time.teamName : (time.person ? time.person.firstName + ' ' + time.person.lastName : <span>&nbsp;</span>)}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Event Gender
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {time.eventCompetitionGenderType.typeName}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Date
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatDate(time.swimDate)}&nbsp;
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Event Course
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {time.event.eventName}&nbsp;
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Session
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {time.session?.typeName}&nbsp;
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Place
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatPlaceString(time.finishPosition)}&nbsp;
      </div>
    </div>
  </div>
);

const GridRowIcons = ({ id, expandedId, onExpandCollapseClicked, onEdit, time }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={onExpandCollapseClicked}>
      {expandedId === id ? <HideIcon /> : <ShowIcon />}
    </button>
  </Fragment>
);

const GridRow = ({ time, expandedId, onClick }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{time.time ? formatTimeForDisplay(time.time) : 'Time'}</span>&nbsp;
          {time.swimTimeRelay?.length > 0 &&
          <GridRowIcons
            id={time.swimTimeId}
            expandedId={expandedId}
            onExpandCollapseClicked={(e) => onClick(e, time.swimTimeId)}
            time={time}
          />
        }
        </div>
        <GridRowContent time={time} />
      </div>
      {time.individualOrganization?.length > 0 && expandedId === time.swimTimeId &&
        <GridRowDetailTableSingle individualOrganization={time.individualOrganization} />
      }
      {time.swimTimeRelay?.length > 0 && expandedId === time.swimTimeId &&
        <GridRowDetailTableRelay relayTeam={time.swimTimeRelay} />
      }
    </Fragment>
  );
};

const AddMeetTimeSmallGrid = ({ state, expandedId, onClick }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(state) && state.length > 0 ?
      state.map((time, i) =>
        <GridRow
          key={i}
          time={time}
          expandedId={expandedId}
          onClick={onClick}
        />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>
              Click 'Add Time Result' to add a time result to the meet
            </div>
          </div>
        </div>)
    }
  </div>
);

export default AddMeetTimeSmallGrid;