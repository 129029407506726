import React, { createContext, useState } from 'react';

import OtsMeetSessionsData from './OtsMeetSessionsData';

export const OtsMeetSessionsStateContext = createContext();

const OtsMeetSessionsContextProvider = ({ children }) => {
  const stateHook = useState(OtsMeetSessionsData.INITIAL_STATE);

  return (
    <OtsMeetSessionsStateContext.Provider value={stateHook}>
      {children}
    </OtsMeetSessionsStateContext.Provider>
  );
};

export default OtsMeetSessionsContextProvider;