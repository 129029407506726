import React, { Fragment } from 'react';

import usePersonalInfoForm from './UsePersonalInfoForm';

import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import DatePicker from '../datepickers/DatePicker';
import Input from '../inputs/Input';
import ReadOnly from '../readOnly/ReadOnly';

const PersonalInfoForm = ({ formObj, showButtons, onBackClicked }) => {
  const { formState, errorState, onFormValueChanged, onSubmit } = usePersonalInfoForm(formObj);

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Legal First Name*"
            name="firstName"
            value={formState.firstName}
            error={errorState.firstName}
            message={errorState.firstName}
            onChange={(value) => { onFormValueChanged('firstName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Preferred Name"
            name="preferredName"
            value={formState.preferredName}
            error={errorState.preferredName}
            message={errorState.preferredName}
            onChange={(value) => { onFormValueChanged('preferredName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Middle Name or Middle Initial"
            name="middleName"
            value={formState.middleName}
            error={errorState.middleName}
            message={errorState.middleName}
            onChange={(value) => { onFormValueChanged('middleName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label="Legal Last Name*"
            name="lastName"
            value={formState.lastName}
            error={errorState.lastName}
            message={errorState.lastName}
            onChange={(value) => { onFormValueChanged('lastName', value); }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <ReadOnly
            label="Email Address"
            name="email"
            value={formState.email} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Birth Date*"
            name="birthDate"
            value={formState.birthDate}
            error={errorState.birthDate}
            message={errorState.birthDate}
            onChange={(value) => { onFormValueChanged('birthDate', value); }} />
        </div>
      </div>
      {showButtons === true && (
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSubmit}>Submit</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onBackClicked}>Back</SecondaryButton>
        </div>
      </div>
      )}
    </Fragment>
  );
};

export default PersonalInfoForm;