import { useContext } from 'react';

import { PersonAcknowledgementStateContext } from './PersonAcknowledgementContextProvider';

import PersonAcknowledgementData from './PersonAcknowledgementData';

const usePersonAcknowledgementData = () => {
  const [ personAcknowledgementState, setPersonAcknowledgementState ] = useContext(PersonAcknowledgementStateContext);

  const getPersonAcknowledgements = (memberId) => {
    return PersonAcknowledgementData.getPersonAcknowledgements(memberId, personAcknowledgementState, setPersonAcknowledgementState);
  };

  const postPersonAcknowledgements = (memberId, acknowledgements) => {
    return PersonAcknowledgementData.postPersonAcknowledgements(memberId, acknowledgements, personAcknowledgementState, setPersonAcknowledgementState);
  };

  return {
    personAcknowledgementState,
    getPersonAcknowledgements,
    postPersonAcknowledgements
  };
}

export default usePersonAcknowledgementData;
