import React, { Fragment } from 'react';

import useReportList from './UseReportList';

import LoadingModal from '../dialogs/LoadingModal';
import ListTitle from './ListTitle';
import ReportGrid from './ReportGrid';
import ViewReport from './ViewReport';
import NoReport from './NoReport';

const ReportList = ({ excludeBar, listTitle, routeName, reportParameters, contextId }) => {
  const {
    isLoading,
    showGrid,
    showReport,
    reportInfoId,
    listNames,
    reportList,
    onListNamesChanged,
    onReportSelected,
    onBackToGrid } = useReportList(routeName, contextId);

  return (
    <Fragment>

      {(!showReport || reportInfoId <= 0) &&
        <ListTitle
          excludeBar={excludeBar ?? false}
          listTitle={listTitle}
          listNames={listNames}
          onListNamesChanged={onListNamesChanged} />}

      {isLoading
        ? <LoadingModal />
        : showGrid
          ? <ReportGrid reportList={reportList} onReportSelected={onReportSelected} />
          : showReport && reportInfoId > 0
            ? <ViewReport 
                reportInfoId={reportInfoId}
                reportParameters={reportParameters}
                onBackToGrid={reportList?.length > 0 && onBackToGrid} /> 
            : <NoReport />}

    </Fragment>
  );
};
/*

import LoadingModal from '../dialogs/LoadingModal';
import ReportGrid from './ReportGrid';
import Report from './Report';
import { Fragment } from 'react';
import LinkButton from '../buttons/LinkButton';
import Headings from '../headings/Headings';

const ReportList = ({listTitle, listName}) => {
  const {isLoading, embedSlotName, reportList, onReportSelected, onBackToGrid} = useReportList(listName);

  if (isLoading) {
    return (
      <Fragment>
        {listTitle && <div className="row">
          <div className="col-xs-12">
            <Headings.H3>{listTitle}</Headings.H3>
          </div>
        </div>}
        <LoadingModal />
      </Fragment>
    );
  } else if (!embedSlotName) {
    if (Array.isArray(reportList) && reportList.length > 0) {
      return (
        <Fragment>
          {listTitle && <div className="row">
            <div className="col-xs-12">
              <Headings.H3>{listTitle}</Headings.H3>
            </div>
          </div>}
          <div className='row'>
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
              <ReportGrid reportList={reportList} onReportSelected={onReportSelected} />
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {listTitle && <div className="row">
            <div className="col-xs-12">
              <Headings.H3>{listTitle}</Headings.H3>
            </div>
          </div>}
          <div className='row'>
            <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            There are no available reports to view at this time.
            </div>
          </div>
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12 text-right">
          <LinkButton action={onBackToGrid}>Back to List</LinkButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <Report embedSlotName={embedSlotName} />
        </div>
      </div>
    </Fragment>
  );
};
*/
export default ReportList;