import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../wrappers/ReactRouterDom';

import validate from './SessionsDetailValidation';

import useMeetData from '../../state/meet/UseMeetData';
import useMeetSessionsData from '../../state/meetSessions/UseMeetSessionsData';
import useTimeData from '../../state/time/UseTimeData';

import Constants from '../../utils/Constants';
import { formatDate, formatDateTimeToTime, formatDateTimeToTimeTwelveHourClock } from '../../utils/DateFunctions';
import ToIntIfInt from '../../utils/ToIntIfInt';

const SESSION_NUMBER_DUPE_ERROR = 'The provided Session Number is already associated with an existing session. Duplicate session numbers are not allowed.'

const INITIAL_VIEW_STATE = {
  trySave: false,
  tryRedirect: false
};

const useSessionsDetail = () => {
  const navigate = useNavigate();
  const { meetState, putMeet } = useMeetData();
  const { sessionState } = useTimeData();
  const location = useLocation();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [meetDatesState, setMeetDatesState] = useState([{ id: Constants.DEFAULT_ID, name: "--" }]);
  const { meetSessionsState, setMeetSessionsState, resetDetailViewState } = useMeetSessionsData();

  const saveFunction = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const updatedMeetCopy = JSON.parse(JSON.stringify(meetState.objData));
    //Add
    if (meetSessionsState.formState?.meetSessionId < 0) {
      updatedMeetCopy.meetSession.push({
        meetId: meetSessionsState.formState?.meetId,
        sessionOrderId: meetSessionsState.formState?.sessionOrderId,
        sessionTypeId: meetSessionsState.formState?.sessionTypeId,
        sessionType: undefined,
        sessionDate: meetSessionsState.formState?.sessionDate,
        warmUpTime: meetSessionsState.formState?.warmUpTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.warmUpTime : null,
        startTime: meetSessionsState.formState?.startTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.startTime : null,
        meetEvent: []
      })
    }
    //Edit
    else {
      const selectedIndex = updatedMeetCopy.meetSession?.findIndex(x => x.meetSessionId === meetSessionsState.formState?.meetSessionId);
      if (selectedIndex > -1) {
        updatedMeetCopy.meetSession[selectedIndex] = {
          ...updatedMeetCopy.meetSession[selectedIndex],
          sessionOrderId: meetSessionsState.formState?.sessionOrderId,
          sessionTypeId: meetSessionsState.formState?.sessionTypeId,
          sessionType: undefined,
          sessionDate: meetSessionsState.formState?.sessionDate,
          warmUpTime: meetSessionsState.formState?.warmUpTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.warmUpTime : null,
          startTime: meetSessionsState.formState?.startTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.startTime : null
        }
      }
    }

    putMeet(meetState.objData?.meetId, updatedMeetCopy)
  }

  const tryValidateBeforeSave = async () => {
    const errors = await validate(meetSessionsState.formState) || {};
    setMeetSessionsState({ ...meetSessionsState, errorState: errors });
    if (Object.keys(errors).length === 0) {
      const updatedMeetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState?.meetSessionArray));

      if (tryAddEditSession(updatedMeetSessionArrayCopy) === true) {
        saveFunction();
        setState({ ...state, tryRedirect: true });
      }
    }
  };

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(meetSessionsState.formState) || {};
    setMeetSessionsState({ ...meetSessionsState, errorState: errors });
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onSaveButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (meetSessionsState.isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      tryValidateBeforeSave();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    resetDetailViewState();
    navigate(meetSessionsState.backNavLink);
  };

  const onFormValueChangedSetMeetSessionsState = (compnentName, componentValue) => {
    if (meetSessionsState.formState[compnentName] !== componentValue) {
      const updatedFormState = { ...meetSessionsState.formState, [compnentName]: componentValue };
      setMeetSessionsState({ ...meetSessionsState, formState: updatedFormState, isDirty: true });
    }
  };

  const onValueTextPairChangedSetMeetSessionsState = (newValue, valuePropertyName, newValueLabel, labelPropertyName) => {
    if (meetSessionsState.formState[valuePropertyName] !== newValue
      || meetSessionsState.formState[labelPropertyName] !== newValueLabel) {
      const updatedFormState = {
        ...meetSessionsState.formState,
        [valuePropertyName]: newValue,
        [labelPropertyName]: newValueLabel
      };
      setMeetSessionsState({ ...meetSessionsState, formState: updatedFormState, isDirty: true });
    }
  };

  const getMeetDates = (startDate, endDate) => {
    let dates = []
    const theStartDate = new Date(startDate)
    const theEndDate = new Date(endDate)
    while (theStartDate <= theEndDate) {
      dates = [...dates, formatDate(theStartDate)]
      let newStartDate = theStartDate.getDate() + 1;
      theStartDate.setDate(newStartDate)
    }
    return dates
  };

  function validateSession(meetSessionArrayCopy) {
    //Check for duplicate session numbers
    let duplicate = false;
    for (let i = 0; i < meetSessionArrayCopy.length; i++) {
      if (ToIntIfInt(meetSessionArrayCopy[i].meetSessionId) !== ToIntIfInt(meetSessionsState?.formState?.meetSessionId)) {
        if (ToIntIfInt(meetSessionArrayCopy[i].sessionOrderId) === ToIntIfInt(meetSessionsState?.formState?.sessionOrderId)) {
          duplicate = true;
        }
      }
    }

    if (duplicate === true) {
      setMeetSessionsState({
        ...meetSessionsState,
        errorState:
        {
          error: SESSION_NUMBER_DUPE_ERROR
        }
      });
      return false;
    }

    return true;
  }

  function tryAddEditSession(updatedMeetSessionArrayCopy) {
    if (validateSession(updatedMeetSessionArrayCopy) === true) {
      return true;
    }
    else {
      return null;
    }
  }

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      const startDate = formatDate(meetState.objData.startDate);
      const endDate = formatDate(meetState.objData.endDate);
      const meetDatesRange = getMeetDates(startDate, endDate).map((date, i) => {
        return {
          id: i + 1,
          name: date
        }
      })
      setMeetDatesState([
        { id: Constants.DEFAULT_ID, name: "--" },
        ...meetDatesRange
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState]);

  useEffect(() => {
    if (meetSessionsState?.meetSessionArray && sessionState.isArrayLoading === false && sessionState.isArrayLoaded === true
      && meetDatesState.length > 1) {
      //Add
      if (location.state && location.state?.prevHighestSessionOrderId >= 0) {
        setMeetSessionsState({
          ...meetSessionsState,
          formState: {
            ...meetSessionsState.formState,
            sessionOrderId: location.state?.prevHighestSessionOrderId >= 0 ? (location.state?.prevHighestSessionOrderId + 1) : ''
          },
          isDirty: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState, meetDatesState]);

  useEffect(() => {
    if (meetSessionsState?.meetSessionArray && Object.keys(meetSessionsState?.meetSessionArray).length > 0
      && sessionState.isArrayLoading === false && sessionState.isArrayLoaded === true && meetDatesState.length > 1) {
      //Edit
      const meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState?.meetSessionArray));
      if (meetSessionArrayCopy.length > 0 && location.state && location.state?.meetSessionId) {
        const selectedMeetSession = meetSessionArrayCopy?.find(x =>
          x.meetSessionId === location?.state?.meetSessionId);
        if (Object.keys(selectedMeetSession).length > 0) {
          setMeetSessionsState({
            ...meetSessionsState,
            formState: {
              ...meetSessionsState.formState,
              meetSessionId: selectedMeetSession.meetSessionId || Constants.DEFAULT_ID,
              sessionOrderId: selectedMeetSession.sessionOrderId || Constants.DEFAULT_ID,
              sessionTypeId: selectedMeetSession.sessionTypeId || Constants.DEFAULT_ID,
              sessionTypeName: selectedMeetSession?.sessionType?.typeName || '',
              sessionDateId: selectedMeetSession.sessionDate ? (meetDatesState.find(x => x.name === formatDate(selectedMeetSession.sessionDate))?.id || Constants.DEFAULT_ID) : Constants.DEFAULT_ID,
              sessionDate: selectedMeetSession.sessionDate ? formatDate(selectedMeetSession.sessionDate) : '',
              startTime: selectedMeetSession.startTime ? formatDateTimeToTime(selectedMeetSession.startTime) : Constants.BLANK_TIME_STRING,
              startTimeTwelveHourClock: selectedMeetSession.startTime ? formatDateTimeToTimeTwelveHourClock(selectedMeetSession.startTime) : '',
              warmUpTime: selectedMeetSession.warmUpTime ? formatDateTimeToTime(selectedMeetSession.warmUpTime) : Constants.BLANK_TIME_STRING,
              warmUpTimeTwelveHourClock: selectedMeetSession.warmUpTime ? formatDateTimeToTimeTwelveHourClock(selectedMeetSession.warmUpTime) : '',
              isOfficialsQualifyingSession: selectedMeetSession.isOfficialsQualifyingSession ?? ''
            },
            isDirty: false
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState, meetDatesState]);

  useEffect(() => {
    if (meetState.isSaving === false && state.tryRedirect === true) {
      resetDetailViewState();
      navigate(meetSessionsState.saveNavLink);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState.isSaving, state.tryRedirect]);

  return {
    meetSessionsState,
    setMeetSessionsState,
    sessionState,
    meetDatesState,
    onSaveButtonClicked,
    onBackButtonClicked,
    onFormValueChangedSetMeetSessionsState,
    onValueTextPairChangedSetMeetSessionsState
  };
};

export default useSessionsDetail;