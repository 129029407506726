import { useEffect, useState } from 'react';

import useMeetReconData from '../../../state/meet/meetRecon/UseMeetReconData';
import useEmbeddedReportData from '../../../state/embeddedReport/UseEmbeddedReportData';

import sdifConversion from '../meetResultsUpload/lib/SDIFConversion';

const INITIAL_VIEW_STATE = {
  reportParameters: { meetId: '' },
  contextId: '',
  routeName: ''
};

const useMeetReconUpload = (meetId) => {
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE);
  const { meetReconState, postMeetRecon } = useMeetReconData();
  const [jsonFileState, setJsonFileState] = useState(getInitialJSONFileState);
  const { getEmbeddedReportListForContext } = useEmbeddedReportData();

  const makeClubObject = sdifConversion.makeClubObject;
  const populateClubObjSwimmers = sdifConversion.populateClubObjSwimmers;
  const makeErrorsObject = sdifConversion.makeErrorsObject;
  const getMemberId = sdifConversion.getMemberId;

  const onSubmitMeetReconResults = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let resultsObj = JSON.parse(jsonFileState.reconContent);
    if (meetReconState.isSaving !== true) {
      postMeetRecon(resultsObj);
    }
  };

  const onDownloadClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let output = jsonFileState.reconContent;
    const file = new Blob([output], { type: 'application/json' });
    const a = document.createElement('a');

    a.href = URL.createObjectURL(file);
    let fileName = "MeetRecon.json";
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);

    if (Object.keys(jsonFileState.errorsContent).length !== 0) {
      let output3 = jsonFileState.errorsContent;
      const file3 = new Blob([output3], { type: 'application/json' });
      const a3 = document.createElement('a');

      a3.href = URL.createObjectURL(file3);
      fileName = "Errors.json";
      a3.download = fileName;
      a3.click();

      URL.revokeObjectURL(a3.href);
    }
  };

  const createClubArray = (clubArray, fileArray, startIndex, endIndex, errorArray) => {
    let swimmerArray = [];
    let clubObj = {};
    let memberIdObj = { id: "" };

    for (let index = startIndex; index < endIndex + 1; index++) {
      const fileLine = fileArray[index];
      const nextFileLine = fileArray[index + 1];

      if (fileLine.substring(0, 2) === "D0" && nextFileLine.substring(0, 2) === "D3") {
        memberIdObj.id = getMemberId(fileArray, index);
      }

      switch (fileLine.substring(0, 2)) {
        case 'C1':
          clubObj = makeClubObject(fileLine);
          break;
        case 'D0':
          populateClubObjSwimmers(fileLine, clubObj, swimmerArray, errorArray, index, memberIdObj);
          break;
        case 'F0':
          populateClubObjSwimmers(fileLine, clubObj, swimmerArray, errorArray, index, memberIdObj);
          break;
        default:
          break;
      }
    }

    if (Object.keys(clubObj).length !== 0) {
      clubArray.push(clubObj);
    }
  }

  const processFile = (fileContent) => {
    setJsonFileState({
      ...jsonFileState,
      processingStarted: true
    });

    const fileArray = fileContent.toString().replace(/\r\n/g, '\n').split('\n');
    let clubArray = [];
    let fileArrayIndex = 0;
    let clubIndexArray = [];
    let startIndex = 0;
    let endIndex = 0;
    let swimmerCount = 0
    let errorArray = [];

    for (let fileLine of fileArray) {
      if (fileLine.substring(0, 2) === 'C1' || fileLine.substring(0, 2) === 'Z0') {
        clubIndexArray.push(fileArrayIndex);
      }

      fileArrayIndex++;
    }

    let arrayIndex = 0;
    for (let clubIndex of clubIndexArray) {
      startIndex = clubIndex;
      endIndex = clubIndexArray[arrayIndex + 1] - 1;
      arrayIndex++

      createClubArray(clubArray, fileArray, startIndex, endIndex, errorArray);
    }

    let reconObj = {
      meetId: meetId,
      clubs: clubArray
    }

    for (let club of clubArray) {
      for (const swimmer of club.swimmers) {
        swimmerCount++;
      }
    }

    const errorsObject = makeErrorsObject(errorArray, meetId);

    setJsonFileState({
      ...jsonFileState,
      reconContent: JSON.stringify(reconObj, null, 4),
      errorsContent: errorsObject.errors.length > 0 ? JSON.stringify(errorsObject, null, 4) : {},
      clubCount: reconObj.clubs.length,
      swimmerCount: swimmerCount,
      errors: errorArray.length
    });
  };

  const onRunMeetReconReport = (meetId) => {
    setViewState({
      ...viewState,
      reportParameters: { meetId: meetId },
      contextId: meetId,
      routeName: 'MEET_RECON',
    });
  };

  useEffect(() => {
    if (viewState.routeName && viewState.contextId) {
      const reportListPromise = getEmbeddedReportListForContext(viewState.routeName, viewState.contextId);

      if (reportListPromise) {

        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const listNames = [];
            stateObj.arrayData.forEach(x => {
              if (listNames.filter(a => a.id === x.listName).length === 0) {
                listNames.push({ id: x.listName, name: x.listDisplayName });
              }
            });

            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: stateObj.arrayData.length === 1,
              reportInfoId: stateObj.arrayData.length === 1 ? stateObj.arrayData[0].reportInfoId : -1,
              listNames,
              rawReportList: [...stateObj.arrayData],
              reportList: [...stateObj.arrayData]
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: false,
              reportInfoId: -1,
              listNames: [],
              rawReportList: [],
              reportList: []
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showGrid: false,
            showReport: false,
            reportInfoId: -1,
            listNames: [],
            rawReportList: [],
            reportList: []
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.routeName && viewState.contextId]);

  function getInitialJSONFileState() {
    return {
      reconContent: '',
      errorsContent: '',
      fileDownloadUrl: '',
      clubCount: 0,
      swimmerCount: 0,
      errorCount: 0,
      processingStarted: false
    };
  };

  return {
    INITIAL_VIEW_STATE,
    viewState,
    setViewState,
    jsonFileState,
    meetReconState,
    processFile,
    onDownloadClicked,
    onSubmitMeetReconResults,
    onRunMeetReconReport
  };
};

export default useMeetReconUpload;