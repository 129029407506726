import { useState } from 'react';

import AccountLoginData from './AccountLoginData';

import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

const INITIAL_ACCOUNT_LOGIN_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountLoginData = () => {
  const [accountLoginState, setAccountLoginState] = useState(INITIAL_ACCOUNT_LOGIN_STATE);

  const postAccountLogin = (username, password) => {
    return AccountLoginData.postAccountLoginData(username, password, accountLoginState, setAccountLoginState);
  };

  const resetAccountLoginState = () => {
    setAccountLoginState(INITIAL_ACCOUNT_LOGIN_STATE);
  };

  return {
    accountLoginState,
    resetAccountLoginState,
    postAccountLogin
  };
};

export default useAccountLoginData;