import React from 'react';

import global from '../GlobalStyle.module.css';
import useInput from './UseInput';
//TODO: add error checking to Textarea component

const Textarea = ({ error, message, label, name, value, className, rows, onChange, makeLabelBold = false }) => {
  const {fullClassAttribute, isReadWrite} = useInput(className, global, error);
  
  return (
    <div className={global.ComponentFlex}>
      <label className={[global.UsasLabel, makeLabelBold === true ? 'bold' : ''].join(' ')} htmlFor={name}>{label}</label>
      <div>
        <textarea
          className={fullClassAttribute}
          id={name}
          name={name}
          value={value}
          rows={rows}
          readOnly={isReadWrite === true ? '' : 'readonly'}
          onChange={(e) => { onChange(e.target.value) }}
        />
      </div>
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
};

export default Textarea;