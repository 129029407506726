import { Fragment } from 'react';

import SecondaryButton from '../buttons/SecondaryButton';
import StateData from '../../state/state/StateData_NEW';

const AddressValidation = ({ formState, setFormData }) => {
  function acceptValidatedAddress() {
    setFormData({
      ...formState,
      address1: formState.validatedAddress.address1,
      address2: formState.validatedAddress.address2 !== null ? formState.validatedAddress.address2?.trim() : '',
      city: formState.validatedAddress.city,
      stateCode: formState.validatedAddress.stateCode,
      stateName: StateData.USA.find(state => state.StateAbbreviation === formState.validatedAddress.stateCode).StateName,
      postalCode: formState.validatedAddress.postalCode,
      validatedAddress: null
    }, true);
  }

  return (
    <Fragment>
      {
        formState.validatedAddress !== null
        && (formState.address1 !== formState.validatedAddress.address1
          || formState.address2 !== formState.validatedAddress.address2?.trim()
          || formState.city !== formState.validatedAddress.city
          || formState.stateCode !== formState.validatedAddress.stateCode
          || formState.postalCode !== formState.validatedAddress.postalCode)
        && (
          <div>
            <div className="col-xs-12 usas-extra-top-margin">
              <fieldset style={{ borderRadius: 5 }}>
                <legend>Address Suggestion</legend>
                {formState.address1 === formState.validatedAddress.address1 && (
                  <span>{formState.validatedAddress.address1}</span>
                )}
                {formState.address1 !== formState.validatedAddress.address1 && (
                  <mark>{formState.validatedAddress.address1}</mark>
                )}
                <br />
                {formState.validatedAddress.address2 !== null && formState.validatedAddress.address2?.trim() !== '' && (
                  <div>
                    {formState.address2 === formState.validatedAddress.address2.trim() && (
                      <span>{formState.validatedAddress.address2.trim()}</span>
                    )}
                    {formState.address2 !== formState.validatedAddress.address2.trim() && (
                      <mark>{formState.validatedAddress.address2.trim()}</mark>
                    )}
                    <br />
                  </div>
                )}
                {formState.city === formState.validatedAddress.city && (
                  <span>{formState.validatedAddress.city}</span>
                )}
                {formState.city !== formState.validatedAddress.city && (
                  <mark>{formState.validatedAddress.city}</mark>
                )}
                ,&nbsp;
                {formState.stateCode === formState.validatedAddress.stateCode && (
                  <span>{formState.validatedAddress.stateCode}</span>
                )}
                {formState.stateCode !== formState.validatedAddress.stateCode && (
                  <mark>{formState.validatedAddress.stateCode}</mark>
                )}
                &nbsp;
                {formState.postalCode === formState.validatedAddress.postalCode && (
                  <span>{formState.validatedAddress.postalCode}</span>
                )}
                {formState.postalCode !== formState.validatedAddress.postalCode && (
                  <mark>{formState.validatedAddress.postalCode}</mark>
                )}
                <br />
                <SecondaryButton className="usas-extra-top-margin" onClick={() => acceptValidatedAddress()}>Use</SecondaryButton>
              </fieldset>
            </div>
          </div>
        )
      }
    </Fragment>
  );
}

export default AddressValidation;