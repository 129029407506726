import { useEffect } from 'react';
import { useSearchParams } from '../../wrappers/ReactRouterDom';

import {
  CART_STEP_PAYMENT_COMPLETE, CART_STEP_CART_ITEMS,
} from './utils/PaymentsConstants';

import usePaymentsShoppingCartData from '../../state/payments/paymentsShoppingCart/UsePaymentsShoppingCartData';

const useShoppingCart = (orgUnitId, itemSubsystemId, itemContract, referenceId) => {
  const [searchParams,] = useSearchParams();
  const { paymentsShoppingCartState, initiatePaymentsShoppingCart } = usePaymentsShoppingCartData();

  // handles initial load of ShoppingCart component
  useEffect(() => {
    if (paymentsShoppingCartState.currentStep === null) {
      if (searchParams.get('OrderId')) {
        const checkoutPageData = {
          orderId: searchParams.get('OrderId')
        };

        initiatePaymentsShoppingCart(orgUnitId, itemSubsystemId, itemContract, referenceId, CART_STEP_PAYMENT_COMPLETE, checkoutPageData, 'checkoutPageData');
      } else {
        initiatePaymentsShoppingCart(orgUnitId, itemSubsystemId, itemContract, referenceId, CART_STEP_CART_ITEMS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsShoppingCartState.currentStep]);

  return {
    currentStep: paymentsShoppingCartState.currentStep
  };
};

export default useShoppingCart;