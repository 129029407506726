import React from 'react';

import useViewReport from './UseViewReport';

import PopUpModal from '../dialogs/PopUpModal';

import style from './ViewReport.module.css';

const ViewReport = ({ reportInfoId, reportParameters, onBackToGrid }) => {
  const {
    frameId,
    title,
    src,
    containerStyle } = useViewReport(reportInfoId, reportParameters);

  return (
    <PopUpModal
      id={frameId}
      className={style.PopUpModalMargin}
      widthPct={98}
      maxWidth={2560}
      title={title}
      displayPopUp={true}
      onModalCanceled={onBackToGrid}>
      <div className='row'>
        <div className="col-xs-12">
          {src &&
            <div style={{ position: 'relative', zIndex: 1 }}>
              <iframe
                id={frameId}
                title={title}
                style={containerStyle}
                src={src}
                width="100%"
                height="100%"
              ></iframe>
            </div>}
        </div>
      </div>
    </PopUpModal>
  );
};

export default ViewReport;