import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './OtsSessionsDetailValidation';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useOtsMeetSessionsData from '../../../../common/state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData';
import useMeetSessionsData from '../../../../common/state/meetSessions/UseMeetSessionsData';
import useTimeData from '../../../../common/state/time/UseTimeData';

import Constants from '../../../../common/utils/Constants';
import { formatDate, formatDateTimeToTime, formatDateTimeToTimeTwelveHourClock } from '../../../../common/utils/DateFunctions';
import ToIntIfInt from '../../../../common/utils/ToIntIfInt';

const SESSION_NUMBER_DUPE_ERROR = 'The provided Session Number is already associated with an existing session. Duplicate session numbers are not allowed.'

const INITIAL_VIEW_STATE = {
  trySave: false,
  tryRedirect: false
};

const useOtsSessionsDetail = () => {
  const navigate = useNavigate();
  const { sessionState } = useTimeData();
  const { meetState } = useMeetData();
  const { otsMeetSessionsState, postMeetSession, putMeetSession } = useOtsMeetSessionsData();
  const location = useLocation();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [meetDatesState, setMeetDatesState] = useState([{ id: Constants.DEFAULT_ID, name: "--" }]);
  const { meetSessionsState, setMeetSessionsState, resetDetailViewState } = useMeetSessionsData();

  const saveFunction = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    //Add
    if (meetSessionsState.formState?.meetSessionId < 0) {
      postMeetSession({
        sessionOrderId: meetSessionsState.formState?.sessionOrderId,
        meetId: meetState.objData?.meetId,
        sessionTypeId: meetSessionsState.formState?.sessionTypeId,
        isOfficialsQualifyingSession: meetSessionsState.formState?.isOfficialsQualifyingSession,
        sessionDate: meetSessionsState.formState?.sessionDate,
        warmUpTime: meetSessionsState.formState?.warmUpTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.warmUpTime : null,
        startTime: meetSessionsState.formState?.startTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.startTime : null,
      }
      );
    }
    //Edit
    else {
      putMeetSession(meetSessionsState.formState?.meetSessionId,
        {
          meetSessionId: meetSessionsState.formState?.meetSessionId,
          sessionOrderId: meetSessionsState.formState?.sessionOrderId,
          meetId: meetState.objData?.meetId,
          sessionTypeId: meetSessionsState.formState?.sessionTypeId,
          isOfficialsQualifyingSession: meetSessionsState.formState?.isOfficialsQualifyingSession,
          sessionDate: meetSessionsState.formState?.sessionDate,
          warmUpTime: meetSessionsState.formState?.warmUpTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.warmUpTime : null,
          startTime: meetSessionsState.formState?.startTime !== Constants.BLANK_TIME_STRING ? meetSessionsState.formState?.sessionDate + ' ' + meetSessionsState.formState?.startTime : null,
        }
      );
    }
  }

  const tryValidateBeforeSave = async () => {
    const errors = await validate(meetSessionsState.formState) || {};
    setMeetSessionsState({ ...meetSessionsState, errorState: errors });
    if (Object.keys(errors).length === 0) {
      const updatedMeetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState?.meetSessionArray));

      if (tryAddEditSession(updatedMeetSessionArrayCopy) === true) {
        saveFunction();
        setState({ ...state, tryRedirect: true });
      }
    }
  };

  const tryValidateBeforeRedirect = async () => {
    const errors = await validate(meetSessionsState.formState) || {};
    setMeetSessionsState({ ...meetSessionsState, errorState: errors });
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true });
    }
  };

  const onSaveButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (meetSessionsState.isDirty === false) {
      tryValidateBeforeRedirect();
    }
    else {
      tryValidateBeforeSave();
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    resetDetailViewState();
    navigate(meetSessionsState.backNavLink);
  };

  const onFormValueChangedSetMeetSessionsState = (compnentName, componentValue) => {
    if (meetSessionsState.formState[compnentName] !== componentValue) {
      const updatedFormState = { ...meetSessionsState.formState, [compnentName]: componentValue };
      setMeetSessionsState({ ...meetSessionsState, formState: updatedFormState, isDirty: true });
    }
  };

  const onValueTextPairChangedSetMeetSessionsState = (newValue, valuePropertyName, newValueLabel, labelPropertyName) => {
    if (meetSessionsState.formState[valuePropertyName] !== newValue
      || meetSessionsState.formState[labelPropertyName] !== newValueLabel) {
      const updatedFormState = {
        ...meetSessionsState.formState,
        [valuePropertyName]: newValue,
        [labelPropertyName]: newValueLabel
      };
      setMeetSessionsState({ ...meetSessionsState, formState: updatedFormState, isDirty: true });
    }
  };

  const getMeetDates = (startDate, endDate) => {
    let dates = []
    const theStartDate = new Date(startDate)
    const theEndDate = new Date(endDate)
    while (theStartDate <= theEndDate) {
      dates = [...dates, formatDate(theStartDate)]
      let newStartDate = theStartDate.getDate() + 1;
      theStartDate.setDate(newStartDate)
    }
    return dates
  };

  function validateSession(meetSessionArrayCopy) {
    //Check for duplicate session numbers
    let duplicate = false;
    for (let i = 0; i < meetSessionArrayCopy.length; i++) {
      if (ToIntIfInt(meetSessionArrayCopy[i].meetSessionId) !== ToIntIfInt(meetSessionsState?.formState?.meetSessionId)) {
        if (ToIntIfInt(meetSessionArrayCopy[i].sessionOrderId) === ToIntIfInt(meetSessionsState?.formState?.sessionOrderId)) {
          duplicate = true;
        }
      }
    }

    if (duplicate === true) {
      setMeetSessionsState({
        ...meetSessionsState,
        errorState:
        {
          error: SESSION_NUMBER_DUPE_ERROR
        }
      });
      return false;
    }

    return true;
  }

  function tryAddEditSession(updatedMeetSessionArrayCopy) {
    if (validateSession(updatedMeetSessionArrayCopy) === true) {
      return true;
    }
    else {
      return null;
    }
  }

  useEffect(() => {
    if (meetState.isObjLoaded === true) {
      const startDate = formatDate(meetState.objData.startDate);
      const endDate = formatDate(meetState.objData.endDate);
      const meetDatesRange = getMeetDates(startDate, endDate).map((date, i) => {
        return {
          id: i + 1,
          name: date
        }
      })
      setMeetDatesState([
        { id: Constants.DEFAULT_ID, name: "--" },
        ...meetDatesRange
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState]);

  useEffect(() => {
    if (meetSessionsState?.meetSessionArray && sessionState.isArrayLoading === false && sessionState.isArrayLoaded === true
      && meetDatesState.length > 1) {
      //Add
      if (location.state && location.state?.prevHighestSessionOrderId >= 0) {
        setMeetSessionsState({
          ...meetSessionsState,
          formState: {
            ...meetSessionsState.formState,
            sessionOrderId: location.state?.prevHighestSessionOrderId >= 0 ? (location.state?.prevHighestSessionOrderId + 1) : ''
          },
          isDirty: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState, meetDatesState]);

  useEffect(() => {
    if (meetSessionsState?.meetSessionArray && Object.keys(meetSessionsState?.meetSessionArray).length > 0
      && sessionState.isArrayLoading === false && sessionState.isArrayLoaded === true && meetDatesState.length > 1) {
      //Edit
      const meetSessionArrayCopy = JSON.parse(JSON.stringify(meetSessionsState?.meetSessionArray));
      if (meetSessionArrayCopy.length > 0 && location.state && location.state?.meetSessionId) {
        const selectedMeetSession = meetSessionArrayCopy?.find(x =>
          x.meetSessionId === location?.state?.meetSessionId);
        if (Object.keys(selectedMeetSession).length > 0) {
          setMeetSessionsState({
            ...meetSessionsState,
            formState: {
              ...meetSessionsState.formState,
              meetSessionId: selectedMeetSession.meetSessionId || Constants.DEFAULT_ID,
              sessionOrderId: selectedMeetSession.sessionOrderId || Constants.DEFAULT_ID,
              sessionTypeId: selectedMeetSession.sessionTypeId || Constants.DEFAULT_ID,
              sessionTypeName: selectedMeetSession?.sessionType?.typeName || '',
              sessionDateId: selectedMeetSession.sessionDate ? (meetDatesState.find(x => x.name === formatDate(selectedMeetSession.sessionDate))?.id || Constants.DEFAULT_ID) : Constants.DEFAULT_ID,
              sessionDate: selectedMeetSession.sessionDate ? formatDate(selectedMeetSession.sessionDate) : '',
              startTime: selectedMeetSession.startTime ? formatDateTimeToTime(selectedMeetSession.startTime) : Constants.BLANK_TIME_STRING,
              startTimeTwelveHourClock: selectedMeetSession.startTime ? formatDateTimeToTimeTwelveHourClock(selectedMeetSession.startTime) : '',
              warmUpTime: selectedMeetSession.warmUpTime ? formatDateTimeToTime(selectedMeetSession.warmUpTime) : Constants.BLANK_TIME_STRING,
              warmUpTimeTwelveHourClock: selectedMeetSession.warmUpTime ? formatDateTimeToTimeTwelveHourClock(selectedMeetSession.warmUpTime) : '',
              isOfficialsQualifyingSession: selectedMeetSession.isOfficialsQualifyingSession ?? ''
            },
            isDirty: false
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState, meetDatesState]);

  useEffect(() => {
    if (otsMeetSessionsState.isSaving === false && state.tryRedirect === true) {
      resetDetailViewState();
      navigate(meetSessionsState.saveNavLink, { state: { tryGet: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetSessionsState.isSaving, state.tryRedirect]);

  return {
    meetSessionsState,
    setMeetSessionsState,
    otsMeetSessionsState,
    meetDatesState,
    onSaveButtonClicked,
    onBackButtonClicked,
    onFormValueChangedSetMeetSessionsState,
    onValueTextPairChangedSetMeetSessionsState
  };
};

export default useOtsSessionsDetail;