//  WARNING!:: The following is imported from Create React App.  It uses older React components,
//  which are now deprecated. When you upgrade the React version, be sure to update the current
//  version # in .eslint.cjs

import React from 'react';
import ReactDOM from 'react-dom';
import AppRoutes from './app/AppRoutes';
import AppStateContextProviders from './app/AppStateContextProviders';
import PageTemplate from './common/components/pageTemplate/PageTemplate';

ReactDOM.render(
  <React.StrictMode>
    <PageTemplate 
      AppStateContextProviders={AppStateContextProviders}
      AppRoutes={AppRoutes} />
  </React.StrictMode>,
  document.getElementById('root')
);

//  The following is the default setup for Vite.  It uses the latest React components,
//  Which SWIMS currently can't use.

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './app/App';

// ReactDOM.createRoot(document.getElementById('root')).render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
// );
