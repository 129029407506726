import React, { createContext, useState } from 'react';

import PersonAcknowledgementInfoData from './PersonAcknowledgementInfoData';

export const PersonAcknowledgementInfoStateContext = createContext();

const PersonAcknowledgementInfoContextProvider = ({ children }) => {
  const stateHook = useState(PersonAcknowledgementInfoData.INITIAL_STATE);

  return (
    <PersonAcknowledgementInfoStateContext.Provider value={stateHook}>
      {children}
    </PersonAcknowledgementInfoStateContext.Provider>
  );
}

export default PersonAcknowledgementInfoContextProvider;
