import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import useReLoginForm from "./UseReLoginForm";

import Input from "../../../inputs/Input";
import ReadOnly from "../../../readOnly/ReadOnly";
import PrimaryButton from "../../../buttons/PrimaryButton";
import SecondaryButton from "../../../buttons/SecondaryButton";

import style from '../LoginForms.module.css';

const ReLoginForm = ({ submitButtonText = 'Login', secondaryButtonText = 'Cancel', username = '',
  verticalButtons = false, onSubmitFormCallback, onSecondaryButtonClicked }) => {
  const {
    formState,
    errorState,
    hidePasswordState,
    handleSubmit,
    onValueChanged,
    onToggleHideShowPassword
  } = useReLoginForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12">
          <ReadOnly
            label={'Username'}
            name={'username'}
            value={username} />
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <FontAwesomeIcon icon={hidePasswordState !== true ? faEye : faEyeSlash} onClick={onToggleHideShowPassword} style={{ cursor: 'pointer', marginRight: hidePasswordState !== true ? 26 : 25, marginTop: 40, position: 'absolute', right: 0 }}></FontAwesomeIcon>
          <Input
            label={'Password'}
            name={'password'}
            value={formState.password}
            error={errorState.password}
            message={errorState.password}
            autoFocus={true}
            type={hidePasswordState !== true ? "text" : "password"}
            onChange={(value) => onValueChanged('Password', 'password', value)} />
        </div>
        <div className={["col-xs-12 usas-extra-top-margin", style.FormButtons, verticalButtons ? style.Vertical : ''].join(' ')}>
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>
          <SecondaryButton onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default ReLoginForm;