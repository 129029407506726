import React, { createContext, useState } from 'react';

export const BasePersonFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: '',
  isAdvancedSearch: false,
  backToSystem: 'Member',
  backToId: ''
};

const BasePersonFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <BasePersonFiltersStateContext.Provider value={stateHook}>
      {children}
    </BasePersonFiltersStateContext.Provider>
  );
};

export default BasePersonFiltersContextProvider;