import { getStoredTokenObj, setStoredToken } from './TokenUtils';

// Custom Error object that also includes the status code returned by the API
function ApiException(message, status) {
  const error = new Error(message || API_ERROR_MESSAGE);
  error.code = status || 500;

  return error;
}
ApiException.prototype = Object.create(Error.prototype);

const handleUnsuccessfulResponse = (response) => {
  if (response.status === 400) {
    const responseText = response.text();

    responseText.then((text) => {
      throw new ApiException(text, response.status);
    });
  } else if (response.status === 403) {
    throw new ApiException(AUTHORIZATION_ERROR_MESSAGE, response.status);
  } else if (response.status === 404) {
    throw new ApiException(NO_DATA_MESSAGE, response.status);
  } else if (response.status >= 500) {
    throw new ApiException(CORS_ERROR_MESSAGE, response.status);
  } else {
    throw new ApiException(API_ERROR_MESSAGE, response.status);
  }
};

const getAccessToken = () => {
  return getStoredTokenObj().token;
};

const getRefreshToken = () => {
  return getStoredTokenObj().refreshToken;
};

const jsonApiHelper = async (url, method, payload) => {
  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      'Usas-Refresh-Token': `${getRefreshToken()}`
    },
    body: JSON.stringify(payload)
  })
    .then((response) => {
      if (response.ok) {
        processTokens(response);

        return response.json();
      } else {
        handleUnsuccessfulResponse(response);
      }
    });
};

const filePostApiHelper = async (url, payload) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Usas-Refresh-Token': `${getRefreshToken()}`
    },
    body: payload
  })
    .then((response) => {
      if (response.ok) {
        processTokens(response);

        return response.text();
      } else {
        handleUnsuccessfulResponse(response);
      }
    })
    .then((data) => {
      if (data) {
        return { uploadUrl: data };
      }
    })
};

const fileGetApiHelper = async (url) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      'Usas-Refresh-Token': `${getRefreshToken()}`
    },
  })
    .then((response) => {
      if (response.ok) {
        processTokens(response);

        return response.blob();
      } else {
        handleUnsuccessfulResponse(response);
      }
    })
    .then((blob) => {
      if (blob.size > 0) {
        var file = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = file;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        return { downloadUrl: file };
      }
    })
};

const processTokens = (response) => {
  const newToken = response.headers.get('Usas-New-Access-Token');
  const newRefreshToken = response.headers.get('Usas-New-Refresh-Token');
  const newExpirationDatetime = response.headers.get('Usas-Expiration-Datetime');
  if (newToken !== undefined && newToken !== null && newToken !== ''
    && newRefreshToken !== undefined && newRefreshToken !== null && newRefreshToken !== ''
    && newExpirationDatetime !== undefined && newExpirationDatetime !== null && newExpirationDatetime !== '') {
    setStoredToken(newToken, newRefreshToken, newExpirationDatetime);
  }
};

const ApiHelper = (baseRoute, route, method, payload, isFileUpload = false, isFileDownload = false) => {
  if (!baseRoute) throw new Error(`The baseRoute is undefined for route: ${route}  You might be missing an environment variable.`);
  const url = `${baseRoute}${route}`;
  console.log(`${method}: ${url}`);

  if (isFileUpload === true) {
    return filePostApiHelper(url, payload);
  } else if (isFileDownload === true) {
    return fileGetApiHelper(url);
  } else {
    return jsonApiHelper(url, method, payload);
  }
};

//The following APIs no longer exist
export const SwimsHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_SWIMSAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const OmrHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_OMRAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const SecurityHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_SECURITYAPI_URL, route, method, payload, isFileUpload, isFileDownload);
// export const CdnHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_CDN_URL, route, method, payload, isFileUpload, isFileDownload);

export const CommonHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_COMMONAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const ClubHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_CLUBAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const MeetHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_MEETAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const PersonHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_PERSONAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const TimesHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_TIMESAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const PreCompHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_PRECOMP_URL, route, method, payload, isFileUpload, isFileDownload);
export const RecHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_RECAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const LmsHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_LMSAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const SafeSportHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => {
  const r = /(^https:\/\/\S+)(\/lms)$/gi;
  const apiUrl = import.meta.env.VITE_LMSAPI_URL.replace(r, '$1');
  return ApiHelper(apiUrl, route, method, payload, isFileUpload, isFileDownload);
};
export const PaymentsHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_PAYMENTSAPI_URL, route, method, payload, isFileUpload, isFileDownload);
export const PaymentsAdminHttpHelper = (route, method, payload, isFileUpload, isFileDownload) => ApiHelper(import.meta.env.VITE_PAYMENTSADMINAPI_URL, route, method, payload, isFileUpload, isFileDownload);

export const API_ERROR_MESSAGE = 'There was a problem with the data store.  Please try again later';
export const CORS_ERROR_MESSAGE = 'There was a security problem while attempting to connect to the database.';
export const NO_DATA_MESSAGE = 'The data could not be retrieved from the database.';
export const AUTHORIZATION_ERROR_MESSAGE = `You are trying to access a function you don't have access to.`;
export const BASIC_INITIAL_STATE = {
  isArrayLoading: false,
  isArrayLoaded: false,
  isObjLoading: false,
  isObjLoaded: false,
  isSaving: false,
  isSaved: false,
  arrayData: [],
  objData: {},
  message: ''
};

const HttpHelper = { ApiHelper };

export default HttpHelper;