import React from 'react';

import useDatePicker from './UseDatePicker';

import Dropdown from '../dropdowns/Dropdown';

import global from '../GlobalStyle.module.css';
import style from './DatePicker.module.css';
import ReadOnly from '../readOnly/ReadOnly';

const DatePicker = ({ error, label, message, name, value, countOfYears, startYearOffset, onChange }) => {
  const {
    isReadWrite,
    month, day, year,
    months, days, years,
    onMonthChanged, onDayChanged, onYearChanged
  } = useDatePicker(value, countOfYears, startYearOffset, onChange);

  if (isReadWrite === true) {
    return (
      <div className={global.ComponentFlex}>
        <label className={global.UsasLabel} htmlFor={name}>{label}</label>
        <div className={style.DateDiv}>
          <div className={style.MonthDiv}>
            <Dropdown
              error={error}
              options={months || []}
              name={`${name}-month`}
              value={month}
              onChange={onMonthChanged}
              isLoading={false}
              areValuesIntegers={false}
              setDefaults={false} />
          </div>
          <div className={style.DayDiv}>
            <Dropdown
              error={error}
              options={days || []}
              name={`${name}-day`}
              value={day}
              onChange={onDayChanged}
              isLoading={false}
              areValuesIntegers={false}
              setDefaults={false} />
          </div>
          <div className={style.YearDiv}>
            <Dropdown
              error={error}
              options={years || []}
              name={`${name}-year`}
              value={year}
              onChange={onYearChanged}
              isLoading={false}
              areValuesIntegers={false}
              setDefaults={false} />
          </div>
        </div>
        {error && <p className={[global.ErrorMessage, style.ErrorMsg].join(' ')}>{message}</p>}
      </div>
    );
  } else {
    return (
      <ReadOnly
        label={label}
        name={name}
        value={value} />
    );
  }
};

export default DatePicker;