import React from 'react';

import useTab from './UseTab';

import style from './Tabs.module.css';

const Tab = ({navLink, isSelected, onTabClicked}) => {
  const {onClick} = useTab(navLink, onTabClicked);
 
  return isSelected === true 
    ? (
      <button  
        type="button"
        className={[style.SelectedTab, style.FourTabs].join(' ')}>
        <span>{navLink.displayName}</span>
      </button>)
    : (
      <button
        type="button" 
        onClick={onClick}
        className={[style.Tab, style.FourTabs].join(' ')}>
        <span>{navLink.displayName}</span>
      </button>);
};

export default Tab;