import { useContext } from 'react';
import Constants from '../../utils/Constants';

import { MeetSessionsStateContext } from './MeetSessionsContextProvider';
import MeetSessionsData from './MeetSessionsData';

const useMeetSessionsData = () => {
  const [meetSessionsState, setMeetSessionsState] = useContext(MeetSessionsStateContext);

  const resetMeetSessionsData = () => {
    setMeetSessionsState(MeetSessionsData.INITIAL_STATE);
  }

  const resetModalState = () => {
    setMeetSessionsState({
      ...meetSessionsState,
      modalState: {
        ...meetSessionsState.modalState,
        displayPopUp: false,
        modalTitle: 'Delete Session?',
        meetSessionId: Constants.DEFAULT_ID,
        sessionOrderId: '',
        sessionTypeName: '',
        sessionDate: Constants.BLANK_DATE_STRING
      }
    })
  };

  const resetDetailViewState = () => {
    setMeetSessionsState({
      ...meetSessionsState,
      formState: {
        ...meetSessionsState.formState,
        meetSessionId: Constants.DEFAULT_ID,
        sessionOrderId: '',
        sessionTypeId: Constants.DEFAULT_ID,
        sessionTypeName: '',
        sessionDateId: Constants.DEFAULT_ID,
        sessionDate: '',
        startTime: Constants.BLANK_TIME_STRING,
        startTimeTwelveHourClock: '',
        warmUpTime: Constants.BLANK_TIME_STRING,
        warmUpTimeTwelveHourClock: '',
        isOfficialsQualifyingSession: ''
      },
      errorState: {},
      isDirty: false,
    })
  };

  return {
    meetSessionsState,
    setMeetSessionsState,
    resetMeetSessionsData,
    resetModalState,
    resetDetailViewState
  };
};

export default useMeetSessionsData;