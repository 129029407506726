const MapArrayToTreeData = (arrayData, idName, nameName) => {
  const treeData = [];
  if (Array.isArray(arrayData)) {
    arrayData.forEach(item => {
      const id = item[idName];
      const name = item[nameName];
      const children = Array.isArray(item.children) && item.children.length > 0
        ? MapArrayToTreeData(item.children, idName, nameName)
        : [];
      treeData.push({id, name, children});
    });
  }

  return treeData;
};

const getNameForIdRecurse = (id, node) => {
  let name = null;
  if (id === node.id){
    name = node.name;
  } else if (Array.isArray(node.children) && node.children.length > 0) {
    node.children.forEach(child => {
      if (!name) {
        name = getNameForIdRecurse(id, child);
      }
    });
  }

  return name;
};

const getNameForCodeRecurse = (code, node) => {
  let name = null;
  if (code === node.name){
    name = node.name;
  } else if (Array.isArray(node.children) && node.children.length > 0) {
    node.children.forEach(child => {
      if (!name) {
        name = getNameForCodeRecurse(code, child);
      }
    });
  }

  return name;
};


const GetNameIdPairs = (treeData, idValues) => {
  const newValues = [];
  if (Array.isArray(idValues) && idValues.length > 0) {
    idValues.forEach(id => {
      let newObj = null;

      treeData.forEach(node => {
        if (!newObj) {
          const name = getNameForIdRecurse(id, node);
          if (name) {
            newObj = {id, name};
          }
        }
      });

      if (newObj) newValues.push(newObj);
    });
  }

  return newValues;
};

const GetNameCodePairs = (treeData, codeValues) => {
  const newValues = [];
  if (Array.isArray(codeValues) && codeValues.length > 0) {
    codeValues.forEach(code => {
      let newObj = null;

      treeData.forEach(node => {
        if (!newObj) {
          const name = getNameForCodeRecurse(code, node);
          if (name) {
            newObj = {code, name};
          }
        }
      });

      if (newObj) newValues.push(newObj);
    });
  }

  return newValues;
};

const HierarchicalDataUtils = {
  MapArrayToTreeData,
  GetNameIdPairs,
  GetNameCodePairs
};
export default HierarchicalDataUtils;