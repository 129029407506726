export const getDomain = (uIProjectName) => {
  const upperProjectName = uIProjectName ? uIProjectName.toUpperCase() : '';
  const varName = `VITE_${upperProjectName.replace(/-/g, '_')}_DOMAIN`;
  return import.meta.env[varName] || 'self';
};

const formatCrossUiRoute = (uIProjectName, relativePath, isInternal) => {
  const domain = getDomain(uIProjectName);
  if (!domain || domain === 'self' ) {
    return {
      route: relativePath,
      isInternal: isInternal === true
    };
  }

  const route = isInternal === true 
    ? `${window.location.protocol}//${domain}${relativePath}`
    : `//${domain}${relativePath}`;

  return {
    route,
    isInternal: false
  };
};

export const navigateToCrossUiRoute = (uIProjectName, relativePath, navigate) => {
  const formattedRoute = formatCrossUiRoute(uIProjectName, relativePath, true);
  if (formattedRoute.isInternal === true && formattedRoute?.route) {
    navigate(formattedRoute.route, { replace: false });
  } else if (formattedRoute?.route) {
    window.location.href = formattedRoute.route;
  } else {
    navigate(relativePath);
  }
};

export default formatCrossUiRoute;
