import React, { Fragment } from 'react';

import Constants from '../../utils/Constants';

import useLinkById from './UseLinkById';

import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import PopUpModal from '../dialogs/PopUpModal';
import Input from '../inputs/Input';

const LinkById = ({ onUseAdditionalInfoClicked, onComplete }) => {
  const { memberId, isReadyToSubmit, errorMessage, onMemberIdChanged, onSubmit,isSubmitting } = useLinkById(onComplete);

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          Please note that the new Member Ids are 14 random digits and letters.  The old USA Swimming Ids were 6 digits, 
          followed by 8 letters.  You should have received your new Member Id from your club or LSC.  If you  do not have 
          it, click the "Use Additional Info" button.
        </div>
        <div className="col-xs-12 col-sm-3 usas-extra-top-margin">
          <Input
            label="Member Id (14 characters)*"
            name="memberId"
            value={memberId}
            error={errorMessage}
            message={errorMessage}
            onChange={onMemberIdChanged} />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onSubmit} isDisabled={isReadyToSubmit !== true}>Link Account</PrimaryButton>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <SecondaryButton type="button" onClick={onUseAdditionalInfoClicked}>Use Additional Info</SecondaryButton>
        </div>
      </div>
      {<PopUpModal widthPct={50} maxWidth={250} title={Constants.SAVING_MSG} displayPopUp={isSubmitting === true} />}
    </Fragment>);
};

export default LinkById;