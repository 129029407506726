import validate from './PayByCheckPopupFormValidation';

import UseForm from "../../../../utils/UseForm";

const INITIAL_FORM_STATE = {
  memberId: '',
  personId: ''
};

const usePayByCheckPopupForm = (onSubmitFormCallback) => {
  const { formState, errorState, setFormData, onFormValueChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  return {
    formState,
    errorState,
    setFormData,
    onFormValueChanged,
    handleSubmit
  };
};

export default usePayByCheckPopupForm;