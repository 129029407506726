import { useEffect } from 'react';

import useContactRelationshipTypeData from '../../../state/contactRelationshipType/UseContactRelationshipTypesData';

import Constants from '../../../utils/Constants';

const useContactRelationshipTypeDropdown = () => {
  const { contactRelationshipTypeState } = useContactRelationshipTypeData();

  useEffect(() => {
    if (contactRelationshipTypeState.arrayData.length > 0 && !contactRelationshipTypeState.dataAsOptionsWithNames.some(x => x.name === '-- Select Relationship --')) {
      contactRelationshipTypeState.dataAsOptionsWithNames.unshift({ id: Constants.DEFAULT_ID, name: '-- Select Relationship --' });
    }
  }, [contactRelationshipTypeState]);

  return {
    contactRelationshipTypeState
  };
};

export default useContactRelationshipTypeDropdown;