import React, { Fragment } from 'react';

import useLinkByPersonalInfo from './UseLinkByPersonalInfo';

import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import PopUpModal from '../dialogs/PopUpModal';
import PersonalInfoForm from './PersonalInfoForm';
import RequestMemberIdGrid from './RequestMemberIdGrid';
import Constants from '../../utils/Constants';

const LinkByPersonalInfo = ({ goToLinkById, onComplete, hasPriorMembership }) => {
  const {
    message,
    showForm,
    showButtons,
    showGrid,
    showCreate,
    showSave,
    showSaving,
    userAccountPersonSearchState,
    sendEmailModalState,
    formObj,
    onBackClicked,
    onCreateClicked,
    onSaveClicked,
    onSendEmailModalClicked,
    onSendEmail,
    onSendEmailModalCanceled } = useLinkByPersonalInfo(goToLinkById, hasPriorMembership, onComplete);

  return (
    <Fragment>
      {showForm === true && <PersonalInfoForm formObj={formObj} onBackClicked={onBackClicked} showButtons={showButtons} />}
      {message && <div className="row usas-extra-top-margin"><div className="col-xs-12">{message}</div></div>}
      {showGrid === true && <RequestMemberIdGrid state={userAccountPersonSearchState} onSendEmailModalClicked={onSendEmailModalClicked} />}
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          {showCreate === true && <SecondaryButton type="button" onClick={onCreateClicked}>Create a New Account</SecondaryButton>}
          {showSave === true && <PrimaryButton type="button" onClick={onSaveClicked}>Create New Account</PrimaryButton>}
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={sendEmailModalState.modalTitle}
        displayPopUp={sendEmailModalState.displayPopUp}
        onModalCanceled={onSendEmailModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p>Email Member ID</p>
            <p>You should receive an email that provides your Member ID. Please use your Member ID to link your login account to your member record.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onSendEmail}>Send Member ID</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onSendEmailModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={showSaving === true} />
    </Fragment>
  );
};

export default LinkByPersonalInfo;