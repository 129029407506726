
import { API_ERROR_MESSAGE, CORS_ERROR_MESSAGE, NO_DATA_MESSAGE } from "./HttpHelper";

// Custom Error object that also includes the status code returned by the API
function ApiException(message, status) {
  const error = new Error(message || API_ERROR_MESSAGE);
  error.code = status || 500;

  return error;
}
ApiException.prototype = Object.create(Error.prototype);

const handleUnsuccessfulResponse = (response) => {
  if (response.status === 400) {
    const responseText = response.text();

    responseText.then((text) => {
      throw new ApiException(text, response.status);
    });
  } else if (response.status === 404) {
    throw new ApiException(NO_DATA_MESSAGE, response.status);
  } else if (response.status >= 500) {
    throw new ApiException(CORS_ERROR_MESSAGE, response.status);
  } else {
    throw new ApiException(API_ERROR_MESSAGE, response.status);
  }
};

const httpHelper = async (fullUrl) => {
  const response = await fetch(fullUrl);
  if (response.ok) {
    return response.json();
  } else {
    handleUnsuccessfulResponse(response);
  }
};

/**
 * Custom Http Helper used for basic HTTP GET requests
 * Have no security or authentication
 * Used for all CDN calls
 */
const CustomHttpHelper = (fullUrl) => {
  return httpHelper(fullUrl);
};

export default CustomHttpHelper;