import useStateData from '../../../state/state/UseStateData';

const useStatesCombobox = (isNorthAmerica, isUSA, isMexico, isCanada) => {
  const { getUSStates, getCanadaStates, getMexicoStates, getNorthAmericaStates } = useStateData();
  const statesData = getStatesData();

  function getStateNameById(stateId) {
    const statesData = getNorthAmericaStates();
    for (const state of statesData) {
      if (stateId === state.id) {
        return state.name;
      }
    }

    return '';
  };

  function getStatesData() {
    let statesData = [];
    if (isUSA === true) {
      statesData = getUSStates();
    } else if (isCanada === true) {
      statesData = getCanadaStates();
    } else if (isMexico) {
      statesData = getMexicoStates();
    } else if (isNorthAmerica) {
      statesData = getNorthAmericaStates();
    }

    return statesData;
  };

  return { statesData, getStateNameById };
};

export default useStatesCombobox;