import { useState } from 'react';

import AccountOneTimePasswordData from './AccountOneTimePasswordData';

import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

const INITIAL_ACCOUNT_ONE_TIME_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountOneTimePasswordData = () => {
  const [accountOneTimePasswordState, setAccountOneTimePasswordState] = useState(INITIAL_ACCOUNT_ONE_TIME_PASSWORD_STATE);

  const postAccountOneTimePassword = (href, oneTimePassword) => {
    return AccountOneTimePasswordData.postAccountOneTimePasswordData(href, oneTimePassword, accountOneTimePasswordState, setAccountOneTimePasswordState);
  };

  const resetAccountOneTimePasswordState = () => {
    setAccountOneTimePasswordState(INITIAL_ACCOUNT_ONE_TIME_PASSWORD_STATE);
  };

  return {
    accountOneTimePasswordState,
    resetAccountOneTimePasswordState,
    postAccountOneTimePassword
  };
};

export default useAccountOneTimePasswordData;