import { isValidCompetitorGender } from '../utils/MeetUtils';
import { formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import { isValidNumber, isValidBirthDate, isValidSwimTime } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../../common/utils/HttpHelper';

export const localValidate = (formState) => {
  let errors = {};

  //Athlete 
  if (formState.memberId === '') {
    errors.memberId = 'Athlete is required';
  }

  //Event
  if (formState.eventId === Constants.DEFAULT_ID) {
    errors.eventId = 'Event is required';
  }

  //Time 
  if (formState.time === '') {
    errors.time = 'Time is required';
  } else if (!isValidSwimTime(formatTimeForFilterObject(formState.time))) {
    errors.time = 'Time must be valid';
  }

  //Place
  if (formState.finishPosition !== '' && (!isValidNumber(formState.finishPosition.trim()) || formState.finishPosition.trim() === '0')) {
    errors.finishPosition = 'Place (finish position) must be a number greater than zero if entered';
  }

  //Swim Date
  if (formState.swimDate === '' || formState.swimDateId === Constants.DEFAULT_ID) {
    errors.swimDateId = 'Swim Date is required';
  } else if (!isValidBirthDate(formState.swimDate)) {
    errors.swimDateId = 'Swim Date cannot be in the future'
  }

  if (formState.lscId === Constants.DEFAULT_ID) {
    errors.lscId = 'LSC is required';
  }

  if (formState.clubCode.trim().length === 0) {
    errors.clubCode = 'Club Code is required';
  } else if (formState.clubCode.trim().length > 15) {
    errors.clubCode = 'Club Code cannot exceed 15 characters';
  }

  return errors;
};

export const serverValidate = async (formState) => {
  let errors = {};

  //Member
  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${memberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        formState.personId = objData.personId;
        formState.personGender = objData.competitionGenderTypeId;
      }
    })
    .catch((e) => {
      errors.apiError = 'Member Id is not valid'
    });

  return errors;
}


const AddMeetTimeIndividualValidation = async (formState) => {
  let errors = localValidate(formState);

  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default AddMeetTimeIndividualValidation;