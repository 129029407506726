import { useEffect, useState } from 'react';

const INITIAL_STATE = {
  optionsList: [],
  isDisabled: true
};

/* eslint-disable no-throw-literal */
const useTree = (options, value, singleSelect, onChange) => {
  const [treeState, setTreeState] = useState(INITIAL_STATE);
  if (!Array.isArray(value) || (value.length > 0 && ((value[0].id !== '' && value[0].id <= 0) || !value[0].name))) {
    throw 'Tree expects an array of objects shaped like {id, name} for the value parameter';
  }
  if (!Array.isArray(options) || (options.length > 0 && ((options[0].id !== '' && options[0].id <= 0) || !options[0].name))) {
    throw 'Tree expects an array of objects shaped like {id, name, children} for the options parameter';
  }

  const onCheckChanged = (isChecked, option) => {
    const newValue = [];

    if (singleSelect === true) {
      newValue.push({ id: option.id, name: option.name });
    } else {
      const getSelectedOptionsRecurse = (nodes) => {
        nodes.forEach(node => {
          if (node.isSelected === true) {
            if (isChecked === true || node.id !== option.id) {
              newValue.push({ id: node.id, name: node.name });
            }
          } else {
            if (isChecked === true && node.id === option.id) {
              newValue.push({ id: node.id, name: node.name });
            }
          }

          if (Array.isArray(node.children) && node.children.length > 0) {
            getSelectedOptionsRecurse(node.children);
          }
        });
      }

      getSelectedOptionsRecurse(JSON.parse(JSON.stringify(treeState.optionsList)));
    }

    onChange(newValue);
  }

  const onExpandCollapse = (id) => {
    let isNodeFound = false;
    const expandCollapseRecurse = (nodes, id) => {
      if (isNodeFound === false) {
        nodes.forEach(node => {
          if (isNodeFound === false) {
            if (node.id === id) {
              isNodeFound = true;
              node.isExpanded = !(node.isExpanded === true);
            } else if (Array.isArray(node.children) && node.children.length > 0) {
              expandCollapseRecurse(node.children, id);
            }
          }
        });
      }
    };

    const optionsList = JSON.parse(JSON.stringify(treeState.optionsList));
    expandCollapseRecurse(optionsList, id);
    setTreeState({
      ...treeState,
      optionsList
    })
  };

  const getOptionsList = () => {
    const getOptionsRecurse = (nodes) => {
      const result = {
        children: [],
        isExpanded: false
      };

      nodes.forEach(node => {
        const { children, isExpanded } = getOptionsRecurse(node.children || [])
        const newNode = {
          ...node,
          children,
          isExpanded,
          isSelected: value.find(x => x.id === node.id) ? true : false
        };

        result.children.push(newNode);
        result.isExpanded = result.isExpanded || isExpanded || newNode.isSelected;
      });

      return result;
    };

    const optionsList = getOptionsRecurse(options);
    return optionsList && Array.isArray(optionsList.children) ? optionsList.children : [];
  };

  useEffect(() => {
    if (Array.isArray(options) && options.length > 0) {
      const optionsList = getOptionsList();
      const isDisabled = false;
      setTreeState({
        ...treeState,
        optionsList,
        isDisabled
      });
    }
    else if (Array.isArray(options) && options.length === 0) {
      setTreeState({
        ...treeState,
        optionsList: [],
        isDisabled: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value]);

  return {
    optionsList: treeState.optionsList,
    isDisabled: treeState.isDisabled,
    onCheckChanged,
    onExpandCollapse
  };
};

export default useTree;
