import { TOKEN_STORAGE_NAME } from './Constants';
import { deleteCookie, getCookie, setCookie } from './CookieUtils';

export const setStoredToken = (token, refreshToken, expirationDatetime, username = getCookie(TOKEN_STORAGE_NAME)?.username) => {
  const newTokenObj = {
    token,
    refreshToken,
    expirationDatetime,
    username
  };

  setCookie(TOKEN_STORAGE_NAME, newTokenObj);
};

export const getStoredTokenObj = () => {
  const tokenObj = getCookie(TOKEN_STORAGE_NAME);

  return {
    token: tokenObj?.token || undefined,
    refreshToken: tokenObj?.refreshToken || undefined,
    expirationDatetime: tokenObj?.expirationDatetime || undefined,
    username: tokenObj?.username || undefined
  }
};

export const resetStoredToken = () => {
  deleteCookie(TOKEN_STORAGE_NAME);
};