import { parse, intervalToDuration, add } from 'date-fns';
import ToIntIfInt from './ToIntIfInt';
import Constants from './Constants';

const databaseDateTimeRegex = /^(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d:\d\d):(\d\d)/;

const breakUpDatabaseDateTime = (value) => {
  if (value) {
    const match = value.toString().match(databaseDateTimeRegex);
    if (match) {

      return {
        month: match[2],
        day: match[3],
        year: match[1],
        time: `${match[4]}`,
        value: `${match[2]}/${match[3]}/${match[1]} ${match[4]}`
      };
    }
  }
  return {
    month: '00',
    day: '00',
    year: '0000',
    time: '99:99',
    value: '00/00/0000 99:99'
  };
};

// eslint-disable-next-line eqeqeq
export const isValidDate = (theDate) => {
  return !theDate || theDate === Constants.BLANK_DATE_STRING
    ? false
    : parse(theDate, Constants.DATE_FORMAT_LOWERCASE, new Date()) !== 'Invalid Date' ? true : false;
};

export const formatDate = (theDate) => {
  // do not use date-fns.format() because it always uses local timezone, and we use UTC for dates
  if (isValidDate(theDate) === false) return Constants.BLANK_DATE_STRING;
  const dt = new Date(theDate);
  const parsedDate = breakUpDatabaseDateTime(theDate);
  if (parsedDate.day === '00' || parsedDate.month === '00' || parsedDate.year === '0000') {
    const year = dt.getUTCFullYear();
    const month = dt.getUTCMonth() + 1 // Date provides month index; not month number
    const day = dt.getUTCDate();
    const monthString = !month || isNaN(month) ? '00' : ('0' + month.toString()).slice(-2); //Keep leading zero
    const dayString = !day || isNaN(day) ? '00' : ('0' + day.toString()).slice(-2); //Keep leading zero
    const yearString = !year || isNaN(year) ? '0000' : ('0000' + year.toString()).slice(-4); //Keep leading zero(s)
    return `${monthString}/${dayString}/${yearString}`;
  } else {
    return `${parsedDate.month}/${parsedDate.day}/${parsedDate.year}`;
  }
};

export const formatDateYMD = (theDate) => {
  // do not use date-fns.format() because it always uses local timezone, and we use UTC for dates
  if (isValidDate(theDate) === false) return Constants.BLANK_DATE_STRING;
  const dt = new Date(theDate);
  const parsedDate = breakUpDatabaseDateTime(theDate);
  if (parsedDate.day === '00' || parsedDate.month === '00' || parsedDate.year === '0000') {
    const year = dt.getUTCFullYear();
    const month = dt.getUTCMonth() + 1 // Date provides month index; not month number
    const day = dt.getUTCDate();
    const monthString = !month || isNaN(month) ? '00' : ('0' + month.toString()).slice(-2); //Keep leading zero
    const dayString = !day || isNaN(day) ? '00' : ('0' + day.toString()).slice(-2); //Keep leading zero
    const yearString = !year || isNaN(year) ? '0000' : ('0000' + year.toString()).slice(-4); //Keep leading zero(s)
    return `${monthString}/${dayString}/${yearString}`;
  } else {
    return `${parsedDate.year}-${parsedDate.month}-${parsedDate.day}`;
  }
};

export const parseDateTime = (theDateTimeFormatted) => {
  const parsedDateTime = parse(theDateTimeFormatted, Constants.DATETIME_FORMAT, new Date())
  return parsedDateTime;
};

export const isValidDateTime = (theDateTimeFormatted) => {
  return !theDateTimeFormatted || theDateTimeFormatted === Constants.BLANK_DATETIME_STRING
    ? false
    : parseDateTime(theDateTimeFormatted) !== 'Invalid Date' ? true : false;
};

export const formatDateTime = (theDateTime) => {
  const dateTime = breakUpDatabaseDateTime(theDateTime);

  if (isValidDateTime(dateTime.value) === false) return Constants.BLANK_DATETIME_STRING;
  return dateTime.value;
};

export const formatDateTimeTwelveHourClock = (theDateTime) => {
  const dateTime = breakUpDatabaseDateTime(theDateTime);

  if (isValidDateTime(dateTime.value) === false) return Constants.BLANK_DATETIME_STRING;
  const timeOptions = [
    { name: 'Time', id: '99:99' },
    { name: '12:00 AM', id: '00:00' },
    { name: '12:59 AM', id: '00:59' },
    { name: '1:00 AM', id: '01:00' },
    { name: '1:59 AM', id: '01:59' },
    { name: '2:00 AM', id: '02:00' },
    { name: '2:59 AM', id: '02:59' },
    { name: '3:00 AM', id: '03:00' },
    { name: '3:59 AM', id: '03:59' },
    { name: '4:00 AM', id: '04:00' },
    { name: '4:59 AM', id: '04:59' },
    { name: '5:00 AM', id: '05:00' },
    { name: '5:59 AM', id: '05:59' },
    { name: '6:00 AM', id: '06:00' },
    { name: '6:59 AM', id: '06:59' },
    { name: '7:00 AM', id: '07:00' },
    { name: '7:59 AM', id: '07:59' },
    { name: '8:00 AM', id: '08:00' },
    { name: '8:59 AM', id: '08:59' },
    { name: '9:00 AM', id: '09:00' },
    { name: '09:59 AM', id: '09:59' },
    { name: '10:00 AM', id: '10:00' },
    { name: '10:59 AM', id: '10:59' },
    { name: '11:00 AM', id: '11:00' },
    { name: '11:59 AM', id: '11:59' },
    { name: '12:00 PM', id: '12:00' },
    { name: '12:59 PM', id: '12:59' },
    { name: '1:00 PM', id: '13:00' },
    { name: '1:59 PM', id: '13:59' },
    { name: '2:00 PM', id: '14:00' },
    { name: '2:59 PM', id: '14:59' },
    { name: '3:00 PM', id: '15:00' },
    { name: '3:59 PM', id: '15:59' },
    { name: '4:00 PM', id: '16:00' },
    { name: '4:59 PM', id: '16:59' },
    { name: '5:00 PM', id: '17:00' },
    { name: '5:59 PM', id: '17:59' },
    { name: '6:00 PM', id: '18:00' },
    { name: '6:59 PM', id: '18:59' },
    { name: '7:00 PM', id: '19:00' },
    { name: '7:59 PM', id: '19:59' },
    { name: '8:00 PM', id: '20:00' },
    { name: '8:59 PM', id: '20:59' },
    { name: '9:00 PM', id: '21:00' },
    { name: '9:59 PM', id: '21:59' },
    { name: '10:00 PM', id: '22:00' },
    { name: '10:59 PM', id: '22:59' },
    { name: '11:00 PM', id: '23:00' },
    { name: '11:59 PM', id: '23:59' }
  ];
  const twelveHourClockTime = timeOptions.find(x => x.id === dateTime.time);

  if (!twelveHourClockTime) {
    return Constants.BLANK_DATETIME_STRING
  }

  return `${dateTime.month}/${dateTime.day}/${dateTime.year} ${twelveHourClockTime.name}`;
};

export const parseTime = (theTimeFormatted) => {
  const parsedDateTime = parse(theTimeFormatted, Constants.TIME_FORMAT, new Date());

  return parsedDateTime;
};

export const isValidTime = (theTimeFormatted) => {
  return !theTimeFormatted || theTimeFormatted === Constants.BLANK_TIME_STRING
    ? false
    : parseTime(theTimeFormatted) !== 'Invalid Date' ? true : false;
};

export const formatDateTimeToTime = (theDateTime) => {
  const dateTime = breakUpDatabaseDateTime(theDateTime);

  if (isValidTime(dateTime.time) === false) return Constants.BLANK_TIME_STRING;
  return dateTime.time;
};

export const formatDateTimeToTimeTwelveHourClock = (theDateTime) => {
  const dateTime = breakUpDatabaseDateTime(theDateTime);

  if (isValidTime(dateTime.time) === false) return Constants.BLANK_TIME_STRING;
  const hourString = dateTime.time.substring(0, 2);
  const hourInt = parseInt(hourString);
  const minuteString = dateTime.time.substring(3, 5);
  let hourToDisplay = '';
  let timeOfDay = '';
  if (hourInt >= 12) {
    timeOfDay = 'PM';
    if (hourInt > 12) {
      //afternoon
      hourToDisplay = (hourInt - 12).toString();
    }
    else {
      //noon
      hourToDisplay = hourString;
    }
  }
  else {
    timeOfDay = 'AM';
    if (hourInt === 0) {
      //midnight
      hourToDisplay = (hourInt + 12).toString();
    }
    else {
      //morning
      hourToDisplay = hourInt.toString();
    }
  }
  return `${hourToDisplay}:${minuteString} ${timeOfDay}`;
};

export const formatTimeToTimeTwelveHourClockForDisplay = (theTime) => {

  if (isValidTime(theTime) === false) return '';
  const hourString = theTime.substring(0, 2);
  const hourInt = parseInt(hourString);
  const minuteString = theTime.substring(3, 5);
  let hourToDisplay = '';
  let timeOfDay = '';
  if (hourInt >= 12) {
    timeOfDay = 'PM';
    if (hourInt > 23) {
      //no hour selected
      timeOfDay = '';
      hourToDisplay = '??';
    }
    else if (hourInt > 12) {
      //afternoon
      hourToDisplay = (hourInt - 12).toString();
    }
    else {
      //noon
      hourToDisplay = hourString;
    }
  }
  else {
    timeOfDay = 'AM';
    if (hourInt === 0) {
      //midnight
      hourToDisplay = (hourInt + 12).toString();
    }
    else {
      //morning
      hourToDisplay = hourInt.toString();
    }
  }
  return `${hourToDisplay}:${minuteString !== '99' ? minuteString : '??'} ${timeOfDay}`;
};


export const getAge = (birthDate, asOfDate) => {
  try {
    const d = asOfDate && asOfDate !== Constants.BLANK_DATE_STRING
      ? intervalToDuration({
        start: new Date(birthDate),
        end: new Date(asOfDate)
      })
      : intervalToDuration({
        start: new Date(birthDate),
        end: new Date(new Date())
      });
    return d.years;
  } catch {
    return undefined;
  }
};

export const addYears = (theDate, years) => {
  if (theDate === Constants.BLANK_DATE_STRING) return undefined;
  const y = ToIntIfInt(years);
  if (isNaN(y) || y <= 0) return undefined;
  return add(parse(theDate, Constants.DATE_FORMAT_LOWERCASE, new Date()), { years: y });
};

export const getTodaysDate = () => {
  const current = new Date();
  const date = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()}`;
  return date
};

export const getTomorrowsDate = () => {
  const current = new Date();
  const date = `${current.getMonth() + 1}/${current.getDate() + 1}/${current.getFullYear()}`;
  return date
};


function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const formatDateToUTC = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getUTCMonth() + 1),
      padTo2Digits(date.getUTCDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getUTCHours()),
      padTo2Digits(date.getUTCMinutes()),
      padTo2Digits(date.getUTCSeconds()),
    ].join(':')
  );
}