import { useState } from 'react';

import { SE_STEP_LOGIN } from './utils/SessionEndingConstants';

const INITIAL_STATE = {
  currentStep: SE_STEP_LOGIN
};

const useSessionEnding = (setShowSessionEnding) => {
  const [popupState, setPopupState] = useState(INITIAL_STATE);

  const updatePopupStep = (newStep, pageData = null, pageDataName = null) => {
    if (pageData !== null && pageDataName !== null) {
      setPopupState({
        ...popupState,
        [pageDataName]: pageData,
        currentStep: newStep
      });
    } else {
      setPopupState({
        ...popupState,
        currentStep: newStep
      });
    }
  };

  const onPopupClosed = (disableSessionEndingPopup) => {
    setShowSessionEnding(false, disableSessionEndingPopup);
  };

  return {
    popupState,
    updatePopupStep,
    onPopupClosed
  };
};

export default useSessionEnding;