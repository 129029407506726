import { useState } from 'react';

import AccountExpiredPasswordData from './AccountExpiredPasswordData';

import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

const INITIAL_ACCOUNT_ONE_TIME_PASSWORD_STATE = {
  ...BASIC_INITIAL_STATE
};

const useAccountExpiredPasswordData = () => {
  const [accountExpiredPasswordState, setAccountExpiredPasswordState] = useState(INITIAL_ACCOUNT_ONE_TIME_PASSWORD_STATE);

  const postAccountResetPassword = (href, currentPassword, newPassword) => {
    return AccountExpiredPasswordData.postAccountResetPasswordData(href, currentPassword, newPassword, accountExpiredPasswordState, setAccountExpiredPasswordState);
  };

  const resetAccountExpiredPasswordState = () => {
    setAccountExpiredPasswordState(INITIAL_ACCOUNT_ONE_TIME_PASSWORD_STATE);
  };

  return {
    accountExpiredPasswordState,
    resetAccountExpiredPasswordState,
    postAccountResetPassword
  };
};

export default useAccountExpiredPasswordData;