import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../utils/HttpHelper';
import Constants from '../../../utils/Constants';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  message: ''
};

const getMeetSoftwares = (state, setState) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/MeetSoftware`;
    MeetHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          data.unshift({ meetSoftwareId: Constants.DEFAULT_ID, softwareName: '--' });

          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((o) => { return { id: o.meetSoftwareId, name: o.softwareName } }),
            message: '',
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const MeetSoftwareData = { INITIAL_STATE, getMeetSoftwares };

export default MeetSoftwareData;