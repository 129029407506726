import React from 'react';
import { Fragment } from 'react';

import ReportGridLarge from './ReportGridLarge';
import ReportGridSmall from './ReportGridSmall';

const ReportGrid = ({reportList, onReportSelected}) => {
  return (
    <Fragment>
      <ReportGridLarge
        reportList={reportList}
        onReportSelected={onReportSelected} />
      <ReportGridSmall
        reportList={reportList}
        onReportSelected={onReportSelected} />
    </Fragment>
  );
};

export default ReportGrid;