import { useEffect, useRef, useState } from 'react';

import useSecurityData from '../../state/security/UseSecurityData';
import { useLocation } from '../../wrappers/ReactRouterDom';

const useSmallNav = (taxonomyName, renderPillsOnly) => {
  const [ menuItems, setMenuItems ] = useState([]);
  const [ expandedId, setExpandedId ] = useState(-1);
  const { contextSecurityState, setContextNavVisibility } = useSecurityData();
  const location = useLocation();
  const pathname = useRef(location.pathname);

  const expandNode = (id) => {
    if (expandedId !== id && id > 0) {
      setExpandedId(id);
    }
  };

  const collapseNode = () => {
    if (expandedId > 0) {
      setExpandedId(-1);
    }
  };

  const getMenuItems = () => {
    if (Array.isArray(contextSecurityState?.objData?.taxonomies) === true) {
      const taxonomy = contextSecurityState.objData.taxonomies.find(t => t.taxonomyName === taxonomyName);

      if (Array.isArray(taxonomy?.taxonomyLinks)) {
        if (renderPillsOnly === true) {
          const newMenuItems = [{
            displayName: " ",
            displayOrder: "1",
            level: "1",
            linkUrl: "",
            taxonomyId: 999999,
            uIProjectName: import.meta.env.VITE_PROJECT_NAME,
            children: [...taxonomy.taxonomyLinks]
          }];
          setMenuItems(newMenuItems)
        } else {
          setMenuItems([...taxonomy.taxonomyLinks]);
        }
      }
    }
  };

  useEffect(() => {
    if (pathname.current !== location.pathname) {
      pathname.current = location.pathname;
      setContextNavVisibility(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (Array.isArray(menuItems) === false || menuItems.length === 0) {
      getMenuItems();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState.showNav]);

  useEffect(() => {
    if (menuItems?.length > 0) {
      expandNode(menuItems[0].taxonomyId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);
  
  return {
    showNav: contextSecurityState.showNav,
    menuItems,
    expandedId,
    expandNode,
    collapseNode
  };
};

export default useSmallNav;