import { useState, useEffect } from "react";

import useSecurityData from "../../../../state/security/UseSecurityData";

const useSwimmingWorldOptInModal = (swimmingWorldOptIn) => {
  const [modalState, setModalState] = useState({ displayPopUp: false });
  const { userInfo } = useSecurityData();

  const goToSwimmingWorldSite = () => {
    window.open(`https://www.swimmingworldmagazine.com/subscribe/usa-swimming?id=${userInfo?.memberId}`, '_blank');
    setModalState({ ...modalState, displayPopUp: false });
  };

  useEffect(() => {
    if (swimmingWorldOptIn === true) {
      setModalState({ ...modalState, displayPopUp: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swimmingWorldOptIn])

  return {
    modalState,
    goToSwimmingWorldSite
  };
};

export default useSwimmingWorldOptInModal;