import { useEffect, useRef, useState } from 'react';

import validate from './LinkByIdValidation';

import useUserAccountData from '../../state/userAccount/UseUserAccountData';
import { useSearchParams } from '../../wrappers/ReactRouterDom';

const INITIAL_VIEW_STATE = {
  memberId: '',
  isReadyToSubmit: false,
  errorMessage: undefined,
  isSaving: false
};

const oldIdRegex = /^[0-9]{2}[0-3][0-9][0-9]{2}([a-z]|\*){8}$/ig;
const readyToSubmitRegex = /^([0-9a-z]|\*){14}$/ig;

const useLinkById = (onComplete) => {
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const { postUserAccountPersonPingAssociation, userAccountPersonState, resetUserAccountState } = useUserAccountData();
  const resetUserAccountStateFlagRef = useRef(false);
  const [searchParams,] = useSearchParams();

  const onMemberIdChanged = (value) => {
    const stripSpaces = value?.replace(' ', '') || '';
    const isOldId = oldIdRegex.test(stripSpaces) === true;
    const isReadyToSubmit = isOldId === false && readyToSubmitRegex.test(stripSpaces) === true;
    const errorMessage = isOldId === true
      && "The value that you've entered is in the format of the old USA Swimming IDs. Please enter a current Id.";

    setState({
      ...state,
      memberId: stripSpaces,
      isReadyToSubmit,
      errorMessage,
      showRequestButton: errorMessage ? true : false
    });
  };

  const onSubmit = async (e) => {
    e?.preventDefault();
    //Parent creating a child account, do not check that the memberId is associated with a member under 16
    const memberId = searchParams.get('memberId');
    if (memberId) {
      postUserAccountPersonPingAssociation(state.memberId.toUpperCase());
    }
    //User creating their own account, check that the memberId is associated with a member 16 or older
    else {
      const errors = await validate(state.memberId);
      if (Object.keys(errors).length === 0) {
        postUserAccountPersonPingAssociation(state.memberId.toUpperCase());
      }
      else {
        setState({
          ...state,
          errorMessage: errors?.errorMessage,
          showRequestButton: errors?.errorMessage ? true : false
        });
      }
    }
  };

  useEffect(() => {
    const memberId = searchParams.get('memberId');
    if (memberId) {
      onMemberIdChanged(memberId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    const memberId = searchParams.get('memberId');
    if (memberId && state.memberId && state.isReadyToSubmit === true && state.errorMessage === false) {
      postUserAccountPersonPingAssociation(state.memberId.toUpperCase());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, state])

  useEffect(() => {
    if (userAccountPersonState.isSaved === true) {
      //happy path
      if (userAccountPersonState.objData?.isSuccess === true) {
        onComplete();
      }
      //sad paths
      else if (userAccountPersonState.objData?.isSuccess === false) {
        // Member Id was not found
        let errorMessage = 'Error';
        if (userAccountPersonState.objData?.message === 'Member Id not found') {
          resetUserAccountStateFlagRef.current = true;
          errorMessage = 'The Member Id did not match any in our system. Please check the Id and try again, or click the "Use Additional Info" button.'
        }
        // Member Id is already in use
        else if (userAccountPersonState.objData?.message === 'Member Id already in use') {
          resetUserAccountStateFlagRef.current = true;
          errorMessage = 'The Member Id is already in use by a login account. Only one login account per Member Id is allowed. Please check the Id to ensure it was entered correctly.'
        }
        setState({
          ...state,
          errorMessage: errorMessage
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountPersonState, state]);

  useEffect(() => {
    if (resetUserAccountStateFlagRef.current === true) {
      resetUserAccountStateFlagRef.current = false;
      resetUserAccountState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return {
    ...state,
    onMemberIdChanged,
    onSubmit,
    isSubmitting: userAccountPersonState.isSaving === true
  };
};

export default useLinkById;