import { createContext, useState } from 'react';

export const PaymentsStateContext = createContext();

const INITIAL_STATE = {
  cartChanged: false
};

const PaymentsContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <PaymentsStateContext.Provider value={stateHook}>
      {children}
    </PaymentsStateContext.Provider>
  );
};

export default PaymentsContextProvider;