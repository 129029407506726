const INITIAL_STATE = {
  isCartError: false,
  redirectToLogin: false, 
  errorTitle: '',
  errorMessage: ''
};

const ShoppingCartLoadingModalData = {
  INITIAL_STATE
};

export default ShoppingCartLoadingModalData;