import { createContext, useState } from 'react';

import ShoppingCartLoadingModalData from './ShoppingCartLoadingModalData';

export const ShoppingCartLoadingModalStateContext = createContext();

const ShoppingCartLoadingModalContextProvider = ({ children }) => {
  const stateHook = useState(ShoppingCartLoadingModalData.INITIAL_STATE);

  return (
    <ShoppingCartLoadingModalStateContext.Provider value={stateHook}>
      {children}
    </ShoppingCartLoadingModalStateContext.Provider>
  );
};

export default ShoppingCartLoadingModalContextProvider;