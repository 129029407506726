import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import searchFacilities from './SearchFacilitiesData';
import getFacility from './GetFacilityData';
import getPotentialFacilityDupes from './GetPotentialFacilityDupesData';
import putFacility from './PutFacilityData';
import postFacility from './PostFacilityData';
import postFacilityClubLink from './PostFacilityClubLinkData';
import deleteFacility from './DeleteFacilityData';
import deleteFacilityPool from './facilityPools/DeleteFacilityPoolData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
};

const FacilityData = {
  INITIAL_STATE,
  searchFacilities,
  getFacility,
  getPotentialFacilityDupes,
  putFacility,
  postFacility,
  postFacilityClubLink,
  deleteFacility,
  deleteFacilityPool
};

export default FacilityData;