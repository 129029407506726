import useMatchSorter from '../../utils/UseMatchSorter';

const useComboboxBase = (items, valueToMatch, onChange) => {
  const getId = (value) => {
    if (Array.isArray(items) === false) return -1;
    const item = items.find(x => x.name === value);
    return item && item.id ? item.id : -1;
  }
  const onValueChanged = (event) => {
    onChange(getId(event.target.value), event.target.value);
  }
  const onSelect = (newTerm) => {
    onChange(getId(newTerm), newTerm);
  }
  const searchResults = useMatchSorter(items, valueToMatch);

  return {
    searchResults,
    onSelect,
    onValueChanged
  };
};

export default useComboboxBase;