import React, { createContext, useState } from 'react';

import PersonAcknowledgementData from './PersonAcknowledgementData';

export const PersonAcknowledgementStateContext = createContext();

const PersonAcknowledgementContextProvider = ({ children }) => {
  const stateHook = useState(PersonAcknowledgementData.INITIAL_STATE);

  return (
    <PersonAcknowledgementStateContext.Provider value={stateHook}>
      {children}
    </PersonAcknowledgementStateContext.Provider>
  );
}

export default PersonAcknowledgementContextProvider;
