import React from 'react';

import ErrorBoundaryFallback from './ErrorBoundaryFallback';

import postErrorLog from '../../state/errorLogger/PostErrorLogData';
import { SecurityStateContext } from '../../state/security/SecurityContextProvider';

import { getErrorObjForLog } from '../../utils/ErrorUtils';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static contextType = SecurityStateContext;

  static getDerivedStateFromError(error) {
    if (error) {
      return { hasError: true };
    }
  }

  componentDidCatch(error) {
    const userInfo = this.context[0]?.userInfo || {};

    postErrorLog(getErrorObjForLog(error, userInfo.memberId));
  }

  render() {
    if (this.state.hasError) {
      const FallbackComponent = this.props.fallback || <ErrorBoundaryFallback />;

      return FallbackComponent;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;