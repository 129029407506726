import React, { Fragment } from 'react';

import useMeetUpload from './UseMeetUpload';

import Headings from '../../../../common/components/headings/Headings';
import MeetResultsUpload from '../../../../common/components/fileUpload/meetResultsUpload/MeetResultsUpload';

const MeetUploadActionSection = ({ meetId, canUploadResults, meetStartDate, meetEndDate }) => {
  return (
    <Fragment>
      {meetId > 0 && canUploadResults === true ?
        <MeetResultsUpload meetId={meetId} meetStartDate={meetStartDate} meetEndDate={meetEndDate} />
        :
        <div className='row'>
          <div className="col-xs-12 usas-extra-top-margin">
            This meet is in the future. You cannot upload meet results at this time.
          </div>
        </div>
      }
    </Fragment>
  )
}

const MeetUpload = () => {
  const {
    meetId,
    canUploadResults,
    meetStartDate,
    meetEndDate
  } = useMeetUpload();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Meet Upload</Headings.H3>
        </div>
      </div>
      <MeetUploadActionSection meetId={meetId} canUploadResults={canUploadResults} meetStartDate={meetStartDate} meetEndDate={meetEndDate} />
    </Fragment>
  );
};

export default MeetUpload;