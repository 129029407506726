import { formatDate } from '../../utils/DateFunctions';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import { evaluateBirthDate } from '../../utils/validation';

const serverValidate = async (memberId) => {
  let errors = {};
  
  const memberIdForUrl = memberId ? encodeURIComponent(memberId) : 'NaN';
  const url = `/Person/flat/memberId/${memberIdForUrl}`;

  await PersonHttpHelper(url, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        const birthDateObj = evaluateBirthDate(formatDate(objData?.birthDate));

        if (birthDateObj?.ageInYears < 16) {
          errors.errorMessage = 'Members under 16 cannot create their own login account'
        }
      }
    })
    .catch((e) => {
    });

  return errors;
};

const LinkByIdValidation = async (memberId) => {
  let errors = await serverValidate(memberId);

  return errors ? errors : {};
};

export default LinkByIdValidation;