import { useContext } from 'react';

import { PersonAcknowledgementInfoStateContext } from './PersonAcknowledgementInfoContextProvider';
import { PersonAcknowledgementInfoPageViewStateContext } from './PersonAcknowledgementInfoPageViewContextProvider';

import PersonAcknowledgementInfoData from './PersonAcknowledgementInfoData';
import Constants from '../../utils/Constants';

const usePersonAcknowledgementInfoData = () => {
  const [personAcknowledgementInfoState, setPersonAcknowledgementInfoState] = useContext(PersonAcknowledgementInfoStateContext);
  const [personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState] = useContext(PersonAcknowledgementInfoPageViewStateContext);

  const getPersonAcknowledgementInfo = (personId) => {
    return PersonAcknowledgementInfoData.getPersonAcknowledgementInfo(personId, personAcknowledgementInfoState, setPersonAcknowledgementInfoState);
  };

  const getPersonAcknowledgementInfoByMemberId = (memberId) => {
    return PersonAcknowledgementInfoData.getPersonAcknowledgementInfoByMemberId(memberId, personAcknowledgementInfoState, setPersonAcknowledgementInfoState);
  } ;

  const resetPersonAcknowledgementInfoPageViewState = () => {
    setPersonAcknowledgementInfoPageViewState({
      ...setPersonAcknowledgementInfoPageViewState,
      showConfirmContactInfoView: true,
      showChangeContactInfoView: false,
      showChangeContactInfoContactDetailView: false,
      showChangeContactInfoContactDetailAddressView: false,
      showChangeContactInfoContactDetailPhoneView: false,
      showAcknowledgeSwitchesView: false,
      person: {},
      personContactId: Constants.DEFAULT_ID,
      personContactAddressId: Constants.DEFAULT_ID,
      personContactPhoneId: Constants.DEFAULT_ID
    })
  };

  return {
    personAcknowledgementInfoState,
    getPersonAcknowledgementInfo,
    getPersonAcknowledgementInfoByMemberId,
    personAcknowledgementInfoPageViewState,
    setPersonAcknowledgementInfoPageViewState,
    resetPersonAcknowledgementInfoPageViewState
  };
}

export default usePersonAcknowledgementInfoData;
