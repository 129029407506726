import { useContext } from 'react';

import { PersonAcknowledgementAddAFamilyMemberStateContext } from './PersonAcknowledgementAddAFamilyMemberContextProvider';

import PersonAcknowledgementAddAFamilyMemberData from './PersonAcknowledgementAddAFamilyMemberData';

const usePersonAcknowledgementAddAFamilyMemberData = () => {
  const [personAcknowledgementAddAFamilyMemberState, setPersonAcknowledgementAddAFamilyMemberState] = useContext(PersonAcknowledgementAddAFamilyMemberStateContext);

  const getPersonAcknowledgementsAddAFamilyMember = (memberId) => {
    return PersonAcknowledgementAddAFamilyMemberData.getPersonAcknowledgementsAddAFamilyMember(memberId, personAcknowledgementAddAFamilyMemberState, setPersonAcknowledgementAddAFamilyMemberState);
  };

  const postPersonAcknowledgementsAddAFamilyMember = (memberId, acknowledgements) => {
    return PersonAcknowledgementAddAFamilyMemberData.postPersonAcknowledgementsAddAFamilyMember(memberId, acknowledgements, personAcknowledgementAddAFamilyMemberState, setPersonAcknowledgementAddAFamilyMemberState);
  };

  const resetPersonAcknowledgementsAddAFamilyMemberState = () => {
    setPersonAcknowledgementAddAFamilyMemberState(PersonAcknowledgementAddAFamilyMemberData.INITIAL_STATE)
  }

  return {
    personAcknowledgementAddAFamilyMemberState,
    getPersonAcknowledgementsAddAFamilyMember,
    postPersonAcknowledgementsAddAFamilyMember,resetPersonAcknowledgementsAddAFamilyMemberState
  };
}

export default usePersonAcknowledgementAddAFamilyMemberData;
