import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import useTree from './UseTree';

import global from '../../../common/components/GlobalStyle.module.css';
import style from './Tree.module.css';

const ExpandCollapseIcon = ({isExpanded, id, hasChildren, onClick}) => {
  return hasChildren
    ? (<span className={style.ExpandCollapse} onClick={() => {onClick(id);}}>
        <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} />
      </span>)
    : <Fragment />;
};

const Li = ({
    option,
    name,
    selectableLeavesOnly,
    singleSelect,
    hasChildren,
    onCheckChanged,
    onExpandCollapse,
    isDisabled }) => {
  return (
    <li role="treeitem" 
      aria-selected={option.isSelected === true}
      aria-expanded={option.isExpanded === true}
      tabIndex="0"
      className={[hasChildren === true ? style.HasChildren : '', style.TreeItem].join(' ')}>
      
      <ExpandCollapseIcon 
        isExpanded={option.isExpanded}
        id={option.id}
        hasChildren={hasChildren}
        onClick={onExpandCollapse} />
      <label className={style.TreeItemLabel}>
        {hasChildren !== true || selectableLeavesOnly !== true || option.isSelected === true
        ? (
          <input
            className={style.TreeCheckbox}
            type={singleSelect === true ? 'radio' : 'checkbox'}
            checked={option.isSelected === true}
            id={`${name}${option.id}`}
            name={name}
            value={option.id}
            onChange={(e) => { onCheckChanged(e.target.checked, option) }}
            disabled={isDisabled === true}/>
        ) : (
          <Fragment />
        )}
        <span> {option.name}</span>
      </label>

      {hasChildren === true && option.isExpanded === true
        ? <Ul optionsList={option.children}
              name={name}
              selectableLeavesOnly={selectableLeavesOnly}
              singleSelect={singleSelect}
              onCheckChanged={onCheckChanged}
              onExpandCollapse={onExpandCollapse}
              isDisabled={isDisabled} /> 
        : <Fragment />}
    </li>
  );
}

const Ul = ({
    className,
    optionsList,
    name,
    selectableLeavesOnly,
    singleSelect,
    onCheckChanged,
    onExpandCollapse,
    isDisabled}) => {
  if (optionsList.length === 0) {
    return <div className={global.InputLoading}>No data to display</div>;
  }

  return (
    <ul className={className} role="tree">
      {optionsList.map((option, i) => 
        <Li
          key={i}
          option={option}
          name={name}
          selectableLeavesOnly={selectableLeavesOnly}
          singleSelect={singleSelect}
          hasChildren={(Array.isArray(option.children) && option.children.length > 0)}
          onCheckChanged={onCheckChanged}
          onExpandCollapse={onExpandCollapse}
          isDisabled={isDisabled} />
      )}
    </ul>
  );
};

const Tree = ({
    label,
    name,
    value,
    error,
    message,
    options,
    selectableLeavesOnly,
    singleSelect,
    onChange,
    isLoading
  }) => {
  const {
    optionsList,
    onCheckChanged,
    onExpandCollapse,
    isDisabled} = useTree(options, value, singleSelect, onChange);

  return (
    <div className={[global.ComponentFlex, style.Tree].join(' ')}>
      {label ? <label className={global.UsasLabel}>{label}</label> : <Fragment />}
      {isLoading === true
        ? <div className={global.InputLoading}>Loading...</div>
        : <Ul
            className={style.TreePrimaryBranch}
            optionsList={optionsList}
            name={name}
            selectableLeavesOnly={selectableLeavesOnly}
            singleSelect={singleSelect === true}
            onCheckChanged={onCheckChanged}
            onExpandCollapse={onExpandCollapse}
            isDisabled={isDisabled} />}
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
};

export default Tree;