const ToIntIfInt = (text) => {
  try{
    const parsedValue = parseInt(text);
    if (isNaN(parsedValue) === false && parsedValue.toString() === text.toString()) {
      return parsedValue;
    }

    return text;
  } catch {
    return text;
  }
};

export default ToIntIfInt;