import Headings from '../../../headings/Headings';
import YesNoSwitch from '../../../yesNoSwitch/YesNoSwitch';

import style from '../../Payments.module.css'

const SwimmingWorldOptIn = ({ swimmingWorldOptIn, onValueChanged }) => (
  <div className="col-xs-12">
    <div className={style.OptionalBox}>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H5>Receive 25% off a digital subscription to Swimming World Magazine</Headings.H5>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-10">
          Calling all passionate swimmers, dedicated swim parents, and expert swim coaches of USA Swimming!
          Are you ready to take your love for the pool to the next level? Experience the thrill, knowledge,
          and inspiration that only Swimming World Magazine can bring you! Select “yes” to get exclusive access
          to a 25% off digital subscription to Swimming World Magazine.
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-6 col-md-4">
          <YesNoSwitch
            name="swimmingWorldOptIn"
            checked={swimmingWorldOptIn === true}
            onChange={(newValue) => { onValueChanged('swimmingWorldOptIn', newValue); }} />
        </div>
      </div>
    </div>
  </div>
);

export default SwimmingWorldOptIn;