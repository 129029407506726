export const SE_STEP_LOGIN = 'Login';
export const SE_STEP_ONE_TIME_PASSWORD = 'One Time Password';
export const SE_STEP_EXPIRED_PASSWORD = 'Expired Password';

const SessionEndingConstants = {
  SE_STEP_LOGIN,
  SE_STEP_ONE_TIME_PASSWORD,
  SE_STEP_EXPIRED_PASSWORD
};

export default SessionEndingConstants;