import StateData from './StateData_NEW';

import Constants from '../../utils/Constants';

const useStateData = () => {
  const statesData = StateData;

  const convertDataToIdNamePair = (arrayData) => {
    const stateArray = arrayData.map(state => { return { id: state.StateAbbreviation, name: state.StateName}});
    stateArray.unshift({id: Constants.DEFAULT_ID, name: ''});
    return stateArray;
  }
  
  const getUSStates = () => {
    return convertDataToIdNamePair(statesData.USA);
  }

  const getCanadaStates = () => {
    return convertDataToIdNamePair(statesData.Canada);
  }

  const getMexicoStates = () => {
    return convertDataToIdNamePair(statesData.Mexico);
  }

  const getNorthAmericaStates = () => {
    const concatStates = statesData.USA.concat(statesData.Canada).concat(statesData.Mexico);
    return convertDataToIdNamePair(concatStates);
  }


  return {
    getUSStates,
    getCanadaStates,
    getMexicoStates,
    getNorthAmericaStates
  };
};

export default useStateData;