import { useEffect, useState } from 'react';

import { useLocation } from '../../wrappers/ReactRouterDom';
import useSecurityData from '../../state/security/UseSecurityData';

const useLargeContextBasedNav = (taxonomyName, homeRoute, selectTab, renderPillsOnly) => {
  const [menuItems, setMenuItems] = useState([]);
  const [pillsState, setPillsState] = useState([]);
  const [expandedId, setExpandedId] = useState(-1);
  const { contextSecurityState, setContextNavVisibility } = useSecurityData();
  const location = useLocation();

  useEffect(() => {
    const found = Array.isArray(contextSecurityState?.objData?.taxonomies)
      ? contextSecurityState?.objData?.taxonomies.find(t => t.taxonomyName === taxonomyName)?.taxonomyLinks || []
      : [];

    if (renderPillsOnly === true) {
      setPillsState(found);
    } else {
      setMenuItems(found);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState?.objData?.taxonomies]);

  useEffect(() => {
    setContextNavVisibility(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (Array.isArray(menuItems) && menuItems.length > 0 && selectTab === true) {
      if (location.pathname === homeRoute) {
        setExpandedId(menuItems[0].taxonomyId);
      } else if (expandedId < 1) {
        let found = false;
        menuItems.forEach(x => {
          if (found === false) {
            if (x.linkUrl === location.pathname) {
              found = true;
              setExpandedId(x.taxonomyId);
            } else {
              x?.children?.forEach(c => {
                if (found === false && c.linkUrl === location.pathname) {
                  found = true;
                  setExpandedId(x.taxonomyId);
                }
              });
            }
          } 
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems, location.pathname, selectTab]);

  useEffect(() => {
    if (renderPillsOnly === false) {
      const newPillsState = menuItems.find(x => x.taxonomyId === expandedId)?.children || [];
      setPillsState([...newPillsState]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedId]);

  const onTabSelected = (tabId) => {
    setExpandedId(tabId);
  };

  const tabsState = [...menuItems];
  const selectedTabId = expandedId;
  return {
    tabsState,
    pillsState,
    selectedTabId,
    onTabSelected,
    setExpandedId
  };
};

export default useLargeContextBasedNav;