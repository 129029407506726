import { useContext } from 'react';

import { CartItemStateContext } from './CartItemContextProvider';
import CartItemData from './CartItemData';

const useCartItemData = () => {
  const [cartItemState, setCartItemState] = useContext(CartItemStateContext);

  const deleteCartItem = (cartItemId) => {
    CartItemData.deleteCartItemData(cartItemId, cartItemState, setCartItemState);
  };

  const resetCartItemState = () => {
    setCartItemState(CartItemData.INITIAL_STATE);
  }

  const clearCartItemObjData = () => {
    setCartItemState({
      ...cartItemState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  }

  return {
    cartItemState,
    deleteCartItem,
    resetCartItemState,
    clearCartItemObjData
  };
};

export default useCartItemData;