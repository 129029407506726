
import React, { Fragment } from 'react';

import useSelectOrgUnit from './UseSelectOrgUnit';

import Headings from '../headings/Headings';
import RadiobuttonList from '../radiobuttonlists/RadiobuttonList';
import PrimaryButton from '../buttons/PrimaryButton';
import PopUpModal from '../dialogs/PopUpModal';

import Constants from '../../utils/Constants';
import global from '../GlobalStyle.module.css';

const SelectOrgUnit = ({ pageTitle, navLink, roleGroupId, getFunction, TAXONOMIES, SCOPE }) => {
    const { state, handleSubmit, formState, errorState, onFormValueChanged, personOrgRoleState } = useSelectOrgUnit({ navLink, roleGroupId, getFunction, TAXONOMIES, SCOPE });

    return (
        <Fragment>
            <div className={state.radioButtonList.length > 0 && personOrgRoleState.isArrayLoading !== true ? global.DisplayComponent : global.HideComponent} >
                <div className="row">
                    <div className="col-xs-12">
                        <Headings.H3>{pageTitle}</Headings.H3>
                    </div>
                </div>
                <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                    <div className="col-xs-12">
                        <Headings.H6>Make a Selection</Headings.H6>
                    </div>
                </div>
                <form onSubmit={handleSubmit} noValidate>
                    <div className="row">
                        <div className="col-xs-12">
                            <RadiobuttonList
                                isVertical={true}
                                items={state.radioButtonList}
                                label="Edit Options*"
                                name="orgUnitId"
                                value={formState.orgUnitId}
                                error={errorState.orgUnitId}
                                message={errorState.orgUnitId}
                                onChange={(value) => { onFormValueChanged('orgUnitId', value); }} />
                        </div>
                    </div>
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
                        </div>
                    </div>
                </form>
            </div>
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={personOrgRoleState.isArrayLoading} />
        </Fragment>
    );
};

export default SelectOrgUnit;