import React from 'react';

import useCombobox from './UseCombobox';

import ComboboxBase from './ComboboxBase';

import ReadOnly from '../readOnly/ReadOnly';

import global from '../GlobalStyle.module.css';

const Combobox = ({ label, name, isLoading, items, valueToMatch, error, message, onChange }) => {
  const {value, isReadWrite} = useCombobox(items, valueToMatch);

  if (isReadWrite === true) {
    return (
      <div className={global.ComponentFlex}>
        {label && <label className={global.UsasLabel} htmlFor={name}>{label}</label>}
        {isLoading === true
          ? <div className={global.InputLoading}>Loading...</div>
          : <ComboboxBase label={label} items={items} valueToMatch={valueToMatch} onChange={onChange} error={error} />}
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    );
  } else {
    return (
      <ReadOnly
        label={label}
        name={name}
        value={value} />
    );
  }
};
  
export default Combobox;