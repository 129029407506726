export const DEFAULT_ID = -1;
export const DEFAULT_MAX_DATE = '01/01/3000';
export const DEFAULT_MIN_DATE = '01/01/1900';
export const DEFAULT_ORG_UNIT_LABEL = 'Secondary Organization';
export const DEFAULT_PHONE_NUMBER_AREA_CODE = '1';
export const DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO = '52';
export const DEFAULT_CANCEL_TEXT = 'Cancel';
export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_LOWERCASE = 'MM/dd/yyyy';
export const BLANK_DATE_STRING = '00/00/0000';
export const DATETIME_FORMAT = 'MM/dd/yyyy HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const BLANK_DATETIME_STRING = '00/00/0000 99:99';
export const BLANK_TIME_STRING = '99:99';
export const MIN_SWIM_TIME_STRING = '00:00:00.00';
export const MAX_SWIM_TIME_STRING = '23:59:59.59';
export const USAS_ORG_UNIT_LABEL = 'Club Name (Club Code)';
export const USAS_ORG_UNIT_LABEL_LSC = 'Club Name (LSC Code/Club Code)';
export const FINA_ORG_UNIT_LABEL = 'Country (Continent/Country Code)';
export const ISL_ORG_UNIT_LABEL = 'Team Name (Team Code)';
export const NCAA_ORG_UNIT_LABEL = 'School (Conference/School Code)';
export const KPI_CLUB_STAFF_MEASURE_TYPE_NAME = 'KPI - Club Staff';
export const KPI_STAFF_ROLE_MEASURE_TYPE_NAME = 'KPI - Staff Role';
export const MULTISELECT_MEASURE_TYPE_NAME = 'Multiselect';
export const PHONE_AREA_CODES = ['1', '52'];
export const WORKFLOW_STATUS_PENDING = 'Pending';
export const WORKFLOW_STATUS_APPROVED = 'Approved';
export const WORKFLOW_STATUS_SEND_BACK = 'Revisit';
export const WORKFLOW_STATUS_REVOKED = 'Revoked';
export const WORKFLOW_STATUS_NEW = 'New';
export const WORKFLOW_STATUS_REJECTED = 'Rejected';
export const WORKFLOW_STATUS_NOT_SUBMITTED = 'Not Submitted';
export const WORKFLOW_STATUS_SUBMITTED = 'Submitted';
export const WORKFLOW_STATUS_IN_PROGRESS = 'In Progress';
export const WORKFLOW_STATUS_IN_REVIEW = 'In Review';
export const WORKFLOW_STATUS_ACHIEVED = 'Achieved';
export const WORKFLOW_STATUS_NOT_CREATED = 'Not Created';
export const WORKFLOW_STATUS_CREATED = 'Created';
export const WORKFLOW_STATUS_EVENT_ENTRY_REQUIRED = 'Event Entry Required';
export const WORKFLOW_STATUS_EVENT_APPROVAL_REQUIRED = 'Event Approval Required';
export const WORKFLOW_STATUS_RENEWED = 'Renewed';
export const WORKFLOW_STATUS_PAID = 'Paid';
export const WORKFLOW_STATUS_NOT_ENTERED = 'Not Entered';
export const WORKFLOW_STATUS_EVENTS_APPROVED = 'Events Approved';
export const WORKFLOW_STATUS_RELAY_ONLY_SWIMMER = 'Relay Only Swimmer';
export const WORKFLOW_STATUS_UNATTACHED = 'Unattached';
export const DEFAULT_POSITIVE_ID = 1;
export const DEFAULT_POSITIVE_PAIR_ID = 2;
export const COMP_GENDER_OPTIONS_UNKNOWN_VALUE = 'U';
export const COMP_GENDER_OPTIONS_MALE_VALUE = 'M';
export const COMP_GENDER_OPTIONS_FEMALE_VALUE = 'F';
export const COMP_GENDER_OPTIONS_MIXED_VALUE = 'MX';
export const COMP_GENDER_OPTIONS_UNKNOWN_NAME = 'Unknown';
export const COMP_GENDER_OPTIONS_MALE_NAME = 'Male';
export const COMP_GENDER_OPTIONS_FEMALE_NAME = 'Female';
export const COMP_GENDER_OPTIONS_MIXED_NAME = 'Mixed';
export const TOKEN_STORAGE_NAME = 'usasauth';
export const REFRESH_TOKEN_STORAGE_NAME = 'usasauthrt';
export const EXPIRATION_DATETIME = 'usasauthed';
export const USERNAME = 'usasauthu';
export const MEMBER_STORAGE_NAME = 'member';
export const CLUB_STORAGE_NAME = 'club';
export const SESSION_DEBUG_TIMER_STORAGE_NAME = 'sessionDebug';
export const COOKIE_OPTIONS = { path: '/', domain: import.meta.env.VITE_COOKIE_DOMAIN, sameSite: false };
export const FILE_TYPE_PDF = 'application/pdf';
export const FILE_TYPE_DOC = 'application/msword';
export const FILE_TYPE_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const FILE_TYPE_JPG = 'image/jpeg';
export const FILE_TYPE_PNG = 'image/png';
export const FILE_TYPE_CSV = 'text/csv';
export const FILE_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const PDF_FILE_EXT = '.pdf';
export const DEFAULT_ALTITUDE = '0';
export const DEFAULT_LANE_COUNT = 0;
export const LCM_LANE_TITLE = 'LCM';
export const SCM_LANE_TITLE = 'SCM';
export const SCY_LANE_TITLE = 'SCY';
export const TEACHING_LANE_TITLE = 'Teaching';
export const LOADING_MSG = 'Loading...';
export const SAVING_MSG = 'Saving...';
export const PROCESSING_PAYMENT_MSG = 'Processing Payment...';
export const PROCESSING_MSG = 'Processing...'
export const SUBMITTING_MSG = 'Submitting...';
export const UPLOADING_MSG = 'Uploading...';
export const ERROR_MSG = 'Error...';
// export const REPORT_CATEGORY_LSC_ID = 1;
// export const REPORT_CATEGORY_CLUB_ID = 2;
// export const REPORT_CATEGORY_ADMIN_ID = 3;
// export const REPORT_CATEGORY_MEMBER_ID = 4;
// export const REPORT_CATEGORY_NCAA_ID = 5;
// export const REPORT_CATEGORY_WORKFLOW_ID = 6;
// export const REPORT_CATEGORY_GROUPS_ID = 7;
// export const REPORT_CATEGORY_PERFORMANCE_ID = 8;
// export const REPORT_CATEGORY_FACILITY_ID = 9;
// functions - exported, but not part of the default export
export const DEFAULT_BLANK_FUNCTION = () => { };
export const CURRENCY_FORMATTER = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
export const UI_PROJECT_NAMES = {
  CLUB: 'club-ui',
  FACILITY: 'facility-ui',
  GROUPS: 'groups-ui',
  LMS: 'lms-ui',
  MEET: 'meet-ui',
  HUB: 'hub-ui',
  MEMBER: 'member-ui',
  OMR: 'omr-ui',
  ORG_UNIT: 'orgunit-ui',
  TIMES: 'times-ui',
  WORKFLOW: 'workflow-ui'
};

const Constants = {
  DEFAULT_ID,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  DEFAULT_DATE_FORMAT,
  BLANK_DATE_STRING,
  DATE_FORMAT_LOWERCASE,
  DATETIME_FORMAT,
  TIME_FORMAT,
  BLANK_DATETIME_STRING,
  BLANK_TIME_STRING,
  MIN_SWIM_TIME_STRING,
  MAX_SWIM_TIME_STRING,
  DEFAULT_ORG_UNIT_LABEL,
  DEFAULT_PHONE_NUMBER_AREA_CODE,
  DEFAULT_PHONE_NUMBER_AREA_CODE_MEXICO,
  DEFAULT_CANCEL_TEXT,
  USAS_ORG_UNIT_LABEL,
  USAS_ORG_UNIT_LABEL_LSC,
  FINA_ORG_UNIT_LABEL,
  ISL_ORG_UNIT_LABEL,
  NCAA_ORG_UNIT_LABEL,
  KPI_CLUB_STAFF_MEASURE_TYPE_NAME,
  KPI_STAFF_ROLE_MEASURE_TYPE_NAME,
  MULTISELECT_MEASURE_TYPE_NAME,
  PHONE_AREA_CODES,
  DEFAULT_POSITIVE_ID,
  DEFAULT_POSITIVE_PAIR_ID,
  COMP_GENDER_OPTIONS_FEMALE_VALUE,
  COMP_GENDER_OPTIONS_MALE_VALUE,
  COMP_GENDER_OPTIONS_UNKNOWN_VALUE,
  COMP_GENDER_OPTIONS_FEMALE_NAME,
  COMP_GENDER_OPTIONS_MALE_NAME,
  COMP_GENDER_OPTIONS_UNKNOWN_NAME,
  TOKEN_STORAGE_NAME,
  REFRESH_TOKEN_STORAGE_NAME,
  EXPIRATION_DATETIME,
  USERNAME,
  MEMBER_STORAGE_NAME,
  CLUB_STORAGE_NAME,
  SESSION_DEBUG_TIMER_STORAGE_NAME,
  COOKIE_OPTIONS,
  COMP_GENDER_OPTIONS_MIXED_NAME,
  COMP_GENDER_OPTIONS_MIXED_VALUE,
  WORKFLOW_STATUS_APPROVED,
  WORKFLOW_STATUS_PENDING,
  WORKFLOW_STATUS_SEND_BACK,
  WORKFLOW_STATUS_REVOKED,
  WORKFLOW_STATUS_NEW,
  WORKFLOW_STATUS_REJECTED,
  WORKFLOW_STATUS_NOT_SUBMITTED,
  WORKFLOW_STATUS_SUBMITTED,
  WORKFLOW_STATUS_IN_PROGRESS,
  WORKFLOW_STATUS_IN_REVIEW,
  WORKFLOW_STATUS_ACHIEVED,
  WORKFLOW_STATUS_NOT_CREATED,
  WORKFLOW_STATUS_CREATED,
  WORKFLOW_STATUS_EVENT_ENTRY_REQUIRED,
  WORKFLOW_STATUS_EVENT_APPROVAL_REQUIRED,
  WORKFLOW_STATUS_PAID, 
  WORKFLOW_STATUS_NOT_ENTERED,
  WORKFLOW_STATUS_EVENTS_APPROVED,
  WORKFLOW_STATUS_RELAY_ONLY_SWIMMER,
  WORKFLOW_STATUS_UNATTACHED,
  WORKFLOW_STATUS_RENEWED,
  FILE_TYPE_PDF,
  FILE_TYPE_DOC,
  FILE_TYPE_DOCX,
  FILE_TYPE_JPG,
  FILE_TYPE_PNG,
  FILE_TYPE_CSV,
  FILE_TYPE_XLSX,
  PDF_FILE_EXT,
  DEFAULT_ALTITUDE,
  DEFAULT_LANE_COUNT,
  LCM_LANE_TITLE,
  SCM_LANE_TITLE,
  SCY_LANE_TITLE,
  TEACHING_LANE_TITLE,
  LOADING_MSG,
  SAVING_MSG,
  PROCESSING_PAYMENT_MSG,
  PROCESSING_MSG, 
  SUBMITTING_MSG,
  UPLOADING_MSG,
  ERROR_MSG,
  UI_PROJECT_NAMES,
  // REPORT_CATEGORY_LSC_ID,
  // REPORT_CATEGORY_CLUB_ID,
  // REPORT_CATEGORY_ADMIN_ID
};

export default Constants;