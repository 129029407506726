import React, { createContext, useState } from 'react';

import MeetClassificationData from './MeetClassificationData';

export const MeetClassificationStateContext = createContext();

const MeetClassificationContextProvider = ({ children }) => {
  const stateHook = useState(MeetClassificationData.INITIAL_STATE);

  return (
    <MeetClassificationStateContext.Provider value={stateHook}>
      {children}
    </MeetClassificationStateContext.Provider>
  );
};

export default MeetClassificationContextProvider;