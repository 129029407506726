import { useEffect } from 'react';
import useSecurityData from '../../state/security/UseSecurityData';

const usePersonalInfoForm = (formObj) => {
  const { userInfo } = useSecurityData();

  const onSubmit = (e) => {
    formObj.handleSubmit(e);
  };

  useEffect(() => {
    if (userInfo.email) {
      formObj.setFormData({
        ...formObj.formState,
        email: userInfo.email
      }, true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.email]);

  return {
    formState: formObj.formState,
    errorState: formObj.errorState,
    onFormValueChanged: formObj.onFormValueChanged,
    onSubmit
  };
};

export default usePersonalInfoForm;