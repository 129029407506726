import {
  ClubHttpHelper,
  CommonHttpHelper,
  LmsHttpHelper,
  MeetHttpHelper,
  OmrHttpHelper,
  PersonHttpHelper,
  PreCompHttpHelper,
  RecHttpHelper,
  SecurityHttpHelper,
  TimesHttpHelper,
  PaymentsHttpHelper,
  PaymentsAdminHttpHelper,
  NO_DATA_MESSAGE
} from './HttpHelper';

export const API_NAMES = {
  CLUB: 'CLUB',
  COMMON: 'COMMON',
  LMS: 'LMS',
  MEET: 'MEET',
  OMR: 'OMR',
  PERSON: 'PERSON',
  PRE_COMP: 'PRE_COMP',
  REC: 'REC',
  SECURITY: 'SECURITY',
  TIMES: 'TIMES',
  PAYMENTS: 'PAYMENTS',
  PAYMENTSADMIN: 'PAYMENTSADMIN'
};

const fakeFetchArray = async (state, setState, arrayData) => {
  if (state.message || state.isArrayLoading === true) {
    return null;
  }

  let newState = {
    ...state,
    isArrayLoading: true,
    isArrayLoaded: false,
    message: ''
  };
  setState({ ...newState });

  return new Promise((resolve) => {
    setTimeout(resolve, 200);
  }).then(() => {
    newState = {
      ...state,
      isArrayLoading: false,
      isArrayLoaded: true,
      arrayData,
      message: ''
    };
    setState({ ...newState });

    return newState;
  }).catch((e) => {
    setState({
      ...newState,
      isArrayLoading: false,
      isArrayLoaded: false,
      message: e?.message || NO_DATA_MESSAGE
    });

    throw e;
  });
};

const fakeFetchObject = async (state, setState, objData) => {
  if (state.message || state.isObjLoading === true) {
    return null;
  }

  let newState = {
    ...state,
    isObjLoading: true,
    isObjLoaded: false,
    message: ''
  };
  setState({ ...newState });

  return new Promise((resolve) => {
    setTimeout(resolve, 200);
  }).then(() => {
    newState = {
      ...state,
      isObjLoading: false,
      isObjLoaded: true,
      objData,
      message: ''
    };
    setState({ ...newState });

    return newState;
  }).catch((e) => {
    setState({
      ...newState,
      isObjLoading: false,
      isObjLoaded: false,
      message: e?.message || NO_DATA_MESSAGE
    });

    throw e;
  });
};

export const getHttpHelper = (apiName) => {
  switch (apiName) {
    case API_NAMES.CLUB:
      return ClubHttpHelper;

    case API_NAMES.COMMON:
      return CommonHttpHelper;

    case API_NAMES.LMS:
      return LmsHttpHelper;

    case API_NAMES.MEET:
      return MeetHttpHelper;

    case API_NAMES.OMR:
      return OmrHttpHelper;

    case API_NAMES.PERSON:
      return PersonHttpHelper;

    case API_NAMES.PRE_COMP:
      return PreCompHttpHelper;

    case API_NAMES.REC:
      return RecHttpHelper;

    case API_NAMES.SECURITY:
      return SecurityHttpHelper;

    case API_NAMES.TIMES:
      return TimesHttpHelper;

    case API_NAMES.PAYMENTS:
      return PaymentsHttpHelper;

    case API_NAMES.PAYMENTSADMIN:
      return PaymentsAdminHttpHelper;

    default:
      return null;
  }
};

const executeArrayApi = async (apiName, state, setState, url, method, payload, isFileUpload, isFileDownload) => {
  const apiHelper = getHttpHelper(apiName);
  if (state.message || state.isArrayLoading === true || !apiHelper) {
    return null;
  }

  let newState = {
    ...state,
    isArrayLoading: true,
    isArrayLoaded: false,
    message: 'Loading...'
  };
  setState({ ...newState });

  return apiHelper(url, method, payload, isFileUpload, isFileDownload)
    .then((arrayData) => {
      if (!arrayData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        newState = {
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: true,
          arrayData,
          message: ''
        };
        setState({ ...newState });

        return newState;
      }
    }).catch((e) => {
      setState({
        ...newState,
        isArrayLoading: false,
        isArrayLoaded: false,
        message: e?.message || NO_DATA_MESSAGE
      });

      throw e;
    });
};

const executeObjectApi = async (apiName, state, setState, url, method, payload, isFileUpload, isFileDownload) => {
  const apiHelper = getHttpHelper(apiName);
  if (state.message || state.isObjLoading === true || !apiHelper) {
    return null;
  }

  let newState = {
    ...state,
    isObjLoading: true,
    isObjLoaded: false,
    message: 'Loading...'
  };
  setState({ ...newState });

  return apiHelper(url, method, payload, isFileUpload, isFileDownload)
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        newState = {
          ...newState,
          isObjLoading: false,
          isObjLoaded: true,
          objData,
          message: ''
        };
        setState({ ...newState });

        return newState;
      }
    }).catch((e) => {
      setState({
        ...newState,
        isObjLoading: false,
        isObjLoaded: false,
        message: e?.message || NO_DATA_MESSAGE
      });

      throw e;
    });
};

export const initApi = (apiName, state, setState, fakeData) => {
  const executeApi = async (isArray, url, method, payload, isFileUpload, isFileDownload) => {
    if (fakeData) {
      return isArray
        ? fakeFetchArray(state, setState, fakeData)
        : fakeFetchObject(state, setState, fakeData);
    }
    return isArray
      ? executeArrayApi(apiName, state, setState, url, method, payload, isFileUpload, isFileDownload)
      : executeObjectApi(apiName, state, setState, url, method, payload, isFileUpload, isFileDownload);
  };

  /**
   * NOTE: If isFileUpload = true method will always be 'POST'.
   * If isFileDownload = true method will always be 'GET' and payload will be ignored
   */
  const executeArray = async (url, method, payload, isFileUpload, isFileDownload) => {
    return executeApi(true, url, method, payload, isFileUpload, isFileDownload);
  };

  /**
   * NOTE: If isFileUpload = true method will always be 'POST'.
   * If isFileDownload = true method will always be 'GET' and payload will be ignored
  */
  const executeObject = async (url, method, payload, isFileUpload, isFileDownload) => {
    return executeApi(false, url, method, payload, isFileUpload, isFileDownload);
  };

  return {
    executeArray,
    executeObject,
    executeApi
  };
};

const HttpApiHelper = { API_NAMES, initApi };

export default HttpApiHelper;