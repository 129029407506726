import React, { Fragment } from 'react';

import useAddMeetTime from './UseAddMeetTime';
import AddMeetTimeGrid from './AddMeetTimeGrid';

import Headings from '../../../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../../../common/components/buttons/ActionIntraPageButton';

const AddMeetTime = () => {
  const {
    listState,
    onContinueToMeetTimeType,
    onBackToTimesResults
  } = useAddMeetTime();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>Add Meet Time Result</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <ActionIntraPageButton onClick={onContinueToMeetTimeType}>Add Time Result</ActionIntraPageButton>&nbsp;
          <ActionIntraPageButton onClick={onBackToTimesResults}>Back to Times Results</ActionIntraPageButton>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12">
          <AddMeetTimeGrid state={listState} />
        </div>
      </div>
    </Fragment>
  )
}

export default AddMeetTime;