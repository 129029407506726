import { Fragment } from 'react';

import { FormatMoneyWithSymbol } from '../../../../utils/FormatMoney';

import useExpandedRow from '../../../grids/UseExpandedRow';

import LinkButton from '../../../buttons/LinkButton';
import ShowIcon from '../../../icons/ShowIcon';
import DeleteIcon from '../../../icons/DeleteIcon';
import HideIcon from '../../../icons/HideIcon';

import global from '../../../GlobalStyle.module.css';
import style from '../../Payments.module.css';

const CartItemsGridRecursive = ({ cartItems, subTotal, onSelectedChanged, onDeleteItem, hideHeader, level = 1 }) => {
  const { expandedId, onClick } = useExpandedRow();

  return (
    <Fragment>
      <table className={global.UsasTable} style={{ marginTop: level === 1 ? 5 : 0 }}>
        {hideHeader !== true && (
          <thead>
            <tr>
              <th className={[style.ItemColumn, 'text-left'].join(' ')} style={{ paddingLeft: 10 }}>Item</th>
              <th className={style.SelectColumn}></th>
              <th className={style.ButtonColumn}></th>
              <th className={style.ButtonColumn}></th>
              <th className={[style.AmountColumn, 'text-right'].join(' ')} style={{ paddingRight: 10 }}>Amount</th>
            </tr>
          </thead>
        )}
        <tbody>
          {cartItems.map((item, i) => (
            <Fragment key={i}>
              <tr>
                <td className={[style.ItemColumn, 'text-left'].join(' ')} style={{ fontWeight: level === 1 ? 'bold' : 'normal', paddingLeft: level * 10 }}>{item.itemDescription}</td>
                <td className={style.SelectColumn}>
                  {item.canRemoveFromCart && level === 1 &&
                    <LinkButton action={() => onSelectedChanged(item)}>{item.isSelected ? 'Pay for this later' : 'Pay for this now'}</LinkButton>
                  }
                </td>
                <td className={[style.ButtonColumn, 'text-right'].join(' ')} style={{ paddingRight: 0 }}>
                  {item.isSelected && item.children?.length > 0 && (
                    <button
                      className={global.IconButtonMargin}
                      type="button"
                      onClick={(e) => onClick(e, item.cartItemDetailId)}>
                      {expandedId === item.cartItemDetailId ? <HideIcon /> : <ShowIcon />}
                    </button>
                  )}
                </td>
                <td className={[style.ButtonColumn, 'text-right'].join(' ')} style={{ paddingRight: 0 }}>
                  {(item.canDeleteFromCart && item.isSelected &&
                    <button
                      className={global.IconButtonMargin}
                      type="button"
                      onClick={() => { onDeleteItem(item); }}>
                      <DeleteIcon />
                    </button>
                  )}
                </td>
                <td className={[style.AmountColumn, 'text-right'].join(' ')} style={{ fontWeight: level === 1 ? 'bold' : 'normal', paddingRight: 10 }}>{item.isSelected && FormatMoneyWithSymbol(item.itemPrice)}</td>
              </tr>
              {item.isSelected && item.children?.length > 0 && expandedId === item.cartItemDetailId && (
                <tr style={{ backgroundColor: '#C1C6C8', borderBottom: 0, margin: 0 }}>
                  <td colSpan="5" style={{ border: 0, padding: 0 }}>
                    <CartItemsGridRecursive cartItems={item.children} onSelectedChanged={onSelectedChanged} onDeleteItem={onDeleteItem} hideHeader={true} level={level + 1} />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
          {subTotal >= 0 && (
            <tr style={{ backgroundColor: 'transparent', border: 0 }}>
              <td colSpan={4} style={{ textAlign: 'right' }}>Subtotal:</td>
              <td className={[style.AmountColumn, 'text-right'].join(' ')} style={{ fontWeight: 'bold', paddingRight: 10 }}>{FormatMoneyWithSymbol(subTotal)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default CartItemsGridRecursive;