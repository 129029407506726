import { useEffect, useState } from "react";

import { formatExpirationDatetimeForDisplay } from "../../utils/SessionEndingUtils";

import { getStoredTokenObj } from "../../../../../../utils/TokenUtils";

const useSessionTimer = () => {
  const [expiringInState, setExpiringInState] = useState('00:00:00');

  const calculateExpiringIn = () => {
    const tokenExpirationDatetime = getStoredTokenObj().expirationDatetime;

    setExpiringInState(formatExpirationDatetimeForDisplay(tokenExpirationDatetime));
  };

  useEffect(() => {
    calculateExpiringIn();
    const interval = setInterval(() => calculateExpiringIn(), 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    expiringIn: expiringInState
  };
};

export default useSessionTimer;