import { useContext } from 'react';

import { ReportPeriodSeasonStateContext } from './ReportPeriodSeasonContextProvider';
import ReportPeriodSeasonData from './ReportPeriodSeasonData';

const useReportPeriodSeasonData = () => {
  const [reportPeriodSeasonState, setReportPeriodSeasonState] = useContext(ReportPeriodSeasonStateContext);

  const getReportPeriodSeason = (organizationId, defaultOption, reverseDataOrder = false) => {
    ReportPeriodSeasonData.getReportPeriodsSeasonByOrganizationId(organizationId, defaultOption, reverseDataOrder, reportPeriodSeasonState, setReportPeriodSeasonState);
  };

  return {
    reportPeriodSeasonState,
    setReportPeriodSeasonState,
    getReportPeriodSeason
  };
};

export default useReportPeriodSeasonData;