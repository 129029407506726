import { useEffect } from 'react';
import useLscData from '../../../state/lsc/UseLscData';

const useLscDropdown = () => {
  const { lscState, getLscs } = useLscData();

  useEffect(() => {
    if (lscState.isLoading !== true && lscState.isLoaded !== true) {
      getLscs();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lscState
  };
};

export default useLscDropdown;