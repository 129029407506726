import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

const GetSessionData = (state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/SessionType`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ sessionTypeId: Constants.DEFAULT_ID, typeName: '--' });
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: arrayData.map((session) => { return { id: session.sessionTypeId, name: session.typeName } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetSessionData;