import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import validate from './OrgUnitAdminCoachesSelectionValidation';

import NavLinks from '../../../meet/NavLinks';
import EntryNavLinks from '../../EntryNavLinks';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetOrgUnitEntryData from '../../../../state/omeMeetOrgUnitEntry/UseOmeMeetOrgUnitEntryData';
import useOmeMeetOrgUnitNonAthleteEntryData from '../../../../state/omeMeetOrgUnitNonAthleteEntry/UseOmeMeetOrgUnitNonAthleteEntryData';
import useOmeMeetOrgUnitNonAthleteEntryCoachesSelectionData from '../../../../state/omeMeetOrgUnitNonAthleteEntryCoachesSelection/UseOmeMeetOrgUnitNonAthleteEntryCoachesSelectionData';

import UseForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  isInGoodStanding: true,
  firstOrPreferredName: '',
  lastName: ''
};

const useOrgUnitAdminCoachesSelection = () => {
  const navigate = useNavigate();
  const { omeMeetState } = useOmeMeetData()
  const { omeMeetOrgUnitEntryState } = useOmeMeetOrgUnitEntryData();
  const { omeMeetOrgUnitNonAthleteEntryState } = useOmeMeetOrgUnitNonAthleteEntryData();
  const { omeMeetOrgUnitNonAthleteEntryCoachesSelectionState,
    getOrgUnitNonAthleteEntryCoachesSelection, postOrgUnitNonAthleteEntryCoachesSelection } = useOmeMeetOrgUnitNonAthleteEntryCoachesSelectionData();
  const [state, setState] = useState({ tryRedirect: false });
  const [gridState, setGridState] = useState({ gridData: [] });
  const [selectionChangesState, setSelectionChangesState] = useState({ arrayData: [] });
  const { handleSubmit, formState, errorState, setErrors, setFormState, onFormValueChanged, onValueTextPairChanged } = UseForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const onFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    handleSubmit();
  }

  const onClearFilterClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(INITIAL_FORM_STATE);
    getOrgUnitNonAthleteEntryCoachesSelection(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, createFilterObj(INITIAL_FORM_STATE));
    setErrors({});
  }

  const onSaveClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    let deleteAfterPayment = false;
    //checking for delete if the team is attached and has submitted, or if the team is unattached
    if (omeMeetOrgUnitEntryState.objData.status !== Constants.WORKFLOW_STATUS_IN_PROGRESS) {
      for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
        if (selectionChangesState.arrayData[i].orgUnitNonAthleteEntryId !== null && selectionChangesState.arrayData[i].isSelected === false &&
          selectionChangesState.arrayData[i].hasPaid === true) {
          deleteAfterPayment = true;
        }
      }
    }

    if (deleteAfterPayment === true) {
      setErrors({ ...errorState, general: `The current selections removed a coach with a paid for credential. Removing a coach after their credential has been purchased is not allowed.` });
    }
    else {
      let coachPassRuleError = false;
      //Compare maxCoachNumber to number of selections
      if (omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber !== null &&
        omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber >= 0) {
        let existingCoachNum = omeMeetOrgUnitNonAthleteEntryState.arrayData?.length;
        let newSelectionsArray = selectionChangesState.arrayData?.filter(x => (x.isSelected === true && x.orgUnitNonAthleteEntryId === null));
        let newSelectionsNum = newSelectionsArray ? newSelectionsArray.length : 0;
        let newUnselectionsArray = selectionChangesState.arrayData?.filter(x => (x.isSelected === false && x.orgUnitNonAthleteEntryId !== null));
        let newUnselectionsNum = newUnselectionsArray ? newUnselectionsArray.length : 0;
        let newCoachNum = existingCoachNum + newSelectionsNum - newUnselectionsNum;
        if (newCoachNum > omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber) {
          coachPassRuleError = true;
        }
      }
      if (coachPassRuleError === true) {
        setErrors({ ...errorState, general: `The current selections exceed the number of allowed coaches according to the meet's coach pass rules. With the current roster, ${omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber ?? ''} coach ${omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber === 1 ? 'selection is' : 'selections are'} allowed.` });
      }
      else {
        if (selectionChangesState.arrayData.length > 0) {
          let allSelectionsInGoodStanding = true;
          for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
            if (selectionChangesState.arrayData[i].isInGoodStanding === false && selectionChangesState.arrayData[i].isSelected === true) {
              allSelectionsInGoodStanding = false
            }
          }
          if (allSelectionsInGoodStanding === true) {
            postOrgUnitNonAthleteEntryCoachesSelection(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, {
              maxCoachNumber: omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.arrayData?.maxCoachNumber,
              coaches: selectionChangesState.arrayData
            });
            setState({ ...state, tryRedirect: true });
          }
          else {
            setErrors({ ...errorState, general: "All selections must be 'In Good Standing'. At least one selection is 'Not In Good Standing', which is not allowed." })
          }
        }
        else {
          setState({ ...state, tryRedirect: true });
        }
      }
    }
  }

  const onBackClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (omeMeetState.route === '') {
      //OME Route
      navigate(EntryNavLinks.OU_ADMIN_COACHES);
    }
    else {
      //Meet Route
      navigate(NavLinks.OU_ADMIN_COACHES);
    }
  }

  const onSelectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].hasPaid === false) {
        updatedGridData[i].isSelected = true;
      }
      const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId);
      //Edit
      if (selectedAthleteIndexArrayData > -1) {
        if (updatedArrayData[selectedAthleteIndexArrayData].hasPaid === false) {
          updatedArrayData[selectedAthleteIndexArrayData].isSelected = true;
        }
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  }

  const onUnselectAll = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    for (let i = 0; i < updatedGridData.length; i++) {
      if (updatedGridData[i].hasPaid === false) {
        updatedGridData[i].isSelected = false;
      }
      const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === updatedGridData[i].personId);
      //Edit
      if (selectedAthleteIndexArrayData > -1) {
        if (updatedArrayData[selectedAthleteIndexArrayData].hasPaid === false) {
          updatedArrayData[selectedAthleteIndexArrayData].isSelected = false;
        }
      }
      else {
        updatedArrayData.push(updatedGridData[i]);
      }
    }

    setSelectionChangesState({
      ...selectionChangesState,
      arrayData: updatedArrayData
    });
    setGridState({ ...gridState, gridData: updatedGridData });
  };

  const onCheckboxChange = (e, personId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const updatedArrayData = JSON.parse(JSON.stringify(selectionChangesState.arrayData));
    const selectedAthleteIndexArrayData = updatedArrayData.findIndex(x => x.personId === personId);

    const updatedGridData = JSON.parse(JSON.stringify(gridState.gridData));
    const selectedAthleteIndexGridData = gridState.gridData?.findIndex(x => x.personId === personId);

    if (selectedAthleteIndexArrayData > -1) {
      updatedArrayData[selectedAthleteIndexArrayData].isSelected = !updatedArrayData[selectedAthleteIndexArrayData].isSelected;
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }

    if (selectedAthleteIndexGridData > -1) {
      updatedGridData[selectedAthleteIndexGridData].isSelected = !updatedGridData[selectedAthleteIndexGridData].isSelected;
      setGridState({
        ...gridState,
        gridData: updatedGridData
      });
    }
    if (selectedAthleteIndexArrayData === -1) {
      const newAthlete = JSON.parse(JSON.stringify(updatedGridData[selectedAthleteIndexGridData]));
      updatedArrayData.push(newAthlete);
      setSelectionChangesState({
        ...selectionChangesState,
        arrayData: updatedArrayData
      });
    }
  };

  function submitFormCallback(formState) {
    getOrgUnitNonAthleteEntryCoachesSelection(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, createFilterObj(formState));
  };

  function createFilterObj(formState) {
    return {
      firstName: formState.firstOrPreferredName.trim() !== '' ? formState.firstOrPreferredName.trim() : undefined,
      lastName: formState.lastName.trim() !== '' ? formState.lastName.trim() : undefined,
      isInGoodStanding: formState.isInGoodStanding ?? undefined
    }
  }

  useEffect(() => {
    if (omeMeetOrgUnitEntryState.isObjLoaded === true && omeMeetOrgUnitEntryState.objData?.omeMeetOrgUnitEntryId) {
      getOrgUnitNonAthleteEntryCoachesSelection(omeMeetOrgUnitEntryState.objData.omeMeetOrgUnitEntryId, createFilterObj(formState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitEntryState])

  useEffect(() => {
    if (omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.isArrayLoaded === true && omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.isArrayLoading === false) {
      const arrayDataCopy = JSON.parse(JSON.stringify(omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.arrayData?.coaches || []));
      //keep unsaved selections / unselections from previous searches
      if (selectionChangesState.arrayData.length > 0) {
        for (let i = 0; i < selectionChangesState.arrayData.length; i++) {
          const matchingIndex = arrayDataCopy.findIndex(x => x.personId === selectionChangesState.arrayData[i].personId);
          if (matchingIndex > -1) {
            arrayDataCopy[matchingIndex].isSelected = selectionChangesState.arrayData[i].isSelected;
          }
        }
      }
      setGridState({ ...gridState, gridData: arrayDataCopy });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.isArrayLoaded, omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.isArrayLoading])

  useEffect(() => {
    if (state.tryRedirect === true && omeMeetOrgUnitNonAthleteEntryCoachesSelectionState.isSaving === false) {
      if (omeMeetState.route === '') {
        //OME Route
        navigate(EntryNavLinks.OU_ADMIN_COACHES, { state: { tryGet: selectionChangesState.arrayData.length > 0 ? true : false } });
      }
      else {
        //Meet Route
        navigate(NavLinks.OU_ADMIN_COACHES, { state: { tryGet: selectionChangesState.arrayData.length > 0 ? true : false } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, omeMeetOrgUnitNonAthleteEntryCoachesSelectionState])

  return {
    gridState,
    omeMeetOrgUnitNonAthleteEntryCoachesSelectionState,
    onSelectAll,
    onUnselectAll,
    onSaveClicked,
    onBackClicked,
    onCheckboxChange,
    onFilterClicked,
    onClearFilterClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    formState,
    errorState,
    omeMeetOrgUnitEntryState
  };
}

export default useOrgUnitAdminCoachesSelection;