import { useContext, useEffect } from 'react';

import { EnvironmentVariableStateContext } from './EnvironmentVariableContextProvider';
import { SecurityStateContext } from '../security/SecurityContextProvider';
import EnvironmentVariableData from './EnvironmentVariableData';

const useEnvironmentVariableData = () => {
  const [securityState] = useContext(SecurityStateContext);
  const [environmentVariableState, setEnvironmentVariableState] = useContext(EnvironmentVariableStateContext);
  const getEnvironmentVariables = (code) => {
    EnvironmentVariableData.getEnvironmentVariables(code, environmentVariableState, setEnvironmentVariableState);
  };

  useEffect(() => {
    if (environmentVariableState.isLoaded !== true || EnvironmentVariableData.getCode() !== environmentVariableState.code) {
      getEnvironmentVariables(EnvironmentVariableData.getCode());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, securityState]);

  return {
    environmentVariableState,
    ...(environmentVariableState.variables)
  };
};

export default useEnvironmentVariableData;