import { useState, useEffect } from 'react';

const UseForm = (defaultFormState, submitFormCallback, validate) => {

  const [formState, setFormState] = useState(defaultFormState);
  const [savedFormState, setSavedFormState] = useState(defaultFormState);
  const [errorState, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const setFormData = (newFormState, setDirty) => {
    setFormState(formState => ({ ...newFormState }));
    setSavedFormState(formState => ({ ...newFormState }));
    setIsDirty(setDirty === true);
  }

  const doSubmitCallback = async (formState) => {
    try {
      await submitFormCallback(formState);
      setIsDirty(false);
      setIsSubmitting(false);
      setFormData({ ...formState });
    } catch (e) {
      setIsSubmitting(false);
      setErrors({ errorOnSubmitAction: 'There was an error when the form was submitted.' });
    }
  };

  useEffect(() => {
    if (Object.keys(errorState).length === 0 && isSubmitting === true && isDirty === true) {
      if (submitFormCallback) {
        doSubmitCallback(formState);
      }
    }
    else {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorState]);

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsSubmitting(true);
    if (validate) {
      const errors = await validate(formState) || {};
      setErrors(errors);
    }
  };

  const handleChange = (event) => {
    event.persist();
    if (formState[event.target.name] !== event.target.value) {
      setFormState(formState => ({ ...formState, [event.target.name]: event.target.value }));
      setIsDirty(true);
    }
  };

  const updateFormState = (compnentName, componentValue) => {
    if (formState[compnentName] !== componentValue) {
      setFormState(formState => ({ ...formState, [compnentName]: componentValue }));
      setIsDirty(true);
    }
  }

  const onValueTextPairChanged = (newValue, valuePropertyName, newValueLabel, labelPropertyName) => {
    if (formState[valuePropertyName] !== newValue
      || formState[labelPropertyName] !== newValueLabel) {
      setFormState(formState => ({
        ...formState,
        [valuePropertyName]: newValue,
        [labelPropertyName]: newValueLabel
      }));
      setIsDirty(true);
    }
  }

  const resetForm = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setFormState(formState => ({ ...savedFormState }));
    setIsDirty(false);
    setErrors({});
  }

  return {
    handleChange,
    handleSubmit,
    updateFormState,
    formState,
    setFormState,
    errorState,
    setErrors,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    setFormData,
    resetForm,
    isSubmitting,
    isDirty,
    setIsDirty
  }
};

export default UseForm;