import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../wrappers/ReactRouterDom';

import validate from './MemberContactAddressDetailValidation';

import useStatesCombobox from '../comboboxes/statesCombobox/UseStatesCombobox';
import useCountryCombobox from '../comboboxes/countryCombobox/UseCountryCombobox';

import useNavRoutes from '../../state/security/UseNavRoutes';
import usePersonGeneralData from '../../state/personGeneral/UsePersonGeneralData';

import useForm from '../../utils/UseForm';
import Constants from '../../utils/Constants';

const LOCAL_CONSTANTS = {
  USA: 'USA',
  CANADA: 'CA',
  MEXICO: 'MX',
  DEFAULT_USA_STATE_CODE: 'AL',
  DEFAULT_USA_STATE: 'Alabama (AL)',
  DEFAULT_CAN_STATE_CODE: 'AB',
  DEFAULT_CAN_STATE: 'Alberta (AB)',
  DEFAULT_MEX_STATE_CODE: 'AG',
  DEFAULT_MEX_STATE: 'Aguascalientes (AG)',
  DEFAULT_NON_STATE_CODE: '  ',
  DEFAULT_NON_STATE: ''
};

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useMemberContactAddressDetail = (acknowledgementsView, personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { personGeneralState, putPersonGeneralContact } = usePersonGeneralData();
  const { getStateNameById } = useStatesCombobox();
  const { getCountryNameById } = useCountryCombobox();
  const { formState, errorState, isDirty, onValueTextPairChanged, handleSubmit, updateFormState, setIsDirty, setFormData
  } = useForm(getInitialFormState(), submitFormCallback, validate);
  const [state, setState] = useState(getInitialState());
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const [modalState, setModalState] = useState(getInitialModalState());

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setValidateState({
      isValidating: true
    });
    isDirty === false ? onCancelClicked(e) : await handleSubmit(e);

    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });

    updateFormState('showedAddressSuggestion', true);
  };


  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (acknowledgementsView === true) {
      setPersonAcknowledgementInfoPageViewState({
        ...personAcknowledgementInfoPageViewState,
        showChangeContactInfoView: true,
        showChangeContactInfoContactDetailAddressView: false,
        showChangeContactInfoContactDetailPhoneView: false,
        personContactId: Constants.DEFAULT_ID,
        personContactAddressId: Constants.DEFAULT_ID,
        personContactPhoneId: Constants.DEFAULT_ID
      });
    }
    else {
      navigate(navRoutes.MEMBER_CONTACTS?.route, { state: { personContactId: location.state.personContactId } });
    }
  };

  const onModalCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setIsDirty(true);
    setModalState(getInitialModalState());
  };

  const onCountryChange = (newValue, valuePropertyName, newValueLabel, labelPropertyName, e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    onValueTextPairChanged(newValue, valuePropertyName, newValueLabel, labelPropertyName);

    if (newValue !== Constants.DEFAULT_ID) {
      setFormStateBasedOnCountry(newValue);
    }
  };

  const putPersonGeneralContactAddresses = (doPrimaryCheck) => {
    if (personGeneralState.isSaving === false) {
      putPersonGeneralContact(personGeneralState.objData.personId, createPersonGeneralObject(doPrimaryCheck));
    }

    setState({
      ...state,
      tryRedirect: true
    });

    setModalState(getInitialModalState);
  };

  useEffect(() => {
    if (state.tryRedirect === true && personGeneralState.isObjLoading === false && personGeneralState.isSaving === false) {
      if (acknowledgementsView === true) {
        setPersonAcknowledgementInfoPageViewState({
          ...personAcknowledgementInfoPageViewState,
          showChangeContactInfoView: true,
          showChangeContactInfoContactDetailAddressView: false,
          showChangeContactInfoContactDetailPhoneView: false,
          personContactId: Constants.DEFAULT_ID,
          personContactAddressId: Constants.DEFAULT_ID,
          personContactPhoneId: Constants.DEFAULT_ID
        });
      }
      else {
        navigate(navRoutes.MEMBER_CONTACTS?.route, { state: { personContactId: location.state.personContactId } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personGeneralState, state.tryRedirect]);

  useEffect(() => {
    if (personGeneralState.isObjLoaded === true && (location.state?.personContactId > 0 || personAcknowledgementInfoPageViewState?.personContactId > 0)) {
      const targetContact = location.state?.personContactId > 0 ? personGeneralState.objData?.personContact?.find(x => x.personContactId === location.state?.personContactId) :
        personAcknowledgementInfoPageViewState?.personContactId > 0 ?
          personGeneralState.objData?.personContact?.find(x => x.personContactId === personAcknowledgementInfoPageViewState?.personContactId) : {};

      if (targetContact) {
        if (location.state?.personContactAddressId > 0 || personAcknowledgementInfoPageViewState?.personContactAddressId > 0) { // edit
          const targetAddress = location.state?.personContactAddressId > 0 ? targetContact.personContactAddress?.find(x => x.personContactAddressId === location.state.personContactAddressId) :
            personAcknowledgementInfoPageViewState?.personContactId > 0 ?
              targetContact.personContactAddress?.find(x => x.personContactAddressId === personAcknowledgementInfoPageViewState?.personContactAddressId) : {};

          if (targetAddress) {
            setFormData({
              ...formState,
              personContactAddressId: targetAddress.personContactAddressId,
              addressTypeId: targetAddress.addressTypeId || Constants.DEFAULT_ID,
              addressTypeName: targetAddress.addressType?.typeName || '',
              isPrimary: targetAddress.isPrimary === true,
              country: targetAddress.address?.countryCode || '',
              countryName: targetAddress.address?.countryCode ? getCountryNameById(targetAddress.address.countryCode) : '',
              address1: targetAddress.address?.address1 || '',
              address2: targetAddress.address?.address2 || '',
              city: targetAddress.address?.city || '',
              stateCode: targetAddress.address?.stateCode || '',
              state: targetAddress.address?.stateCode ? getStateNameById(targetAddress.address.stateCode) : '',
              postalCode: targetAddress.address?.postalCode || '',
            }, true);

            setState({
              ...state,
              personContactId: targetContact.personContactId,
              personContactName: `${targetContact.firstName || ''} ${targetContact.lastName || ''}`,
              disablePrimaryCheckbox: targetAddress.isPrimary === true
            });
          }
        } else if (targetContact.personContactAddress.length === 0) { // add first address, force isPrimary true
          setFormData({
            ...formState,
            isPrimary: true,
            country: LOCAL_CONSTANTS.USA,
            countryName: getCountryNameById(LOCAL_CONSTANTS.USA),
            stateCode: LOCAL_CONSTANTS.DEFAULT_USA_STATE_CODE,
            state: LOCAL_CONSTANTS.DEFAULT_USA_STATE
          }, true);

          setState({
            ...state,
            personContactId: targetContact.personContactId,
            personContactName: `${targetContact.firstName || ''} ${targetContact.lastName || ''}`,
            disablePrimaryCheckbox: true
          });
        } else { // add
          setFormData({
            ...formState,
            country: LOCAL_CONSTANTS.USA,
            countryName: getCountryNameById(LOCAL_CONSTANTS.USA),
            stateCode: LOCAL_CONSTANTS.DEFAULT_USA_STATE_CODE,
            state: LOCAL_CONSTANTS.DEFAULT_USA_STATE
          }, true);

          setState({
            ...state,
            personContactId: targetContact.personContactId,
            personContactName: `${targetContact.firstName || ''} ${targetContact.lastName || ''}`
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, personGeneralState]);

  function submitFormCallback(formState) {
    if (state.disablePrimaryCheckbox === false && formState.isPrimary === true) {
      const targetContact = personGeneralState.objData.personContact.find(x => x.personContactId === state.personContactId);

      if (targetContact) {
        const currentPrimary = targetContact.personContactAddress.find(x => x.isPrimary === true);

        if (currentPrimary) {
          setModalState({
            ...modalState,
            displayPopUp: true,
            currentPrimaryInfo: `${currentPrimary.address?.address1}${currentPrimary.address?.address2 ? ' ' + currentPrimary.address.address2 : ''}, ${currentPrimary.address?.city}, ${currentPrimary.address?.stateCode} ${currentPrimary.address?.postalCode} (${currentPrimary.addressType?.typeName || 'Unknown Address Type'})`
          });
        } else {
          putPersonGeneralContactAddresses(true);
        }
      }
    } else {
      putPersonGeneralContactAddresses(false);
    }
  };

  function setFormStateBasedOnCountry(country) {
    let stateCode = '';
    let state = '';

    switch (country) {
      case LOCAL_CONSTANTS.USA:
        stateCode = LOCAL_CONSTANTS.DEFAULT_USA_STATE_CODE;
        state = LOCAL_CONSTANTS.DEFAULT_USA_STATE;
        break;
      case LOCAL_CONSTANTS.CANADA:
        stateCode = LOCAL_CONSTANTS.DEFAULT_CAN_STATE_CODE;
        state = LOCAL_CONSTANTS.DEFAULT_CAN_STATE;
        break;
      case LOCAL_CONSTANTS.MEXICO:
        stateCode = LOCAL_CONSTANTS.DEFAULT_MEX_STATE_CODE;
        state = LOCAL_CONSTANTS.DEFAULT_MEX_STATE;
        break;
      default:
        stateCode = LOCAL_CONSTANTS.DEFAULT_NON_STATE_CODE;
        state = LOCAL_CONSTANTS.DEFAULT_NON_STATE;
    }

    updateFormState('country', country);
    updateFormState('stateCode', stateCode);
    updateFormState('state', state);
  };

  function createPersonGeneralObject(doPrimaryCheck) {
    const newPersonGeneralObj = JSON.parse(JSON.stringify(personGeneralState.objData));
    const personContactIndex = newPersonGeneralObj.personContact.findIndex(x => x.personContactId === state.personContactId);

    if (personContactIndex >= 0) {
      if (doPrimaryCheck === true) {
        for (let i = 0; i < newPersonGeneralObj.personContact[personContactIndex].personContactAddress.length; i++) {
          newPersonGeneralObj.personContact[personContactIndex].personContactAddress[i].isPrimary = false;
        }
      }

      const newPersonContactAddress = {
        addressTypeId: formState.addressTypeId,
        isPrimary: formState.isPrimary,
        addressType: undefined
      };

      const newAddress = {
        address1: formState.address1.trim(),
        address2: formState.address2.trim(),
        city: formState.city.trim(),
        stateCode: formState.stateCode,
        postalCode: formState.postalCode.trim(),
        countryCode: formState.country
      };

      // Edit
      if (formState.personContactAddressId > 0) {
        const personContactAddressIndex = newPersonGeneralObj.personContact[personContactIndex].personContactAddress.findIndex(x => x.personContactAddressId === formState.personContactAddressId);

        if (personContactAddressIndex >= 0) {
          newPersonGeneralObj.personContact[personContactIndex].personContactAddress[personContactAddressIndex] = {
            ...newPersonGeneralObj.personContact[personContactIndex].personContactAddress[personContactAddressIndex],
            ...newPersonContactAddress,
            address: {
              ...newPersonGeneralObj.personContact[personContactIndex].personContactAddress[personContactAddressIndex].address,
              ...newAddress
            }
          };
        }
      } else {
        newPersonGeneralObj.personContact[personContactIndex].personContactAddress.push({
          personContactId: state.personContactId,
          ...newPersonContactAddress,
          address: {
            ...newAddress
          }
        });
      }
    }

    return newPersonGeneralObj;
  };

  function getInitialState() {
    return {
      tryRedirect: false,
      disablePrimaryCheckbox: false,
      personContactId: Constants.DEFAULT_ID,
      personContactName: ''
    };
  };

  function getInitialFormState() {
    return {
      personContactAddressId: Constants.DEFAULT_ID,
      addressTypeId: Constants.DEFAULT_ID,
      addressTypeName: '',
      isPrimary: false,
      country: '',
      countryName: '',
      address1: '',
      address2: '',
      city: '',
      stateCode: '',
      state: '',
      postalCode: '',
      validatedAddress: null,
      showedAddressSuggestion: false
    };
  };

  function getInitialModalState() {
    return {
      displayPopUp: false,
      currentPrimaryInfo: '',
      modalTitle: 'Change the primary address?'
    };
  };

  return {
    personGeneralState,
    formState,
    errorState,
    state,
    modalState,
    validateState,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onCancelClicked,
    onModalCancelClicked,
    onCountryChange,
    putPersonGeneralContactAddresses,
    handleSubmitProxy,
    setFormData,
    LOCAL_CONSTANTS,
    addressTypeOptions: [
      { id: Constants.DEFAULT_ID, name: "--" },
      { id: 1, name: "Home" },
      { id: 2, name: "School" },
      { id: 3, name: "Work" },
      { id: 4, name: "Other" }]
  };
};

export default useMemberContactAddressDetail;