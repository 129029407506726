import useMeetSoftwareData from '../../../state/meet/meetSoftware/UseMeetSoftwareData';

const useMeetSoftwareDropdown = () => {
  const { meetSoftwareState } = useMeetSoftwareData();

  return {
    meetSoftwareState
  };
};

export default useMeetSoftwareDropdown;