import React from 'react';
import Unauthorized from './Unauthorized';

const SecuredRouteElement = ({ navRoute, editElement, readOnlyElement, simpleElement, unAuthorizedElement = <Unauthorized /> }) => {
  if (navRoute?.permission === 'simple' && simpleElement) {
    return simpleElement;
  }

  if (navRoute?.permission === 'read' && readOnlyElement) {
    return readOnlyElement;
  }

  if (navRoute?.permission === 'edit' && editElement) {
    return editElement;
  }

  return unAuthorizedElement;
};

export default SecuredRouteElement;