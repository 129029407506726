import { API_NAMES, initApi } from "../../utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../utils/HttpHelper";

const getMemberLookupByBirthDateData = (firstName, lastName, birthDate, state, setState) => {
  if (firstName && lastName && birthDate) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const firstNameForUrl = encodeURIComponent(firstName);
    const lastNameForUrl = encodeURIComponent(lastName);
    const birthDateForUrl = birthDate;
    const url = `/Person/lookup?firstName=${firstNameForUrl}&lastName=${lastNameForUrl}&birthDate=${birthDateForUrl}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const getMemberLookupByOrgUnitData = (firstName, lastName, orgUnitId, roleGroupName, isCurrent, state, setState) => {
  if (firstName && lastName && orgUnitId) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const firstNameForUrl = encodeURIComponent(firstName);
    const lastNameForUrl = encodeURIComponent(lastName);
    const orgUnitIdForUrl = encodeURIComponent(orgUnitId);
    const roleGroupNameForUrl = roleGroupName ? encodeURIComponent(roleGroupName) : undefined;
    const isCurrentForUrl = isCurrent === true || isCurrent === false ? isCurrent : undefined;
    const url = `/Person/lookup/orgunit?firstName=${firstNameForUrl}&lastName=${lastNameForUrl}&orgUnitId=${orgUnitIdForUrl}${roleGroupNameForUrl !== undefined ? `&roleGroupName=${roleGroupNameForUrl}` : ''}${isCurrentForUrl !== undefined ? `&isCurrent=${isCurrentForUrl}` : ''}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MemberLookupData = {
  INITIAL_STATE,
  getMemberLookupByBirthDateData,
  getMemberLookupByOrgUnitData
};

export default MemberLookupData;