import React, { Fragment } from 'react';

import useAcknowledgements from '../UseAcknowledgements';

import ExistingMemberInfoReadOnly from './input/ExisitingMemberInfoReadOnly';

import Headings from '../../headings/Headings';
import PopUpModal from '../../dialogs/PopUpModal';
import ActionIntraPageButton from '../../buttons/ActionIntraPageButton';

import { formatDate } from '../../../utils/DateFunctions';
import { evaluateBirthDate, formatPhoneNumber } from '../../../utils/validation';
import Constants from '../../../utils/Constants';

import style from '../Acknowledgements.module.css';
import global from '../../../components/GlobalStyle.module.css';

const ContactInfo = () => {
  const { personAcknowledgementInfoState, onEditContactInfo } = useAcknowledgements();

  return (
    <Fragment>
      {personAcknowledgementInfoState?.arrayData?.map(
        person => {
          const birthDateEval = evaluateBirthDate(formatDate(person?.birthDate));
          return (
            <div key={person?.memberId} className={style.SectionBorder} >
              <div className='row usas-extra-bottom-margin pull-right'>
                <div className='col-xs-12'>
                  <ActionIntraPageButton onClick={(e) => onEditContactInfo(e, person)}>Update Contact Information</ActionIntraPageButton>
                </div>
              </div>
              <div className='row usas-extra-bottom-margin'>
                <div className='col-xs-12'>
                  <Headings.H6 className={global.Underline}>{`${person?.firstName || ''} ${person?.middleName || ''} ${person?.lastName || ''}`}</Headings.H6>
                </div>
                {person?.memberId && (
                  <div className='col-xs-12 col-sm-6 col-md-3'>
                    <ExistingMemberInfoReadOnly
                      label="Member Id"
                      name="memberId"
                      value={person?.memberId || ''} />
                  </div>
                )}
                <div className='col-xs-12 col-sm-6 col-md-3'>
                  <ExistingMemberInfoReadOnly
                    label="DOB"
                    name="dob"
                    value={person?.birthDate ? formatDate(person?.birthDate) : ''} />
                </div>
              </div>
              <span className={birthDateEval.ageInYears < 16 ? global.HideComponent : global.DisplayComponent}>
                <div className='row'>
                  <div className='col-xs-12'>
                    <Headings.H6 className={global.Underline}>Member Contact
                    </Headings.H6>
                  </div>
                  <div className='col-xs-12 col-sm-6 col-md-3'>
                    <ExistingMemberInfoReadOnly
                      label="Email"
                      name="memberEmail"
                      value={person?.contactInfo?.emailAddress} />
                  </div>
                  <div className='col-xs-12 col-sm-6 col-md-3'>
                    <ExistingMemberInfoReadOnly
                      label="Phone"
                      name="memberPhone"
                      value={person?.contactInfo?.phoneNumber ? formatPhoneNumber(person?.contactInfo?.phoneNumber) : ''} />
                  </div>
                  <div className='col-xs-12 col-sm-6 col-md-4'>
                    <ExistingMemberInfoReadOnly
                      label="Address"
                      name="memberAddress"
                      value={`${person?.contactInfo?.address?.address1 || ''} ${person?.contactInfo?.address?.address2 || ''} ${person?.contactInfo?.address?.city || ''} ${person?.contactInfo?.address?.stateCode || ''} ${person?.contactInfo?.address?.postalCode || ''}`} />
                  </div>
                </div>
              </span>
              <div className='row usas-extra-top-margin usas-extra-bottom-margin'>
                <div className='col-xs-12'>
                  <Headings.H6 className={global.Underline}>Emergency Contact
                  </Headings.H6>
                </div>
                <div className='col-xs-12 col-sm-6 col-md-3'>
                  <ExistingMemberInfoReadOnly
                    label="Full Name"
                    name="emergencyContactFullName"
                    value={`${person?.primaryContactInfo?.firstName || ''} ${person?.primaryContactInfo?.lastName || ''}`} />
                </div>
                <div className='col-xs-12 col-sm-6 col-md-3'>
                  <ExistingMemberInfoReadOnly
                    label="Email"
                    name="emergencyContactEmail"
                    value={person?.primaryContactInfo?.emailAddress} />
                </div>
                <div className='col-xs-12 col-sm-6 col-md-3'>
                  <ExistingMemberInfoReadOnly
                    label="Phone"
                    name="emergencyContactPhone"
                    value={person?.primaryContactInfo?.phoneNumber ? formatPhoneNumber(person?.primaryContactInfo?.phoneNumber) : ''} />
                </div>
                <div className='col-xs-12 col-sm-6 col-md-3'>
                  <ExistingMemberInfoReadOnly
                    label="Address"
                    name="emergencycontactAddress"
                    value={`${person?.primaryContactInfo?.address?.address1 || ''} ${person?.primaryContactInfo?.address?.address2 || ''} ${person?.primaryContactInfo?.address?.city || ''} ${person?.primaryContactInfo?.address?.stateCode || ''} ${person?.primaryContactInfo?.address?.postalCode || ''}`} />
                </div>
              </div>
            </div>
          )
        }
      )
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personAcknowledgementInfoState.isArrayLoading} />
    </Fragment >
  );
};

export default ContactInfo;