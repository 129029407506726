import React, { createContext, useState } from 'react';

import TimeData from './TimeData';

export const TimesOrganizationStateContext = createContext();

const TimesOrganizationContextProvider = ({ children }) => {
  const stateHook = useState(TimeData.INITIAL_STATE);

  return (
    <TimesOrganizationStateContext.Provider value={stateHook}>
      {children}
    </TimesOrganizationStateContext.Provider>
  );
};

export default TimesOrganizationContextProvider;