import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from '../../wrappers/ReactRouterDom';

import usePageTemplate from './UsePageTemplate';

import SecurityContextProvider from '../../state/security/SecurityContextProvider';
import CartItemContextProvider from '../../state/cartItem/CartItemContextProvider';
import EnvironmentVariableContextProvider from '../../state/environmentVariable/EnvironmentVariableContextProvider';
import DateTimeContextProvider from '../../state/datetime/DateTimeContextProvider';
import GlobalRoutesContextProvider from '../../state/security/GlobalRoutesContextProvider';
import PersonAcknowledgementContextProvider from '../../state/personAcknowledgement/PersonAcknowledgementContextProvider';
import PersonAcknowledgementInfoContextProvider from '../../state/personAcknowledgementInfo/PersonAcknowledgementInfoContextProvider';
import PersonAcknowledgeInfoPageViewContextProvider from '../../state/personAcknowledgementInfo/PersonAcknowledgementInfoPageViewContextProvider';
import PersonAcknowledgementAddAFamilyMemberContextProvider from '../../state/personAcknowledgementAddAFamilyMember/PersonAcknowledgementAddAFamilyMemberContextProvider';
import PaymentsContextProvider from '../../state/payments/PaymentsContextProvider';
import BasePersonContextProvider from '../../state/personBase/BasePersonContextProvider';
import BasePersonFiltersContextProvider from '../../state/personBase/BasePersonFiltersContextProvider';
import PersonGeneralContextProvider from '../../state/personGeneral/PersonGeneralContextProvider';
import ContactRelationshipTypesContextProvider from '../../state/contactRelationshipType/ContactRelationshipTypesContextProvider';

import ToLandingPage from './ToLandingPage';
import Header from './header/Header';
import Footer from './footer/Footer';
import PersonCreation from '../personCreation/PersonCreation';
import Acknowledgements from '../acknowledgements/Acknowledgements';
import Headings from '../headings/Headings';
import LoadingModal from '../dialogs/LoadingModal';

import style from './PageTemplate.module.css';

const InnerPageTemplate = ({ AppStateContextProviders, AppRoutes }) => {
  const {
    memberId,
    isLoading,
    isLoggedIn,
    showSessionEnding,
    isUnregisteredUser,
    areAcknowledgementsComplete,
    hasBeenClicked,
    setShowSessionEnding,
  } = usePageTemplate();

  if (isLoading === true) {
    return (
      <Fragment>
        <Header showSessionEnding={showSessionEnding} setShowSessionEnding={setShowSessionEnding} />
        <div onClick={hasBeenClicked}>
          <div className={['content-container', style.MainContent].join(' ')}>
            <LoadingModal />
          </div>
        </div>
        <footer onClick={hasBeenClicked}>
          <Footer />
        </footer>
      </Fragment>
    );
  }

  if (isUnregisteredUser === true) {
    return (
      <div className={style.Container}>
        <Header showSessionEnding={showSessionEnding} setShowSessionEnding={setShowSessionEnding} />
        <div onClick={hasBeenClicked}>
          <div className={['content-container', style.MainContent].join(' ')}>
            <PersonCreation />
          </div>
        </div>
        <footer className={style.UnregisteredFooter} onClick={hasBeenClicked}>
          <Footer />
        </footer>
      </div>
    );
  }

  if (isLoggedIn === true && areAcknowledgementsComplete !== true) {
    return (
      <PersonAcknowledgementAddAFamilyMemberContextProvider>
        <PersonAcknowledgementInfoContextProvider>
          <PersonAcknowledgeInfoPageViewContextProvider>
            <BasePersonContextProvider>
              <BasePersonFiltersContextProvider>
                <PersonGeneralContextProvider>
                  <ContactRelationshipTypesContextProvider>
                    <div className={style.Container}>
                      <Header showSessionEnding={showSessionEnding} setShowSessionEnding={setShowSessionEnding} />
                      <div onClick={hasBeenClicked}>
                        <div className={['content-container', style.MainContent].join(' ')}>
                          <div className="row">
                            <div className="col-xs-12">
                              <Headings.H3>Legal Acknowledgements</Headings.H3>
                            </div>
                            <div className="col-xs-12">
                              <Acknowledgements memberId={memberId} addANewFamilyMemberView={false} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <footer className={style.UnregisteredFooter} onClick={hasBeenClicked}>
                        <Footer />
                      </footer>
                    </div>
                  </ContactRelationshipTypesContextProvider>
                </PersonGeneralContextProvider>
              </BasePersonFiltersContextProvider>
            </BasePersonContextProvider>
          </PersonAcknowledgeInfoPageViewContextProvider>
        </PersonAcknowledgementInfoContextProvider>
      </PersonAcknowledgementAddAFamilyMemberContextProvider>
    );
  }

  return (
    <Fragment>
      <Header showSessionEnding={showSessionEnding} setShowSessionEnding={setShowSessionEnding} />
      <div onClick={hasBeenClicked}>
        <div className={['content-container', style.MainContent].join(' ')}>
          <AppStateContextProviders>
            <AppRoutes />
          </AppStateContextProviders>
        </div>
      </div>
      <footer onClick={hasBeenClicked}>
        <Footer />
      </footer>
    </Fragment>
  );
};

const PageTemplateRoutes = ({ AppStateContextProviders, AppRoutes }) => {
  return (
    <Routes>
      <Route exact path="/" element={<ToLandingPage />} />
      <Route path="/*" element={(<InnerPageTemplate AppStateContextProviders={AppStateContextProviders} AppRoutes={AppRoutes} />)} />
    </Routes>
  );
};

const PageTemplate = ({ AppStateContextProviders, AppRoutes }) => {
  return (
    <BrowserRouter>
      <GlobalRoutesContextProvider>
        <EnvironmentVariableContextProvider>
          <DateTimeContextProvider>
            <SecurityContextProvider>
              <PersonAcknowledgementContextProvider>
                <CartItemContextProvider>
                  <PaymentsContextProvider>
                    <PageTemplateRoutes AppStateContextProviders={AppStateContextProviders} AppRoutes={AppRoutes} />
                  </PaymentsContextProvider>
                </CartItemContextProvider>
              </PersonAcknowledgementContextProvider>
            </SecurityContextProvider>
          </DateTimeContextProvider>
        </EnvironmentVariableContextProvider>
      </GlobalRoutesContextProvider>
    </BrowserRouter>
  );
};

export default PageTemplate;