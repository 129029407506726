import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import searchMeets from './SearchMeetsData';
import getMeetData from './GetMeetData';
import postMeetData from './PostMeetData';
import putMeetData from './PutMeetData';
import deleteMeetData from './DeleteMeetData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetData = {
  INITIAL_STATE, searchMeets, getMeetData, postMeetData, putMeetData, deleteMeetData
};

export default MeetData;