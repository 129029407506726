import React, { Fragment } from 'react';

const Unauthorized = () => (
  <Fragment>
    <div className="row usas-extra-bottom-margin">
      <div className="col-xs-12">
        Unauthorized
      </div>
    </div>
  </Fragment>
);

export default Unauthorized;