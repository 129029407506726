import React from 'react';

import useCheckbox from './UseCheckbox';

import global from '../../../common/components/GlobalStyle.module.css'
import style from './Checkbox.module.css';

const Checkbox = ({ error, className, label, message, name, checked, onChange, disabled }) => {
  const {fullClassAttribute, isDisabled} = useCheckbox(className, error, style, global, disabled);

  return (
      <div>
        <label htmlFor={name}>
          <input
            className={fullClassAttribute}
            type="checkbox"
            checked={checked}
            id={label}
            name={name}
            onChange={(e) => { onChange(e.target.checked); }}
            disabled={isDisabled === true}
          />
          <span> {label}</span>
        </label>
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    );
};

export default Checkbox;