import React from 'react';

import useInput from './UseInput';

import PhoneInput from 'react-phone-input-2';
import ReadOnly from '../readOnly/ReadOnly';

import 'react-phone-input-2/lib/style.css';
import global from '../GlobalStyle.module.css';

const InternationalPhoneInput = ({ className, value, onChange, label, name, error, message }) => {
  const {isReadWrite} = useInput(className, global, error);
  
  if (isReadWrite === true) {
    return (
      <div className={global.ComponentFlex}>
        <label className={global.UsasLabel} htmlFor={name}>{label}</label>
        <div>
          <PhoneInput
            countryCodeEditable={false}
            containerClass={error ? global.ErrorInternationalPhone : global.BaseInputBoxInternationalPhone}
            inputStyle={{ width: "100%" }}
            error={error}
            label={label}
            name={name}
            country={'us'}
            onlyCountries={['us', 'ca', 'mx']}
            value={value}
            onChange={value => onChange(value)} />
        </div>
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    );
  } else {
    return (
      <ReadOnly
        label={label}
        name={name}
        value={value} />
    );
  }
};

export default InternationalPhoneInput;