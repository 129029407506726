import { useContext, useState } from "react";
import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import PaymentsShoppingCartData from "./PaymentsShoppingCartData";

import { PaymentsShoppingCartStateContext } from "./PaymentsShoppingCartContextProvider";

const INITIAL_CART_ITEMS_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_CHECKOUT_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_TRANSACTION_RESULT_STATE = {
  ...BASIC_INITIAL_STATE
};

const usePaymentsShoppingCartData = () => {
  const [paymentsShoppingCartState, setPaymentsShoppingCartState] = useContext(PaymentsShoppingCartStateContext);
  const [cartItemsState, setCartItemsState] = useState(INITIAL_CART_ITEMS_STATE);
  const [checkoutState, setCheckoutState] = useState(INITIAL_CHECKOUT_STATE);
  const [paymentCompleteState, setPaymentCompleteState] = useState(INITIAL_TRANSACTION_RESULT_STATE);

  const postCheckout = (cartItemDetailIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn, payByCheck, personId = undefined) => {
    return PaymentsShoppingCartData.postCheckoutData(cartItemDetailIds, foundationDonation, coverTechnologyFee, swimmingWorldOptIn, payByCheck, personId, checkoutState, setCheckoutState);
  };

  const postPaymentComplete = (swimmingWorldOptInObj) => {   
    return PaymentsShoppingCartData.postPaymentCompleteData(swimmingWorldOptInObj, paymentCompleteState, setPaymentCompleteState);
  };

  const getCart = () => {
    const orgUnitId = paymentsShoppingCartState.orgUnitId || null;
    const itemSubsystemId = paymentsShoppingCartState.itemSubsystemId || null;
    const itemContract = paymentsShoppingCartState.itemContract || null;
    const referenceId = paymentsShoppingCartState.referenceId || null;

    return PaymentsShoppingCartData.getCartData(orgUnitId, itemSubsystemId, itemContract, referenceId, cartItemsState, setCartItemsState);
  };

  const deleteCartItem = (cartItemDetailId) => {
    const orgUnitId = paymentsShoppingCartState.orgUnitId || null;
    const itemSubsystemId = paymentsShoppingCartState.itemSubsystemId || null;
    const itemContract = paymentsShoppingCartState.itemContract || null;
    const referenceId = paymentsShoppingCartState.referenceId || null;

    return PaymentsShoppingCartData.deleteCartItemData(orgUnitId, itemSubsystemId, itemContract, referenceId, cartItemDetailId, cartItemsState, setCartItemsState);
  };

  const initiatePaymentsShoppingCart = (orgUnitId, itemSubsystemId, itemContract, referenceId, currentStep, pageData = null, pageDataName = null) => {
    if (pageData !== null && pageDataName !== null) {
      setPaymentsShoppingCartState({
        ...PaymentsShoppingCartData.INITIAL_STATE,
        [pageDataName]: pageData,
        currentStep,
        orgUnitId,
        itemSubsystemId,
        itemContract,
        referenceId
      });
    } else {
      setPaymentsShoppingCartState({
        ...PaymentsShoppingCartData.INITIAL_STATE,
        currentStep,
        orgUnitId,
        itemSubsystemId,
        itemContract,
        referenceId
      });
    }
  };

  const updatePaymentsShoppingCartStep = (newStep, pageData = null, pageDataName = null) => {
    if (pageData !== null && pageDataName !== null) {
      setPaymentsShoppingCartState({
        ...paymentsShoppingCartState,
        [pageDataName]: pageData,
        currentStep: newStep
      });
    } else {
      setPaymentsShoppingCartState({
        ...paymentsShoppingCartState,
        currentStep: newStep
      });
    }
  };

  const updatePaymentsShoppingCartPageData = (pageData, pageDataName) => {
    setPaymentsShoppingCartState({
      ...paymentsShoppingCartState,
      [pageDataName]: pageData
    });
  };

  const resetPaymentsShoppingCartState = () => {
    setPaymentsShoppingCartState(PaymentsShoppingCartData.INITIAL_STATE);
  };

  const resetCartItemsState = () => {
    setCartItemsState(INITIAL_CART_ITEMS_STATE);
  };

  const resetCheckoutState = () => {
    setCheckoutState(INITIAL_CHECKOUT_STATE);
  };

  return {
    paymentsShoppingCartState,
    cartItemsState,
    checkoutState,
    paymentCompleteState,

    postCheckout,
    postPaymentComplete,
    getCart,
    deleteCartItem,

    initiatePaymentsShoppingCart,
    updatePaymentsShoppingCartStep,
    updatePaymentsShoppingCartPageData,
    resetPaymentsShoppingCartState,
    resetCartItemsState,
    resetCheckoutState,
  };
};

export default usePaymentsShoppingCartData;