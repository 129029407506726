import React from 'react';

import ActionButton from './ActionButton';

const ActionIntraPageButton = ({ children, type, onClick, className }) => (
  <ActionButton 
  type={type} 
  isPrimary={null}
  onClick={onClick} 
  className={className}>
  {children}
  </ActionButton>
);

export default ActionIntraPageButton;