import React, { createContext, useState } from 'react';

import DateTimeData from './DateTimeData';

export const DateTimeStateContext = createContext();

const DateTimeContextProvider = ({ children }) => {
  const stateHook = useState(DateTimeData.INITIAL_STATE);

  return (
    <DateTimeStateContext.Provider value={stateHook}>
      {children}
    </DateTimeStateContext.Provider>
  );
};

export default DateTimeContextProvider;