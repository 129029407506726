import { initCustomApi } from "../AccountApiHelper";

const postAccountOneTimePasswordData = (href, oneTimePassword, state, setState) => {
  if (href && oneTimePassword) {
    const api = initCustomApi(state, setState);
    const fullUrl = `${import.meta.env.VITE_SECURITYAPI_URL}/Account/OneTimePasswordCheck`;
    const headers = {
      "Content-Type": "application/json"
    };
    const payload = {
      href,
      otp: oneTimePassword
    };
    
    return api?.executeApi ? api.executeApi(fullUrl, headers, 'POST', payload, 'include') : null;
  }
};

const AccountOneTimePasswordData = {
  postAccountOneTimePasswordData
};

export default AccountOneTimePasswordData;