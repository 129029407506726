import {useMemo} from 'react';
import {matchSorter} from 'match-sorter';
import useThrottle from './UseThrottle';

// items: the collection of values to search for:
//      [{name: 'foo bar', id: 1}, {name: 'John Doe', id: 2}]
// valueToMatch: the value to search the collection for: 
//      'doe' would find id: 2 above
const UseMatchSorter = (items, valueToMatch) => {
  const throttledTerm = useThrottle(valueToMatch, 100);
  return useMemo(
    () =>
    !valueToMatch || valueToMatch.trim() === ""
        ? null
        : matchSorter(items, valueToMatch, {keys: [(item) => `${item.name}`]}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [throttledTerm]
  );
};

export default UseMatchSorter;