export const setCookie = (cookieName, cookieValue, path = '/', domain = import.meta.env.VITE_COOKIE_DOMAIN) => {
  try {
    const cookieValueString = JSON.stringify(cookieValue);
    document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(cookieValueString)}; path=${path}; domain=${domain}`;
    // eslint-disable-next-line no-empty
  } catch (e) { }
};

export const getCookie = (cookieName) => {
  try {
    const key = `${cookieName}=`;
    let decodedCookies = decodeURIComponent(document.cookie);
    let cookieArray = decodedCookies.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      let targetCookie = cookieArray[i];

      while (targetCookie.charAt(0) == ' ') {
        targetCookie = targetCookie.substring(1);
      }

      if (targetCookie.indexOf(key) == 0) {
        return JSON.parse(targetCookie.substring(key.length, targetCookie.length));
      }
    }

    return '';
  } catch (e) {
    return '';
  }
};

export const deleteCookie = (cookieName, path = '/', domain = import.meta.env.VITE_COOKIE_DOMAIN) => {
  try {
    const expires = new Date('01/01/1000');
    document.cookie = `${encodeURIComponent(cookieName)}=; expires=${expires}; path=${path}; domain=${domain}`;
    // eslint-disable-next-line no-empty
  } catch (e) {}
};