import { useState } from 'react';

import Constants from '../../utils/Constants';

const useExpandedRow = () => {
  const [expandedId, setExpandedId] = useState(Constants.DEFAULT_ID);

  const onClick = (e, id) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (expandedId === id) {
      if (expandedId !== Constants.DEFAULT_ID) {
        setExpandedId(Constants.DEFAULT_ID);
      }
    } else {
      setExpandedId(id);
    }
  };

  return {
    onClick,
    expandedId,
    setExpandedId
  }
};

export default useExpandedRow;