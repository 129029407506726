import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import getPersonOrgRolesData from './GetPersonOrgRolesData';
import getPersonOrgRoleSummaryData from './GetPersonOrgRoleSummaryData';
import getPersonOrgRoleFilteredData from './GetPersonOrgRoleFilteredData';
import getPersonOrgRoleByRoleGroupData from './GetPersonOrgRoleByRoleGroup';
import putPersonOrgRoleData from './PutPersonOrgRoleData';
import postPersonOrgRoleData from './PostPersonOrgRoleData';
import deletePersonOrgRoleData from './DeletePersonOrgRoleData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PersonOrgRoleData = {
  INITIAL_STATE, getPersonOrgRolesData, getPersonOrgRoleSummaryData, getPersonOrgRoleFilteredData,
  getPersonOrgRoleByRoleGroupData, putPersonOrgRoleData, postPersonOrgRoleData, deletePersonOrgRoleData
};

export default PersonOrgRoleData;