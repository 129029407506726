import React from 'react';

import useInput from './UseInput';

import ReadOnly from '../readOnly/ReadOnly';

import global from '../GlobalStyle.module.css';

//NOTE:  When this component is used as a date picker, the value must be in the format yyyy-mm-dd, or it will not set initially
//use the utility function formatDateYMD in DateFunctions
const Input = ({ error, className, type, label, message, name, min, max, step, preText, postText, value, onChange, onBlur, onFocus, autoFocus = false }) => {
  const { fullClassAttribute, isReadWrite, filterNumberInput } = useInput(className, global, error);

  if (isReadWrite === true) {
    return (
      <div className={global.ComponentFlex}>
        {label && <label className={global.UsasLabel} htmlFor={name}>{label}</label>}
        <div>
          {preText}
          <input
            className={fullClassAttribute}
            type={type ? type : 'text'}
            onKeyDown={type === 'number' ? (evt) => ["e", "E", "+"].includes(evt.key) && evt.preventDefault() : undefined}
            onInput={type === 'number' ? (evt) => filterNumberInput(name, evt) : undefined}
            min={min}
            max={max}
            step={step}
            value={value}
            id={name}
            name={name}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(e) => { onChange(e.target.value); }}
            autoFocus={autoFocus}
          />
          {postText}
        </div>
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    );
  } else {
    return (
      <ReadOnly
        label={label}
        name={name}
        value={value} />
    );
  }
};

export default Input;