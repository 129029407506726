import { useEffect, useState } from 'react';
import { useSearchParams } from '../../wrappers/ReactRouterDom';
import useEnvironmentVariableData from '../../state/environmentVariable/UseEnvironmentVariableData';

const INITIAL_VIEW_STATE = {
  hasPriorMembership: '',
  showLinkById: false,
  showLinkByPersonalInfo: false,
  showCreateAccountDialog: false,
  createAccountDialogText: ''
};

const usePersonCreation = () => {
  const [state, setState] = useState({ ...INITIAL_VIEW_STATE });
  const [searchParams,] = useSearchParams();
  const { environmentVariableState } = useEnvironmentVariableData();

  const onHasPriorMembershipChanged = (value) => {
    setState({
      ...state,
      hasPriorMembership: value,
      showLinkById: value,
      showLinkByPersonalInfo: !value,
      showCreateAccountDialog: !value
    });
  };

  const onUseAdditionalInfoClicked = (e) => {
    e?.preventDefault();

    setState({
      ...state,
      showLinkById: false,
      showLinkByPersonalInfo: true
    });
  };

  const goToLinkById = (e) => {
    e?.preventDefault();

    setState({
      ...state,
      hasPriorMembership: true,
      showLinkById: true,
      showLinkByPersonalInfo: false
    });
  };

  const onComplete = () => {
    const memberId = searchParams.get('memberId');
    if (memberId) {
      const url = window.location.origin + window.location.pathname;
      window.location.search = '';
      window.location.href = url;
    }
    else {
      window.location.reload();
    }
  };

  useEffect(() => {
    const memberId = searchParams.get('memberId');
    if (memberId) {
      goToLinkById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  useEffect(() => {
    if (environmentVariableState.isLoaded === true) {
      const createAccountDialog = environmentVariableState.data.filter((ev => ev.variableName === 'CREATE_ACCOUNT_DIALOG'));
      if (createAccountDialog.length === 1) {
        setState({
          ...state,
          createAccountDialogText: createAccountDialog[0].variableValue
        });
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState]);

  const okClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setState({
      ...state,
      showCreateAccountDialog: false
    });
  }

  return {
    ...state,
    onHasPriorMembershipChanged,
    onUseAdditionalInfoClicked,
    goToLinkById,
    onComplete,
    okClicked
  };
};

export default usePersonCreation;