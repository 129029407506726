import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const getEmbeddedReport = async (reportInfoId, reportParameters, state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/ReportInfo/ExecuteReport/${reportInfoId}`;
  return api?.executeObject ? api.executeObject(url, 'POST', reportParameters) : null;
};

const getEmbeddedReportListForRoute = async (routeName, state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/ReportInfo/ListForRoute/${routeName}`;
  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const getEmbeddedReportListForContext = async (routeName, contextId, state, setState) => {
  const contextIdForUrl = contextId ? encodeURIComponent(contextId) : undefined;
  if (contextIdForUrl) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/ReportInfo/ListForContext/${contextId}/${routeName}`;
    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  } else {
    throw new Error('contextId is required to fetch an embedded report.');
  }
};

const EmbeddedReportData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  getEmbeddedReport,
  getEmbeddedReportListForRoute,
  getEmbeddedReportListForContext
};

export default EmbeddedReportData;