import style from './LinkButton.module.css';

const useLinkButton = (className, action) => {
  const fullClassAttribute = [style.LinkButton, (className || '')].join(' ');
  
  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (action instanceof Function) {
      action();
    }
  };

  return {
    fullClassAttribute,
    onClick
  };
};

export default useLinkButton;