import { Fragment } from "react";

import SecondaryButton from "../../../buttons/SecondaryButton";

const EmptyCart = ({ onCancelClicked }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <p>There are no items to check out in your shopping cart.</p>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        {onCancelClicked && (
          <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
        )}
      </div>
    </div>
  </Fragment>
);

export default EmptyCart;