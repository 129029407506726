import { useNavigate } from '../../wrappers/ReactRouterDom';

const useTab = (navLink, onTabClicked) => {
  const navigate = useNavigate();

  const openExternalLink = (navLink) => {
    const win = window.open(navLink.linkUrl, '_blank');
    win.focus();
  };

  const onClick = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (navLink.linkUrl) {
      if (navLink.isInternal === false) {
        openExternalLink(navLink);
      } else {
        navigate(navLink.linkUrl);
      }
    }

    if (onTabClicked) {
      onTabClicked(navLink.taxonomyId);
    }
  };

  return { onClick };
};

export default useTab;