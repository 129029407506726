import { evaluateBirthDate, isValidBirthDate, isValidDate, isValidName } from '../../../common/utils/validation';
import { BLANK_DATE_STRING } from '../../../common/utils/Constants';

export const localValidate = (formState) => {
  let errors = {};

  //First Name
  if (formState.firstName?.trim() === '') {
    errors.firstName = 'First Name is required'
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'First Name must be a valid name'
  }

  //Last Name
  if (formState.lastName?.trim() === '') {
    errors.lastName = 'Last Name is required'
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name must be a valid name'
  }

  const birthDateObj = evaluateBirthDate(formState.birthDate);

  //Birth Date
  if (formState.birthDate?.trim() === BLANK_DATE_STRING) {
    errors.birthDate = 'Birth Date is required'
  } else if (!isValidDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date must be a valid date';
  } else if (!isValidBirthDate(formState.birthDate)) {
    errors.birthDate = 'Birth Date cannot be in the future'
  } else if (birthDateObj?.ageInYears < 16) {
    errors.birthDate = 'Members under 16 cannot create their own login account'
  }

  return errors;
};

const AboutMemberValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default AboutMemberValidation;