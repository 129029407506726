const useCombobox = (items, valueToMatch) => {
  const getId = (value) => {
    if (Array.isArray(items) === false) return -1;
    const item = items.find(x => x.name === value);
    return item && item.id ? item.id : -1;
  }
  
  const isReadWrite = true;

  return {value: getId(valueToMatch), isReadWrite};
};

export default useCombobox;