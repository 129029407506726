import useSessionTimer from "./UseSessionTimer";

import style from '../../SessionEnding.module.css';

const SessionDebugTimer = ({ setShowSessionEnding }) => {
  const { expiringIn } = useSessionTimer();

  return (
    <table cellPadding="4" className={style.SessionDebugTimer} onClick={() => setShowSessionEnding(true, false)}>
      <tbody>
        <tr>
          <td align="center" className={style.Number}>
            {expiringIn.substring(3, 5)}
          </td>
          <td align="center" className={style.Colon}>
            :
          </td>
          <td align="center" className={style.Number}>
            {expiringIn.substring(6, 8)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SessionDebugTimer;