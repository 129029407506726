const formatExpirationDatetimeForDisplay = (expirationDatetime) => {
  try {
    if (Number.isInteger(Date.parse(expirationDatetime))) {
      const formattedDatetime = new Date(Date.parse(expirationDatetime));
      const now = new Date();
      let ms = formattedDatetime.getTime() - now.getTime();
      const hours = Math.floor(ms / (60 * 60 * 1000));
      ms -= hours * (60 * 60 * 1000);
      const minutes = Math.floor(ms / (60 * 1000));
      ms -= minutes * (60 * 1000);
      const seconds = Math.floor(ms / (1000));
      
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds < 0 ? '0' : seconds}`;
    }

    return '00:00:00';
  } catch (e) {
    return '00:00:00';
  }
}

export {
  formatExpirationDatetimeForDisplay
};