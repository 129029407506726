import useSecurityData from '../../../state/security/UseSecurityData';
import { SESSION_DEBUG_TIMER_STORAGE_NAME } from '../../../utils/Constants';
import { getCookie } from '../../../utils/CookieUtils';

const useHeader = () => {
  const { userInfo, currentNavItem, logout, closeSecondaryNav } = useSecurityData();

  const hasBeenClicked = () => {
    if (currentNavItem?.displayName) {
      closeSecondaryNav();
    }
  };

  const onLogoutClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    logout();
  };

  return {
    isUnregisteredUser: userInfo?.isUnregistered === true,
    isSessionDebugTimerEnabled: getCookie(SESSION_DEBUG_TIMER_STORAGE_NAME)?.enableDebugTimer === true,
    onLogoutClicked,
    hasBeenClicked
  };
};

export default useHeader;