import React, { createContext, useState } from 'react';

import LscData from './LscData';

export const LscStateContext = createContext();

const LscContextProvider = ({ children }) => {
  const stateHook = useState(LscData.INITIAL_STATE);

  return (
    <LscStateContext.Provider value={stateHook}>
      {children}
    </LscStateContext.Provider>
  );
};

export default LscContextProvider;