import React, { createContext, useState } from 'react';

import TimeData from './TimeData';

export const TimeStateContext = createContext();

const TimeContextProvider = ({ children }) => {
  const stateHook = useState(TimeData.INITIAL_STATE);

  return (
    <TimeStateContext.Provider value={stateHook}>
      {children}
    </TimeStateContext.Provider>
  );
};

export default TimeContextProvider;