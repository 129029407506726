import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../../../meet/NavLinks';
import HostNavLinks from '../../HostNavLinks';
import MyMeetsNavLinks from '../../../myMeets/MyMeetsNavLinks';
import HostRegistrationConstants from '../HostRegistrationConstants';

import validate from './OmeSubmitValidation';

import useOmeMeetData from '../../../../state/omeMeet/UseOmeMeetData';
import useOmeMeetDateValidationData from '../../../../state/omeMeetDateValidation/UseOmeMeetDateValidationData';
import useMyMeetsFiltersData from '../../../../state/myMeetsFilters/UseMyMeetsFiltersData';

import useMeetData from '../../../../../common/state/meet/UseMeetData';
import useEnvironmentVariableData from '../../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSearchMeetData from '../../../../../common/state/searchMeet/UseSearchMeetData';
import useBasePersonData from '../../../../../common/state/personBase/UseBasePersonData';
import usePersonGeneralData from '../../../../../common/state/personGeneral/UsePersonGeneralData';
import useLeftNavModalData from '../../../../../common/state/leftNavModal/UseLeftNavModalData';
import useSelectOrgUnitData from '../../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useCountryCombobox from '../../../../../common/components/comboboxes/countryCombobox/UseCountryCombobox';
import useStatesCombobox from '../../../../../common/components/comboboxes/statesCombobox/UseStatesCombobox';

import useForm from '../../../../../common/utils/UseForm';
import Constants from '../../../../../common/utils/Constants';
import { formatPhoneNumber } from '../../../../../common/utils/validation';

const INITIAL_VIEW_STATE = {
  submitButton: '',
  tryRedirect: false
};

const INITIAL_FORM_STATE = {
  meetContactPersonId: '',
  meetContactName: '',
  meetContactEmail: '',
  meetContactPhone: '',
  entryContactMemberId: '',
  entryContactPersonId: '',
  entryContactName: '',
  entryContactEmail: '',
  entryContactPhone: '',
  paymentContactName: '',
  paymentRecipient: '',
  emailAddress: '',
  country: '',
  countryName: '',
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  state: '',
  postalCode: '',
  validatedAddress: null,
  showedAddressSuggestion: false
};

const LOCAL_CONSTANTS = {
  USA: 'USA',
  CANADA: 'CA',
  MEXICO: 'MX',
  DEFAULT_USA_STATE_CODE: 'AL',
  DEFAULT_USA_STATE: 'Alabama (AL)',
  DEFAULT_CAN_STATE_CODE: 'AB',
  DEFAULT_CAN_STATE: 'Alberta (AB)',
  DEFAULT_MEX_STATE_CODE: 'AG',
  DEFAULT_MEX_STATE: 'Aguascalientes (AG)',
  DEFAULT_NON_STATE_CODE: '  ',
  DEFAULT_NON_STATE: ''
};

const INITIAL_VALIDATE_STATE = {
  isValidating: false,
  validatedAddress: null
};

const useOmeSubmit = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_VIEW_STATE);
  const [validateState, setValidateState] = useState(INITIAL_VALIDATE_STATE);
  const { omeMeetState, putOmeMeet } = useOmeMeetData();
  const { omeMeetDateValidationState } = useOmeMeetDateValidationData();
  const { meetState, putMeet, getMeet } = useMeetData();
  const { searchMeetState, setSearchMeetState } = useSearchMeetData();
  const { basePersonState, getBasePerson } = useBasePersonData();
  const { personGeneralState, getPersonGeneral } = usePersonGeneralData();
  const { environmentVariableState, MEET_CONTACT_STAFF_TYPE_ID, ENTRY_CONTACT_STAFF_TYPE_ID } = useEnvironmentVariableData();
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { myMeetsFiltersState } = useMyMeetsFiltersData();
  const { getCountryNameById } = useCountryCombobox();
  const { getStateNameById } = useStatesCombobox();
  const { formState, errorState, handleSubmit, isSubmitting, isDirty, setErrors, onFormValueChanged, onValueTextPairChanged, updateFormState, setFormData, setIsDirty
  } = useForm(INITIAL_FORM_STATE, submitFormCallback, validate);

  const handleSubmitProxy = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setValidateState({
      isValidating: true
    });
    await handleSubmit(e);

    setValidateState({
      isValidating: false,
      validatedAddress: formState.validatedAddress
    });

    updateFormState('showedAddressSuggestion', true);
  };

  const tryValidateBeforeRedirect = async (submitButton) => {
    const errors = await validate(formState) || {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      setState({ ...state, tryRedirect: true, submitButton: submitButton });
    }
  };

  const onNextButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && omeMeetState.objData.status === Constants.WORKFLOW_STATUS_CREATED) {
      tryValidateBeforeRedirect(HostRegistrationConstants.CONTINUE);
    }
    else {
      setIsDirty(true);
      handleSubmitProxy();
      setState({ ...state, submitButton: HostRegistrationConstants.CONTINUE });
    }
  };

  const onSaveAndExitButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && omeMeetState.objData.status === Constants.WORKFLOW_STATUS_CREATED) {
      tryValidateBeforeRedirect(HostRegistrationConstants.SAVE_AND_EXIT);
    }
    else {
      setIsDirty(true);
      handleSubmitProxy();
      setState({ ...state, submitButton: HostRegistrationConstants.SAVE_AND_EXIT });
    }
  };

  const onBackButtonClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    if (isDirty === false && omeMeetState.objData.status === Constants.WORKFLOW_STATUS_CREATED) {
      tryValidateBeforeRedirect(HostRegistrationConstants.BACK);
    }
    else {
      setIsDirty(true);
      handleSubmitProxy();
      setState({ ...state, submitButton: HostRegistrationConstants.BACK });
    }
  };

  const onCountryChange = (newValue, valuePropertyName, newValueLabel, labelPropertyName, e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    onValueTextPairChanged(newValue, valuePropertyName, newValueLabel, labelPropertyName);

    if (newValue !== Constants.DEFAULT_ID) {
      setFormStateBasedOnCountry(newValue);
    }
  };

  function setFormStateBasedOnCountry(country) {
    let stateCode = '';
    let state = '';

    switch (country) {
      case LOCAL_CONSTANTS.USA:
        stateCode = LOCAL_CONSTANTS.DEFAULT_USA_STATE_CODE;
        state = LOCAL_CONSTANTS.DEFAULT_USA_STATE;
        break;
      case LOCAL_CONSTANTS.CANADA:
        stateCode = LOCAL_CONSTANTS.DEFAULT_CAN_STATE_CODE;
        state = LOCAL_CONSTANTS.DEFAULT_CAN_STATE;
        break;
      case LOCAL_CONSTANTS.MEXICO:
        stateCode = LOCAL_CONSTANTS.DEFAULT_MEX_STATE_CODE;
        state = LOCAL_CONSTANTS.DEFAULT_MEX_STATE;
        break;
      default:
        stateCode = LOCAL_CONSTANTS.DEFAULT_NON_STATE_CODE;
        state = LOCAL_CONSTANTS.DEFAULT_NON_STATE;
    }

    updateFormState('country', country);
    updateFormState('stateCode', stateCode);
    updateFormState('state', state);
  };


  function submitFormCallback(formState) {
    const updatedOmeMeet = JSON.parse(JSON.stringify(omeMeetState.objData));

    updatedOmeMeet.status = Constants.WORKFLOW_STATUS_CREATED;

    updatedOmeMeet.omeMeetPayee = updatedOmeMeet.omeMeetPayee?.length === 0 ?
      [{
        omeMeetId: updatedOmeMeet.omeMeetId,
        paymentContactName: formState.paymentContactName?.trim(),
        paymentRecipient: formState.paymentRecipient?.trim(),
        emailAddress: formState.emailAddress?.trim(),
        address1: formState.address1.trim(),
        address2: formState.address2.trim(),
        city: formState.city?.trim(),
        stateCode: formState.stateCode,
        postalCode: formState.postalCode.trim(),
        countryCode: formState.country
      }] :
      [{
        ...updatedOmeMeet.omeMeetPayee[0],
        paymentContactName: formState.paymentContactName?.trim(),
        paymentRecipient: formState.paymentRecipient?.trim(),
        emailAddress: formState.emailAddress?.trim(),
        address1: formState.address1.trim(),
        address2: formState.address2.trim(),
        city: formState.city?.trim(),
        stateCode: formState.stateCode,
        postalCode: formState.postalCode.trim(),
        countryCode: formState.country,
        modifiedDatetime: undefined,
      }]

    const updatedMeet = JSON.parse(JSON.stringify(meetState.objData));

    let entryContactPersonMeetStaffIndex = -1;
    let entryContactMeetStaffTypeIndex = -1;

    for (let i = 0; i < updatedMeet.personMeetStaff.length; i++) {
      for (let j = 0; j < updatedMeet.personMeetStaff[i].personMeetStaffType.length; j++) {
        if (updatedMeet.personMeetStaff[i].personMeetStaffType[j].meetStaffTypeId === ENTRY_CONTACT_STAFF_TYPE_ID) {
          entryContactPersonMeetStaffIndex = i;
          entryContactMeetStaffTypeIndex = j;
        }
      }
    }

    if (entryContactPersonMeetStaffIndex > -1 && entryContactMeetStaffTypeIndex > -1) {
      updatedMeet.personMeetStaff.splice(entryContactPersonMeetStaffIndex, 1);
    }

    updatedMeet.personMeetStaff.push(
      {
        meetId: updatedMeet.meetId,
        personId: formState.entryContactPersonId,
        personMeetStaffType: [
          {
            meetStaffTypeId: ENTRY_CONTACT_STAFF_TYPE_ID,
            meetStaffType: null
          }
        ]
      });

    updatedOmeMeet.meet = undefined;
    putOmeMeet(omeMeetState?.objData?.omeMeetId, updatedOmeMeet);
    putMeet(meetState?.objData?.meetId, updatedMeet);
    setState({ ...state, tryRedirect: true });
  };

  function formStateHasBeenSaved(formState) {
    //compare formState to meetState to see if formState has been saved
    let entryContactPersonId = '';
    if (meetState.objData.personMeetStaff?.length > 0) {
      for (let i = 0; i < meetState.objData.personMeetStaff.length; i++) {
        for (let j = 0; j < meetState.objData.personMeetStaff[i].personMeetStaffType.length; j++) {
          if (meetState.objData.personMeetStaff[i].personMeetStaffType[j].meetStaffTypeId === ENTRY_CONTACT_STAFF_TYPE_ID) {
            entryContactPersonId = meetState.objData.personMeetStaff[i].personId;
          }
        }
      }
    }

    if (formState?.entryContactPersonId !== entryContactPersonId && (
      formState?.entryContactPersonId !== '' || entryContactPersonId !== '')) {
      return false;
    }

    let paymentContactName = '';
    let emailAddress = '';
    let paymentRecipient = '';
    let country = '';
    let address1 = '';
    let address2 = '';
    let city = '';
    let stateCode = '';
    let postalCode = '';

    if (omeMeetState.objData?.omeMeetPayee?.length > 0) {
      paymentContactName = omeMeetState.objData?.omeMeetPayee[0]?.paymentContactName;
      emailAddress = omeMeetState.objData?.omeMeetPayee[0]?.emailAddress;
      paymentRecipient = omeMeetState.objData?.omeMeetPayee[0]?.paymentRecipient;
      country = omeMeetState.objData.omeMeetPayee[0]?.countryCode;
      address1 = omeMeetState.objData.omeMeetPayee[0]?.address1;
      address2 = omeMeetState.objData.omeMeetPayee[0]?.address2;
      city = omeMeetState.objData.omeMeetPayee[0]?.city;
      stateCode = omeMeetState.objData.omeMeetPayee[0]?.stateCode;
      postalCode = omeMeetState.objData.omeMeetPayee[0]?.postalCode;
    }

    if (formState?.paymentContactName !== paymentContactName && (
      formState?.paymentContactName !== '' || paymentContactName !== '')) {
      return false;
    }

    if (formState?.emailAddress !== emailAddress && (
      formState?.emailAddress !== '' || emailAddress !== '')) {
      return false;
    }

    if (formState?.paymentRecipient !== paymentRecipient && (
      formState?.paymentRecipient !== '' || paymentRecipient !== '')) {
      return false;
    }

    if (formState?.country !== country && (
      formState?.country !== '' || country !== '')) {
      return false;
    }

    if (formState?.address1 !== address1 && (
      formState?.address1 !== '' || address1 !== '')) {
      return false;
    }

    if (formState?.address2 !== address2 && (
      formState?.address2 !== '' || address2 !== '')) {
      return false;
    }

    if (formState?.city !== city && (
      formState?.city !== '' || city !== '')) {
      return false;
    }
 
    if (formState?.stateCode !== stateCode && (
      formState?.stateCode !== '' || stateCode !== '')) {
      return false;
    }

    if (formState?.postalCode !== postalCode && (
      formState?.postalCode !== '' || postalCode !== '')) {
      return false;
    }

    if (isDirty === true) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (meetState.isObjLoaded === true && meetState?.objData?.meetId) {
      getMeet(meetState?.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (environmentVariableState.isLoaded === true && Object.keys(meetState.objData).length > 0) {
      let meetContactPersonId = '';
      let entryContactPersonId = '';
      if (meetState.objData.personMeetStaff?.length > 0) {
        for (let i = 0; i < meetState.objData.personMeetStaff.length; i++) {
          for (let j = 0; j < meetState.objData.personMeetStaff[i].personMeetStaffType.length; j++) {
            if (meetState.objData.personMeetStaff[i].personMeetStaffType[j].meetStaffTypeId === MEET_CONTACT_STAFF_TYPE_ID) {
              meetContactPersonId = meetState.objData.personMeetStaff[i].personId;
            }
            if (meetState.objData.personMeetStaff[i].personMeetStaffType[j].meetStaffTypeId === ENTRY_CONTACT_STAFF_TYPE_ID) {
              entryContactPersonId = meetState.objData.personMeetStaff[i].personId;
            }
          }
        }
      }

      if (allowEdits() === true) {
        setFormData({
          ...formState,
          meetContactPersonId: meetContactPersonId,
          entryContactPersonId: entryContactPersonId,
          entryContactMemberId: '',
          entryContactName: '',
          entryContactEmail: '',
          entryContactPhone: '',
          paymentContactName: omeMeetState.objData.omeMeetPayee?.length > 0 ? omeMeetState.objData.omeMeetPayee[0]?.paymentContactName || '' : '',
          paymentRecipient: omeMeetState.objData.omeMeetPayee?.length > 0 ? omeMeetState.objData.omeMeetPayee[0]?.paymentRecipient || '' : '',
          emailAddress: omeMeetState.objData.omeMeetPayee?.length > 0 ? omeMeetState.objData.omeMeetPayee[0]?.emailAddress || '' : '',
          country: omeMeetState.objData.omeMeetPayee?.length > 0 ? omeMeetState.objData.omeMeetPayee[0]?.countryCode || '' : LOCAL_CONSTANTS.USA,
          countryName: omeMeetState.objData.omeMeetPayee?.length > 0 ? (omeMeetState.objData.omeMeetPayee[0]?.countryCode ? getCountryNameById(omeMeetState.objData.omeMeetPayee[0].countryCode) : '') : getCountryNameById(LOCAL_CONSTANTS.USA),
          address1: omeMeetState.objData.omeMeetPayee[0]?.address1 || '',
          address2: omeMeetState.objData.omeMeetPayee[0]?.address2 || '',
          city: omeMeetState.objData.omeMeetPayee[0]?.city || '',
          stateCode: omeMeetState.objData.omeMeetPayee?.length > 0 ? (omeMeetState.objData.omeMeetPayee[0]?.stateCode || '') : LOCAL_CONSTANTS.DEFAULT_USA_STATE_CODE,
          state: omeMeetState.objData.omeMeetPayee?.length > 0 ? (omeMeetState.objData.omeMeetPayee[0]?.stateCode ? getStateNameById(omeMeetState.objData.omeMeetPayee[0].stateCode) : '') : LOCAL_CONSTANTS.DEFAULT_USA_STATE,
          postalCode: omeMeetState.objData.omeMeetPayee[0]?.postalCode || ''
        })
      }
      else {
        setFormData({
          ...formState,
          meetContactPersonId: meetContactPersonId,
          entryContactPersonId: entryContactPersonId,
          entryContactMemberId: '',
          entryContactName: '',
          entryContactEmail: '',
          entryContactPhone: '',
          paymentContactName: omeMeetState.objData.omeMeetPayee?.length > 0 ? omeMeetState.objData.omeMeetPayee[0]?.paymentContactName || '' : '',
          paymentRecipient: omeMeetState.objData.omeMeetPayee?.length > 0 ? omeMeetState.objData.omeMeetPayee[0]?.paymentRecipient || '' : '',
          emailAddress: omeMeetState.objData.omeMeetPayee?.length > 0 ? omeMeetState.objData.omeMeetPayee[0]?.emailAddress || '' : '',
          country: omeMeetState.objData.omeMeetPayee?.length > 0 ? omeMeetState.objData.omeMeetPayee[0]?.countryCode || '' : '',
          countryName: omeMeetState.objData.omeMeetPayee?.length > 0 ? (omeMeetState.objData.omeMeetPayee[0]?.countryCode ? getCountryNameById(omeMeetState.objData.omeMeetPayee[0].countryCode) : '') : '',
          address1: omeMeetState.objData.omeMeetPayee[0]?.address1 || '',
          address2: omeMeetState.objData.omeMeetPayee[0]?.address2 || '',
          city: omeMeetState.objData.omeMeetPayee[0]?.city || '',
          stateCode: omeMeetState.objData.omeMeetPayee?.length > 0 ? (omeMeetState.objData.omeMeetPayee[0]?.stateCode || '') : '',
          state: omeMeetState.objData.omeMeetPayee?.length > 0 ? (omeMeetState.objData.omeMeetPayee[0]?.stateCode ? getStateNameById(omeMeetState.objData.omeMeetPayee[0].stateCode) : '') : '',
          postalCode: omeMeetState.objData.omeMeetPayee[0]?.postalCode || '',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, meetState.objData, omeMeetState.objData]);

  useEffect(() => {
    if (formState.entryContactPersonId !== '' && formState.entryContactMemberId === '') {
      if (basePersonState.objData.personId !== formState.entryContactPersonId) {
        getBasePerson(formState.entryContactPersonId);
      }
      if (personGeneralState.objData.personId !== formState.entryContactPersonId) {
        getPersonGeneral(formState.entryContactPersonId);
      }
      if (basePersonState.objData.personId === formState.entryContactPersonId &&
        formState.entryContactPersonId === personGeneralState.objData.personId) {
        let personPhone = [];
        for (let i = 0; i < personGeneralState.objData.personPhone?.length; i++) {
          if (personGeneralState.objData.personPhone[i].isPrimary === true) {
            const formattedPhoneNumber = formatPhoneNumber(personGeneralState.objData.personPhone[i].phoneNumber);
            const phoneType = personGeneralState.objData.personPhone[i].phoneType?.typeName;
            const isPrimary = personGeneralState.objData.personPhone[i].isPrimary === true ? 'Primary ' : 'Secondary '
            const formattedPersonPhone = formattedPhoneNumber + ' (' + isPrimary + phoneType + ')'
            personPhone.push(formattedPersonPhone)
          }
        }
        const phoneString = personPhone.join(', ');
        setFormData({
          ...formState,
          entryContactMemberId: basePersonState.objData.memberId,
          entryContactName: `${basePersonState.objData.firstName || ''} ${basePersonState.objData.lastName || ''}`,
          entryContactPhone: phoneString || '',
          entryContactEmail: basePersonState.objData.email || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.entryContactPersonId, formState.entryContactName, formState.paymentContactName,
  meetState.objData, omeMeetState.objData, basePersonState.objData, personGeneralState.objData]);

  useEffect(() => {
    if (formState.meetContactPersonId !== '' && formState.meetContactName === '') {
      if (basePersonState.objData.personId !== formState.meetContactPersonId) {
        getBasePerson(formState.meetContactPersonId);
      }
      if (personGeneralState.objData.personId !== formState.meetContactPersonId) {
        getPersonGeneral(formState.meetContactPersonId);
      }
      if (basePersonState.objData.personId === formState.meetContactPersonId &&
        formState.meetContactPersonId === personGeneralState.objData.personId) {
        let personPhone = [];
        for (let i = 0; i < personGeneralState.objData.personPhone?.length; i++) {
          if (personGeneralState.objData.personPhone[i].isPrimary === true) {
            const formattedPhoneNumber = formatPhoneNumber(personGeneralState.objData.personPhone[i].phoneNumber);
            const phoneType = personGeneralState.objData.personPhone[i].phoneType?.typeName;
            const isPrimary = personGeneralState.objData.personPhone[i].isPrimary === true ? 'Primary ' : 'Secondary '
            const formattedPersonPhone = formattedPhoneNumber + ' (' + isPrimary + phoneType + ')'
            personPhone.push(formattedPersonPhone)
          }
        }
        const phoneString = personPhone.join(', ');
        setFormData({
          ...formState,
          meetContactName: `${basePersonState.objData.firstName || ''} ${basePersonState.objData.lastName || ''}`,
          meetContactPhone: phoneString || '',
          meetContactEmail: basePersonState.objData.email || ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.meetContactPersonId, meetState.objData, omeMeetState.objData, basePersonState.objData, personGeneralState.objData]);

  useEffect(() => {
    if (Object.keys(omeMeetState.objData).length > 0) {
      const isSaved = formStateHasBeenSaved(formState);
      setLeftNavModalState({ ...leftNavModalState, formStateSaved: isSaved });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, omeMeetState.objData]);

  useEffect(() => {
    if (omeMeetState.isSaving === false && meetState.isSaving === false &&
      isSubmitting === false && state.tryRedirect === true) {
      if (state.submitButton === HostRegistrationConstants.CONTINUE) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_SUBMIT);
        }
        else {
          navigate(HostNavLinks.OME_MEET_SUBMIT);
        }
      }
      else if (state.submitButton === HostRegistrationConstants.SAVE_AND_EXIT) {
        if (omeMeetState.route !== '') {
          setSearchMeetState({ ...searchMeetState, showMeetHeader: true });
          navigate(NavLinks.MEET_HOST_MANAGEMENT, { state: { tryGetOmeMeet: true } });
        }
        else {
          navigate(MyMeetsNavLinks.MEET_HOST, { state: { selectOrgUnitState: selectOrgUnitState, myMeetsFiltersState: myMeetsFiltersState } });
        }
      }
      else if (state.submitButton === HostRegistrationConstants.BACK) {
        if (omeMeetState.route !== '') {
          navigate(NavLinks.MEET_HOST_PRICING);
        }
        else {
          navigate(HostNavLinks.OME_MEET_PRICING);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, omeMeetState.isSaving, meetState.isSaving, state]);

  function allowEdits() {
    if (omeMeetState.objData?.status === Constants.WORKFLOW_STATUS_CREATED) {
      if (omeMeetDateValidationState.objData?.isCurrentDateBeforeMeetEntryEndDate === true) {
        return true;
      }
      return false;
    }

    return true;
  }

  return {
    omeMeetDateValidationState,
    isSubmitting,
    omeMeetState,
    meetState,
    basePersonState,
    personGeneralState,
    formState,
    errorState,
    validateState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    onNextButtonClicked,
    onBackButtonClicked,
    onSaveAndExitButtonClicked,
    allowEdits,
    LOCAL_CONSTANTS,
    handleSubmitProxy,
    onCountryChange
  };
};

export default useOmeSubmit;