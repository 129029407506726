import useSessionEnding from "./UseSessionEnding";

import SessionTimer from "./components/sessionTimer/SessionTimer";

import SessionEndingLogin from "./subViews/sessionEndingLogin/SessionEndingLogin";
import SessionEndingExpiredPassword from "./subViews/sessionEndingExpiredPassword/SessionEndingExpiredPassword";
import SessionEndingOneTimePassword from "./subViews/sessionEndingOneTimePassword/SessionEndingOneTimePassword";

import PopUpModal from "../../../dialogs/PopUpModal";

import {
  SE_STEP_EXPIRED_PASSWORD, SE_STEP_LOGIN, SE_STEP_ONE_TIME_PASSWORD
} from "./utils/SessionEndingConstants";

import style from './SessionEnding.module.css';

const CurrentPopupStep = ({ popupState, updatePopupStep, onPopupClosed }) => {
  switch (popupState.currentStep) {
    case SE_STEP_LOGIN:
      return <SessionEndingLogin updatePopupStep={updatePopupStep} onPopupClosed={onPopupClosed} />;

    case SE_STEP_ONE_TIME_PASSWORD:
      return <SessionEndingOneTimePassword popupState={popupState} onPopupClosed={onPopupClosed} />;

    case SE_STEP_EXPIRED_PASSWORD:
      return <SessionEndingExpiredPassword popupState={popupState} updatePopupStep={updatePopupStep} onPopupClosed={onPopupClosed} />;

    default:
      return <SessionEndingLogin updatePopupStep={updatePopupStep} onPopupClosed={onPopupClosed} />;
  }
};

const SessionEnding = ({ setShowSessionEnding }) => {
  const {
    popupState,
    updatePopupStep,
    onPopupClosed
  } = useSessionEnding(setShowSessionEnding);

  return (
    <PopUpModal
      displayPopUp={true}
      widthPct={90}
      maxWidth={720}
      onModalCanceled={() => onPopupClosed(true)}
      title={`Your Session Expires Soon`}>
      <div className={['row', style.SessionPopupBody].join(' ')}>
        <div className="col-xs-12 col-sm-8">
          <CurrentPopupStep
            popupState={popupState}
            updatePopupStep={updatePopupStep}
            onPopupClosed={onPopupClosed} />
        </div>
        <SessionTimer />
      </div>
    </PopUpModal>
  );
};

export default SessionEnding;