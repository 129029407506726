import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from '../../wrappers/ReactRouterDom';

import useSecurityData from '../../state/security/UseSecurityData';
import useDateTimeData from '../../state/datetime/UseDateTimeData';
import useGlobalRoutesData from '../../state/security/UseGlobalRoutesData';
import useClubPersistentStorage from '../../state/clubPersistentStorage/UseClubPersistentStorage';
import useMemberPersistentStorage from '../../state/memberPersistentStorage/UseMemberPersistentStorage';
import usePersonAcknowledgementData from '../../state/personAcknowledgement/UsePersonAcknowledgementData';

import { getStoredTokenObj, resetStoredToken } from '../../utils/TokenUtils';

const SESSION_TIMEOUT_WARNING_MS = 5 * 60 * 1000;
const SESSION_INTERVAL_CHECK_MS = 30 * 1000;

const INITIAL_SESSION_STATE = {
  showSessionEnding: false,
  disableSessionEndingPopup: false
};

let logoutTimeout = null;

const usePageTemplate = () => {
  const location = useLocation();
  const [searchParams,] = useSearchParams();
  const { securityState, currentNavItem, userInfo,
    closeSecondaryNav, verifyUser, completeLogin
  } = useSecurityData();
  const { getDateTimeData } = useDateTimeData();
  const { getGlobalRoutesData, globalRoutesState } = useGlobalRoutesData();
  const { resetExpiration } = useMemberPersistentStorage();
  const { resetClubExpiration } = useClubPersistentStorage();
  const { personAcknowledgementState, getPersonAcknowledgements } = usePersonAcknowledgementData(true);
  const [sessionState, setSessionState] = useState(INITIAL_SESSION_STATE);
  const [isLoading, setIsLoading] = useState(true);

  const hasBeenClicked = () => {
    if (currentNavItem?.displayName) {
      closeSecondaryNav();
    }
  };

  const logout = () => {
    resetStoredToken();
    const ipLogoutUrl = import.meta.env.VITE_LOGOUT_URL;
    const postLogoutRedirectUri = import.meta.env.VITE_LOGIN_URL ? import.meta.env.VITE_LOGIN_URL.replace('/landing', '') : undefined;
    if (ipLogoutUrl && postLogoutRedirectUri) {
      const href = `${ipLogoutUrl}?post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`;
      window.location.href = href;
    }
  };

  const calculateSessionEnding = (skipShowSessionEndingLogic = false) => {
    const tokenExpiration = getStoredTokenObj().expirationDatetime;

    if (Number.isInteger(Date.parse(tokenExpiration))
      && import.meta.env.VITE_TIMES_MICROSITE_UI_DOMAIN !== 'self') {
      const expirationDatetime = new Date(Date.parse(tokenExpiration));
      const now = new Date();

      let ms = expirationDatetime.getTime() - now.getTime();

      if (ms <= 0) {
        logout();
      } else if (ms < SESSION_INTERVAL_CHECK_MS) {
        if (logoutTimeout !== null) {
          clearTimeout(logoutTimeout);
        }

        logoutTimeout = setTimeout(() => { logout(); }, ms);

      } else if (skipShowSessionEndingLogic !== true) {
        ms -= SESSION_TIMEOUT_WARNING_MS;

        if (ms <= 0 && sessionState.disableSessionEndingPopup !== true && sessionState.showSessionEnding !== true) {
          setSessionState({ ...sessionState, showSessionEnding: true });
        } else if (ms > 0 && sessionState.showSessionEnding === true) {
          setSessionState({ ...sessionState, showSessionEnding: false });
        }
      }
    }
  };

  const setShowSessionEnding = (showSessionEnding, disableSessionEndingPopup = false) => {
    setSessionState({
      ...sessionState,
      showSessionEnding,
      disableSessionEndingPopup
    });
  };

  useEffect(() => {
    const qsToken = searchParams.get('token');
    const qsRefreshToken = searchParams.get('refresh_token');
    const qsExpirationDatetime = searchParams.get('expiration_datetime');
    const qsUsername = searchParams.get('username');
    if (qsToken || qsRefreshToken || qsExpirationDatetime || qsUsername) {
      completeLogin(qsToken, qsRefreshToken, qsExpirationDatetime, qsUsername);
    } else if (securityState.isObjLoading !== true) {
      verifyUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, location]);

  useEffect(() => {
    calculateSessionEnding(true);
    const expirationCheckInterval = setInterval(() => calculateSessionEnding(false), SESSION_INTERVAL_CHECK_MS);

    return () => {
      clearInterval(expirationCheckInterval);
      clearTimeout(logoutTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionState]);

  useEffect(() => {
    if (globalRoutesState.isArrayLoaded) {
      getDateTimeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalRoutesState.arrayData]);

  useEffect(() => {
    getGlobalRoutesData();
    if (import.meta.env.VITE_MEMBER_UI_DOMAIN === 'self') {
      resetExpiration();
    } else if (import.meta.env.VITE_CLUB_UI_DOMAIN === 'self') {
      resetClubExpiration();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (securityState.isObjLoaded === true) {
      if (!userInfo.memberId || userInfo.memberId === 'Unregistered') {
        setIsLoading(false);
      } else if (personAcknowledgementState.isArrayLoaded !== true) {
        getPersonAcknowledgements(userInfo.memberId);
      } else {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personAcknowledgementState, securityState]);

  return {
    isLoading,
    memberId: userInfo.memberId,
    isLoggedIn: userInfo?.isLoggedIn === true,
    showSessionEnding: sessionState.showSessionEnding,
    isUnregisteredUser: userInfo.memberId === 'Unregistered',
    areAcknowledgementsComplete: personAcknowledgementState.isArrayLoaded === true && personAcknowledgementState.arrayData.length === 0,
    hasBeenClicked,
    setShowSessionEnding
  };
};

export default usePageTemplate;