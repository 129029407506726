import { Fragment } from "react";

import SecondaryButton from "../../../buttons/SecondaryButton";

const ZeroDollarCheckout = ({ onReturnClicked, onBackToCartClicked }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <p>No payment is due. Thank you.</p>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        {onReturnClicked
          ? <SecondaryButton type="button" onClick={onReturnClicked}>Return</SecondaryButton>
          : <SecondaryButton type="button" onClick={() => onBackToCartClicked()}>Back To Cart</SecondaryButton>
        }
      </div>
    </div>
  </Fragment>
);

export default ZeroDollarCheckout;