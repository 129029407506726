import { useEffect, useState } from 'react';
import useEmbeddedReportData from '../../state/embeddedReport/UseEmbeddedReportData';

const INITIAL_VIEW_STATE = {
  isLoading: true,
  showGrid: false,
  showReport: false,
  reportInfoId: -1,
  listNames: [],
  rawReportList: [],
  reportList: [],
};

const useReportList = (routeName, contextId) => {
  const [viewState, setViewState] = useState({ ...INITIAL_VIEW_STATE });
  const { getEmbeddedReportListForRoute, getEmbeddedReportListForContext } = useEmbeddedReportData();

  const onListNamesChanged = (newValue) => {
    const reportList = newValue ? viewState.rawReportList.filter(x => x.listName === newValue) : [...viewState.rawReportList];
    setViewState({ ...viewState, reportInfoId: -1, showGrid: true, showReport: true, reportList });
  };

  const onReportSelected = (reportInfoId) => {
    if (reportInfoId > 0) {
      setViewState({ ...viewState, reportInfoId, showGrid: false, showReport: true });
    }
  };

  const onBackToGrid = () => {
    setViewState({ ...viewState, reportInfoId: -1, showGrid: true, showReport: false });
  };

  useEffect(() => {
    if (routeName) {

      const reportListPromise = contextId ? getEmbeddedReportListForContext(routeName, contextId) :
        getEmbeddedReportListForRoute(routeName);

      if (reportListPromise) {

        reportListPromise.then((stateObj) => {
          if (Array.isArray(stateObj.arrayData)) {
            const listNames = [];
            stateObj.arrayData.forEach(x => {
              if (listNames.filter(a => a.id === x.listName).length === 0) {
                listNames.push({ id: x.listName, name: x.listDisplayName });
              }
            });

            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: stateObj.arrayData.length > 0,
              showReport: false,
              reportInfoId: -1,
              listNames,
              rawReportList: [...stateObj.arrayData],
              reportList: [...stateObj.arrayData]
            });
          } else {
            setViewState({
              ...viewState,
              isLoading: false,
              showGrid: false,
              showReport: false,
              reportInfoId: -1,
              listNames: [],
              rawReportList: [],
              reportList: []
            });
          }
        }).catch((err) => {
          setViewState({
            ...viewState,
            isLoading: false,
            showGrid: false,
            showReport: false,
            reportInfoId: -1,
            listNames: [],
            rawReportList: [],
            reportList: []
          });
        });
      }
    }
    else if (routeName === undefined || routeName === null) {  //if route name is missing, return empty grid
      setViewState({
        ...viewState,
        isLoading: false,
        showGrid: false,
        showReport: false,
        reportInfoId: -1,
        listNames: [],
        rawReportList: [],
        reportList: []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeName && contextId]);

  return {
    ...viewState,
    onListNamesChanged,
    onReportSelected,
    onBackToGrid
  };
};

export default useReportList;