import { useState } from 'react';

import useSecurityData from '../../../state/security/UseSecurityData';

const useFooter = () => {
  const {userInfo} = useSecurityData();
  const [{privacyPolicy, termsAndConditions}, ] = useState({
    privacyPolicy: {
      displayName: 'Privacy Policy',
      linkUrl: '//www.usaswimming.org/landing-pages/privacy-policy'
    },
    termsAndConditions: {
      displayName: 'Terms & Conditions',
      linkUrl: '//www.usaswimming.org/utility/landing-pages/terms-of-use'
    }
  });
  const menuItems = Array.isArray(userInfo?.taxonomies) 
  ? userInfo?.taxonomies.find(t => t.taxonomyName === 'Footer')?.taxonomyLinks || [] 
  : [];
  const dt = new Date();
  const yeardtc = dt.getUTCFullYear();
  const year = !yeardtc || isNaN(yeardtc) ? '0000' : ('0000' + yeardtc.toString()).slice(-4); //Keep leading zero(s)

  return {
    menuItems,
    privacyPolicy,
    termsAndConditions,
    year
  };
};

export default useFooter;