import useLscDropdown from './UseLscDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css'

const LscDropdown = ({ label, name, value, error, message, onChange }) => {
  const { lscState } = useLscDropdown();
  return lscState.message
    ? <span className={global.LoadingMsg}>{lscState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={lscState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={lscState.isLoading}
      />
    );
};

export default LscDropdown;