import { useContext, useEffect } from 'react';

import { MeetSoftwareStateContext } from './MeetSoftwareContextProvider';

import MeetSoftwareData from './MeetSoftwareData';

const useMeetSoftwareData = () => {
  const [meetSoftwareState, setMeetSoftwareState] = useContext(MeetSoftwareStateContext);
  const getMeetSoftwares = () => { MeetSoftwareData.getMeetSoftwares(meetSoftwareState, setMeetSoftwareState); }

  useEffect(() => {
    if (meetSoftwareState.isLoading !== true && meetSoftwareState.isLoaded !== true) {
      getMeetSoftwares();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    meetSoftwareState,
    getMeetSoftwares
  };
};

export default useMeetSoftwareData;