import React, { createContext, useState } from 'react';

import PersonOrgRoleData from './PersonOrgRoleData';

export const PersonOrgRoleStateContext = createContext();

const PersonOrgRoleContextProvider = ({ children }) => {
  const stateHook = useState(PersonOrgRoleData.INITIAL_STATE);

  return (
    <PersonOrgRoleStateContext.Provider value={stateHook}>
      {children}
    </PersonOrgRoleStateContext.Provider>
  );
};

export default PersonOrgRoleContextProvider;