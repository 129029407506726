import React, { Fragment } from 'react';

import useMeetUploadLock from './UseMeetUploadLock';

import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import Headings from '../../../../common/components/headings/Headings';
import Constants from '../../../../common/utils/Constants';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';

const MeetUploadLock = () => {
    const {
        meetState,
        simpleFormState,
        onSimpleFormValueChanged,
        onSaveClicked,
        futureMeet
    } = useMeetUploadLock();

    return (
        <Fragment>
            <div className="row">
                <div className="col-xs-12">
                    <Headings.H3>Meet Upload Lock</Headings.H3>
                </div>
            </div>
            {futureMeet === false ?
                <Fragment>
                    <div className="row">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <YesNoSwitch
                                label="Upload Locked?*"
                                name="isUploadLocked"
                                checked={simpleFormState.isUploadLocked}
                                error={() => { }}
                                message={() => { }}
                                onChange={(value) => { onSimpleFormValueChanged('isUploadLocked', value) }} />
                        </div>
                    </div>
                    <div className="row usas-extra-top-margin usas-extra-bottom-margin">
                        <div className="col-xs-12 usas-extra-top-margin">
                            <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
                        </div>
                    </div>
                </Fragment>
                :
                <div className="row">
                    <div className="col-xs-12 usas-extra-top-margin">
                        Meet Upload locking is only available for meets in the past.
                    </div>
                </div>
            }
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.LOADING_MSG}
                displayPopUp={meetState.isObjLoading} />
            <PopUpModal
                widthPct={50}
                maxWidth={250}
                title={Constants.SAVING_MSG}
                displayPopUp={meetState.isSaving} />
        </Fragment>)
}

export default MeetUploadLock;