import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import GetSecurityInfoForToken from './GetSecurityInfoForToken';
import getRoutesForContext from './GetRoutesForContext';

const SECURITY_INITIAL_STATE = {
  isObjLoading: false,
  isObjLoaded: false,
  userInfo: {},
  message: '',
  retries: 0
};

const CONTEXT_SECURITY_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  showNav: false,
  contextId: '',
  scope: '',
  taxonomies: [],
};

const NAV_ROUTES_INITIAL_STATE = {
  isReadyToRoute: false,
  navRoutes: {}
};

const login = () => {
  window.location.href = `${import.meta.env.VITE_LOGIN_URL}?retUrl=${encodeURIComponent(window.location.href)}`;
};

const logout = (retUrl) => {
  const ipLogoutUrl = import.meta.env.VITE_LOGOUT_URL;
  const postLogoutRedirectUri = `${window.location.protocol}//${import.meta.env.VITE_BASE_DOMAIN}`;
  const href = `${ipLogoutUrl}?post_logout_redirect_uri=${encodeURIComponent(retUrl || postLogoutRedirectUri)}`;
  window.location.href = href;
  return;
};

const logoutWithMemberIdParam = (childMemberId) => {
  const ipLogoutUrl = import.meta.env.VITE_LOGOUT_URL;
  const postLogoutRedirectUri = `${window.location.protocol}//${import.meta.env.VITE_BASE_DOMAIN}`;
  const postLogoutRedirectUriWithMemberIdParam = `${window.location.protocol}//${import.meta.env.VITE_BASE_DOMAIN}/landing?memberId=${childMemberId}`;
  const href = `${ipLogoutUrl}?post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`;
  window.location.href = href;
  window.location.href = postLogoutRedirectUriWithMemberIdParam;
  return;
};

const logoutWithParams = (paramsObj) => {
  const ipLogoutUrl = import.meta.env.VITE_LOGOUT_URL;
  const postLogoutRedirectUri = `${window.location.protocol}//${import.meta.env.VITE_BASE_DOMAIN}`;
  let paramsForUrl = '';

  for (const [key, value] of Object.entries(paramsObj)) {
    if (paramsForUrl === '') {
      paramsForUrl = `?${key}=${value}`
    } else {
      paramsForUrl += `&${key}=${value}`
    }
  }

  const postLogoutRedirectUriWithParams = `${window.location.protocol}//${import.meta.env.VITE_BASE_DOMAIN}/landing${paramsForUrl}`;
  const href = `${ipLogoutUrl}?post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`;
  window.location.href = href;
  window.location.href = postLogoutRedirectUriWithParams;
  return;
};

const SecurityData = {
  SECURITY_INITIAL_STATE,
  CONTEXT_SECURITY_INITIAL_STATE,
  NAV_ROUTES_INITIAL_STATE,
  login,
  logout,
  logoutWithMemberIdParam,
  logoutWithParams,
  GetSecurityInfoForToken,
  getRoutesForContext
};

export default SecurityData;