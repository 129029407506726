import React from 'react';

import style from './ReadOnly.module.css';
import global from '../GlobalStyle.module.css';

const ReadOnly = ({ label, name, value }) => (
  <div className={global.ComponentFlex}>
    <label className={global.UsasLabel} htmlFor={name}>{label}</label>
    <div>
      <input
        type="hidden"
        value={value}
        id={name}
        name={name}
      />
      <span className={style.ReadOnlyValue}>{value || <span>&nbsp;</span>}</span>
    </div>
  </div>
);

export default ReadOnly;