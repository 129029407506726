import useSessionTimer from "./UseSessionTimer";

import StopwatchIcon from "../../../../../icons/StopwatchIcon";
import ExclamationIcon from "../../../../../icons/ExclamationIcon";

import style from '../../SessionEnding.module.css';

const SessionTimer = () => {
  const { expiringIn } = useSessionTimer();

  return (
    <div className={['col-xs-12 col-sm-4', style.SessionTimer].join(' ')}>
      <div className={style.SessionTimerWatchIcon}>
        <StopwatchIcon />&nbsp;Session expires in...<br /><br />
      </div>
      <table cellPadding="4">
        <tbody>
          <tr>
            <td align="center" className={style.Number}>
              {expiringIn.substring(0, 2)}
            </td>
            <td align="center" className={style.Colon}>
              :
            </td>
            <td align="center" className={style.Number}>
              {expiringIn.substring(3, 5)}
            </td>
            <td align="center" className={style.Colon}>
              :
            </td>
            <td align="center" className={style.Number}>
              {expiringIn.substring(6, 8)}
            </td>
          </tr>
          <tr>
            <td align="center" className={style.Text}>
              <b>Hours</b>
            </td>
            <td align="center">
            </td>
            <td align="center" className={style.Text}>
              <b>Minutes</b>
            </td>
            <td align="center">
            </td>
            <td align="center" className={style.Text}>
              <b>Seconds</b>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div className={style.SessionTimerWarning}>
        <div className={style.SessionTimerWarningIcon}>
          <ExclamationIcon />&nbsp;For your consideration...<br /><br />
        </div>
        <div>
          If you are in the middle of extensive work and choose not to login again, you may lose your progress, and you may have to start over.
        </div>
      </div>
    </div>
  );
};

export default SessionTimer;