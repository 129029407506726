const useYesNoSwitch = (className, error, style, global, disabled, checked, onChange) => {
  const fullClassAttribute = [(error ? global.Error : global.BaseInputBox), (className || '')].join(' ');
  const iconClass = checked === true ? style.YesIcon : style.NoIcon;
  
  const isDisabled = (disabled === true);

  const onChanged = (e) => {
    onChange(e.target.checked);
  };

  return {
    fullClassAttribute, 
    iconClass, 
    isDisabled, 
    onChanged
  };
};

export default useYesNoSwitch;