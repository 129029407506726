import { useEffect, useState } from 'react';
import useSecurityData from '../../../../state/security/UseSecurityData';

const useSmallNav = () => {
  const [ menuItems, setMenuItems ] = useState([]);
  const [ expandedId, setExpandedId ] = useState(-1);
  const { userInfo } = useSecurityData();

  const expandNode = (id) => {
    if (expandedId !== id && id > 0) {
      setExpandedId(id);
    }
  };

  const collapseNode = () => {
    if (expandedId > 0) {
      setExpandedId(-1);
    }
  };

  useEffect(() => {
    const getMenuItems = (userInfo) => {
      const topMenuItems = Array.isArray(userInfo?.taxonomies) 
      ? userInfo?.taxonomies.find(t => t.taxonomyName === 'TopMenu')?.taxonomyLinks || [] 
      : [];
      const mainMenuItems = Array.isArray(userInfo?.taxonomies) 
      ? userInfo?.taxonomies.find(t => t.taxonomyName === 'MainMenu')?.taxonomyLinks || [] 
      : [];
      const topMenuAry = topMenuItems.map((item) => {
        return {...item, menuName: 'TopMenu'};
      });
      const mainMenuAry = mainMenuItems.map((item) => {
        return {...item, menuName: 'MainMenu'};
      });

      return topMenuAry.concat(mainMenuAry);
    };

    setMenuItems(getMenuItems(userInfo))
  }, [userInfo]);
  
  return { menuItems, expandedId, expandNode, collapseNode };
};

export default useSmallNav;