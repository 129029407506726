import React from 'react';
import Headings from '../headings/Headings';
import YesNoSwitch from '../yesNoSwitch/YesNoSwitch';

const Acknowledgement = ({ acknowledgement, onItemClick }) => {
  return (
    <div className="row">
      <div className="col-xs-12">
        <Headings.H4>{acknowledgement.acknowledgeForm}</Headings.H4>
      </div>
      <div className="col-xs-12" dangerouslySetInnerHTML={{ __html: acknowledgement.acknowledgementFormText }}></div>
      <div className="col-xs-12">
        <YesNoSwitch
          isHorizontal={false}
          name={`formAcknowledgement${acknowledgement.acknowledgementFormId}`}
          message={acknowledgement.message}
          label="Do you acknowledge this information?"
          checked={acknowledgement.isAcknowledged}
          onChange={value => onItemClick(acknowledgement.acknowledgementFormId, value)} />
      </div>
    </div>
  );
};

export default Acknowledgement;