import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getMeetClassificationsByOrgUnitId from './GetMeetClassificationsByOrgUnitIdData';
import getMeetClassifications from './GetMeetClassificationsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  orgUnitId: '',
  treeData: []
};

const MeetClassificationData = {
  INITIAL_STATE, getMeetClassificationsByOrgUnitId, getMeetClassifications
};

export default MeetClassificationData;