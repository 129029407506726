import React, { Fragment } from 'react';

import { Link, NavLink } from '../../wrappers/ReactRouterDom';

import useSmallNav from './UseSmallNav';

import style from './ContextBasedNav.module.css';

const SmallNavItem = ({menuItem, expandedId, expandNode, collapseNode}) => {
  const hasChildren = Array.isArray(menuItem.children) && menuItem.children.length > 0 ? true : false;

  if (hasChildren === true && expandedId === menuItem.taxonomyId) {
    return (
      <Fragment>
        <li className={[style[`Level${menuItem.level}`], style.Selected].join(' ')} onClick={() => collapseNode()}>
          {menuItem.linkUrl ? <Link to={menuItem.linkUrl}>{menuItem.displayName}</Link> : <span>{menuItem.displayName}</span>}
        </li>
        {menuItem.children.map((x, i) => <SmallNavItem menuItem={x} key={i} />)}
      </Fragment>
    );
  } else if (hasChildren === true) {
    return (
      <li className={style[`Level${menuItem.level}`]} onClick={() => expandNode(menuItem.taxonomyId)}>
        {menuItem.linkUrl ? <Link to={menuItem.linkUrl}>{menuItem.displayName}</Link> : <span>{menuItem.displayName}</span>}
      </li>
    );
  } else {
    return (
      <li className={[style[`Level${menuItem.level}`], style[`Level${menuItem.level}NotSelected`]].join(' ')}>
        {menuItem.linkUrl 
        ? <NavLink
          className={navData => (navData.isActive ? style.ActiveNavLink : style.NavLink)} 
          to={menuItem.linkUrl}>
            {menuItem.displayName}
        </NavLink>
        : <span>{menuItem.displayName}</span>}
      </li>
    );
  }
};

const SmallNav = ({taxonomyName, renderPillsOnly}) => {
  const {showNav, menuItems, expandedId, expandNode, collapseNode} = useSmallNav(taxonomyName, renderPillsOnly);
  return (
    <div
      className={[style.SmallNav, showNav ? 'visible' : 'hidden'].join(' ')}>
      <ul>
        {menuItems.map((x, i) => (
        <SmallNavItem 
          menuItem={x}
          expandedId={expandedId}
          expandNode={expandNode}
          collapseNode={collapseNode}
          key={i} />))}
      </ul>
    </div>
  );
};

export default SmallNav;