import React from 'react';

import useLinkButton from './UseLinkButton';

const LinkButton = ({ className, children, action }) => {
  const { onClick, fullClassAttribute } = useLinkButton(className, action);

  return (
    <button type="button" className={fullClassAttribute} onClick={onClick}>{children}</button>
  );
};

export default LinkButton;