import { useContext } from 'react';

import { PersonGeneralStateContext } from './PersonGeneralContextProvider';
import PersonGeneralData from './PersonGeneralData';

const usePersonGeneralData = () => {
  const [personGeneralState, setPersonGeneralState] = useContext(PersonGeneralStateContext);

  const getPersonGeneral = (personId) => PersonGeneralData.getPersonGeneralData(personId, personGeneralState, setPersonGeneralState);
  const getPersonGeneralByMemberId = (memberId) => PersonGeneralData.getPersonGeneralByMemberIdData(memberId, personGeneralState, setPersonGeneralState);
  const putPersonGeneral = (personId, personObj) => PersonGeneralData.putPersonGeneralData(personId, personObj, personGeneralState, setPersonGeneralState);
  const putPersonGeneralContact = (personId, personObj) => PersonGeneralData.putPersonGeneralContactData(personId, personObj, personGeneralState, setPersonGeneralState);
  const deletePersonAddress = (personAddressId) => PersonGeneralData.deletePersonAddressData(personAddressId, personGeneralState, setPersonGeneralState);
  const deletePersonPhone = (personPhoneId) => PersonGeneralData.deletePersonPhoneData(personPhoneId, personGeneralState, setPersonGeneralState);
  const deletePersonContact = (personContactId) => PersonGeneralData.deletePersonContactData(personContactId, personGeneralState, setPersonGeneralState);
  const deletePersonContactAddress = (personContactAddressId) => PersonGeneralData.deletePersonContactAddressData(personContactAddressId, personGeneralState, setPersonGeneralState);
  const deletePersonContactPhone = (personContactPhoneId) => PersonGeneralData.deletePersonContactPhoneData(personContactPhoneId, personGeneralState, setPersonGeneralState);

  const clearObjData = () => {
    setPersonGeneralState({
      ...personGeneralState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearArrayData = () => {
    setPersonGeneralState({
      ...personGeneralState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmSave = () => {
    setPersonGeneralState({
      ...personGeneralState,
      isSaved: false
    });
  };

  return {
    personGeneralState,
    clearObjData,
    clearArrayData,
    confirmSave,
    getPersonGeneral,
    getPersonGeneralByMemberId,
    putPersonGeneral,
    putPersonGeneralContact,
    deletePersonAddress,
    deletePersonPhone,
    deletePersonContact,
    deletePersonContactAddress,
    deletePersonContactPhone
  };
};

export default usePersonGeneralData;