import { useContext } from 'react';

import { MeetClassificationStateContext } from './MeetClassificationContextProvider';

import MeetClassificationData from './MeetClassificationData';

const useMeetClassificationData = () => {
  const [meetClassificationState, setMeetClassificationState] = useContext(MeetClassificationStateContext);

  const getMeetClassificationsByOrgUnitId = (orgUnitId, filterId, filterMode) => {
    MeetClassificationData.getMeetClassificationsByOrgUnitId(orgUnitId, filterId, filterMode, meetClassificationState, setMeetClassificationState);
  };

  return {
    meetClassificationState,
    setMeetClassificationState,
    getMeetClassificationsByOrgUnitId
  };
};

export default useMeetClassificationData;