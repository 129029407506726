import useRadioButtonList from './UseRadioButtonList';

import global from '../GlobalStyle.module.css';
import style from './RadiobuttonList.module.css';

const RadiobuttonList = ({ name, label, items, error, message, value, isVertical, onChange }) => {
  const {isDisabled} = useRadioButtonList(); 

  return (
    <div className={global.ComponentFlex}>
      <label className={global.UsasLabel} htmlFor={name}>{label}</label>
      <div className={isVertical === true ? style.VerticalRadios : style.HorizontalRadios}>
        {items.map((item) => (
          <div className={style.RadioButton} key={item.value}>
            <label htmlFor={name + "_" + item.value}>
              <input
                type="radio"
                id={name + "_" + item.value}
                name={name}
                value={item.value}
                checked={value === item.value}
                onChange={(e) => { onChange(e.target.value) }}
                disabled={isDisabled === true ? 'disabled' : ''}
              />
              {item.label}
            </label>
          </div>
        ))}
      </div>
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
}

export default RadiobuttonList;