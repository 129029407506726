import React, {createContext, useState} from 'react';

import OrganizationData from './OrganizationData';

export const OrganizationStateContext = createContext();

const OrganizationContextProvider = ({children}) => {
  const stateHook = useState(OrganizationData.INITIAL_STATE);

  return (
    <OrganizationStateContext.Provider value={stateHook}>
      {children}
    </OrganizationStateContext.Provider>
  );
};

export default OrganizationContextProvider;