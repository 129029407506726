import { useState } from 'react';

import MemberLookupData from './MemberLookupData';

const useMemberLookupData = () => {
  const [memberLookupState, setMemberLookupState] = useState(MemberLookupData.INITIAL_STATE);

  const getMemberLookupByBirthDate = (firstName, lastName, birthDate) => {
    return MemberLookupData.getMemberLookupByBirthDateData(firstName, lastName, birthDate, memberLookupState, setMemberLookupState);
  };

  const getMemberLookupByOrgUnit = (firstName, lastName, orgUnitId, roleGroupName = undefined, isCurrent = undefined) => {
    return MemberLookupData.getMemberLookupByOrgUnitData(firstName, lastName, orgUnitId, roleGroupName, isCurrent, memberLookupState, setMemberLookupState);
  };

  return {
    memberLookupState,
    getMemberLookupByBirthDate,
    getMemberLookupByOrgUnit
  };
};

export default useMemberLookupData;