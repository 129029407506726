//TODO error format changes based on browser, standardized way to parse error stacks?
export const getComponentNameFromError = (error) => {
  try {
    if (error.fileName && error.lineNumber && error.columnNumber) {
      const fileNameArray = error.fileName.split('/');

      return `${fileNameArray[fileNameArray.length - 1]}:${error.lineNumber}:${error.columnNumber}`;
    } else if (error.stack) {
      const stackArray = error.stack.split('\n');
      const stackLineArray = stackArray[1].split('/');

      return `${stackLineArray[stackLineArray.length - 1]}`;
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
}

export const getErrorObjForLog = (error, memberId = '') => {
  return {
    datetime: new Date(),
    memberId: memberId || '',
    host: window.location.hostname || '',
    path: window.location.pathname || '',
    componentName: getComponentNameFromError(error),
    message: error.message || ''
  };
};