import useSecurityData from '../../state/security/UseSecurityData';

const useMobileContextBasedNav = () => {
  const { contextSecurityState, setContextNavVisibility } = useSecurityData();

  const toggleMobileMenu = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    
    setContextNavVisibility(contextSecurityState.showNav !== true);
  };

  return {
    showNav: contextSecurityState.showNav,
    toggleMobileMenu
  };
};

export default useMobileContextBasedNav;