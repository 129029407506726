import {VisuallyHidden} from '@reach/visually-hidden';

import useShield from './UseShield';

const Shield = ({className}) => {
  const { onClick } = useShield();
  const url = `${import.meta.env.VITE_CDN_URL}/swimShield.svg`;
  
  return (
    <a href="" onClick={onClick}>
      <VisuallyHidden>Logo - Link to Home</VisuallyHidden>
      
      <img className={className} src={url} alt="Logo" />
    </a>
  );
};

export default Shield;