export const CART_STEP_CART_ITEMS = 'CartItems';
export const CART_STEP_CHECKOUT = 'Checkout'
export const CART_STEP_PAYMENT_COMPLETE = 'PaymentComplete';

export const COLLECTION_NAME_FOUNDATION_DONATION = 'Foundation Donation';
export const COLLECTION_NAME_TECHNOLOGY_FEE = 'Technology Fee';

export const CLIENT_SECRET_ZERO_DOLLAR_TRANSACTION = 'ZERO DOLLAR';
export const CLIENT_SECRET_CHECK_TRANSACTION = 'CHECK';

const PaymentsConstants = {
  CART_STEP_CART_ITEMS,
  CART_STEP_CHECKOUT,
  CART_STEP_PAYMENT_COMPLETE,
  COLLECTION_NAME_FOUNDATION_DONATION,
  COLLECTION_NAME_TECHNOLOGY_FEE,
  CLIENT_SECRET_ZERO_DOLLAR_TRANSACTION,
  CLIENT_SECRET_CHECK_TRANSACTION
};

export default PaymentsConstants;