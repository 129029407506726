import { useState, useEffect } from 'react';

import DateData from '../../state/date/DateData';

const regex = /^([0-1][0-9])\/([0-3][0-9])\/([0-9]{4})$/;
const breakUpDate = (value) => {
  if (value) {
    const match = value.match(regex);
    if (match) {
      return {
        month: match[1],
        day: match[2],
        year: match[3],
        value: `${match[1]}/${match[2]}/${match[3]}`
      };
    }
  }

  return {
    month: '00',
    day: '00',
    year: '0000',
    value: '00/00/0000'
  };
};

const useDatePicker = (value, countOfYears, startYearOffset, onChange) => {
  const [state, setState] = useState({
    month: '00',
    day: '00',
    year: '0000',
    value: '00/00/0000',
    months: [],
    days: [],
    years: []
  });

  const onValueChanged = (newValue) => {
    if (newValue !== state.value) {
      onChange(newValue);
    }
  }

  const onMonthChanged = (newMonth, newValueLabel, e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newValue = `${newMonth}/${state.day}/${state.year}`;
    setState({
      ...state,
      value: newValue,
      month: newMonth
    });
    onValueChanged(newValue);
  };

  const onDayChanged = (newDay, newValueLabel, e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newValue = `${state.month}/${newDay}/${state.year}`;
    setState({
      ...state,
      value: newValue,
      day: newDay
    });
    onValueChanged(newValue);
  };

  const onYearChanged = (newYear, newValueLabel, e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const newValue = `${state.month}/${state.day}/${newYear}`;
    setState({
      ...state,
      value: newValue,
      year: newYear
    });
    onValueChanged(newValue);
  };

  useEffect(() => {
    const dateValue = breakUpDate(value);
    if (Array.isArray(state.months) === false
      || state.months.length === 0) {
      const months = DateData.getMonthsAsOptions(false);
      const days = DateData.getDaysAsOptions('00');
      const years = DateData.getYearsAsOptions(countOfYears, startYearOffset);
      setState({
        ...dateValue,
        months,
        days,
        years
      });
    } else if (dateValue.value !== state.value
      || dateValue.month !== state.month
      || dateValue.day !== state.day
      || dateValue.year !== state.year) {
      setState({
        ...state,
        ...dateValue
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, countOfYears, startYearOffset]);

  const isReadWrite = true;

  return {
    ...state,
    onMonthChanged,
    onDayChanged,
    onYearChanged,
    isReadWrite
  };
};

export default useDatePicker;