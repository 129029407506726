import Constants from '../../utils/Constants';
import ToIntIfInt from '../../utils/ToIntIfInt';
import { isValidNumber, isValidDate, isValidTime } from '../../utils/validation';

export const localValidate = (formState) => {
  const sessionDate = new Date(formState.sessionDate);
  const meetStartDate = new Date(formState.meetStartDate);
  const meetEndDate = new Date(formState.meetEndDate);
  const warmUpTime = new Date(formState.sessionDate + ' ' + formState.warmUpTime);
  const startTime = new Date(formState.sessionDate + ' ' + formState.startTime);

  let errors = {};

  if (formState.sessionOrderId === '') {
    errors.sessionOrderId = 'Session Number is required';
  } else if (!isValidNumber(formState.sessionOrderId) || ToIntIfInt(formState.sessionOrderId) === 0 || ToIntIfInt(formState.sessionOrderId) > 30) {
    errors.sessionOrderId = 'Session Number must be between 1 and 30';
  }

  if (formState.sessionTypeId < 0) {
    errors.sessionTypeId = 'Session Type is required';
  }

  if (formState.sessionDate === '' || formState.sessionDateId === Constants.DEFAULT_ID) {
    errors.sessionDateId = 'Session Date is required'
  } else if (!isValidDate(formState.sessionDate)) {
    errors.sessionDateId = 'Session Date must be a valid date'
  } else if (sessionDate < meetStartDate || sessionDate > meetEndDate) {
    errors.sessionDateId = `Session Date must be between the Meet Start Date and Meet End Date (${formState.meetStartDate} - ${formState.meetEndDate})`
  }

  if (formState.startTime !== Constants.BLANK_TIME_STRING) {
    if (!isValidTime(formState.startTime)) {
      errors.startTime = 'Start Time must be a valid time';
    }
  }

  if (formState.warmUpTime !== Constants.BLANK_TIME_STRING) {
    if (!isValidTime(formState.warmUpTime)) {
      errors.warmUpTime = 'Warm Up Time must be a valid time';
    }
  }

  if (formState.startTime !== Constants.BLANK_TIME_STRING && formState.warmUpTime !== Constants.BLANK_TIME_STRING) {
    if (isValidTime(formState.startTime) && isValidTime(formState.warmUpTime)) {
      if (warmUpTime >= startTime) {
        errors.startTime = 'Start Time must be after the Warm Up Time';
        errors.warmUpTime = 'Warm Up Time must be before the Start Time';
      }
    }
  }

  return errors;
};

const SessionsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SessionsDetailValidation;