import React from 'react';
import { Fragment } from 'react';
import ListNamesDropdown from './ListNamesDropdown';
import Headings from '../headings/Headings';

const ListTitle = ({listTitle, listNames, onListNamesChanged, onBackToGrid, excludeBar}) => {
  return (
    <Fragment>
      {listTitle && 
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3 excludeBar={excludeBar}>{listTitle}</Headings.H3>
          </div>
        </div>}
        
      {((listNames?.length > 0 && onListNamesChanged) || onBackToGrid) &&
        <div className="row">
          <div className="col-md-8 sm-hidden xs-hidden">
            &nbsp;
          </div>
          <div className="col-md-4 col-xs-12 usas-extra-bottom-margin">
            {listNames?.length > 0 && onListNamesChanged && 
              <ListNamesDropdown listNames={listNames} onListNamesChanged={onListNamesChanged} />}
          </div>
        </div>}
    </Fragment>
  );
};

export default ListTitle;