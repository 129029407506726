import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';
import Constants from '../../../common/utils/Constants';

export const INITIAL_STATE = {
  organizationId: Constants.DEFAULT_ID,
  isArrayLoading: false,
  isArrayLoaded: false,
  arrayData: [],
  options: [],
  currentAndFutureOptions: [],
  message: ''
};

const getReportPeriodsSeasonByOrganizationId = (organizationId, defaultOption, reverseDataOrder, state, setState) => {
  if (state.isArrayLoading !== true && organizationId > 0 && !state.message) {
    let newState = {
      ...state,
      organizationId,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const organizationIdForUrl = organizationId ? encodeURIComponent(organizationId) : 'NaN';
    const url = `/ReportPeriod/Organization/Season/LowestChildren/${organizationIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          if (reverseDataOrder === true) {
            data.reverse();
          }

          const options = data.map((o) => { return { id: o.reportPeriodId, name: o.periodName }; });
          const today = new Date();
          const currentAndFutureOptions = data.filter((x) => new Date(x.endDate) > today).map((o) => { return { id: o.reportPeriodId, name: o.periodName }; });
          
          if (defaultOption === true) {
            options.unshift({ id: Constants.DEFAULT_ID, name: '--' });
            currentAndFutureOptions.unshift({ id: Constants.DEFAULT_ID, name: '--' })
          }
          
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: data,
            options,
            currentAndFutureOptions,
            message: '',
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

const ReportPeriodSeasonData = { INITIAL_STATE, getReportPeriodsSeasonByOrganizationId };

export default ReportPeriodSeasonData;