import React, { Fragment } from 'react';

import useListNamesDropdown from './UseListNamesDropdown';

import Dropdown from '../dropdowns/Dropdown';

const ListNamesDropdown = ({ listNames, onListNamesChanged }) => {
  const {value, options, onChange} = useListNamesDropdown(listNames, onListNamesChanged);

  if (Array.isArray(listNames) && listNames.length > 1) {
    return (
      <Dropdown
        label="Filter report list by"
        name="listNames"
        options={options}
        value={value}
        onChange={onChange}
        areValuesIntegers={false}
        defaultValue=""
        notSelectedLabel="List all reports" />
   );
  }

  return <Fragment />;
};

export default ListNamesDropdown;