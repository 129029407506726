import useSwimmingWorldOptInModal from './UseSwimmingWorldOptInModal';

import ActionIntraPageButton from '../../../buttons/ActionIntraPageButton';
import PopUpModal from '../../../dialogs/PopUpModal';

/**
 * Modal that is displayed after a payment completes and the swimmingWorldOptIn field was set to true
 */
const SwimmingWorldOptInModal = ({ swimmingWorldOptIn }) => {
  const {
    modalState,
    goToSwimmingWorldSite
  } = useSwimmingWorldOptInModal(swimmingWorldOptIn);

  return (
    <PopUpModal
      widthPct={90}
      maxWidth={720}
      title={'25% off a digital subscription to Swimming World Magazine'}
      displayPopUp={modalState.displayPopUp}>
      <div className="row">
        <div className="col-xs-12">
          <p>You selected “yes” to get exclusive access to a 25% off digital subscription to Swimming World Magazine.</p>
          <p>Please click the &apos;Subscribe to Swimming World&apos; button below to complete the subscription process.</p>
        </div>
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={() => goToSwimmingWorldSite()}>Subscribe to Swimming World</ActionIntraPageButton>
        </div>
      </div>
    </PopUpModal>
  );
};

export default SwimmingWorldOptInModal;