import React, { Fragment } from 'react';

import style from './Button.module.css';
import useButton from './UseButton';

const ActionButton = ({ children, type, onClick, className, isPrimary }) => {
  const {buttonType, actionClassAttribute, isReadWrite} = useButton(type, className, style, isPrimary);
  
  return isReadWrite === true
    ? (
      <button
        className={actionClassAttribute}
        onClick={onClick}
        type={buttonType}>
        {children}
      </button>
    )
    : <Fragment />;
}

export default ActionButton;