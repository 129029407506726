import useCrossUINavigation from '../../state/crossUINavigation/UseCrossUINavigation';
import useMemberPersistentStorage from '../../state/memberPersistentStorage/UseMemberPersistentStorage';

import Constants from '../../utils/Constants';

const useShield = () => {
  const { cookies, resetPersistentStorage } = useMemberPersistentStorage();
  const { navigateToLandingPage } = useCrossUINavigation();

  const onClick = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const cookiesObj = cookies[Constants.MEMBER_STORAGE_NAME]
    if (cookiesObj) {
      resetPersistentStorage();
    }

    navigateToLandingPage();
  };

  return { onClick };
};

export default useShield;