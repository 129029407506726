import { useContext } from 'react';

import { LeftNavModalStateContext } from './LeftNavModalContextProvider';

const useLeftNavModalData = () => {
  const [leftNavModalState, setLeftNavModalState] = useContext(LeftNavModalStateContext);

  const onLeftNavModalCanceled = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setLeftNavModalState({
      ...leftNavModalState,
      displayPopUp: false,
      path: ''
    });
  }

  return {
    leftNavModalState,
    setLeftNavModalState,
    onLeftNavModalCanceled
  }
}


export default useLeftNavModalData;