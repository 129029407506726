import useDataHubSwimmerIconPic from './UseDataHubSwimmerIconPic';

import style from './DataHubSwimmerIconPic.module.css';

const DataHubSwimmerIconPic = ({ size }) => {
  const { fillColor } = useDataHubSwimmerIconPic();
  const upperSize = size ? size.toUpperCase() : '';

  return (
    <div className={upperSize === 'SMALL' ? style.SmallDataHubSwimmerIconPic : style.DataHubSwimmerIconPic}>
      <svg height={upperSize === 'SMALL' ? '50' : '100'} width={upperSize === 'SMALL' ? '50' : '100'}>
        <rect x="0" y="0" width="100%" height="100%" fill={fillColor} />
          <svg x="17%" y="17%" width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill={'#ffffff'}>
            <path d="M189.6 310.6c3.5 3.3 15.3 9.4 34.4 9.4s30.9-6.2 34.4-9.4c16-14.8 34.5-22.6 53.5-22.6h16.3c19 0 37.5 7.8 53.5 22.6 3.5 3.3 15.3 9.4 34.4 9.4s30.9-6.2 34.4-9.4c14.9-13.7 31.9-21.1 49.4-22.2l-112.8-80.6 18-12.9c3.6-2.6 8.3-3.5 12.6-2.6l100.4 21.5c25.9 5.5 51.4-11 57-36.9 5.6-25.9-11-51.4-36.9-57L437.7 98.5c-30.7-6.6-63 .1-88.6 18.4l-80 57.2c-10.4 7.4-19.4 16.4-26.7 26.9L173.8 299c5.5 3.2 10.8 6.9 15.9 11.6zM624 352h-16c-26 0-45.8-8.4-56.1-17.9-8.9-8.2-19.7-14.1-31.8-14.1h-16.3c-12.1 0-22.9 5.9-31.8 14.1C461.8 343.6 442 352 416 352s-45.8-8.4-56.1-17.9c-8.9-8.2-19.7-14.1-31.8-14.1h-16.3c-12.1 0-22.9 5.9-31.8 14.1C269.8 343.6 250 352 224 352s-45.8-8.4-56.1-17.9c-8.9-8.2-19.7-14.1-31.8-14.1h-16.3c-12.1 0-22.9 5.9-31.8 14.1C77.8 343.6 58 352 32 352H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h16c38.6 0 72.7-12.2 96-31.8 23.3 19.7 57.4 31.8 96 31.8s72.7-12.2 96-31.8c23.3 19.7 57.4 31.8 96 31.8s72.7-12.2 96-31.8c23.3 19.7 57.4 31.8 96 31.8h16c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-512-96c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80z" />
          </svg>
      </svg>
    </div>
  );
};

export default DataHubSwimmerIconPic;