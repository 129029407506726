import { useContext } from 'react';
import { DateTimeStateContext } from './DateTimeContextProvider';
import DateTimeData from './DateTimeData';

const dateTimeRegex = /^([0-1][0-9])\/([0-3][0-9])\/([0-9]{4}) ([0-2][0-9]:[0-5][0-9])?$/;
const timeRegex = /^([0-9][0-9]):([0-9][0-9])?$/;

const useDateTimeData = () => {
  const [dateTimeState, setDateTimeState] = useContext(DateTimeStateContext);

  const getDateTimeData = () => {
    DateTimeData.getDateTimeData(dateTimeState, setDateTimeState);
  };

  const breakUpDateTime = (value) => {
    if (value) {
      const match = value.match(dateTimeRegex);
      if (match) {
        return {
          month: match[1],
          day: match[2],
          year: match[3],
          time: `${match[4]}`,
          value: `${match[1]}/${match[2]}/${match[3]} ${match[4]}`
        };
      }
    }
    return {
      month: '00',
      day: '00',
      year: '0000',
      time: '99:99',
      value: '00/00/0000 99:99'
    };
  };

  const breakUpDate = (value) => {
    const values = breakUpDateTime(value);
    return {
      month: values.month,
      day: values.day,
      year: values.year,
      value: `${values.month}/${values.day}/${values.year}`
    };
  };

  const breakUpTime = (value) => {
    if (value) {
      const match = value.match(timeRegex);
      if (match) {
        return {
          hour: match[1],
          minute: match[2],
          value: `${match[1]}:${match[2]}`
        };
      }
    }
    return {
      hour: '99',
      minute: '99',
      value: '99:99'
    };
  };

  return {
    dateTimeState,
    getDateTimeData,
    breakUpDateTime,
    breakUpDate,
    breakUpTime,
    getYearsAsOptions: DateTimeData.getYearsAsOptions
  };
};

export default useDateTimeData;