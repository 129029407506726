import React, {createContext, useState} from 'react';

import EnvironmentVariableData from './EnvironmentVariableData';

export const EnvironmentVariableStateContext = createContext();

const EnvironmentVariableContextProvider = ({children}) => {
  const stateHook = useState(EnvironmentVariableData.INITIAL_STATE);

  return (
    <EnvironmentVariableStateContext.Provider value={stateHook}>
      {children}
    </EnvironmentVariableStateContext.Provider>
  );
};

export default EnvironmentVariableContextProvider;