import { Fragment } from "react";

import useSessionEndingOneTimePassword from "./UseSessionEndingOneTimePassword";

import PopUpModal from "../../../../../dialogs/PopUpModal";
import SecondaryButton from "../../../../../buttons/SecondaryButton";
import OneTimePasswordForm from "../../../../../forms/login/oneTimePassword/OneTimePasswordForm";

import Constants from "../../../../../../utils/Constants";

const SessionEndingOneTimePassword = ({ popupState, onPopupClosed }) => {
  const {
    isLoading,
    device,
    loginErrorPopupState,
    onCloseLoginErrorPopupClicked,
    onSubmitFormCallback
  } = useSessionEndingOneTimePassword(popupState, onPopupClosed);

  return (
    <Fragment>
      <div className={'col-xs-12 usas-extra-bottom-margin'}>
        <b>One Time Code</b>
        <p>Look for the one-time code that was sent to {device} and enter it here.</p>
        <OneTimePasswordForm
          submitButtonText={'Submit'}
          secondaryButtonText={'Cancel'}
          onSubmitFormCallback={onSubmitFormCallback}
          onSecondaryButtonClicked={() => onPopupClosed(true)} />
      </div>
      <PopUpModal
        widthPct={60}
        maxWidth={720}
        title={'Login Error'}
        displayPopUp={loginErrorPopupState.displayPopup === true}>
        <div className="row">
          <div className="col-xs-12">
            <p>{loginErrorPopupState.errorMessage || 'An error occurred.'}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type={'button'} onClick={onCloseLoginErrorPopupClicked}>Close</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default SessionEndingOneTimePassword;