import { Fragment } from 'react';

import useToLandingPage from './UseToLandingPage';


const ToLandingPage = () => {
  useToLandingPage();

  return <Fragment />;
};

export default ToLandingPage;