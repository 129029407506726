import React from 'react';

import { faCaretUp, faCaretDown, faSort } from '@fortawesome/free-solid-svg-icons';

import SGConstants from "../../utils/SGConstants";

import Icon from "../icons/Icon";

const SGColumnIcon = ({ sortableGridState, columnField, columnLabel, sortType }) => {
  if (sortableGridState.targetField === columnField) {
    if (sortableGridState.sortDirection === SGConstants.SORT_DIRECTION_ASCENDING) {
      return <Icon tooltip={`Sort ${columnLabel} By ${sortType}`} iconObj={faCaretUp} />;
    } else if (sortableGridState.sortDirection === SGConstants.SORT_DIRECTION_DESCENDING) {
      return <Icon tooltip={`Sort ${columnLabel} By ${sortType}`} iconObj={faCaretDown} />;
    }
  }

  return <Icon tooltip={`Sort ${columnLabel} By ${sortType}`} iconObj={faSort} />;
};

const SGColumnHeader = ({ sortableGridState, columnLabel, columnField, sortType, onColumnClicked,
  className = undefined, style = undefined, colSpan = undefined }) => (
  <th
    className={className ? className : undefined}
    style={style ? style : undefined}
    colSpan={colSpan ? colSpan : '1'}
    onClick={() => onColumnClicked(columnField, sortType)}>
    {columnLabel} <SGColumnIcon sortableGridState={sortableGridState} columnField={columnField} columnLabel={columnLabel} sortType={sortType} />
  </th>
);

export default SGColumnHeader;