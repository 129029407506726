import React, { createContext, useState } from 'react';

import SecurityData from './SecurityData';

export const SecurityStateContext = createContext();
export const CurrentNavStateContext = createContext();
export const ContextSecurityStateContext = createContext();
export const NavRoutesStateContext = createContext();

const SecurityContextProvider = ({ children }) => {
  const securityHook = useState(SecurityData.SECURITY_INITIAL_STATE);
  const currentNavHook = useState({});
  const contextSecurityHook = useState(SecurityData.CONTEXT_SECURITY_INITIAL_STATE);
  const navRoutesSecurityHook = useState(SecurityData.NAV_ROUTES_INITIAL_STATE);

  return (
    <SecurityStateContext.Provider value={securityHook}>
      <CurrentNavStateContext.Provider value={currentNavHook}>
          <ContextSecurityStateContext.Provider value={contextSecurityHook}>
            <NavRoutesStateContext.Provider value={navRoutesSecurityHook}>
              {children}
            </NavRoutesStateContext.Provider>
          </ContextSecurityStateContext.Provider>
      </CurrentNavStateContext.Provider>
    </SecurityStateContext.Provider>
  );
};

export default SecurityContextProvider;