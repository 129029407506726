export const convertNumberToCurrencyString = (number) => {
  if (typeof number === 'number' || !isNaN(parseFloat(number))) {
    return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
  } else {
    return number;
  }
};

export const validateCurrencyValue = (currencyValue) => {
  if (!isNaN(currencyValue) && currencyValue !== '') {
    const result = parseFloat(Math.floor(currencyValue * 100) / 100).toFixed(2);
    //Prevent 'e+22' or 'Infinity' from showing
    if (result > 999999999999999) {
      return '';
    }
    //Keep formatting of numbers ending in '.' or '.0' or '.X0' 
    return currencyValue.charAt(currencyValue.length - 1) === '.' ||
      (currencyValue.charAt(currencyValue.length - 2) === '.') || (currencyValue.charAt(currencyValue.length - 3) === '.')
      ? currencyValue : Math.abs(result);
  }

  return '';
};