import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const SearchUserAccountPersonData = (firstName, lastName, birthDate, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/Person/query?firstName=${firstName}&lastName=${lastName}&birthDate=${birthDate}`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          //TODO: eliminate memberId, birthDate, genderIdentityTypeId, competitionGenderTypeId from results 
          //returned from API.  This extra data is a security risk.
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: arrayData.map(x => {return {...x, memberId: undefined}}),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default SearchUserAccountPersonData;