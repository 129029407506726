import { useContext } from 'react';

import { OtsMeetSessionsStateContext } from './OtsMeetSessionsContextProvider';

import OtsMeetSessionsData from './OtsMeetSessionsData';

const useOtsMeetSessionsData = () => {
  const [otsMeetSessionsState, setOtsMeetSessionsState] = useContext(OtsMeetSessionsStateContext);

  const getMeetSessions = (meetId) => OtsMeetSessionsData.getMeetSessionData(meetId, otsMeetSessionsState, setOtsMeetSessionsState);
  const getMeetSessionsSetIsQualifyingForAllSessionsInMeet = (meetId) => OtsMeetSessionsData.getMeetSessionsSetIsQualifyingForAllSessionsInMeetData(meetId, otsMeetSessionsState, setOtsMeetSessionsState);
  const postMeetSession = (meetSessionObj) => OtsMeetSessionsData.postMeetSessionData(meetSessionObj, otsMeetSessionsState, setOtsMeetSessionsState);
  const putMeetSession = (meetSessionId, meetSessionObj) => OtsMeetSessionsData.putMeetSessionData(meetSessionId, meetSessionObj, otsMeetSessionsState, setOtsMeetSessionsState);
  const deleteMeetSession = (meetSessionId) => OtsMeetSessionsData.deleteMeetSessionData(meetSessionId, otsMeetSessionsState, setOtsMeetSessionsState);

  const clearOtsMeetSessionsObjData = () => {
    setOtsMeetSessionsState({
      ...otsMeetSessionsState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearOtsMeetSessionsArrayData = () => {
    setOtsMeetSessionsState({
      ...otsMeetSessionsState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  const confirmOtsMeetSessionSave = () => {
    setOtsMeetSessionsState({
      ...otsMeetSessionsState,
      isSaved: false
    });
  };

  return {
    otsMeetSessionsState,
    getMeetSessions,
    getMeetSessionsSetIsQualifyingForAllSessionsInMeet,
    postMeetSession,
    putMeetSession,
    deleteMeetSession,
    clearOtsMeetSessionsObjData,
    clearOtsMeetSessionsArrayData,
    confirmOtsMeetSessionSave
  };
};

export default useOtsMeetSessionsData;