import { Link } from '../wrappers/ReactRouterDom';

import global from './GlobalStyle.module.css';

const PageNotFound = () => {

  return (
    <div className={global.CenterText}>
      <img src={`${import.meta.env.VITE_CDN_URL}/Usas404.jpg`} alt="Page not found" />
      <p className="CenterText">
        <Link to="/">Return to Home </Link>
      </p>
    </div>
  );
};

export default PageNotFound;