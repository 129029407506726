import { formatDate } from '../../../common/utils/DateFunctions';
import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

const GetMeetSessionData = (meetId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const meetIdForUrl = meetId ? encodeURIComponent(meetId) : 'NaN';
    const url = `/MeetSession/Meet/${meetIdForUrl}`;
    MeetHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData,
            options: arrayData.map((session) => { return { id: session.meetSessionId, name: `${session.sessionOrderId || ''} (${session.sessionType?.typeName || ''} ${session.sessionDate ? formatDate(session.sessionDate) : ''})` } }),
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
};

export default GetMeetSessionData;