import useCountryData from "../../../state/country/UseCountryData";

const useCountryCombobox = () => {
  const { getCountryState } = useCountryData();
  const countryData = getCountryState();

  function getCountryNameById(countryId) {
    for (const country of countryData) {
      if (countryId === country.id) {
        return country.name;
      }
    }

    return '';
  };

  return { countryData, getCountryNameById };
};

export default useCountryCombobox;