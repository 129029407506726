import { useNavigate } from '../../wrappers/ReactRouterDom';

const useTabList = (tabsState, selectedTabId, onTabSelected) => {
  const navigate = useNavigate();
  const sortedTabState = Array.isArray(tabsState) && tabsState.length > 0
    ? tabsState.sort((a, b) => {
      if (a.displayOrder < b.displayOrder) {
        return -1;
      }
      if (a.displayOrder > b.displayOrder) {
        return 1;
      }
      return 0;
    }) : [];

  const onTabClicked = (tabId) => {
    const tId = Number(tabId);
    if (selectedTabId !== tId) {
      onTabSelected(tId);

      const tabData = tabsState.find(x => x.taxonomyId === tId);
      if (tabData && Array.isArray(tabData.children) && tabData.children.length > 0 && tabData.children.linkUrl) {
        navigate(tabData.children.linkUrl);
      }
    }
  };

  return { sortedTabState, onTabClicked };
};

export default useTabList;
