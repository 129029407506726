import { useEffect, useState } from "react";
import { DEFAULT_ID } from "../../../../utils/Constants";

const FOUNDATION_BLURB = 'Established in 2004, the USA Swimming Foundation works to strengthen our sport by Saving Lives, Building Champions, and Impacting Communities. Whether providing grant funding to members of our lesson provider network, supporting the dreams of National Team members, or growing the sport in traditionally underserved populations, the Foundation is proud to serve as the philanthropic arm of both USA Swimming and U.S. Masters Swimming. Swimming is the only sport that can save a life, and our work is critical towards making the sport an equitable space.';
const FOUNDATION_TOOL_TIP = 'Donating $50 or more qualifies you to join the USA Swimming Foundation’s Supporters Club. You’ll receive exclusive benefits and USA Swimming swag.';

const DROPDOWN_OPTIONS = [
  { id: DEFAULT_ID, name: '$0.00', value: 0.00 },
  { id: 1, name: '$10.00', value: 10.00 },
  { id: 2, name: '$20.00', value: 20.00 },
  { id: 3, name: '$50.00', value: 50.00 },
  { id: 9, name: 'Other', value: null }
];

const INITIAL_STATE = {
  dropdownId: DROPDOWN_OPTIONS[0].id,
  dropdownName: DROPDOWN_OPTIONS[0].name,
  dropdownValue: DROPDOWN_OPTIONS[0].value,
  displayTextInput: false
};

const useFoundationDonation = (foundationDonation, onValueChanged) => {
  const [state, setState] = useState(INITIAL_STATE);

  const onFoundationDonationChanged = (newValue) => {
    const foundationDonation = parseFloat(newValue);

    onValueChanged('foundationDonation', foundationDonation >= 0.00 ? foundationDonation : 0.00);
  };

  const onFoundationDonationBlurred = (value) => {
    const foundationDonation = parseFloat(value);

    onValueChanged('foundationDonation', foundationDonation >= 0.00 ? foundationDonation.toFixed(2) : 0.00);
  };

  const onDropdownValueChanged = (id) => {
    const newDropdownValue = DROPDOWN_OPTIONS.find((x) => x.id === id) || DROPDOWN_OPTIONS[0];

    setState({
      ...state,
      dropdownId: newDropdownValue.id,
      dropdownName: newDropdownValue.name,
      dropdownValue: newDropdownValue.value,
      displayTextInput: newDropdownValue.value === null ? true : false
    });

    onFoundationDonationBlurred(newDropdownValue.value === null ? '0.00' : newDropdownValue.value);
  };

  useEffect(() => {
    if (foundationDonation ?? false) {
      const donationValue = parseFloat(foundationDonation);
      const initialDropdownValue = DROPDOWN_OPTIONS.find((x) => x.value === donationValue) || DROPDOWN_OPTIONS[DROPDOWN_OPTIONS.length - 1];

      setState({
        ...state,
        dropdownId: initialDropdownValue.id,
        dropdownName: initialDropdownValue.name,
        dropdownValue: initialDropdownValue.value,
        displayTextInput: initialDropdownValue.value === null ? true : false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    DROPDOWN_OPTIONS,
    FOUNDATION_BLURB,
    FOUNDATION_TOOL_TIP,
    state,
    onFoundationDonationChanged,
    onFoundationDonationBlurred,
    onDropdownValueChanged
  };
};

export default useFoundationDonation;