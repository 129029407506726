import { useContext } from 'react';

import { PersonOrgRoleStateContext } from './PersonOrgRoleContextProvider';
import PersonOrgRoleData from './PersonOrgRoleData';

const usePersonOrgRoleData = () => {
  const [personOrgRoleState, setPersonOrgRoleState] = useContext(PersonOrgRoleStateContext);

  const getPersonOrgRoles = (personId) => PersonOrgRoleData.getPersonOrgRolesData(personId, personOrgRoleState, setPersonOrgRoleState);
  const getPersonOrgRoleSummary = (personId) => PersonOrgRoleData.getPersonOrgRoleSummaryData(personId, personOrgRoleState, setPersonOrgRoleState);
  const getPersonOrgRoleFiltered = (personId, orgRoleId) => PersonOrgRoleData.getPersonOrgRoleFilteredData(personId, orgRoleId, personOrgRoleState, setPersonOrgRoleState);
  const getPersonOrgRoleByRoleGroup = (personId, roleGroupId) => PersonOrgRoleData.getPersonOrgRoleByRoleGroupData(personId, roleGroupId, personOrgRoleState, setPersonOrgRoleState);
  const putPersonOrgRole = (personId, personOrgRoleId, orgRoleObj) => PersonOrgRoleData.putPersonOrgRoleData(personId, personOrgRoleId, orgRoleObj, personOrgRoleState, setPersonOrgRoleState);
  const postPersonOrgRole = (personId, orgRoleObj) => PersonOrgRoleData.postPersonOrgRoleData(personId, orgRoleObj, personOrgRoleState, setPersonOrgRoleState);
  const deletePersonOrgRole = (personId, personOrgRoleId) => PersonOrgRoleData.deletePersonOrgRoleData(personId, personOrgRoleId, personOrgRoleState, setPersonOrgRoleState);

  const clearObjData = () => {
    setPersonOrgRoleState({
      ...personOrgRoleState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearArrayData = () => {
    setPersonOrgRoleState({
      ...personOrgRoleState,
      isArrayLoading: false,
      isArrayLoaded: false,
      isSaving: false,
      isSaved: false,
      arrayData: [],
      message: ''
    });
  };

  return {
    personOrgRoleState,
    clearObjData,
    clearArrayData,
    getPersonOrgRoles,
    getPersonOrgRoleSummary,
    getPersonOrgRoleFiltered,
    getPersonOrgRoleByRoleGroup,
    putPersonOrgRole,
    postPersonOrgRole,
    deletePersonOrgRole
  };
};

export default usePersonOrgRoleData;