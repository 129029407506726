import { COLLECTION_NAME_FOUNDATION_DONATION, COLLECTION_NAME_TECHNOLOGY_FEE } from "./PaymentsConstants";

/**
 * Calculates the technology fee
 * @param {Number} totalAmount the current total amount, minus the tech fee
 * @param {Number} technologyFeePercent number provided by the db
 * @param {Number} technologyFeePlus number provided by the db
 * @returns technologyFee 
 */
export const calculateTechFee = (amount, technologyFeePercent, technologyFeePlus) => {
  let technologyFee = 0;

  if (amount > 0) {
    technologyFee = (amount / (1 - technologyFeePercent) * technologyFeePercent + technologyFeePlus + 0.01);
  }

  return technologyFee;
};

/**
 * Navigates through the cart items array and toggles isSelected for the target item
 * @param {Array} cartItems cart items already formatted for the cart item grid
 * @param {String} cartItemId id of the cart item being selected or unselected
 * @returns newItems formatted for cart item grid, subTotal of all selected cart items
 */
export const toggleIsSelectedRecurse = (cartItems, cartItemId) => {
  const newItems = [];
  let subTotal = 0;

  if (Array.isArray(cartItems)) {
    cartItems.forEach(item => {
      const isSelected = item.cartItemId === cartItemId ? !item.isSelected : item.isSelected;

      if (item.children?.length > 0) {
        const [children, itemPrice] = toggleIsSelectedRecurse(item.children, cartItemId);
        if (isSelected) {
          subTotal += itemPrice;
        }

        newItems.push({
          ...item,
          itemPrice,
          isSelected,
          children
        });
      } else {
        if (isSelected) {
          subTotal += item.itemPrice;
        }

        newItems.push({
          ...item,
          itemPrice: item.itemPrice,
          isSelected
        });
      }
    });
  }

  return [newItems, subTotal];
};

/**
 * Creates cart item grid data for initial page render
 * @param {Array} itemsData cart items provided by the db
 * @returns newItems formatted for cart item grid, subTotal of all cart items
 */
export const createInitiateCartItemsGridRecurse = (itemsData) => {
  const newItems = [];
  let subTotal = 0;

  if (Array.isArray(itemsData)) {
    itemsData.forEach(item => {
      if (item.itemDescription !== COLLECTION_NAME_FOUNDATION_DONATION
        && item.itemDescription !== COLLECTION_NAME_TECHNOLOGY_FEE) {
        if (item.children?.length > 0) {
          const [children, itemPrice] = createInitiateCartItemsGridRecurse(item.children);
          subTotal += itemPrice;
          newItems.push({
            ...item,
            itemPrice,
            isSelected: true,
            children
          });
        } else {
          subTotal += item.itemPrice;
          newItems.push({
            ...item,
            isSelected: true,
            children: []
          });
        }
      }
    });
  }

  return [newItems, subTotal];
};

/**
 * Creates cart item grid data for page revisit/reload
 * @param {Array} itemsData cart items provided by the db
 * @param {Array} unselectedCartItemIds cart item ids that were previously unselected by the user
 * @returns newItems formatted for the cart item grid, subTotal of all selected cart items
 */
export const createUpdatedCartItemsGridRecurse = (itemsData, unselectedCartItemIds) => {
  const newItems = [];
  let subTotal = 0;

  if (Array.isArray(itemsData) && Array.isArray(unselectedCartItemIds)) {
    itemsData.forEach(item => {
      if (item.itemDescription !== COLLECTION_NAME_FOUNDATION_DONATION
        && item.itemDescription !== COLLECTION_NAME_TECHNOLOGY_FEE) {
        if (item.children?.length > 0) {
          const [children, itemPrice] = createUpdatedCartItemsGridRecurse(item.children, unselectedCartItemIds);
          const isSelected = unselectedCartItemIds.find((value) => value === item.cartItemId) === undefined ? true : false;

          if (isSelected === true) {
            subTotal += itemPrice;
          }

          newItems.push({
            ...item,
            itemPrice,
            isSelected,
            children
          });
        } else {
          const isSelected = unselectedCartItemIds.find((value) => value === item.cartItemId) === undefined ? true : false;

          if (isSelected === true) {
            subTotal += item.itemPrice;
          }

          newItems.push({
            ...item,
            isSelected,
            children: []
          });
        }
      }
    });
  }

  return [newItems, subTotal];
};

const PaymentsUtils = {
  calculateTechFee,
  toggleIsSelectedRecurse,
  createInitiateCartItemsGridRecurse,
  createUpdatedCartItemsGridRecurse
};

export default PaymentsUtils;