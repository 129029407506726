import React, { Fragment } from 'react';

import useLargeContextBasedNav from './UseLargeContextBasedNav';

import PillList from '../pills_new/PillList';
import TabList from '../tabs/TabList';

const LargeContextBasedNav = ({ taxonomyName, homeRoute, renderTabsOnly = false, renderPillsOnly = false, selectTab = true }) => {
  const { tabsState, pillsState, selectedTabId, onTabSelected } 
    = useLargeContextBasedNav(taxonomyName, homeRoute, selectTab, renderPillsOnly);

  return (
    <Fragment>
      {(renderTabsOnly === true || tabsState?.length > 0)
        && <TabList tabsState={tabsState} selectedTabId={selectedTabId} onTabSelected={onTabSelected} />}
      {pillsState?.length > 0 && <PillList pillsState={pillsState} />}
    </Fragment>
  );
};

export default LargeContextBasedNav;