import { useEffect } from 'react';

import useTimeData from '../../../state/time/UseTimeData';

const useSessionDropdown = () => {
  const { sessionState, getSession } = useTimeData();

  useEffect(() => {
    if (sessionState.isArrayLoading !== true && sessionState.isArrayLoaded !== true) {
      getSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    sessionState
  };
};

export default useSessionDropdown;