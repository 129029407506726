import useMeetSoftwareDropdown from './UseMeetSoftwareDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const MeetSoftwareDropdown = ({ label, name, value, error, message, onChange }) => {
  const { meetSoftwareState } = useMeetSoftwareDropdown();

  return meetSoftwareState.message
    ? <span className={global.LoadingMsg}>{meetSoftwareState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={meetSoftwareState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={meetSoftwareState.isLoading}
      />
    );
};

export default MeetSoftwareDropdown;