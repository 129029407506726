import useInput from './UseInput';
import useCalendarDateInput from './UseCalendarDateInput';

import ReadOnly from '../readOnly/ReadOnly';

import global from '../GlobalStyle.module.css';

const CalendarDateInput = ({ className, label, name, value, error, message, onChange }) => {
  const { fullClassAttribute, isReadWrite } = useInput(className, global, error);
  const { formattedDateValue, onDateValueChanged } = useCalendarDateInput(value, onChange);

  if (isReadWrite === true) {
    return (
      <div className={global.ComponentFlex}>
        <label className={global.UsasLabel} htmlFor={name}>{label}</label>
        <div>
          <input
            className={fullClassAttribute}
            type={'date'}
            value={formattedDateValue}
            id={name}
            name={name}
            onChange={(e) => { onDateValueChanged(e.target.value); }} />
        </div>
        {error && <p className={global.ErrorMessage}>{message}</p>}
      </div>
    );
  } else {
    return (
      <ReadOnly
        label={label}
        name={name}
        value={value} />
    );
  }
};

export default CalendarDateInput;