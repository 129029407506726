import React, { Fragment } from 'react';
import {VisuallyHidden} from "@reach/visually-hidden";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({tooltip, iconObj}) => (
  <Fragment>
    <VisuallyHidden>{tooltip}</VisuallyHidden>
    <FontAwesomeIcon icon={iconObj} title={tooltip} />
  </Fragment>
);

export default Icon;