import Constants from '../../../../../common/utils/Constants';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../../common/utils/HttpHelper';
import { isValid5DigitZip, isValid9DigitZip, isValidAddressLine, isValidCanadianZip, isValidEmail, isValidMemberId, isValidName, validateAddress } from '../../../../../common/utils/validation';

export const localValidate = async (formState) => {
  let errors = {};

  //Meet Director
  if (formState.entryContactMemberId.trim() === '') {
    errors.entryContactMemberId = 'Entry Contact Member Id is required';
  } else if (!isValidMemberId(formState.entryContactMemberId.trim())) {
    errors.entryContactMemberId = 'Entry Contact Member Id must be 14 alphanumeric characters';
  }

  //Payment Contact Name
  if (formState.paymentContactName.trim() === '') {
    errors.paymentContactName = 'Payment Contact Name is required';
  } else if (!isValidName(formState.paymentContactName.trim())) {
    errors.paymentContactName = 'Payment Contact Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  //Email
  if (formState.emailAddress.trim() === '') {
    errors.emailAddress = 'Payment Contact Email is required';
  } else if (!isValidEmail(formState.emailAddress.trim())) {
    errors.emailAddress = 'Payment Contact Email must be in a valid format';
  } else if (formState.emailAddress.trim().length > 512) {
    errors.emailAddress = 'Payment Contact Email cannot exceed 512 characters'
  }

  //To Whom To Make Out Payment
  if (formState.paymentRecipient.trim() === '') {
    errors.paymentRecipient = 'To Whom To Make Out Payment is required';
  } else if (formState.paymentRecipient.trim().length > 200) {
    errors.paymentRecipient = 'To Whom To Make Out Payment cannot exceed 200 characters';
  }

  let addressError = false;

  //Country
  if (formState.country === Constants.DEFAULT_ID || formState.country === '') {
    addressError = true;
    errors.country = 'Country is required';
  }

  //Address Line 1
  if (formState.address1.trim() === '') {
    addressError = true;
    errors.address1 = 'Address Line 1 is required';
  }
  else if (!isValidAddressLine(formState.address1.trim())) {
    addressError = true;
    errors.address1 = 'Address Line 1 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  //Address Line 2
  if (formState.address2.trim() !== '') {
    if (!isValidAddressLine(formState.address2.trim())) {
      addressError = true;
      errors.address2 = 'Address Line 2 cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
    }
  }

  //City
  if (formState.city.trim() === '') {
    addressError = true;
    errors.city = 'City is required';
  }
  else if (!isValidAddressLine(formState.city.trim())) {
    addressError = true;
    errors.city = 'City cannot exceed 100 characters. Letters, numbers, number signs, spaces, periods, dashes, slashes, and apostrophes are allowed.';
  }

  //State
  // formState.stateCode !== '  ' is there for non-NA countries -TL
  if (formState.state === '' && formState.stateCode !== '  ') {
    addressError = true;
    errors.state = 'State is required';
  } else if (formState.stateCode === Constants.DEFAULT_ID) {
    addressError = true;
    errors.state = 'A valid State is required';
  }

  //Zip Code
  if (formState.postalCode.trim() === '') {
    addressError = true;
    errors.postalCode = 'Zip Code is required';
  } else if ((formState.country === 'USA') && !isValid5DigitZip(formState.postalCode.trim()) && !isValid9DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'USA Zip Code must be 5 digits, or 9 digits in XXXXX-XXXX format';
  } else if ((formState.country === 'CAN') && !isValidCanadianZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Canadian Zip Code must be valid and in A1A 1A1 format';
  } else if ((formState.country === 'MEX') && !isValid5DigitZip(formState.postalCode.trim())) {
    addressError = true;
    errors.postalCode = 'Mexican Zip Code must be 5 digits';
  }

  if (addressError === false
    && formState.showedAddressSuggestion === false) {
    formState.validatedAddress = await validateAddress(formState.address1?.trim(), formState.address2?.trim(), formState.city?.trim(), formState.stateCode, formState.postalCode?.trim());
    if (formState.validatedAddress.isValid === true
      && (
        formState.address1?.trim() !== formState.validatedAddress.address1?.trim()
        || formState.address2?.trim() !== formState.validatedAddress.address2?.trim()
        || formState.city?.trim() !== formState.validatedAddress.city?.trim()
        || formState.stateCode !== formState.validatedAddress.stateCode
        || formState.postalCode?.trim() !== formState.validatedAddress.postalCode?.trim()
      )
    ) {
      if (formState.address1?.trim() !== formState.validatedAddress.address1?.trim()) {
        errors.address1 = 'See Address Suggestion';
      }
      if (formState.address2?.trim() !== formState.validatedAddress.address2?.trim()) {
        errors.address2 = 'See Address Suggestion';
      }
      if (formState.city?.trim() !== formState.validatedAddress.city?.trim()) {
        errors.city = 'See Address Suggestion';
      }
      if (formState.stateCode !== formState.validatedAddress.stateCode) {
        errors.stateCode = 'See Address Suggestion';
      }
      if (formState.postalCode?.trim() !== formState.validatedAddress.postalCode?.trim()) {
        errors.postalCode = 'See Address Suggestion';
      }
    }
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  //Entry Contact
  const entryContactMemberIdForUrl = formState.entryContactMemberId ? encodeURIComponent(formState.entryContactMemberId) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${entryContactMemberIdForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        if (objData.isOrgRoleDurationActive === false || !(objData.personId)) {
          errors.entryContactMemberId = "Entry Contact Member Id does not have an active member registration";
        } else {
          formState.entryContactPersonId = objData.personId;
        }
      }
    })
    .catch(() => {
      errors.entryContactMemberId = 'Entry Contact Member Id is not valid';
    });

  return errors;
};

const OmeSubmitValidation = async (formState) => {
  let errors = await localValidate(formState);
  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default OmeSubmitValidation;