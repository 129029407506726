import { DEFAULT_ID } from '../../../utils/Constants';
import { isValidName } from '../../../utils/validation';

export const localValidate = (formState) => {
  let errors = {};

  // First Name
  if (formState.firstName.trim() === '') {
    errors.firstName = 'First Name is required';
  } else if (!isValidName(formState.firstName.trim())) {
    errors.firstName = 'First Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  // Last Name
  if (formState.lastName.trim() === '') {
    errors.lastName = 'Last Name is required';
  } else if (!isValidName(formState.lastName.trim())) {
    errors.lastName = 'Last Name cannot contain numbers, special characters, or exceed 100 characters';
  }

  // LSC
  if (formState.orgUnitId === '' || formState.orgUnitId === DEFAULT_ID) {
    if (formState.orgUnitName.trim() !== '') {
      errors.orgUnitId = 'Must select a valid LSC';
    }
  }

  return errors;
};

const SearchMemberWithLscPopupValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
}

export default SearchMemberWithLscPopupValidation;