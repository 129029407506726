import React from 'react';
import { Link } from 'react-router-dom';
import { getDomain } from '../../utils/FormatCrossUiRoute';

const MenuLink = ({ menuItem, onClick = () => { }, onClickLanding }) => {
  if (menuItem.linkUrl) {
    if (!menuItem.uIProjectName || menuItem.uIProjectName.toLowerCase() === 'external') {
      if (menuItem.linkUrl === '/landing' && menuItem.uIProjectName === 'hub-ui') {
        return <a href={menuItem.linkUrl} target="_blank" onClick={onClickLanding} rel="noreferrer">{menuItem.displayName}</a>;
      }
      else {
        return <a href={menuItem.linkUrl} target="_blank" onClick={onClick} rel="noreferrer">{menuItem.displayName}</a>;
      }
    }

    const domain = getDomain(menuItem.uIProjectName);
    if (!domain || domain === 'self') {
      if (menuItem.linkUrl === '/landing') {
        return <a href={menuItem.linkUrl} onClick={onClickLanding}>{menuItem.displayName}</a>;
      }
      else {
        return <Link to={menuItem.linkUrl} onClick={onClick}>{menuItem.displayName}</Link>;
      }
    }

    if (menuItem.linkUrl === '/landing' && menuItem.uIProjectName === 'hub-ui') {
      return <a href={`//${domain}${menuItem.linkUrl}`} onClick={onClickLanding}>{menuItem.displayName}</a>;
    }
    else {
      return <a href={`//${domain}${menuItem.linkUrl}`} onClick={onClick}>{menuItem.displayName}</a>;
    }
  } else {
    return <span>{menuItem.displayName}</span>;
  }
};

export default MenuLink;