import useStatesCombobox from './UseStatesCombobox';

import Combobox from '../Combobox';

const StatesCombobox = ({
  label, name, value, valueToMatch, error, message, onChange,
  isNorthAmerica = false, isUSA = false, isMexico = false, isCanada = false
}) => {
  const { statesData, getStateNameById } = useStatesCombobox(isNorthAmerica, isUSA, isMexico, isCanada, value, valueToMatch);

  return (
    <Combobox
      label={label}
      name={name}
      isLoading={false}
      items={statesData}
      valueToMatch={valueToMatch || getStateNameById(value)}
      error={error}
      message={message}
      onChange={onChange} />
  );
};

export default StatesCombobox;