import React, { Fragment } from 'react';

import useComboboxBase from './UseComboboxBase';

import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption }
  from '@reach/combobox';

import '@reach/combobox/styles.css';
import global from '../GlobalStyle.module.css';
import style from './Combobox.module.css';


const ComboboxBase = ({ label, items, valueToMatch, onChange, error }) => {
  const className = error ? global.Error : global.BaseInputBox;
  const { searchResults, onSelect, onValueChanged }
    = useComboboxBase(items, valueToMatch, onChange);

  return (
    <Combobox aria-label={label} onSelect={onSelect} openOnFocus={true}>
      <ComboboxInput value={valueToMatch} onChange={onValueChanged} className={className} />
      {searchResults && (
        <Fragment>
          {searchResults.length > 0 && searchResults[0].name === valueToMatch ?
            <Fragment /> :
            <ComboboxPopover className={style.ComboboxPopover}>
              {searchResults.length > 0
                ? (
                  <ComboboxList>
                    {searchResults.slice(0, 10)
                      .map((result, index) => <ComboboxOption key={index} value={`${result.name}`} className={style.ComboboxOption} />)
                    }
                  </ComboboxList>
                ) : (
                  <div className={style.NoResults}>
                    No results found
                  </div>
                )}
            </ComboboxPopover>}
        </Fragment>
      )}
    </Combobox >
  );
};

export default ComboboxBase;