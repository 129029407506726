import Constants from "../../utils/Constants";

const INITIAL_STATE = {
  formState: {
    meetId: Constants.DEFAULT_ID,
    meetSessionId: Constants.DEFAULT_ID,
    sessionOrderId: '',
    sessionTypeId: Constants.DEFAULT_ID,
    sessionTypeName: '',
    sessionDateId: Constants.DEFAULT_ID,
    sessionDate: '',
    startTime: Constants.BLANK_TIME_STRING,
    startTimeTwelveHourClock: '',
    warmUpTime: Constants.BLANK_TIME_STRING,
    warmUpTimeTwelveHourClock: '',
    meetStartDate: Constants.BLANK_DATE_STRING,
    meetEndDate: Constants.BLANK_DATE_STRING,
    isOfficialsQualifyingSession: ''
  },
  errorState: {},
  isDirty: false,
  modalState: {
    deleteButtonFirst: false,
    displayPopUp: false,
    modalTitle: 'Delete Session?',
    meetSessionId: Constants.DEFAULT_ID,
    sessionOrderId: '',
    sessionTypeName: '',
    sessionDate: Constants.BLANK_DATE_STRING
  },
  showOptionalFields: false,
  showOtsField: false,
  requiredFieldsReadOnly: false,
  meetSessionArray: [],
  sortedMeetSessionArray: [],
  saveNavLink: '',
  backNavLink: ''
};

const MeetSessionsData = {
  INITIAL_STATE
};

export default MeetSessionsData;