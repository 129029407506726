import PlusIcon from '../../icons/PlusIcon';

import global from '../../GlobalStyle.module.css';

const GridRow = ({ person, onSelectPerson }) => (
  <tr>
    <td>{person.firstName || ''} {person.middleName || ''} {person.lastName || ''}</td>
    <td>{person.parentOrgUnitCode || ''}/{person.orgUnitCode || ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={(e) => onSelectPerson(e, person.memberId)}>
        <PlusIcon />
      </button>
    </td>
  </tr>
);

const SearchMemberPopupGrid = ({ data, isLoading, onSelectPerson }) => (
  <table className={global.UsasTable}>
    <thead>
      <tr>
        <th>Name</th>
        <th>LSC/Club</th>
        <th>Select</th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="3">Loading...</td></tr>
        : Array.isArray(data) && data.length > 0
          ? data.map((person, i) => <GridRow key={i} person={person} onSelectPerson={onSelectPerson} />)
          : <tr><td colSpan="3">No Results - Person Not Found</td></tr>
      }
    </tbody>
  </table>
);

export default SearchMemberPopupGrid;