import { useEffect } from 'react';

import useMeetClassificationData from '../../../state/meet/meetClassification/UseMeetClassificationData';

const useMeetClassificationTreeView = (orgUnitId, filterId, filterMode) => {
  const { meetClassificationState, getMeetClassificationsByOrgUnitId } = useMeetClassificationData();

  useEffect(() => {
    if (orgUnitId && (orgUnitId !== meetClassificationState.orgUnitId ||
      (meetClassificationState.treeData.length === 0 && meetClassificationState.isArrayLoaded === false))) {
      getMeetClassificationsByOrgUnitId(orgUnitId, filterId, filterMode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, meetClassificationState]);

  return {
    meetClassificationState
  };
};

export default useMeetClassificationTreeView;