import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import getContactRelationshipTypes from './GetContactRelationshipTypesData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  dataAsOptionsWithNames: []
};

const ContactRelationshipData = { INITIAL_STATE, getContactRelationshipTypes };

export default ContactRelationshipData;
