import React, { Fragment } from 'react';
import useMemberContactAddressDetail from './UseMemberContactAddressDetail';

import Headings from '../headings/Headings';
import Input from '../inputs/Input';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import YesNoSwitch from '../yesNoSwitch/YesNoSwitch';
import PopUpModal from '../dialogs/PopUpModal';
import Dropdown from '../dropdowns/Dropdown';
import StatesCombobox from '../comboboxes/statesCombobox/StatesCombobox';
import CountryCombobox from '../comboboxes/countryCombobox/CountryCombobox';
import AddressValidation from '../addressValidation/AddressValidation';

import Constants from '../../utils/Constants';

const MemberContactAddressDetail = ({ acknowledgementsView, personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState }) => {
  const {
    personGeneralState,
    formState,
    errorState,
    state,
    modalState,
    validateState,
    onCountryChange,
    onCancelClicked,
    onFormValueChanged,
    onValueTextPairChanged,
    onModalCancelClicked,
    handleSubmitProxy,
    setFormData,
    putPersonGeneralContactAddresses,
    LOCAL_CONSTANTS,
    addressTypeOptions
  } = useMemberContactAddressDetail(acknowledgementsView, personAcknowledgementInfoPageViewState, setPersonAcknowledgementInfoPageViewState);

  return (
    <Fragment>
      {acknowledgementsView === true ?
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Headings.H4>{formState.personContactAddressId > 0 ? "Edit" : "Add"} Address</Headings.H4>
          </div>
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H5>Selected Member: {personAcknowledgementInfoPageViewState.selectedPerson?.firstName || ''} {personAcknowledgementInfoPageViewState.selectedPerson?.middleName || ''}  {personAcknowledgementInfoPageViewState.selectedPerson?.lastName || ''}</Headings.H5>
          </div>
        </div>
        :
        <div className="row">
          <div className="col-xs-12">
            <Headings.H3>{formState.personContactAddressId > 0 ? "Edit" : "Add"} Address</Headings.H3>
          </div>
        </div>}
      <div className="row">
        <div className="col-xs-12">
          <p><b>Contact Name:</b> {state.personContactName}</p>
        </div>
      </div>
      <form onSubmit={handleSubmitProxy} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Address Type*"
              name="addressTypeId"
              value={formState.addressTypeId}
              error={errorState.addressTypeId}
              message={errorState.addressTypeId}
              options={addressTypeOptions}
              onChange={(newValue, newValueLabel, e) => {
                e && e.target && e.target.value &&
                  onValueTextPairChanged(newValue, 'addressTypeId', newValueLabel, 'addressTypeName');
              }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <CountryCombobox
              label="Country*"
              name="country"
              valueToMatch={formState.countryName}
              error={errorState.country}
              message={errorState.country}
              onChange={(newValue, newValueLabel, e) => { onCountryChange(newValue, 'country', newValueLabel, 'countryName', e) }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Address Line 1*"
              name="address1"
              value={formState.address1}
              error={errorState.address1}
              message={errorState.address1}
              onChange={(value) => { onFormValueChanged('address1', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Address Line 2"
              name="address2"
              value={formState.address2}
              error={errorState.address2}
              message={errorState.address2}
              onChange={(value) => { onFormValueChanged('address2', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="City*"
              name="city"
              value={formState.city}
              error={errorState.city}
              message={errorState.city}
              onChange={(value) => { onFormValueChanged('city', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            {formState.country === LOCAL_CONSTANTS.USA || formState.country === LOCAL_CONSTANTS.CANADA || formState.country === LOCAL_CONSTANTS.MEXICO ?
              <StatesCombobox
                label="State*"
                name="state"
                valueToMatch={formState.state}
                error={errorState.state}
                message={errorState.state}
                onChange={(id, name) => { onValueTextPairChanged(id, 'stateCode', name, 'state'); }}
                isUSA={formState.country === LOCAL_CONSTANTS.USA}
                isCanada={formState.country === LOCAL_CONSTANTS.CANADA}
                isMexico={formState.country === LOCAL_CONSTANTS.MEXICO} />
              : <Fragment />
            }
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Zip Code*"
              name="postalCode"
              value={formState.postalCode}
              error={errorState.postalCode}
              message={errorState.postalCode}
              onChange={(value) => { onFormValueChanged('postalCode', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Primary Address?"
              name="isPrimary"
              checked={formState.isPrimary === true}
              error={errorState.isPrimary}
              message={errorState.isPrimary}
              onChange={(value) => { onFormValueChanged('isPrimary', value); }}
              disabled={state.disablePrimaryCheckbox === true} />
          </div>
        </div>
        <AddressValidation formState={formState} setFormData={setFormData} />
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={personGeneralState.isObjLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={personGeneralState.isSaving} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCancelClicked}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Current Primary Address:</b> {modalState.currentPrimaryInfo}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={(e) => putPersonGeneralContactAddresses(true)}>Yes</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCancelClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment >
  );
};

export default MemberContactAddressDetail;