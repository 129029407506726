import { useState } from 'react';

import validate from './SearchMemberPopupValidation';

import useMemberLookupData from '../../../state/memberLookup/UseMemberLookupData';

import UseForm from '../../../utils/UseForm';
import { formatDate } from '../../../utils/DateFunctions';

const INITIAL_STATE = {
  displayPopup: false
};

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  birthDate: formatDate(new Date())
};

const useSearchMemberPopup = (viewFormState, setViewFormData, memberIdName) => {
  const { memberLookupState, getMemberLookupByBirthDate } = useMemberLookupData();
  const { formState, errorState, updateFormState, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onDisplayPopupClicked = () => {
    setState({ ...state, displayPopup: true });
  };

  const onHidePopupClicked = () => {
    setState({ ...state, displayPopup: false });
  };

  const onSelectPerson = (e, memberId) => {
    e?.preventDefault();

    setViewFormData({ ...viewFormState, [memberIdName]: memberId }, true);
    setState({ ...state, displayPopup: false });
  };

  function onSubmitFormCallback(formState) {
    getMemberLookupByBirthDate(formState.firstName, formState.lastName, formState.birthDate);
  }

  return {
    state,
    memberLookupState,
    popupFormState: formState,
    popupErrorState: errorState,
    updatePopupFormState: updateFormState,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectPerson
  };
};

export default useSearchMemberPopup;