import HierarchicalDataUtils from './HierarchicalDataUtils';

const CERT = 'Certifications';
const CERT_EXPIRED_STATUS = 'Expired';
const CERT_NOT_COMPLETED_STATUS = 'Not Completed';

/**
 * @param {String} certification 
 * @returns {String}
 */
const formatCertificationKeyName = (certification) => {
  const certTypeStringLength = certification.length - CERT.length;

  return `${certification.slice(0, 1).toUpperCase()}${certification.slice(1, certTypeStringLength)} ${CERT}`;
};

/**
 * @param {Array} certs 
 * @returns {String}
 */
const determineCertStatus = (certs) => {
  let certStatus = '';
  let isExpired = false;
  let isNotComplete = false;

  if (Array.isArray(certs) && certs.length > 0) {
    certs.forEach(cert => {
      if (cert.isRequired === true) {
        if (cert.status === CERT_EXPIRED_STATUS) {
          isExpired = true;
        } else if (cert.status === CERT_NOT_COMPLETED_STATUS) {
          isNotComplete = true;
        }
      }
    });
  } else {
    certStatus = 'No Certifications Available';
  }

  if (isExpired === true) {
    certStatus = 'Expired';
  }

  if (isNotComplete === true) {
    if (certStatus !== '') {
      certStatus += ' & Not Completed';
    } else {
      certStatus = 'Not Completed';
    }
  }

  if (certStatus === '') {
    certStatus = 'Current';
  }

  return certStatus;
};

/**
 * @param {String} personId 
 * @returns {Object}
 */
const createUsasPersonIfUndefinedOrNull = (personId) => {
  return {
    personId,
    maidenName: null,
    suffix: null,
    maidenNameUsedAsLastName: false,
    birthDateConfirmed: false,
    birthCertificateUrl: null,
    isUsCitizen: false,
    hispanicOrLatinoTypeId: null,
    hispanicOrLatinoType: undefined,
    personDisability: [],
    personEthnicity: [],
    personNarrative: []
  };
};


/**
 * @param {Array} treeArrayData 
 * @param {String} dataIdKey
 * @param {String} dataNameKey
 * @param {Array} selectedIdValues optional
 * @returns {Object} An object with two fields, newTreeData that is used to populate the tree component & idNamePairs that is
 * used to initially select options in the tree 
 */
const createTreeViewOptions = (treeArrayData, dataIdKey, dataNameKey, selectedIdValues) => {
  const newTreeData = HierarchicalDataUtils.MapArrayToTreeData(treeArrayData, dataIdKey, dataNameKey);
  const idNamePairs = Array.isArray(newTreeData) === true ? HierarchicalDataUtils.GetNameIdPairs(newTreeData, selectedIdValues || []) : [];

  return { newTreeData, idNamePairs };
};

const isPersonArrayDataCurrent = (personId, currentArrayData) => {
  if (currentArrayData.length === 0) {
    return false;
  } else {
    return currentArrayData[0].personId === personId; 
  }
};

const isPersonObjectDataCurrent = (personId, currentObjectData) => {
  return personId === currentObjectData.personId;
};

export { formatCertificationKeyName, determineCertStatus, createUsasPersonIfUndefinedOrNull, createTreeViewOptions, isPersonArrayDataCurrent, isPersonObjectDataCurrent };