import usePaymentsShoppingCartData from "../../../../state/payments/paymentsShoppingCart/UsePaymentsShoppingCartData";
import useShoppingCartLoadingModalData from "../../../../state/payments/paymentsShoppingCart/shoppingCartLoadingModal/UseShoppingCartLoadingModalData";
import useSecurityData from "../../../../state/security/UseSecurityData";

const useShoppingCartLoadingModal = () => {
  const { login } = useSecurityData();
  const { resetPaymentsShoppingCartState } = usePaymentsShoppingCartData();
  const { shoppingCartErrorState, resetShoppingCartErrorState } = useShoppingCartLoadingModalData();

  const onReloadShoppingCartClicked = () => {
    if (shoppingCartErrorState.redirectToLogin === true) {
      login();
    } else {
      resetShoppingCartErrorState();
      resetPaymentsShoppingCartState();
    }
  };

  return {
    shoppingCartErrorState,
    onReloadShoppingCartClicked
  };
};

export default useShoppingCartLoadingModal;