import { Fragment } from "react";

import useSearchMemberPopup from './UseSearchMemberPopup';

import SearchMemberPopupGrid from "./SearchMemberPopupGrid";

import Input from "../../inputs/Input";
import PopUpModal from "../../dialogs/PopUpModal";
import LookupIcon from "../../icons/LookupIcon";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import CalendarDateInput from "../../inputs/CalendarDateInput";

const SearchMemberPopup = ({ label = "Member ID", memberIdName = "memberId", formState, errorState,
  setFormData, onFormValueChanged }) => {
  const {
    state,
    memberLookupState,
    popupFormState,
    popupErrorState,
    updatePopupFormState,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectPerson
  } = useSearchMemberPopup(formState, setFormData, memberIdName);

  return (
    <Fragment>
      <div className="usas-extra-bottom-margin">
        <Input
          label={label}
          name="memberId"
          value={formState[memberIdName]}
          error={errorState[memberIdName]}
          message={errorState[memberIdName]}
          onChange={(value) => { onFormValueChanged(memberIdName, value); }} />
        <a onClick={() => onDisplayPopupClicked()}><LookupIcon /></a>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={400}
        title={'Member ID Lookup'}
        displayPopUp={state.displayPopup === true}
        onModalCanceled={onHidePopupClicked}>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <SearchMemberPopupGrid
              data={memberLookupState.arrayData}
              isLoading={memberLookupState.isArrayLoading}
              onSelectPerson={onSelectPerson} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <Input
              label="First Name*"
              name="firstName"
              value={popupFormState.firstName}
              error={popupErrorState.firstName}
              message={popupErrorState.firstName}
              onChange={(value) => { updatePopupFormState('firstName', value) }} />
            <Input
              label="Last Name*"
              name="lastName"
              value={popupFormState.lastName}
              error={popupErrorState.lastName}
              message={popupErrorState.lastName}
              onChange={(value) => { updatePopupFormState('lastName', value) }} />
            <CalendarDateInput
              label="Birth Date*"
              name="birthDate"
              value={popupFormState.birthDate}
              error={popupErrorState.birthDate}
              message={popupErrorState.birthDate}
              onChange={(value) => { updatePopupFormState('birthDate', value) }} />
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={handleSubmit}>Search</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onHidePopupClicked}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default SearchMemberPopup;