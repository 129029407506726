import { useContext } from 'react';

import { CourseStateContext } from './CourseContextProvider';

import CourseData from './CourseData';

import useEnvironmentVariableData from '../environmentVariable/UseEnvironmentVariableData';

const useCourseData = () => {
  const { OW_COURSE_ID } = useEnvironmentVariableData();
  const [courseState, setCourseState] = useContext(CourseStateContext);
  const getCourses = () => { CourseData.getCourses(courseState, setCourseState, OW_COURSE_ID); }

  return {
    courseState,
    getCourses
  };
};

export default useCourseData;