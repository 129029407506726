/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Headings from './headings/Headings';

import global from './GlobalStyle.module.css';

class UnexpectedError extends React.Component {
  render() {
    return (
      <div className={global.CenterText}>
        <img src={`${import.meta.env.VITE_CDN_URL}/exclamation1.png`} alt="Unexpected error" height="100" width="100" />
        <Headings.H5>Sorry, we've encountered an error.  Please try your request later.</Headings.H5>
      </div>
    );
  }
}

export default UnexpectedError;