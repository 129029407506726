import { useNavigate } from '../../wrappers/ReactRouterDom';
import useLeftNavModalData from '../../state/leftNavModal/UseLeftNavModalData';

const useLeftNav = (state) => {
  const { leftNavModalState, setLeftNavModalState } = useLeftNavModalData();
  const navigate = useNavigate();
  const items = Array.isArray(state) ? [...state] : [];

  const onClick = (e, path, validateBeforeRedirect) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (validateBeforeRedirect === true && leftNavModalState.formStateSaved === false) {
      setLeftNavModalState({
        ...leftNavModalState,
        displayPopUp: true,
        path: path
      });
    }
    else {
      navigate(path);
    }
  };

  return { items, onClick };
};

export default useLeftNav;