import { createContext, useState } from 'react';

export const PaymentsShoppingCartStateContext = createContext();

import PaymentsShoppingCartData from './PaymentsShoppingCartData';

const PaymentsShoppingCartContextProvider = ({ children }) => {
  const stateHook = useState(PaymentsShoppingCartData.INITIAL_STATE);

  return (
    <PaymentsShoppingCartStateContext.Provider value={stateHook}>
      {children}
    </PaymentsShoppingCartStateContext.Provider>
  );
};

export default PaymentsShoppingCartContextProvider;