import React, { createContext, useState } from 'react';

import ContactRelationshipTypeData from './ContactRelationshipTypesData';

export const ContactRelationshipTypesStateContext = createContext();

const ContactRelationshipTypesContextProvider = ({ children }) => {
  const stateHook = useState(ContactRelationshipTypeData.INITIAL_STATE);

  return (
    <ContactRelationshipTypesStateContext.Provider value={stateHook}>
      {children}
    </ContactRelationshipTypesStateContext.Provider>
  );
};

export default ContactRelationshipTypesContextProvider;