import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import deleteCartItemData from './DeleteCartItemData';

const CartItemData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  deleteCartItemData
};

export default CartItemData;
