import { useEffect, useState } from "react";
import useGlobalRoutesData from "../../state/security/UseGlobalRoutesData";

import formatCrossUiRoute from "../../utils/FormatCrossUiRoute";

const useErrorBoundaryFallback = (routeName) => {
  const [linkState, setLinkState] = useState('');
  const { globalRoutesState, getGlobalRoute } = useGlobalRoutesData();

  useEffect(() => {
    if (globalRoutesState.isArrayLoaded === true) {
      const targetRoute = getGlobalRoute(routeName);

      if (targetRoute) {
        setLinkState(formatCrossUiRoute(targetRoute.uIProjectName, targetRoute.route, true).route);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkState, globalRoutesState.isArrayLoaded]);

  return {
    linkUrl: linkState
  };
};

export default useErrorBoundaryFallback;