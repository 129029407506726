import { useState } from 'react';

import MeetTimesStatusData from './MeetTimesStatusData';

const useMeetTimesStatusData = () => {
  const [meetTimesStatusState, setMeetTimesStatusState] = useState(MeetTimesStatusData.INITIAL_STATE);
  const getMeetTimesStatus = (meetId) => MeetTimesStatusData.getMeetTimesStatus(meetId, meetTimesStatusState, setMeetTimesStatusState);

  return { meetTimesStatusState, getMeetTimesStatus };
};

export default useMeetTimesStatusData;