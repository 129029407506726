import React, { Fragment } from 'react';

import useTabList from './UseTabList';

import Tab from './Tab';

import style from './Tabs.module.css';

const TabList = ({tabsState, selectedTabId, onTabSelected}) => {
  const {sortedTabState, onTabClicked} = useTabList(tabsState, selectedTabId, onTabSelected);

  return Array.isArray(sortedTabState) === true && sortedTabState.length > 0
    ? <div className={style.Tabs}>
        {sortedTabState.map((navLink, i) => {
          return (Array.isArray(navLink.children) === true && navLink.children.length > 0) || navLink.linkUrl
            ? <Tab
                navLink={navLink} 
                isSelected={navLink.taxonomyId === selectedTabId} 
                onTabClicked={onTabClicked}
                key={i} />
            : <Fragment key={i} />;})}
      </div>
    : <Fragment />;
};

export default TabList;