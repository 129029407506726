const useInput = (className, global, error) => {
  let fullClassAttribute;
  if (!className) {
    fullClassAttribute = error ? global.Error : global.BaseInputBox;
  } else {
    fullClassAttribute = className + ' ' + (error ? global.Error : global.BaseInputBox);
  }

  const isReadWrite = true;

  function filterNumberInput(id) {
    //If the input is type="number", do not allow the user to paste 'E', 'e', or '+' into the input
    if (document.getElementById(id)?.value !== undefined) {
      if (document.getElementById(id).value === '' || document.getElementById(id).value.includes('E', 'e', '+')) {
        document.getElementById(id).value = (document.getElementById(id).value).replace(/^[eE+]$/, '');
      }
    }
  }

  return {
    fullClassAttribute,
    isReadWrite,
    filterNumberInput
  };
}

export default useInput;