import { useEffect } from 'react';

import ToIntIfInt from '../../utils/ToIntIfInt';

const useDropdown = (value, options, onChange, areValuesIntegers, defaultValue, setDefaults=true) => {
  const convertValue = (value) => {
    if (areValuesIntegers === false) {
      return value || defaultValue;
    } else {
      return ToIntIfInt(value) || ToIntIfInt(defaultValue);
    }
  };
  
  const onSelectionChanged = (newValue, newValueLabel, e) => {
    if (onChange && convertValue(value) !== convertValue(newValue)) {
      if (Array.isArray(options) && options.find(x => convertValue(x.id) === convertValue(newValue))) {
        onChange(convertValue(newValue), newValueLabel, e);
      } else {
        onChange(convertValue(defaultValue || -1), '', e);
      }
    }
  };

  const handleChange = (e) => {
    onSelectionChanged(
      convertValue(e.target.value),
      e.target.selectedOptions.length > 0 ? e.target.selectedOptions[0].label : '',
      e);
  };

  useEffect(() => {
    if (setDefaults === true && Array.isArray(options)) {
      if (options.length === 0) {
        onSelectionChanged(convertValue(defaultValue || -1), '');
      } else {
        onSelectionChanged(options[0].id, options[0].name);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  
  const isReadWrite = true;

  const selectedOption = options.find(x => ToIntIfInt(x.id) === ToIntIfInt(value));

  return {
    selectedValue: selectedOption ? selectedOption.id : -1,
    selectedName: selectedOption ? selectedOption.name : '',
    isReadWrite,
    handleChange
  };
};

export default useDropdown;