import { useEffect, useState } from 'react';

const INITIAL_VIEW_STATE = {
  value: '',
  options: []
};

const useListNamesDropdown = (listNames, onListNamesChanged) => {
  const [ state, setState ] = useState({...INITIAL_VIEW_STATE});

  const onChange = (newValue) => {
    setState({...state, value: newValue});
    onListNamesChanged(newValue);
  };

  useEffect(() => {
    if (!listNames.find(x => x.id === '')) {
      const allArray = [{id: '', name: 'List all reports'}] 
      const options = allArray.concat(listNames);
      setState({...state, options});
      onListNamesChanged('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listNames]);

  return {
    ...state,
    onChange
  };
};

export default useListNamesDropdown;