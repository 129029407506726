import { useEffect, useState } from 'react';
import useEmbeddedReportData from '../../state/embeddedReport/UseEmbeddedReportData';

const INITIAL_VIEW_STATE = {frameId: '', title: '', src: '', containerStyle: {}};

const useViewReport = ( reportInfoId, reportParameters) => {
  const [viewState, setViewState] = useState({...INITIAL_VIEW_STATE});
  const { getEmbeddedReport } = useEmbeddedReportData();

  useEffect(() => {
    if (reportInfoId > 0) {
      const reportPromise = getEmbeddedReport(reportInfoId, reportParameters);
      
      if (reportPromise) {
        reportPromise
          .then((stateObj) => {
            if (stateObj.objData?.reportInfoId === reportInfoId && stateObj.objData.reportUrl) {
              setViewState({
                frameId: (stateObj.objData.reportName || 'report') + '-container', 
                title: stateObj.objData.reportTitle,
                src: stateObj.objData.reportUrl,
                containerStyle: {
                  padding: 0,
                  minHeight: stateObj.objData.minIframeHeight > 0 ? stateObj.objData.minIframeHeight : 600
                }
              });
            }
          })
          .catch((e) => { setViewState({ ...INITIAL_VIEW_STATE }); });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportInfoId, reportParameters]);

  return { ...viewState };
};

export default useViewReport;