import React from 'react';
import Constants from '../../utils/Constants';
import PopUpModal from './PopUpModal';

const LoadingModal = () => {

  return (
    <PopUpModal
      widthPct={50}
      maxWidth={250}
      title={Constants.LOADING_MSG}
      displayPopUp={true} />
  );
};

export default LoadingModal;