import React, { createContext, useState } from 'react';

import BasePersonData from './BasePersonData';

export const BasePersonStateContext = createContext();

const BasePersonContextProvider = ({ children }) => {
  const stateHook = useState(BasePersonData.INITIAL_STATE);

  return (
    <BasePersonStateContext.Provider value={stateHook}>
      {children}
    </BasePersonStateContext.Provider>
  );
};

export default BasePersonContextProvider;