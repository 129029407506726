import { isValidCompetitorGender } from '../utils/MeetUtils';
import { isValidNumber, isValidBirthDate, isValidSwimTime } from '../../../../../common/utils/validation';
import Constants from '../../../../../common/utils/Constants';
import { convertTimeToMilliseconds, formatTimeForFilterObject } from '../../../../../common/utils/TimesUtils';
import { PersonHttpHelper, NO_DATA_MESSAGE } from '../../../../../common/utils/HttpHelper';
import { constants } from 'buffer';

const PERSON_ID_KEY = 'personId';
const PERSON_GENDER_KEY = 'personGender';

const eventGenders = [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Mixed" }
];

export const localValidate = (formState) => {
  const competitors = [
    { id: formState.memberId1, number: 1, gender: formState.personGender1 },
    { id: formState.memberId2, number: 2, gender: formState.personGender2 },
    { id: formState.memberId3, number: 3, gender: formState.personGender3 },
    { id: formState.memberId4, number: 4, gender: formState.personGender4 },
  ];
  let errors = {};

  //Athlete 1
  if (formState.memberId1 === '') {
    errors.memberId1 = 'Leg 1 Athlete is required';
  } else {
    const dupResultString = isDuplicateCompetitor(formState.memberId1, 1, competitors);

    if (dupResultString) {
      errors.memberId1 = dupResultString;
    }
  }

  if (formState.time1 !== '' && !isValidSwimTime(formatTimeForFilterObject(formState.time1))) {
    errors.time1 = 'Leg 1 Time must be valid';
  }

  //Athlete 2
  if (formState.memberId2 === '') {
    errors.memberId2 = 'Leg 2 Athlete is required';
  } else {
    const dupResultString = isDuplicateCompetitor(formState.memberId2, 2, competitors);

    if (dupResultString) {
      errors.memberId2 = dupResultString;
    }
  }

  if (formState.time2 !== '' && !isValidSwimTime(formatTimeForFilterObject(formState.time2))) {
    errors.time2 = 'Leg 2 Time must be valid';
  }

  //Athlete 3
  if (formState.memberId3 === '') {
    errors.memberId3 = 'Leg 3 Athlete is required';
  } else {
    const dupResultString = isDuplicateCompetitor(formState.memberId3, 3, competitors);

    if (dupResultString) {
      errors.memberId3 = dupResultString;
    }
  }

  if (formState.time3 !== '' && !isValidSwimTime(formatTimeForFilterObject(formState.time3))) {
    errors.time3 = 'Leg 3 Time must be valid';
  }

  //Athlete 4
  if (formState.memberId4 === '') {
    errors.memberId4 = 'Leg 4 Athlete is required';
  } else {
    const dupResultString = isDuplicateCompetitor(formState.memberId4, 4, competitors);

    if (dupResultString) {
      errors.memberId4 = dupResultString;
    }
  }

  if (formState.time4 !== '' && !isValidSwimTime(formatTimeForFilterObject(formState.time4))) {
    errors.time4 = 'Leg 4 Time must be valid';
  }

  //Event
  if (formState.eventId === Constants.DEFAULT_ID) {
    errors.eventId = 'Event is required';
  }

  //Event Gender
  if (formState.eventCompetitionGenderTypeId === Constants.DEFAULT_ID) {
    errors.eventCompetitionGenderTypeId = 'Event Gender is required';
  }

  //Time 
  if (formState.time === '') {
    errors.time = 'Relay Team Time is required';
  } else if (!isValidSwimTime(formatTimeForFilterObject(formState.time))) {
    errors.time = 'Relay Team Time must be valid';
  }
  else if (formState.time1 || formState.time2 || formState.time3 || formState.time4) {
    const time1Milliseconds = formState.time1 ? convertTimeToMilliseconds(formatTimeForFilterObject(formState.time1)) : 0;
    const time2Milliseconds = formState.time2 ? convertTimeToMilliseconds(formatTimeForFilterObject(formState.time2)) : 0;
    const time3Milliseconds = formState.time3 ? convertTimeToMilliseconds(formatTimeForFilterObject(formState.time3)) : 0;
    const time4Milliseconds = formState.time4 ? convertTimeToMilliseconds(formatTimeForFilterObject(formState.time4)) : 0;
    const totalTimeMilliseconds = time1Milliseconds + time2Milliseconds + time3Milliseconds + time4Milliseconds;
    const timeMilliseconds = convertTimeToMilliseconds(formatTimeForFilterObject(formState.time));

    if (totalTimeMilliseconds > timeMilliseconds) {
      errors.time = 'The sum of the Relay Leg Times cannot be greater than the Relay Team Time';
    }
  }

  //Place
  if (formState.finishPosition !== '' && (!isValidNumber(formState.finishPosition.trim()) || formState.finishPosition.trim() === '0')) {
    errors.finishPosition = 'Place (finish position) must be a number greater than zero if entered';
  }

  //Team Name
  if (formState.teamName.trim() !== '' && formState.teamName.trim().length > 200) {
    errors.teamName = 'Team Name cannot exceed 200 characters';
  }

  //Swim Date
  if (formState.swimDate === '' || formState.swimDateId === Constants.DEFAULT_ID) {
    errors.swimDateId = 'Swim Date is required';
  } else if (!isValidBirthDate(formState.swimDate)) {
    errors.swimDateId = 'Swim Date cannot be in the future'
  }

  if (formState.lscId === Constants.DEFAULT_ID) {
    errors.lscId = 'LSC is required';
  }

  if (formState.clubCode.trim().length === 0) {
    errors.clubCode = 'Club Code is required';
  } else if (formState.clubCode.trim().length > 15) {
    errors.clubCode = 'Club Code cannot exceed 15 characters';
  }

  return errors;
};

export const serverValidate = async (formState) => {
  let errors = {};

  //Member 1
  const memberId1ForUrl = formState.memberId1 ? encodeURIComponent(formState.memberId1) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${memberId1ForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        formState.personId1 = objData.personId;
        formState.personGender1 = objData.competitionGenderTypeCode;
      }
    })
    .catch((e) => {
      errors.memberId1 = 'Member Id is not valid';
    });

  //Member 2
  const memberId2ForUrl = formState.memberId2 ? encodeURIComponent(formState.memberId2) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${memberId2ForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        formState.personId2 = objData.personId;
        formState.personGender2 = objData.competitionGenderTypeCode;
      }
    })
    .catch((e) => {
      errors.memberId2 = 'Member Id is not valid';
    });

  //Member 3
  const memberId3ForUrl = formState.memberId3 ? encodeURIComponent(formState.memberId3) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${memberId3ForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        formState.personId3 = objData.personId;
        formState.personGender3 = objData.competitionGenderTypeCode;
      }
    })
    .catch((e) => {
      errors.memberId3 = 'Member Id is not valid';
    });

  const memberId4ForUrl = formState.memberId4 ? encodeURIComponent(formState.memberId4) : 'NaN';
  await PersonHttpHelper(`/Person/flat/memberId/${memberId4ForUrl}`, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        formState.personId4 = objData.personId;
        formState.personGender4 = objData.competitionGenderTypeCode;
      }
    })
    .catch((e) => {
      errors.memberId4 = 'Member Id is not valid';
    });

    let cntMale = 0;
    let cntFemale = 0;
    const genders = [formState.personGender1, formState.personGender2, formState.personGender3, formState.personGender4];
    
    if (formState.eventCompetitionGenderTypeId === 1 && !genders.every(gender => gender === 'M')) {
      errors.eventCompetitionGenderTypeId = 'All Leg Athletes must be Male';
    } else if (formState.eventCompetitionGenderTypeId === 2 && !genders.every(gender => gender === 'F')) {
      errors.eventCompetitionGenderTypeId = 'All Leg Athletes must be Female';
    } else if (formState.eventCompetitionGenderTypeId === 3) {
      genders.forEach(gender => {
        if (gender === 'M') {
          cntMale++;
        } else {
          cntFemale++;
        }
      });
    
      if (cntMale === 4 || cntFemale === 4) {
        errors.eventCompetitionGenderTypeId = 'Mixed gender relay teams must have at least 1 male and 1 female athlete';
      }
    }

  return errors;
}

const AddMeetTimeRelayValidation = async (formState) => {
  let errors = localValidate(formState);

  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default AddMeetTimeRelayValidation;

// function isDuplicateCompetitor2(competitor, competitorsList) {
//   return competitorsList.some(existingCompetitor => existingCompetitor.id === competitor.id);
// }

function isDuplicateCompetitor(targetMemberId, targetPersonNumber, competitors) {
  let matchNumbers = [];

  for (const competitor of competitors) {
    if (competitor.id === targetMemberId && competitor.number !== targetPersonNumber) {
      matchNumbers.push(competitor.number);
    }
  }

  if (matchNumbers.length > 0) {
    let errorMessage = `Leg ${targetPersonNumber} Athlete cannot be the same as`;

    if (matchNumbers.length === 1) {
      errorMessage += ` Leg ${matchNumbers[0]} Athlete`
    }
    else {
      errorMessage += ' Leg Athletes';
      for (let i = 0; i < matchNumbers.length; i++) {
        if (i === matchNumbers.length - 1) {
          errorMessage += ` and ${matchNumbers[i]}`;
        } else {
          errorMessage += ` ${matchNumbers[i]}`
        }
      }
    }

    return errorMessage;
  } else {
    return undefined;
  }
}