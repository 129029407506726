import searchTimes from './SearchTimesData';
import getTime from './GetTimeData';
import getTimesOrganization from './GetTimesOrganizationData';
import getTimesAthleteRoster from './GetTimesAthleteRosterData';
import getSession from './GetSessionData';
import putTime from './PutTimeData';
import deleteTime from './DeleteTimeData';

import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  treeData: [],
  athleteData: []
};

// const FAKE_ARRAY_DATA = [
//   {
// "eventRank": 0,
// "eventCode": "string",
// "swimTime": "string",
// "fullName": "string",
// "swimmerAge": 0,
// "teamName": "string",
// "meetName": "string",
// "swimDate": "2023-10-23T18:09:19.501Z",
// "timeStandardName": "string"
//   }
// ];

const searchTimesEventRankData = (filterObject, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.TIMES, state, setState);
    const url = `/Time/TopTimes/search?filters=${encodeURIComponent(filterObject)}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const TimeData = {
  INITIAL_STATE,
  searchTimes,
  searchTimesEventRankData,
  getTime,
  getTimesOrganization,
  getTimesAthleteRoster,
  getSession,
  putTime,
  deleteTime
};

export default TimeData;