import useReportPeriodDropdown from './UseReportPeriodDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const ReportPeriodDropdown = ({ label, name, value, error, message, onChange, organizationId, defaultOption,
  reverseDataOrder = false, currentAndFutureOptionsOnly }) => {
  const { reportPeriodSeasonState } = useReportPeriodDropdown(organizationId, defaultOption, reverseDataOrder);

  return reportPeriodSeasonState.message
    ? <span className={global.LoadingMsg}>{reportPeriodSeasonState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={currentAndFutureOptionsOnly === true ? reportPeriodSeasonState.currentAndFutureOptions : reportPeriodSeasonState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={reportPeriodSeasonState.isArrayLoading} />
    );
};

export default ReportPeriodDropdown;