import { useState } from 'react';

import PersonInboxData from './PersonInboxData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const usePersonInboxData = () => {
  const [personInboxState, setPersonInboxState] = useState(INITIAL_STATE);
  
  const postPersonInbox = (personId) => {
    PersonInboxData.postPersonInbox(personId, personInboxState, setPersonInboxState);
  }

  return {
    personInboxState,
    postPersonInbox
  };
};

export default usePersonInboxData;