import { useState } from "react";

import useAccountExpiredPasswordData from "../../../../../../state/account/accountExpiredPassword/UseAccountExpiredPasswordData";

import { SE_STEP_ONE_TIME_PASSWORD } from "../../utils/SessionEndingConstants";
import { setStoredToken } from "../../../../../../utils/TokenUtils";

const INITIAL_LOGIN_ERROR_POPUP_STATE = {
  displayPopup: false,
  errorMessage: ''
};

const useAccountCreationExpiredPassword = (popupState, updatePopupStep, onPopupClosed) => {
  const { accountExpiredPasswordState, postAccountResetPassword, resetAccountExpiredPasswordState
  } = useAccountExpiredPasswordData();
  const [loginErrorPopupState, setLoginErrorPopupState] = useState(INITIAL_LOGIN_ERROR_POPUP_STATE);

  const onCloseLoginErrorPopupClicked = () => {
    resetAccountExpiredPasswordState();
    setLoginErrorPopupState(INITIAL_LOGIN_ERROR_POPUP_STATE);
  };

  const onSubmitFormCallback = (formState) => {
    const href = popupState.loginData.href;
    const currentPassword = formState.currentPassword;
    const newPassword = formState.newPassword;

    const postAccountResetPasswordPromise = postAccountResetPassword(href, currentPassword, newPassword);

    if (postAccountResetPasswordPromise ?? false) {
      postAccountResetPasswordPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.objData?.oneTimePasswordRequired === true) {
            const loginData = {
              href: newState.objData?.href,
              device: newState.objData?.device
            };

            updatePopupStep(SE_STEP_ONE_TIME_PASSWORD, loginData, 'loginData');
          } else if (newState.objData?.errorMessage !== null) {
            setLoginErrorPopupState({
              ...loginErrorPopupState,
              displayPopup: true,
              errorMessage: newState.objData.errorMessage
            });
          } else {
            setStoredToken(newState.objData.accessToken, newState.objData.refreshToken, newState.objData.expirationDatetime, newState.objData.username);
            onPopupClosed(false);
          }
        }
      }).catch((e) => {
        setLoginErrorPopupState({
          ...loginErrorPopupState,
          displayPopup: true,
          errorMessage: e?.message
        });
      });
    }
  };

  return {
    isLoading: accountExpiredPasswordState.isObjLoading,
    username: popupState.loginData?.username,
    loginErrorPopupState,
    onSubmitFormCallback,
    onCloseLoginErrorPopupClicked
  };
};

export default useAccountCreationExpiredPassword;