import {
  BrowserRouter as BrowserRouterAlias,
  //cannot use a Routes Wrapper if Route is wrapped, because it can only contain react-router-dom's version of Route, or React.Fragment
  //Routes is implemented in './Routes' below using the useRoutes hook
  Outlet as OutletAlias,
  Link as LinkAlias,
  NavLink as NavLinkAlias,
  Navigate as NavigateAlias,
  useNavigate as useNavigateAlias,
  useLocation as useLocationAlias,
  useParams as useParamsAlias,
  useRoutes as useRoutesAlias,
  useSearchParams as useSearchParamsAlias
} from 'react-router-dom';

export const BrowserRouter = ({ children }) => <BrowserRouterAlias>{children}</BrowserRouterAlias>;
export const Outlet = () => <OutletAlias />;
export const Link = ({ className, to, children }) => <LinkAlias className={className} to={to}>{children}</LinkAlias>;
export const NavLink = ({ className, to, children }) => <NavLinkAlias className={className} to={to}>{children}</NavLinkAlias>;
export const Navigate = ({ to, replace, state }) => <NavigateAlias to={to} replace={replace} state={state}></NavigateAlias>
export const useNavigate = () => useNavigateAlias();
export const useLocation = () => useLocationAlias();
export const useParams = () => useParamsAlias();
export const useRoutes = () => useRoutesAlias();
export const useSearchParams = () => useSearchParamsAlias();

const ReactRouterDom = {
  useNavigate,
  useLocation,
  useParams,
  useRoutes,
  useSearchParams
};

export default ReactRouterDom;