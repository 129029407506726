import { MeetHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';
import Constants from '../../utils/Constants';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  data: [],
  dataAsOptionsWithNames: [],
  poolOnlyData: [],
  poolOnlyDataAsOptionsWithNames: [],
  message: ''
};

const getCourses = (state, setState, OW_COURSE_ID) => {
  if (state.isLoading !== true && state.isLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/Course`;
    MeetHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          data.unshift({ courseId: Constants.DEFAULT_ID, courseCode: '--' });
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            data: data,
            dataAsOptionsWithNames: data.map((o) => { return { id: o.courseId, name: o.courseCode } }),
            poolOnlyData: data.filter(x => x.courseId !== OW_COURSE_ID),
            poolOnlyDataAsOptionsWithNames: data.filter(x => x.courseId !== OW_COURSE_ID).map((o) => { return { id: o.courseId, name: o.courseCode } }),
            message: '',
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
};

const CourseData = { INITIAL_STATE, getCourses };

export default CourseData;