import React, { createContext, useState } from 'react';

import LeftNavModalData from './LeftNavModalData';

export const LeftNavModalStateContext = createContext();

const LeftNavModalContextProvider = ({ children }) => {
  const stateHook = useState(LeftNavModalData.INITIAL_STATE);

  return (
    <LeftNavModalStateContext.Provider value={stateHook}>
      {children}
    </LeftNavModalStateContext.Provider>
  );
};

export default LeftNavModalContextProvider;