import { useState } from 'react';

import UserAccountData from './UserAccountData';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const useUserAccountData = () => {
  const [userAccountPersonSearchState, setUserAccountPersonSearchState] = useState({...INITIAL_STATE});
  const [userAccountPersonState, setUserAccountPersonState] = useState({...INITIAL_STATE});

  const searchUserAccountPerson = (firstName, lastName, birthDate) => {
    UserAccountData.searchUserAccountPerson(firstName, lastName, birthDate, userAccountPersonSearchState, setUserAccountPersonSearchState);
  }

  const postUserAccountPersonPingAssociation = (memberId) => {
    UserAccountData.postUserAccountPerson(memberId, userAccountPersonState, setUserAccountPersonState);
  }

  const postPersonAndUserAccountPersonPingAssociation = (personObj) => {
    UserAccountData.postPersonAndUserAccountPerson(personObj, userAccountPersonState, setUserAccountPersonState);
  }

  const resetUserAccountState = () => {
    setUserAccountPersonState({ ...INITIAL_STATE });
  };

  return {
    userAccountPersonSearchState,
    userAccountPersonState,
    resetUserAccountState,
    searchUserAccountPerson,
    postUserAccountPersonPingAssociation,
    postPersonAndUserAccountPersonPingAssociation
  };
};

export default useUserAccountData;