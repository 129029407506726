import { useState } from 'react';

const useTreeView = () => {
  const isReadWrite = true;
  const [isTreeVisible, setIsTreeVisible] = useState(false);
  const openTree = () => {
    setIsTreeVisible(true);
  };
  const closeTree = () => {
    setIsTreeVisible(false);
  };
  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      // Not triggered when swapping focus between children
      closeTree();
    }
  };

  return {
    isTreeVisible,
    openTree,
    closeTree,
    handleBlur,
    isReadWrite
  };
};

export default useTreeView;
