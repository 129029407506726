import { useEffect, useState } from 'react';
import { useLocation } from '../../../../wrappers/ReactRouterDom';

import useCrossUINavigation from '../../../../state/crossUINavigation/UseCrossUINavigation';
import useMemberPersistentStorage from '../../../../state/memberPersistentStorage/UseMemberPersistentStorage';
import useSecurityData from '../../../../state/security/UseSecurityData';

import Constants from '../../../../utils/Constants';

const useBottomHeader = () => {
  const [isSmallNavOpen, setIsSmallNavOpen] = useState(false);
  const { userInfo, currentNavItem, closeSecondaryNav, onPrimaryNavItemChanged } = useSecurityData();
  const menuItems = Array.isArray(userInfo?.taxonomies)
    ? userInfo?.taxonomies.find(t => t.taxonomyName === 'MainMenu')?.taxonomyLinks || []
    : [];
  const location = useLocation();
  const { cookies, resetPersistentStorage } = useMemberPersistentStorage();
  const { navigateToLandingPage } = useCrossUINavigation();

  const onPrimaryNavClicked = (e, taxonomyId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    const navItem = menuItems.find(x => x.taxonomyId === taxonomyId);
    if (navItem) {
      onPrimaryNavItemChanged({ ...navItem });
    }
  };

  const onShowMenuClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
  };

  const closeSecondaryNavigation = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    closeSecondaryNav();
    //TODO:  Properly set focus on menu item
  };

  const toggleNav = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    setIsSmallNavOpen(!isSmallNavOpen);
  };

  const onClickLanding = () => {
    const cookiesObj = cookies[Constants.MEMBER_STORAGE_NAME];
    if (cookiesObj) {
      resetPersistentStorage();
    }

    navigateToLandingPage();
  }

  useEffect(() => {
    closeSecondaryNav();
    setIsSmallNavOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    menuItems,
    currentNavItem,
    onPrimaryNavClicked,
    onShowMenuClicked,
    closeSecondaryNav: closeSecondaryNavigation,
    isOpen: isSmallNavOpen && Array.isArray(menuItems) && menuItems.length > 0,
    toggleNav,
    onClickLanding
  };
};

export default useBottomHeader;