import { initCustomApi } from "../AccountApiHelper";

const postAccountLoginData = (username, password, state, setState) => {
  if (username && password) {
    const api = initCustomApi(state, setState);
    const fullUrl = `${import.meta.env.VITE_SECURITYAPI_URL}/Account/Login`;
    const headers = {
      "Content-Type": "application/json"
    };
    const payload = {
      username,
      password
    };

    return api?.executeApi ? api.executeApi(fullUrl, headers, 'POST', payload, 'include') : null;
  }
};

const AccountLoginData = {
  postAccountLoginData
};

export default AccountLoginData;