import React from 'react';

import LinkButton from '../buttons/LinkButton';

import global from '../GlobalStyle.module.css';

const ReportGridLarge = ({ reportList, onReportSelected }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Report Name</th>
      </tr>
    </thead>
    <tbody>
      {reportList.map((report, i) => (
        <tr key={i}>
          <td>
            <LinkButton action={(e) => {
              e?.preventDefault();
              onReportSelected(report.reportInfoId);
            }}>{report.reportTitle}</LinkButton>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default ReportGridLarge;