import useContactRelationshipTypeDropdown from './UseContactRelationshipTypeDropdown';

import Dropdown from '../Dropdown';

import global from '../../GlobalStyle.module.css';

const ContactRelationshipTypeDropdown = ({ label, name, value, error, message, onChange }) => {
  const { contactRelationshipTypeState } = useContactRelationshipTypeDropdown();

  return contactRelationshipTypeState.message
    ? <span className={global.LoadingMsg}>{contactRelationshipTypeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={contactRelationshipTypeState.dataAsOptionsWithNames}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={contactRelationshipTypeState.isArrayLoading}
      />
    );
};

export default ContactRelationshipTypeDropdown;