export const convertTimeToMilliseconds = (time) => {
  if (!time) return '';
  const hoursToMilliseconds = parseInt(time.substring(0, 2)) * 3600000;
  const minutesToMilliseconds = parseInt(time.substring(3, 5)) * 60000;
  const secondsToMilliseconds = parseInt(time.substring(6, 8)) * 1000;
  const milliseconds = parseInt(time.substring(9, 11));
  const totalMilliseconds = hoursToMilliseconds + minutesToMilliseconds + secondsToMilliseconds + milliseconds;
  return totalMilliseconds;
}

export const convertTimeToSeconds = (time) => {
  if (!time) return '';

  const [hr, min, sec] = time.split(":");
  const hoursToSeconds = parseInt(hr) * 60 * 60;
  const minsToSeconds = parseInt(min) * 60;
  const seconds = parseInt(sec.split(".")[0]);
  const milliseconds = sec.split(".")[1] || "00";

  let timeString = (hoursToSeconds + minsToSeconds + seconds).toString() + "." +milliseconds;
  
  return timeString = milliseconds.length === 1 ? timeString + "0" : timeString;
}

export const formatMillisecondsToTime = (value) => {
  const ms = value || 0;
  let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
  let minutes = Math.floor((ms / (1000 * 60)) % 60);
  let seconds = Math.floor((ms / 1000) % 60);
  const totalHoursMinutesSecondsInMilliseconds = (hours * 3600000) + (minutes * 60000) + (seconds * 1000);
  let milliseconds = ms - totalHoursMinutesSecondsInMilliseconds;
  if (milliseconds >= 900) {
    milliseconds = 100 - (1000 - milliseconds);
  }
  //Handle any carry over from adding times together
  else if (milliseconds >= 100) {
    const additionalSeconds = Math.floor((milliseconds / 100));
    seconds = seconds + additionalSeconds;
    milliseconds = milliseconds - (additionalSeconds * 100);

    if (seconds >= 60) {
      minutes = minutes + 1;
      seconds = seconds - 60;

      if (minutes >= 60) {
        hours = hours + 1;
        minutes = minutes - 60;
      }
    }
  }

  hours = (hours < 10) ? "0" + hours.toString() : hours;
  minutes = (minutes < 10) ? "0" + minutes.toString() : minutes;
  seconds = (seconds < 10) ? "0" + seconds.toString() : seconds;
  milliseconds = (milliseconds < 10) ? "0" + milliseconds.toString() : milliseconds;
  return `${hours}:${minutes}:${seconds}.${milliseconds}00000`;
}

export const formatTimeForSqlTime = (time) => {
  if (!time) return '';
  let timeDecimalFormatted = "";
  if (time.indexOf(":") > -1) {
    const [m, s] = time.split(":");
    const [ss, t] = s.split(".");

    timeDecimalFormatted = t.toString().length === 1 ? t.toString() + '0' : t.toString();
    
    return '00:' + m.toString() + ":" + ss.toString() + "." + timeDecimalFormatted;
  } else {
    const [s, t] = time.split(".");

    timeDecimalFormatted = t.toString().length === 1 ? t.toString() + '0' : t.toString();
    const secFormatted = s.toString().length === 1 ? '0' + s.toString() : s.toString();

    return '00:00:' + secFormatted + "." + timeDecimalFormatted; 
  }
}

export const formatTimeForDisplay = (time) => {
  if (!time) return '';
  let newTime = time;
  if (time?.length > 3 && time?.length < 9
    && time?.includes('.') === false
  ) {
    newTime = newTime + ".00";
  }
  const currentTime = newTime.replace(/^(00:){1,2}/g, '').replace(/\d{5}$/g, '');
  if (currentTime[0] === "0") {
    return currentTime.substring(1);
  } else {
    return currentTime;
  }
};

export const formatTimeForInput = (value) => {
  if (!value) return '';
  const currentValue = value.replace(/[^\d]/g, '');
  const timeLength = currentValue.length;
  if (timeLength < 3) {
    return currentValue;
  }
  if (timeLength < 4) {
    return `${currentValue.slice(0, 1)}.${currentValue.slice(1)}`;
  }
  if (timeLength < 5) {
    return `${currentValue.slice(0, 2)}.${currentValue.slice(2)}`;
  }
  if (timeLength < 6) {
    return `${currentValue.slice(0, 1)}:${currentValue.slice(1, 3)}.${currentValue.slice(3)}`;
  }
  if (timeLength < 7) {
    return `${currentValue.slice(0, 2)}:${currentValue.slice(2, 4)}.${currentValue.slice(4)}`;
  }
  if (timeLength < 8) {
    return `${currentValue.slice(0, 1)}:${currentValue.slice(1, 3)}:${currentValue.slice(3, 5)}.${currentValue.slice(5)}`;
  }
  return `${currentValue.slice(0, 2)}:${currentValue.slice(2, 4)}:${currentValue.slice(4, 6)}.${currentValue.slice(6, 8)}`;
};

export const formatTimeForFilterObject = (time) => {
  if (!time) return '';
  const currentValue = time.replace(/[^\d]/g, '');
  const zeroCurrentValue = "0" + currentValue;
  const timeLength = currentValue.length;
  if (timeLength < 2) {
    return `00:00:00.${zeroCurrentValue}`;
  }
  if (timeLength < 3) {
    return `00:00:00.${currentValue}`;
  }
  if (timeLength < 4) {
    return `00:00:${zeroCurrentValue.substring(0, 2)}.${zeroCurrentValue.substring(2, 4)}`;
  }
  if (timeLength < 5) {
    return `00:00:${currentValue.substring(0, 2)}.${currentValue.substring(2, 4)}`;
  }
  if (timeLength < 6) {
    return `00:${zeroCurrentValue.substring(0, 2)}:${zeroCurrentValue.substring(2, 4)}.${zeroCurrentValue.substring(4, 6)}`;
  }
  if (timeLength < 7) {
    return `00:${currentValue.substring(0, 2)}:${currentValue.substring(2, 4)}.${currentValue.substring(4, 6)}`;
  }
  if (timeLength < 8) {
    return `${zeroCurrentValue.substring(0, 2)}:${zeroCurrentValue.substring(2, 4)}:${zeroCurrentValue.substring(4, 6)}.${zeroCurrentValue.substring(6, 8)}`;
  }
  return `${currentValue.substring(0, 2)}:${currentValue.substring(2, 4)}:${currentValue.substring(4, 6)}.${currentValue.substring(6, 8)}`;
};

export const formatPlaceString = (finalPosition) => {
  if (finalPosition) {
    const position = finalPosition.toString();

    if (position) {
      if (position.length > 1) {
        const lastTwoDigits = position.slice(-2);

        if (lastTwoDigits === '11' || lastTwoDigits === '12' || lastTwoDigits === '13') {
          return `${position}th`
        }
      }
      const lastDigit = position.slice(-1);

      if (lastDigit === '1') {
        return `${position}st`;
      }
      else if (lastDigit === '2') {
        return `${position}nd`;
      }
      else if (lastDigit === '3') {
        return `${position}rd`;
      }
      else {
        return `${position}th`;
      }
    }
  }

  return finalPosition;
}