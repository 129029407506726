import React, { createContext, useState } from 'react';

import CourseData from './CourseData';

export const CourseStateContext = createContext();

const CourseContextProvider = ({ children }) => {
  const stateHook = useState(CourseData.INITIAL_STATE);

  return (
    <CourseStateContext.Provider value={stateHook}>
      {children}
    </CourseStateContext.Provider>
  );
};

export default CourseContextProvider;