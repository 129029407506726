/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import { formatPlaceString } from '../utils/MeetUtils';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';

import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';
import { formatDate } from '../../../../../common/utils/DateFunctions';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableSingleRow = ({ org }) => (
  <tr>
    <td>{org.qualifiedOrgUnitCode}</td>
  </tr>
);

const GridRowDetailTableRelay = ({ relayTeam }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>1st Leg</th>
        <th>2nd Leg</th>
        <th>3rd Leg</th>
        <th>4th Leg</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        {Array.isArray(relayTeam) && relayTeam.map((swimmer, i) => <td key={i}>{swimmer.person.firstName} {swimmer.person.lastName}</td>)}
      </tr>
    </tbody>
  </table>
);

const GridRowDetailTableSingle = ({ individualOrganization }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Organization</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(individualOrganization) && individualOrganization.map((org, i) => <DetailTableSingleRow key={i} org={org} />)}
    </tbody>
  </table>
);

const GridRow = ({ time, expandedId, onClick }) => {
  return (
    <Fragment>
      <tr>
        <td>{time.time ? formatTimeForDisplay(time.time) : 'Time'}</td>
        <td>{time.teamName ? time.teamName : (time.person ? time.person.firstName + ' ' + time.person.lastName : '')}</td>
        <td>{time.eventCompetitionGenderType.typeName || ''}</td>
        <td>{formatDate(time.swimDate) || ''}</td>
        <td>{time.event.eventName || ''}</td>
        <td>{time.session?.typeName || ''}</td>
        <td>{formatPlaceString(time.finishPosition) || ''}</td>
        <td>
          {time.swimTimeRelay?.length > 0 &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onClick(e, time.swimTimeId)}>
            {expandedId === time.swimTimeId ? <HideIcon /> : <ShowIcon />}
          </button>
        }
        </td>
      </tr>
      {time.individualOrganization?.length > 0 && expandedId === time.swimTimeId &&
        <tr className={global.Expanded}>
          <td colSpan="8">
            <GridRowDetailTableSingle individualOrganization={time.individualOrganization} />
          </td>
        </tr>
      }
      {time.swimTimeRelay?.length > 0 && expandedId === time.swimTimeId &&
        <tr className={global.Expanded}>
          <td colSpan="8">
            <GridRowDetailTableRelay
              relayTeam={time.swimTimeRelay} />
          </td>
        </tr>
      }
    </Fragment>
  );
};

const AddMeetTimeLargeGrid = ({ state, expandedId, onClick }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Time</th>
          <th>Competitor</th>
          <th>Event Gender</th>
          <th>Date</th>
          <th>Event-Course</th>
          <th>Session</th>
          <th>Place</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(state) && state.length > 0
          ? state.map((time, i) => <GridRow key={i} time={time} expandedId={expandedId} onClick={onClick} />)
          : <tr><td colSpan="8">Click 'Add Time Result' to add a time result to the meet</td></tr>}
      </tbody>
    </table>
  </Fragment>
);

export default AddMeetTimeLargeGrid;