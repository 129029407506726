import { useEffect } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';
import NavLinks from '../NavLinks';

import useMeetData from '../../../../common/state/meet/UseMeetData';
import useOtsMeetSessionsData from '../../../../common/state/officialsTrackingSystemMeetSessions/UseOtsMeetSessionsData';
import useMeetSessionsData from '../../../../common/state/meetSessions/UseMeetSessionsData';

import Constants from '../../../../common/utils/Constants';

const useMeetSessions = () => {
  const navigate = useNavigate();
  const { meetState, getMeet } = useMeetData();
  const { otsMeetSessionsState, getMeetSessions, deleteMeetSession, confirmOtsMeetSessionSave } = useOtsMeetSessionsData();
  const { resetModalState } = useMeetSessionsData();

  const onAddSession = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const meetSessionArrayCopy = JSON.parse(JSON.stringify(otsMeetSessionsState.arrayData));
    meetSessionArrayCopy.sort(function (a, b) { return a.sessionOrderId - b.sessionOrderId });
    const prevHighestSessionOrderId = meetSessionArrayCopy?.length > 0 ? Math.max(...meetSessionArrayCopy.map(o => o.sessionOrderId)) : 0;
    navigate(NavLinks.MEET_SESSIONS_DETAIL, { state: { prevHighestSessionOrderId: prevHighestSessionOrderId } });
  }

  const onEditSession = (e, session) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.MEET_SESSIONS_DETAIL, { state: { meetSessionId: session.meetSessionId } });
  }

  const onDeleteSession = (e, meetSessionId) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    resetModalState();
    deleteMeetSession(meetSessionId);
  }

  useEffect(() => {
    if (location.state && location.state?.tryGet === true) {
      getMeet(meetState.objData?.meetId);
      getMeetSessions(meetState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (otsMeetSessionsState.isArrayLoading !== true && otsMeetSessionsState.isArrayLoaded !== true) {
      getMeetSessions(meetState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetState, otsMeetSessionsState])

  useEffect(() => {
    if (otsMeetSessionsState.isSaving !== true && otsMeetSessionsState.isSaved === true) {
      getMeet(meetState.objData?.meetId);
      confirmOtsMeetSessionSave();
      getMeetSessions(meetState.objData?.meetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otsMeetSessionsState.isSaving, otsMeetSessionsState.isSaved])

  return {
    meetState,
    otsMeetSessionsState,
    isLoading: meetState.isObjLoading || otsMeetSessionsState.isArrayLoading,
    isSaving: otsMeetSessionsState.isSaving,
    Constants,
    onAddSession,
    onEditSession,
    onDeleteSession
  };
}

export default useMeetSessions;