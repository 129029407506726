import { useEffect } from 'react';

import useReportPeriodSeasonData from '../../../state/reportPeriodSeason/UseReportPeriodSeasonData';

const useReportPeriodDropdown = (organizationId, defaultOption, reverseDataOrder) => {
  const { reportPeriodSeasonState, getReportPeriodSeason } = useReportPeriodSeasonData();

  useEffect(() => {
    if (reportPeriodSeasonState.isArrayLoading !== true && organizationId !== reportPeriodSeasonState.organizationId) {
      getReportPeriodSeason(organizationId, defaultOption, reverseDataOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, reportPeriodSeasonState]);

  return {
    reportPeriodSeasonState
  };
};

export default useReportPeriodDropdown;