import searchUserAccountPerson from './SearchUserAccountPersonData';
import postUserAccountPerson from './PostUserAccountPersonData';
import postPersonAndUserAccountPerson from './PostPersonAndUserAccountPersonData'

const UserAccountData = {
  searchUserAccountPerson,
  postUserAccountPerson,
  postPersonAndUserAccountPerson
};

export default UserAccountData;