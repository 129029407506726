const SGConstants = {
  SORT_DIRECTION_NONE: 'None',
  SORT_DIRECTION_ASCENDING: 'Ascending',
  SORT_DIRECTION_DESCENDING: 'Descending',
  SORT_TYPE_ALPHABETICAL: 'Alphabetical',
  SORT_TYPE_NUMERICAL: 'Numerical',
  SORT_TYPE_LENGTH: 'Length',
  SORT_TYPE_DATE: 'Date',
  SORT_TYPE_TIME: 'Time'
};

export default SGConstants;