import React, { createContext, useState } from 'react';

import MeetSessionsData from './MeetSessionsData';

export const MeetSessionsStateContext = createContext();

const MeetSessionsContextProvider = ({ children }) => {
  const stateHook = useState(MeetSessionsData.INITIAL_STATE);

  return (
    <MeetSessionsStateContext.Provider value={stateHook}>
      {children}
    </MeetSessionsStateContext.Provider>
  );
};

export default MeetSessionsContextProvider;