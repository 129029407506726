const useMultiSelect = (options) => {
  const formattedOptions = Array.isArray(options)
    ? options.map(x => {
      return {
        id: x.id,
        name: x.name,
        children: []
      }
    }) : [];

  return {formattedOptions};
};

export default useMultiSelect;