import React, { createContext, useState } from 'react';

import FacilityData from './FacilityData';

export const FacilityStateContext = createContext();

const FacilityContextProvider = ({ children }) => {
  const stateHook = useState(FacilityData.INITIAL_STATE);

  return (
    <FacilityStateContext.Provider value={stateHook}>
      {children}
    </FacilityStateContext.Provider>
  );
};

export default FacilityContextProvider;