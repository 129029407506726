import { useContext } from 'react';

import { ShoppingCartLoadingModalStateContext } from './ShoppingCartLoadingModalContextProvider';

import ShoppingCartLoadingModalData from './ShoppingCartLoadingModalData';

const useShoppingCartLoadingModalData = () => {
  const [shoppingCartErrorState, setShoppingCartErrorState] = useContext(ShoppingCartLoadingModalStateContext);

  const setShoppingCartError = (isCartError, errorTitle = 'Shopping Cart Error', errorMessage = 'An error has occurred.', redirectToLogin = false) => {
    setShoppingCartErrorState({
      ...shoppingCartErrorState,
      isCartError,
      redirectToLogin,
      errorTitle,
      errorMessage
    });
  };

  const resetShoppingCartErrorState = () => {
    setShoppingCartErrorState(ShoppingCartLoadingModalData.INITIAL_STATE);
  };

  return {
    shoppingCartErrorState,
    setShoppingCartError,
    resetShoppingCartErrorState
  };
};

export default useShoppingCartLoadingModalData;