import { Fragment } from "react";

import SecondaryButton from "../../../buttons/SecondaryButton";

const UnavailableCart = ({ onCancelClicked }) => (
  <Fragment>
    <div className="row">
      <div className="col-xs-12">
        <p>Your cart is currently unavailable, please try again later.</p>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        {onCancelClicked && (
          <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
        )}
      </div>
    </div>
  </Fragment>
);

export default UnavailableCart;