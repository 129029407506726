import React from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {VisuallyHidden} from '@reach/visually-hidden';

import global from '../GlobalStyle.module.css';
import Tree from './Tree';
import style from './Tree.module.css';
import useTreeView from './UseTreeView';

const TreeView = ({ label, name, value, error, message, options, selectableLeavesOnly,
  singleSelect, onChange, isLoading, className }) => {
  
  const { isTreeVisible, openTree, closeTree, handleBlur, isReadWrite } = useTreeView();
    
  return (
    <div className={[global.ComponentFlex, style.TreeView].join(' ')}>
      <label className={global.UsasLabel} htmlFor={name}>{label}</label>
      <div>
        {isLoading !== true && (
          <button
            className={[global.IconButton, style.ChevronIcon].join(' ')}
            type="button"
            onClick={isTreeVisible === true ? closeTree : openTree}>
            <VisuallyHidden>{isTreeVisible === true ? 'Close' : 'Open'} tree control for {name}</VisuallyHidden>
            <FontAwesomeIcon icon={isTreeVisible === true ? faChevronUp : faChevronDown} title={`Open tree control for ${name}`} />
          </button>
        )}
        <input
          className={[className && '', style.InputBox, error ? global.Error : global.BaseInputBox].join(' ')}
          type="text"
          value={value.map(x => x.name).join(', ')}
          id={name}
          name={name}
          readOnly="readonly" />
      </div>
      <div
        className={[
            style.TreeContainer,
            isTreeVisible === true && isReadWrite === true ? style.Shown : style.Hidden
          ].join(' ')}
        onBlur={handleBlur}>
        <Tree
          name={`${name}-tree`}
          value={value}
          options={options}
          selectableLeavesOnly={selectableLeavesOnly}
          singleSelect={singleSelect}
          onChange={onChange}
          isLoading={isLoading} />
      </div>
      {error && <p className={global.ErrorMessage}>{message}</p>}
    </div>
  );
};

export default TreeView;
