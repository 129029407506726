import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import postMeetResultsData from './PostMeetResultsData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetResultsData = {
  INITIAL_STATE, postMeetResultsData
};

export default MeetResultsData;