const MaskEmail = (email) => {
  const localPart = email.substring(0, email.indexOf('@'));

  switch (localPart.length) {
    case 2:
      return email.replace(email[1], '.');
    case 3:
      return email.replace(email.substring(1, 3), '..');
    case 4:
      return email.replace(email.substring(2, 4), '..');
    case 5:
      return email.replace(email.substring(3, 5), '..');
    case 6:
      return email.replace(email.substring(3, 6), '...');
    default:
      break;
  }

  return email.replace(email.substring(4, localPart.length + 1), '...');
};

export default MaskEmail;