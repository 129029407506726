import React, { createContext, useState } from 'react';

import SelectOrgUnitData from './SelectOrgUnitData';

export const SelectOrgUnitStateContext = createContext();

const SelectOrgUnitContextProvider = ({ children }) => {
  const stateHook = useState(SelectOrgUnitData.INITIAL_STATE);

  return (
    <SelectOrgUnitStateContext.Provider value={stateHook}>
      {children}
    </SelectOrgUnitStateContext.Provider>
  );
};

export default SelectOrgUnitContextProvider;