import { useEffect } from 'react';

import useCourseData from '../../../state/course/UseCourseData';
import useEnvironmentVariableData from '../../../state/environmentVariable/UseEnvironmentVariableData';

const useCourseDropdown = () => {
  const { courseState, getCourses } = useCourseData();
  const { environmentVariableState } = useEnvironmentVariableData();

  useEffect(() => {
    if (environmentVariableState.isLoaded === true &
      courseState.isLoading !== true && courseState.isLoaded !== true) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentVariableState, courseState]);

  return {
    courseState
  };
};

export default useCourseDropdown;